import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import {
	BackButtonIcon,
	ClosePopup,
	RightIcon,
	SecurityIcon,
	UserEmailIcon,
	WrongIcon
} from 'components/commonComponents/icons';
import { notify } from 'components/commonComponents/notification/notification';
import PasswordInput from './passwordMeter';
import { AuthClassProps } from '../interface/auth';

import { API_CONFIG, HttpService } from 'services';
import { popupName } from 'components/layout/constants';
import CustomSpinner from 'components/commonComponents/customSpinner';

import '../auth.scss';

interface UserComponentProps {
	component: string;
	userEmail: string;
	showSuccessPopup?: () => void;
}
const PASSWORD_HINTS = [
	{ errMsg: 'mind. 1  Ziffer (0-9)', regex: /[0-9]/, isValid: false },
	{ errMsg: 'mind. 8 Zeichen', regex: /^(?=\S{8,}$).*/, isValid: false },
	{ errMsg: 'mind. 1 Kleinbuchstaben (a-z)', regex: /[a-z]/, isValid: false },
	{ errMsg: 'mind. 1 Großbuchstaben (A-Z)', regex: /[A-Z]/, isValid: false },
	{
		errMsg: 'Wir empfehlen Sonderzeichen!',
		regex: /[!#@$%^&*)(+=._-]/,
		isValid: false
	}
];

const UserRegister: React.FC<AuthClassProps & UserComponentProps> = (props) => {
	const popupRef = useRef<HTMLDivElement>(null);
	const { handleSubmit } = useForm({
		defaultValues: {
			Password: '',
			repeatPassword: ''
		}
	});
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState('');
	const [repeatPassword, setRepeatPassword] = useState('');
	const [showPassword, setshowPassword] = useState(false);
	const [showRepeatPassword, setShowRepeatPassword] = useState(false);
	const [passwordHint, setPasswordHint] = useState([{ errMsg: '', regex: /[]/, isValid: false }]);
	const [showPasswordHint, setShowPasswordHint] = useState(false);
	const [isConfirm, setIsConfirm] = useState(true);
	const token = location.pathname;
	const newToken = token.replace('/reset-password/', '');
	const onKeyDown = (event: any) => {};

	useEffect(() => {
		const handleOutsideClick = (event: any) => {
			if (popupRef.current && !popupRef.current.contains(event.target)) {
				setShowPasswordHint(false);
			}
		};

		if (showPasswordHint) {
			document.addEventListener('click', handleOutsideClick);
		}

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, [showPasswordHint]);

	const checkValidation = () => {
		const hints = PASSWORD_HINTS.map((item) => {
			item.isValid = new RegExp(item.regex).test(password);
			return item;
		});
		const isValid = hints.every((v) => v.isValid);
		if (!isValid) {
			// setPopup
			setShowPasswordHint(true);
			setPasswordHint(hints);
		}
		return isValid;
	};
	const onSubmit = (data: any) => {
		if (!checkValidation()) {
			return;
		}
		setLoading(true);
		if (props.component === popupName.register) {
			HttpService.post(API_CONFIG.path.addPaasword, {
				email: props.userEmail,
				password: password
			})
				.then((response) => {
					props.handlePopup(popupName.userVerification);
					notify(`${response.message}`, 'success');
				})
				.catch((err) => {
					notify(`${err.response.data.message}`, 'error');
					setLoading(false);
				});
		} else if (props.component === popupName.resetPassword) {
			HttpService.post(API_CONFIG.path.reset, {
				token: newToken,
				password: password
			})
				.then((response) => {
					notify(`${response.message}`, 'success');
					setLoading(false);
					props.handlePopup(popupName.passwordResetSuccessfully);
				})
				.catch((err) => {
					notify(`${err.response.data.message}`, 'error');
					setLoading(false);
				});
		} else {
			HttpService.put(API_CONFIG.path.changePassword, {
				newPassword: password
			})
				.then((response) => {
					notify(`${response.message}`, 'success');
					setLoading(false);
					if (props.showSuccessPopup) {
						props.showSuccessPopup();
					}
				})
				.catch((err) => {
					notify(`${err.response.data.message}`, 'error');
					setLoading(false);
				});
		}
	};
	const disableBUtton = useMemo(
		() => password.length <= 0 || password !== repeatPassword || isConfirm,
		[password, repeatPassword, isConfirm]
	);
	return (
		<div className='user-register-popup login-register-wrapper position-relative '>
			<div
				className='text--right position-absolute close-btn cursor-pointer'
				onClick={() =>
					props.component === 'register' ? props.handlePopup(popupName.loginRegister) : props.closePopup()
				}
			>
				{props.component === 'register' ? <BackButtonIcon /> : <ClosePopup />}
			</div>
			<div className='login-div login-register-div flex align__items--center justify__content--center flex--column'>
				<div className='login-tittle-wrapper '>
					<h3 className='login-register-tittle'>{`${
						props.component === 'register' ? 'Registrieren' : 'Neues Passwort setzen'
					}`}</h3>
				</div>
				<form
					onSubmit={handleSubmit((data, e) => {
						onSubmit(data);
					})}
					className='login-form-wrapper'
				>
					<div className='flex align__items--start user-email-wrapper'>
						{props.component === 'register' ? (
							<>
								<UserEmailIcon />
								<h6 className='user-email'>{props.userEmail}</h6>
							</>
						) : (
							<p>
								Geben Sie hier einfach Ihr neues Passwort ein und wiederholen die Eingabe einmal zur
								Sicherheit.
							</p>
						)}
					</div>
					<div className=' login-input form-group form-primary'>
						{props.component === 'register' && <label>Passwort festlegen</label>}
						<PasswordInput
							handleChanges={(e) => setPassword(e.target.value)}
							value={password}
							name={'Password'}
							onKeyDown={onKeyDown}
							placeholder={''}
							showPassword={showPassword}
							toggleShowPwd={() => setshowPassword(!showPassword)}
							className='login-input-box'
							errorFields={[]}
						/>
					</div>
					<div className='login-input'>
						<label>Passwort wiederholen</label>
						<PasswordInput
							handleChanges={(e) => setRepeatPassword(e.target.value)}
							value={repeatPassword}
							name={'repeatPassword'}
							onKeyDown={onKeyDown}
							placeholder={''}
							showPassword={showRepeatPassword}
							toggleShowPwd={() => setShowRepeatPassword(!showRepeatPassword)}
							className='login-input-box'
							errorFields={[]}
							from='reset'
						/>
					</div>

					<div className='confirm-wrapper'>
						<label className='confirm-container'>
							<p>
								Ich bestätige, dass ich die
								<span style={{ color: '#FFB000' }}> AGBs </span> und
								<span style={{ color: '#FFB000' }}> Datenschutzrichtlinien</span> von{' '}
								<span style={{ color: '#FFB000' }}> immoweb.de </span>
								gelesen und verstanden habe und diese hiermit akzeptiere.
							</p>
							<input type='checkbox' onClick={() => setIsConfirm(!isConfirm)} />
							<span className='checkmark'></span>
						</label>
					</div>
					<div className='flex align__items--center justify__content--center login-button-wrapper'>
						<button
							type='submit'
							value='register'
							className={`login-button  ${disableBUtton ? 'disabled-btn' : 'common-button'} `}
							disabled={disableBUtton || loading}
						>
							{loading ? (
								<CustomSpinner animation='border' className='spinner-border-sm' />
							) : (
								`${props.component === popupName.register ? 'Registeren' : 'Passwort setzen'}`
							)}
						</button>
					</div>
				</form>
				{props.component === 'register' && (
					<div className='flex align__items--start justify__content--center security-wrapper '>
						<SecurityIcon width='10px' height='12px' />
						<h6>Diese Verbindung ist sicher.</h6>
					</div>
				)}
				{showPasswordHint && (
					<div className='password-error-message-wrapper position-absolute'>
						<div className='body-wrapper login-register-wrapper position-relative '>
							<div
								className='text--right position-absolute close-btn cursor-pointer'
								onClick={() => setShowPasswordHint(false)}
							>
								<ClosePopup />
							</div>
							<div className='login-div login-register-div' ref={popupRef}>
								<div className='login-tittle-wrapper '>
									<h3 className='login-register-tittle'>Anforderungen Passwort</h3>
								</div>
								{passwordHint.map((hint, index) => {
									return (
										<div className='flex align__items--center' key={index}>
											{hint.isValid && <RightIcon />}
											{!hint.isValid && <WrongIcon />}
											<p
												className={`password-hint-message ${
													hint.isValid ? '' : 'wrong-message'
												}`}
											>
												{hint.errMsg}
											</p>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
export default UserRegister;
