import { CSSProperties } from 'react';

export const reactSelectStyles = {
	option: (base: CSSProperties, state: any) => ({
		...base,
		color: '#2C344E',
		backgroundColor: state.isSelected
			? 'rgba(217, 217, 217, 0.37)'
			: !state.isSelected && state.isFocused
			? 'transparent'
			: 'transparent',
		fontWeight: state.isSelected ? '700' : '400',
		cursor: 'pointer',
		fontSize: '14px',
		lineHeight: 1,
		// width: "fit-content",
		borderRadius: '4px',
		padding: '9px 20px',
		margin: '5px 0px 5px 0px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		// margin: "0",
		':active': {
			backgroundColor: 'rgba(217, 217, 217, 0.37)',
			fontWeight: state.isSelected ? '700' : '400'
		},
		':hover': {
			backgroundColor: !state.isSelected ? 'rgba(217, 217, 217, 0.37)' : 'rgba(217, 217, 217, 0.37)',
			opacity: 0.8,
			borderRadius: '4px',
			transition: 'all 0.2s linear'
		},
		':focus': {
			backgroundColor: 'rgba(217, 217, 217, 0.37)',
			outline: 0,
			borderRadius: '4px',
			fontWeight: 700
		}
	}),

	menu: (base: CSSProperties) => ({
		...base,
		zIndex: 3,
		marginTop: '-12px',
		backgroundColor: '#FFF',
		border: '0.5px solid #AEAEAE',
		borderRadius: '8px',
		width: '181px',
		boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
		padding: '18px 9px 0 5px',
		right: '7px',
		'.react-select__menu-list': {
			maxHeight: '157px'
		},
		'.css-10rgtze-option': {
			margin: '0',
			padding: '0 0 16px 0',
			color: ' #2C344E',
			fontSize: '16px',
			lineHeight: '23px',
			fontWeight: '400',
			width: '100%',
			':hover': {
				backgroundColor: '#FFF',
				opacity: '1'
			}
		},
		'.css-87mdz8-option': {
			margin: '0',
			padding: '0 0 16px 0',
			color: '#2c344e',
			backgroundColor: '#FFF',
			fontSize: '16px',
			lineHeight: '23px',
			fontWeight: '400',
			border: 'none',
			':hover': {
				backgroundColor: 'rgba(217, 217, 217, 0.37)',
				opacity: '1',
				border: 'none'
			}
		}
	}),

	menuList: (base: CSSProperties) => ({
		...base,
		padding: 0,
		backgroundColor: '#FFF'
	}),
	clearIndicator: (base: CSSProperties) => ({
		...base,
		cursor: 'pointer'
	}),
	dropdownIndicator: (base: CSSProperties, state: any) => ({
		...base,
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
		color: state.selectProps.menuIsOpen && '#2C344E',
		padding: '0 16px ',

		':hover': {
			color: '#2C344E'
		}
	}),
	indicatorSeparator: () => ({
		width: '0'
	}),
	control: (base: CSSProperties, state: any) => ({
		// none of react-selects styles are passed to <View />
		display: 'flex',
		border: '0.5px solid #AEAEAE',
		backgroundColor: 'transparent',
		maxWidth: '320px',
		width: '200px',
		height: '100%',
		minHeight: '45px',
		cursor: 'pointer',
		fontSize: '14px',
		opacity: state.isDisabled ? '0.5' : '1',
		'.css-319lph-ValueContainer': {
			padding: '10px 0 10px 18px'
		}
	}),
	input: (base: CSSProperties, state: any) => ({
		...base,
		color: '#2C344E',
		fontSize: '16px',
		fontWeight: '400',
		lineHeight: '23px'
	}),
	placeholder: (base: CSSProperties, state: any) => ({
		...base,
		color: '#2C344E',
		fontSize: '16px',
		lineHeight: '23px',
		fontWeight: '400'
	}),
	singleValue: (base: CSSProperties, state: any) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return {
			...base,
			opacity: opacity,
			transition: transition,
			color: '#2C344E',
			fontSize: '16px',
			fontWeight: '400',
			lineHeight: '23px'
		};
	}
};

export const roomsSelectStyles = {
	option: (base: CSSProperties, state: any) => ({
		...base,
		color: '#2C344E',
		backgroundColor: state.isSelected
			? 'rgba(217, 217, 217, 0.37)'
			: !state.isSelected && state.isFocused
			? 'transparent'
			: 'transparent',
		fontWeight: state.isSelected ? '700' : '400',
		cursor: 'pointer',
		fontSize: '14px',
		lineHeight: 1,
		// width: "fit-content",
		borderRadius: '4px',
		padding: '9px 20px',
		margin: '5px 0px 5px 0px',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		// margin: "0",
		':active': {
			backgroundColor: 'rgba(217, 217, 217, 0.37)',
			fontWeight: state.isSelected ? '700' : '400'
		},
		':hover': {
			backgroundColor: !state.isSelected ? 'rgba(217, 217, 217, 0.37)' : 'rgba(217, 217, 217, 0.37)',
			opacity: 0.8,
			borderRadius: '4px',
			transition: 'all 0.2s linear'
		},
		':focus': {
			backgroundColor: 'rgba(217, 217, 217, 0.37)',
			outline: 0,
			borderRadius: '4px',
			fontWeight: 700
		}
	}),
	menu: (base: CSSProperties) => ({
		...base,
		zIndex: 3,
		marginTop: '-12px',
		backgroundColor: '#FFF',
		border: '0.5px solid #AEAEAE',
		borderRadius: '8px',
		width: '136px',
		boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
		padding: '18px 9px 0 5px',
		right: '15px',
		'.react-select__menu-list': {
			maxHeight: '157px'
		},
		'.css-10rgtze-option': {
			margin: '0',
			padding: '0 0 16px 0',
			color: ' #2C344E',
			fontSize: '12px',
			lineHeight: '23px',
			fontWeight: '400',
			width: '100%',
			':hover': {
				backgroundColor: '#FFF',
				opacity: '1'
			}
		},
		'.css-87mdz8-option': {
			margin: '0',
			padding: '0 0 16px 0',
			color: '#2c344e',
			backgroundColor: '#FFF',
			fontSize: '12px',
			lineHeight: '23px',
			fontWeight: '400',
			border: 'none',
			':hover': {
				backgroundColor: '#FFF',
				opacity: '1',
				border: 'none'
			}
		}
	}),

	menuList: (base: CSSProperties) => ({
		...base,
		padding: 0,
		backgroundColor: '#FFF'
	}),
	clearIndicator: (base: CSSProperties) => ({
		...base,
		cursor: 'pointer'
	}),
	dropdownIndicator: (base: CSSProperties, state: any) => ({
		...base,
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
		color: state.selectProps.menuIsOpen && '#2C344E',
		padding: '0 16px ',

		':hover': {
			color: '#2C344E'
		}
	}),
	indicatorSeparator: () => ({
		width: '0'
	}),
	control: () => ({
		// none of react-selects styles are passed to <View />
		display: 'flex',
		border: 'none',
		backgroundColor: 'transparent',
		width: '90px',
		height: '100%',
		minHeight: '45px',
		cursor: 'pointer',
		fontSize: '14px',
		'.css-319lph-ValueContainer': {
			textAlign: 'center'
		}
	}),
	input: (base: CSSProperties, state: any) => ({
		...base,
		color: '#2C344E',
		fontSize: '12px',
		fontWeight: '400',
		lineHeight: '23px'
	}),
	placeholder: (base: CSSProperties, state: any) => ({
		...base,
		color: '#2C344E',
		fontSize: '12px',
		lineHeight: '23px',
		fontWeight: '400'
	}),
	singleValue: (base: CSSProperties, state: any) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return {
			...base,
			opacity: opacity,
			transition: transition,
			color: '#2C344E',
			fontSize: '12px',
			fontWeight: '400',
			lineHeight: '23px'
		};
	}
};

export const addobjectSelectStyles = {
	...reactSelectStyles,
	control: () => ({
		// none of react-selects styles are passed to <View />
		display: 'flex',
		border: '0.5px solid #AEAEAE',
		backgroundColor: 'transparent',
		maxWidth: '180px',
		width: '100%',
		height: '100%',
		minHeight: '36px',
		cursor: 'pointer',
		fontSize: '14px',
		borderRadius: '6px',
		'.css-319lph-ValueContainer': {
			padding: '0px 0px 0px 5px'
		}
	}),
	menu: (base: CSSProperties) => ({
		...base,

		zIndex: 20,
		marginTop: '-12px',
		backgroundColor: '#FFF',
		border: '0.5px solid #AEAEAE',
		borderRadius: '8px',
		width: '181px',
		boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
		padding: '18px 9px 0 5px',
		right: '0px',
		top: '44px',
		'.react-select__menu-list': {
			maxHeight: '157px'
		},
		'.css-10rgtze-option': {
			margin: '0',
			padding: '0 0 16px 0',
			color: ' #2C344E',
			fontSize: '16px',
			lineHeight: '23px',
			fontWeight: '400',
			width: '100%',
			':hover': {
				backgroundColor: '#FFF',
				opacity: '1'
			}
		},
		'.css-87mdz8-option': {
			margin: '0',
			padding: '0 0 16px 0',
			color: '#2c344e',
			backgroundColor: '#FFF',
			fontSize: '16px',
			lineHeight: '23px',
			fontWeight: '400',
			border: 'none',
			':hover': {
				backgroundColor: 'rgba(217, 217, 217, 0.37)',
				opacity: '1',
				border: 'none'
			}
		}
	}),
	placeholder: (base: CSSProperties, state: any) => ({
		...base,
		color: '#838383',
		fontSize: '14px',
		lineHeight: '23px',
		fontWeight: '400'
	})
};
