import React, { FC, MutableRefObject } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface IProps {
	quillRef: MutableRefObject<ReactQuill | null>;
	errorTxt: string;
	className?: string;
}

const modules = {
	toolbar: [
		['bold', 'italic'],
		[{ list: 'ordered' }, { list: 'bullet' }]
	]
};

const TextEditor: FC<IProps> = ({ quillRef, errorTxt, className, ...rest }) => (
	<>
		<ReactQuill className={className || ''} ref={quillRef} modules={modules} {...rest} />
		{errorTxt && <span className='error-message'>{errorTxt}</span>}
	</>
);

export default TextEditor;
