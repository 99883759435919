import React from 'react';
import TitleImageSection from '../components/titleImageSection';
import appraiseImage from '../../../assets/images/appraise-image.png';
import { appraisePhotos, marketReport } from '../utils/constants';
import MetaDecorator from 'shared/meta/metaDecorator';

const AppraisePage = () => {
	return (
		<div className='appraise-section public-page-font'>
			<MetaDecorator title='Gutachten - Immoweb | Immobilien, Wohnungen und Häuser bei immoweb' />

			<TitleImageSection backgroundImage={appraiseImage} titleText={'Gutachten'} />
			<section className='section-width information-section'>
				<h1 className='text--center'>
					Professionelle Verkehrswertgutachten: <br />
					Ihr Weg zum Erfolg
				</h1>
				<p className='text--center'>
					Ein Verkehrswertgutachten für eine Immobilie ist ein detailliertes und objektives Dokument, das von
					einem qualifizierten Sachverständigen erstellt wird. Es zielt darauf ab, den aktuellen Marktwert
					einer Immobilie zu ermitteln. Das Gutachten basiert auf einer umfassenden Analyse verschiedener
					Faktoren, die den Wert der Immobilie beeinflussen. Dazu gehören Aspekte wie die Lage, die Größe, der
					Zustand, die Ausstattung und die allgemeine Marktentwicklung. Das Wertgutachten verwendet anerkannte
					Bewertungsmethoden, um eine genaue Schätzung des Immobilienwerts zu liefern. Dies kann sowohl für
					den Verkauf als auch für andere Zwecke wie die Erbschaftssteuer, die Vermögensaufteilung oder die
					Finanzierung von Immobilien relevant sein.
				</p>
			</section>
			<section className='appraise-photos'>
				<div className='section-width card-wrapper'>
					{appraisePhotos.map((item) => (
						<div className='about-us-card' key={item.title}>
							<img className='card-image' src={item.image} alt={item.title} />
							<div className='card-title-wrapper flex align__items--center justify__content--center'>
								<h1>{item.title}</h1>
							</div>
						</div>
					))}
				</div>
			</section>
			<section className='section-width certificate-needed-section market-report-section'>
				<h1 className='text--center title-text '>
					Vorteile eines Verkehrswertgutachtens
					<br /> beim Verkauf
				</h1>
				<div className='flex justify__content--center certificate-cards'>
					{marketReport.map((item, i) => (
						<div className='certificate-needed-card' key={i}>
							<h1>{item.title}</h1>
							<p>{item.subTitle}</p>
						</div>
					))}
				</div>
			</section>
			<section className='section-width information-section'>
				<h1 className='text--center'>Unser Angebot</h1>
				<p className='text--center'>
					Die DG Deutsche Gutachten AG ist Ihr verlässlicher Partner für transparente und professionelle
					Verkehrswertgutachten in ganz Deutschland. Kontaktieren (
					<a href='https://www.deutsche-gutachten.de/verkehrsgutachten' target={'_blank'} rel='noreferrer'>
						https://www.deutsche-gutachten.de/verkehrsgutachten
					</a>
					) Sie uns noch heute, um mehr über unsere Dienstleistungen zu erfahren und Ihren ersten Schritt zu
					einer fundierten Immobilienbewertung zu machen.
				</p>
			</section>
		</div>
	);
};

export default AppraisePage;
