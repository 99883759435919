import React, { FC, useCallback, useEffect, useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

import { AddPropertyIcon, DeleteButton, EditButton, HideShowButton } from 'components/commonComponents/icons';
import { notify } from 'components/commonComponents/notification/notification';
import PropertyCard from 'components/finder/components/propertyCard';
import MyProperty from 'components/newMyProperty/container/myProperty';
import CommonWaringPopup from '../components/commonWaringPopup';
import PropertyFilter, { Params } from '../components/propertyFilter';
import Modal from 'components/modal';
import { setMyProperties } from 'components/finder/utils/commonFunctions';
import CustomSpinner from 'components/commonComponents/customSpinner';
import SkeletonLoading from 'components/finder/components/skeletonLoading';

import { Property } from '../utils/propertyPage.interface';
import { myPropertyTypes, propertyOptions } from 'utils';

import { API_CONFIG, HttpService } from 'services';

const PropertyPage: FC = () => {
	const recordPerPage = 10;
	const [propertyData, setPropertyData] = useState([] as Property[]);
	const [imageLoadedList, SetImageLoadedList] = useState<string[]>([]);
	const [hoveredId, setHoveredId] = useState('');
	const [editProperty, setEditProperty] = useState({
		isEdit: false,
		listItem: {}
	});
	const [waringPopup, setWaringPopup] = useState<{
		titleTex?: string;
		subTitleTex?: string;
		onSubmit: () => void;
	}>();
	const [isWarningPopup, setIsWarningPopup] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isReset, setIsReset] = useState(false);
	const [isPropertyList, setIsPropertyList] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [pageOption, setPageOption] = useState({
		currentPage: 1,
		totalPages: 0,
		totalRecord: 0
	});

	useEffect(() => {
		getUserPropertyDetail();
	}, [pageOption.currentPage]);

	const getUserPropertyDetail = (params?: Params) => {
		setIsPropertyList(true);
		HttpService.post(API_CONFIG.path.userPropertyDetail, {
			...params,
			currentPage: pageOption.currentPage,
			recordPerPage: recordPerPage
		})
			.then((response) => {
				const combinedResult = setMyProperties(response.data.data);
				setPropertyData(combinedResult);
				setPageOption({
					...pageOption,
					totalPages: response.data.totalPages,
					totalRecord: response.data.total
				});
				setIsReset(false);
				setIsPropertyList(false);
			})
			.catch((err) => {
				console.log('err:', err);
				setIsReset(false);
				setIsPropertyList(false);
			});
	};

	const getImageLoadedList = (id: string) => {
		if (imageLoadedList.includes(id)) {
			return;
		}
		SetImageLoadedList((previousState) => [...previousState, id]);
	};

	const PropertyDetail = (id: string) => {
		setIsEdit(true);
		HttpService.get(`${API_CONFIG.path.objectDetails}/${id}`)
			.then((response) => {
				setEditProperty({ isEdit: true, listItem: response.data });
				setIsEdit(false);
			})
			.catch((err) => {
				console.log('err:', err);
				setIsEdit(false);
			});
	};
	const DeleteProperty = (id: string) => {
		setIsLoading(true);
		HttpService.deleteRequest(`${API_CONFIG.path.deleteNewUserProperty}/${id}`)
			.then((response) => {
				handlePropertyChange(id, response.data, 'delete');
				setIsWarningPopup(false);
				setIsLoading(false);
				notify(`${response.message}`, 'success');
			})
			.catch((err) => {
				notify(`${err.response.data.message}`, 'error');
				setIsLoading(false);
			});
	};

	const handlePopup = (id: string, actionName: string) => {
		if (actionName === 'delete') {
			setWaringPopup({
				titleTex: 'Bist du sicher, dass du dieses Angebot löschen willst?',
				onSubmit: () => DeleteProperty(id)
			});
		} else if (actionName === 'Inactive') {
			setWaringPopup({
				titleTex: 'Bist du sicher. dass du dieses Angebot verstecken möchtest?',
				subTitleTex: 'Das Angebot taucht nicht mehr in der Suche auf und kann wieder aktiviert werden.',
				onSubmit: () => propertyStatus(id, actionName)
			});
		} else {
			setWaringPopup({
				titleTex: 'Möchten Sie dieses Angebot wieder aktiv machen?',
				subTitleTex: 'Das Angebot wird wieder veröffentlicht und erscheint in der Suche',
				onSubmit: () => propertyStatus(id, actionName)
			});
		}
		setIsWarningPopup(true);
	};

	const propertyStatus = (id: string, status: string) => {
		setIsLoading(true);

		HttpService.put(`${API_CONFIG.path.userPropertyStatus}/${id}?status=${status}`)
			.then((response) => {
				setIsWarningPopup(false);
				handlePropertyChange(response.data.id, response.data, status);
				setIsLoading(false);
				notify(`${response.message}`, 'success');
			})
			.catch((err) => {
				notify(`${err.response.data.message}`, 'error');
				setIsWarningPopup(false);
				setIsLoading(false);
			});
	};

	const handlePropertyChange = useCallback(
		(id: string, data: Property, action: string) => {
			const list = [...propertyData];
			const sleetedIndex = list.findIndex((property) => property.id === id);
			if (sleetedIndex !== -1) {
				if (action === 'delete') {
					list.splice(sleetedIndex, 1);
				} else {
					list[sleetedIndex] = data;
				}
				setPropertyData(setMyProperties(list));
			}
		},
		[propertyData, setPropertyData]
	);

	return (
		<div className='user-property'>
			<div className='flex align__items--center justify__content--end property-search-div'></div>
			<div>
				<PropertyFilter
					getUserPropertyDetail={getUserPropertyDetail}
					isReset={isReset}
					setIsReset={setIsReset}
					setEditProperty={setEditProperty}
					totalRecord={propertyData.length}
				/>
			</div>
			<hr className='contact-frame' />
			{isPropertyList && (
				<div
					style={{
						backgroundColor: 'rgba(0, 0, 0, 0.1)',
						minHeight: 'calc(100vh - 100px)'
					}}
				>
					<div>
						<div
							className={`property-card-wrapper d-flex flex-wrap justify-content-start `}
							style={{
								maxWidth: '100%',
								pointerEvents: 'none',
								padding: '10px 20px 0 10px'
							}}
						>
							<SkeletonLoading
								className={`column-wrapper w--25 property-card-responsive skeleton-property-card`}
							/>
						</div>
					</div>
				</div>
			)}
			{!isPropertyList && (
				<div className='property-card-wrapper user-property-card '>
					<div className='d-flex flex-wrap justify-content-start'>
						{propertyData &&
							propertyData.length > 0 &&
							propertyData.map((item: any, index: number) => {
								return (
									<LazyLoadComponent visibleByDefault={true} key={index} delayTime={2000}>
										<div className='user-property-card-div position-relative '>
											<PropertyCard
												isFullMapScreen={false}
												listItem={item}
												highlightclass={''}
												addHighLightClass={(id) => setHoveredId(id)}
												removeHighlight={() => setHoveredId('')}
												imageLoadedList={imageLoadedList}
												index={index}
												hoveredId={hoveredId}
												setImageLoadedList={(id) => getImageLoadedList(id)}
												changeScrollTop={() => console.log('changeScrollTop')}
												isShowExtraFeatures={true}
											/>
											{item.marketingType && (
												<div
													className={`property-type position-absolute ${
														item.marketingType === propertyOptions.SELL
															? 'property-sell'
															: ''
													} `}
												>
													<p>{myPropertyTypes[item.marketingType]}</p>
												</div>
											)}
											<div className='position-absolute property-button-div'>
												<div
													className='property-button cursor-pointer'
													onClick={() =>
														handlePopup(
															item.id,
															item.status === 'Active' ? 'Inactive' : 'Active'
														)
													}
													title={item.status === 'Active' ? 'deaktivieren' : 'aktivieren'}
												>
													<HideShowButton width='25px' height='25px' />
												</div>
												{/* <div
													className={`property-button ${
														item.status === 'Inactive' ? 'not-allowed ' : 'cursor-pointer'
													}`}
												>
													<Link to={`/object-detail/${item.id}`} target='_blank'>
														<ViewDetailsButton width='25px' height='25px' />
													</Link>
												</div> */}
												<div
													className={`property-button cursor-pointer`}
													onClick={(e) => {
														e.stopPropagation();
														PropertyDetail(item.id);
													}}
													title={'bearbeiten'}
												>
													<EditButton width='25px' height='25px' />
												</div>
												<div
													className='property-button cursor-pointer'
													onClick={() => {
														handlePopup(item.id, 'delete');
													}}
													title={'löschen'}
												>
													<DeleteButton width='25px' height='25px' />
												</div>
											</div>
											{item.status === 'Inactive' && (
												<Link to={`/objekt/${item.id}`} target='_blank'>
													<div className='inactive-property position-absolute'></div>
												</Link>
											)}
										</div>
									</LazyLoadComponent>
								);
							})}
						<div
							className='column-wrapper w--25 add-property-div property-card-responsive cursor-pointer'
							onClick={() => setEditProperty({ isEdit: true, listItem: {} })}
						>
							<div className='property-card flex align__items--center justify__content--center'>
								<div className='flex align__items--center flex--column'>
									<AddPropertyIcon width='70px' height='70px' />
									<p>Inserat hinzufügen</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			{propertyData && pageOption.totalRecord > recordPerPage && (
				<div>
					<div className={`page-bottom-fixed pagination-width mt-4 `}>
						<ReactPaginate
							previousLabel={<i className='fa fa-caret-left next-page-icon' aria-hidden='true'></i>}
							previousClassName={`paginate-btn ${pageOption.currentPage === 1 ? 'prev-class' : ''}`}
							nextClassName={`paginate-btn ${
								pageOption.currentPage === pageOption.totalPages ? 'prev-class' : ''
							}`}
							nextLabel={<i className='fa fa-caret-right next-page-icon' aria-hidden='true'></i>}
							breakLabel={'...'}
							breakClassName={'break-me'}
							pageCount={pageOption.totalPages}
							marginPagesDisplayed={2}
							pageRangeDisplayed={5}
							onPageChange={(e) => setPageOption({ ...pageOption, currentPage: e.selected + 1 })}
							containerClassName={'pagination'}
							activeClassName={'active'}
							initialPage={0}
							disableInitialCallback={true}
						/>
					</div>
				</div>
			)}
			{editProperty.isEdit && (
				<MyProperty
					closeModal={() => setEditProperty({ ...editProperty, isEdit: false })}
					objectDetail={editProperty.listItem}
					resetFilter={getUserPropertyDetail}
				/>
			)}
			{isWarningPopup && (
				<CommonWaringPopup
					titleTex={waringPopup?.titleTex}
					subTitleTex={waringPopup?.subTitleTex}
					onSubmit={waringPopup?.onSubmit || (() => {})}
					cancel={() => setIsWarningPopup(false)}
					isLoading={isLoading}
				/>
			)}
			{isEdit && (
				<Modal className={'flex align__items--center justify__content--center transparent-spinner'}>
					<CustomSpinner
						animation='border'
						className='spinner-border'
						style={{ width: '3rem', height: '3rem' }}
					/>
				</Modal>
			)}
		</div>
	);
};

export default PropertyPage;
