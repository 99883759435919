const dashboardEnglishText = {
  MAP: "Map",
  RESET_FILTER: "Reset filter",
  PRICE: "Price",
  SPACE: "Space",
  ROOMS: "Rooms",
  MORE_FILTERS: "More Filters",
  AREA: "Area",
  YEAR_BUILT: "Year built",
  RES_COMPLEX: "Residential complex",
  PARKING: "Parking Space / Garage",
  YEAR_OF_CONSTRUCTION: "Year of construction",
  APPLY: "Apply",
  PLOT: "Plot",
  ALL: "All",
  SEARCH_BY_RADIUS: "Search by Radius",
  SEARCH_BY_ZIP: "Search by ZIP-Code, name or district",
  SAERCH_LOCATION: "Search location",
  HIDE_FORECLOSURE: "Hide Foreclosure Sale Properties",
  SCORE_MIN: "Score Min.",
  SCORE_MAX: "Score Max.",
  SHOW_ALL: "Show All",
  SHOW_ON_MAP: "Show on map",
  RESULTS: "results",
  OUT_OF: "out of more than",
  PAGE_RESULTS: "results",
  SCHOOLS: "Schools",
  TRANSPORTS: "Public transportation",
  MEDICLES: "Medical facilities",
  PARKS: "Parks",
  SUPERMARKETS: "Supermarkets",
  NO_RESULT_FOUND_TRY_OTHER: "No results found. Please try another filter.",
  TYPES_OF_BUILDING: "Types of building",
  HOUSES: "Houses",
  ADVERT_AGE: "Advertising age in days",
  YEAR_MIN: "Year min.",
  YEAR_MAX: "Year max.",
  DEACTIVATED: "Already Sold",
  FULL_SCREEN: "Full screen",
  EXIT_FULL_SCREEN: "Exit full screen",
  SIZE: "size",
  OBJECT_DISABLED_ERROR: "This property has already been sold.",
  CANCEL: "Cancel",
  CONTACT: "Contact",
  LOGIN_AGAIN_ERROR: "Your login session has expired. Please login again",
  OTHER_FEATURES: "Other features",
  STANDARD_SORTING: "Neu",
  PRICE_FILTER: "Price ",
  NEW_CONSTRUCTION: "New, planned or under construction",
  PARKING_SPACE: "Parking space",
  GARDEN_BALCONY: "Garden / Balcony",
  NO_COMMISSION: "Commission free",
};

export default dashboardEnglishText;
