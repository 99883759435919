import React, { FC, useEffect, useRef, useState } from 'react';

import { CustomCheckBox } from 'components/commonComponents/form/checkBox/checkbox';
import { FILTERS, sortList } from '../utils/constants';
import SortComponent from 'components/finder/components/sortComponent';
import * as utilFunctions from 'utils/functions';
import { FadeInSection } from 'components/commonComponents/fadeInSection/fadeInSection';

export interface Params {
	[key: string]: string[] | string; // Index signature indicating that the keys are strings and values are string arrays
}
interface IProps {
	getUserPropertyDetail: (params?: Params) => void;
	isReset: boolean;
	setIsReset: React.Dispatch<React.SetStateAction<boolean>>;
	setEditProperty: React.Dispatch<
		React.SetStateAction<{
			isEdit: boolean;
			listItem: {};
		}>
	>;
	totalRecord: number;
}
const PropertyFilter: FC<IProps> = ({ getUserPropertyDetail, isReset, setIsReset, setEditProperty, totalRecord }) => {
	const ref = useRef(null);
	const wrapperRef = useRef(null);
	const [filters, setFilters] = useState(FILTERS);
	const [selectedFilter, setSelectedFilter] = useState('');
	const [selectValue, setSelectValue] = useState<Params>({});
	const [selectedSortType, setSelectedSortType] = useState('DESC');
	const [selectedSortName, setSelectedSortName] = useState('');
	const [selectedSortLabel, setSelectedSortLabel] = useState('');
	const [visibleModal, setVisibleModal] = useState('');

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !(ref.current as any).contains(event.target)) {
				setSelectedFilter('');
			}
			if (wrapperRef.current && !(wrapperRef.current as any).contains(event.target)) {
				setVisibleModal('');
			}
		};
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [ref, wrapperRef]);

	const handleCheck = (filterName: string, label: string, isChecked: boolean) => {
		const tempFilters = [...filters];
		const filter = tempFilters.map((filter) => {
			if (filter.name === filterName) {
				return {
					...filter,
					options: filter.options.map((option) => {
						if (label === option.label) {
							return { ...option, isChecked };
						} else {
							return option;
						}
					})
				};
			} else {
				return filter;
			}
		});
		setFilters(filter);

		const params: Params = {};
		filter.forEach((filter) => {
			if (filter.options.some((option) => option.isChecked)) {
				params[filter.value] = filter.options.filter((op) => op.isChecked).map((op) => op.value);
			}
		});
		getUserPropertyDetail({ ...params, sortType: selectedSortType, sortBy: selectedSortName });
		setSelectValue(params);
	};

	const changeSortingName = (sortObj: any) => {
		if (selectedSortName !== sortObj.value) {
			setSelectedSortName(sortObj.value);
			setSelectedSortLabel(sortObj.label);
			setVisibleModal('');
			getUserPropertyDetail({ ...selectValue, sortType: selectedSortType, sortBy: sortObj.value });
		}
	};
	const changeSortingType = (type: string) => {
		if (selectedSortType !== type && selectedSortName) {
			setSelectedSortType(type);
			setVisibleModal('');
			getUserPropertyDetail({ ...selectValue, sortBy: selectedSortName, sortType: type });
		}
	};
	const toggleSortMenu = (value: any) => {
		!value && utilFunctions.disableScrolling(value);
		setVisibleModal(value ? 'short_menu' : '');
	};

	return (
		<div className='property-filter-section '>
			<div className='object-number'>{`Meine Objekte (${totalRecord})`}</div>
			<div className='filter-button-div '>
				<div className={`sort-div-wrapper position-relative user-property-sort-wrapper`}>
					<SortComponent
						sortObj={{
							sortList,
							selectedSortType,
							selectedSortName,
							selectedSortLabel,
							top: '50px',
							left: '0px'
						}}
						changeSorting={changeSortingName}
						changeSortingType={changeSortingType}
						toggleSortMenu={toggleSortMenu}
						visibleModal={visibleModal}
						setWrapperRefForSortMenu={(node) => {
							wrapperRef.current = node;
						}}
					/>
				</div>
				<div className='flex align__items--center'>
					<div className='flex align__items--center' ref={ref}>
						{filters.map(({ name, options }) => (
							<div key={name} className='position-relative'>
								<button
									className={`filter-button ${
										options.some((opt) => opt.isChecked) || selectedFilter === name
											? 'color-yellow'
											: ''
									} `}
									onClick={() => {
										if (selectedFilter !== name) {
											setSelectedFilter(name);
										} else {
											setSelectedFilter('');
										}
									}}
								>
									{name}
								</button>
								<FadeInSection
									hide={selectedFilter !== name}
									className='sort-list check-box-filter-div'
									to
								>
									<div className={`filter-menu`}>
										<div className='check-box-filter'>
											{options.map((item) => (
												<CustomCheckBox
													key={item.label}
													label={item.label}
													checked={item.isChecked}
													customOnChange={(isChecked) =>
														handleCheck(name, item.label, isChecked)
													}
													className={`user-property-checkbox ${
														item.isChecked ? 'active-check-box' : ''
													}`}
												/>
											))}
										</div>
									</div>
								</FadeInSection>
							</div>
						))}
					</div>
					<div
						className='button-item mr-2'
						onClick={() => {
							if (
								Object.keys(selectValue).length !== 0 ||
								selectedSortName ||
								selectedSortType !== 'DESC'
							) {
								setSelectedFilter('');
								setIsReset(true);
								setFilters(FILTERS);
								setSelectedSortName('');
								setSelectedSortLabel('');
								setSelectedSortType('DESC');
								getUserPropertyDetail();
								setSelectValue({});
							} else {
								setSelectedFilter('');
							}
						}}
					>
						<button
							className={`button reset-filter top-btn  d-flex align-items-center justify-content-center`}
						>
							<i
								className={`fa fa-refresh reset-icon ${isReset ? 'ani-rotate-360' : ''}`}
								aria-hidden='true'
							></i>
							Filter zurücksetzen
						</button>
					</div>
					<button
						className='advertising-btn-wrapper'
						onClick={() => setEditProperty({ isEdit: true, listItem: {} })}
					>
						+ Inserieren
					</button>
				</div>
			</div>
		</div>
	);
};

export default PropertyFilter;
