/* eslint-disable no-undef */
import cluster from "assets/images/cluster-icon.png";
import multiIcon from "assets/images/purple.png";
import englishText from "languages/english";
import getLocalizeText from "utils/getLocalizeText";
import { IMapMulti } from "../interface/map.interface";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export const clusterStyles = [
  {
    textColor: "white",
    url: cluster,
    height: 45,
    width: 45,
    lineHeight: 45,
    margintop: 15,
  },
  {
    textColor: "white",
    url: cluster,
    height: 45,
    lineHeight: 45,
    width: 45,
  },
  {
    textColor: "white",
    url: cluster,
    height: 45,
    lineHeight: 45,
    width: 45,
  },
];

export const createSingleMarker = (
  maprefs: any,
  onToggleOpen: (event: any, lat: string, lon: string, map: IMapMulti) => void,
  addHighLightClass: (id: string) => void,
  removeHighlight: (id: string) => void,
  mapArray: any,
  map: any,
  index: number
) => {
  const marker: any = new google.maps.Marker({
    position: new google.maps.LatLng(parseFloat(map.lat), parseFloat(map.lon)),
    icon: map.icon,
    // @ts-ignore
    key: index,
  });
  marker.metadata = {
    id: map.id,
    icon: map.icon,
    highlightIcon: map.highlightIcon,
  };

  marker.setMap(maprefs.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED);
  mapArray.push(marker);
  google.maps.event.addDomListener(marker, "click", function (event: any) {
    onToggleOpen(event, map.lat, map.lon, map);
  });
  google.maps.event.addDomListener(marker, "mouseover", function () {
    addHighLightClass(map.id);
  });
  google.maps.event.addDomListener(marker, "mouseout", function () {
    removeHighlight(map.id);
  });
};

export const createMultiMarker = (
  map: any,
  onToggleMultiOpen: (
    event: any,
    lat: string,
    lon: string,
    mapMulti: IMapMulti
  ) => void,
  addHighLightClass: (id: string) => void,
  removeHighlight: (id: string) => void,
  maprefs: any,
  multiMapArray: any,
  index: number
) => {
  let markerIcon = {
    url: multiIcon,
    // labelOrigin: new google.maps.Point(20, 45),
  };

  const marker = new google.maps.Marker({
    position: new google.maps.LatLng(parseFloat(map.lat), parseFloat(map.lon)),
    title: getLocalizeText(englishText.MULTIPLE_PROPERTIES),
    icon: markerIcon,
    // @ts-ignore
    key: index,
  });
  // @ts-ignore
  marker.metadata = {
    id: map.id,
    // score: map.label
  };

  marker.setMap(maprefs.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED);

  multiMapArray.push(marker);
  google.maps.event.addDomListener(marker, "click", function (event: object) {
    onToggleMultiOpen(event, map.lat, map.lon, map);
  });

  google.maps.event.addDomListener(marker, "mouseover", function () {
    addHighLightClass(map.id[0]);
  });
  google.maps.event.addDomListener(marker, "mouseout", function () {
    removeHighlight(map.id[0]);
  });
};

export const createCluster = (array: any[], maprefs: any) =>
  new MarkerClusterer(
    maprefs.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
    // array,
    // {
    //   maxZoom: 10,
    //   styles: clusterStyles,
    // }
  );

