/* eslint-disable no-undef */
import React from 'react';
import { compose, lifecycle } from 'recompose';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import markerIcon from 'assets/images/pin.png';
import * as mapForObInterface from '../interface/objectDetails';

const MapWithAMarker = compose<{}, mapForObInterface.IMapForObProps>(
	lifecycle<mapForObInterface.IMapForObLifeCycle, {}>({
		UNSAFE_componentWillMount() {
			const { fromPopup } = this.props;
			if (fromPopup) {
				const refs: any = {};
				this.setState({
					onMapMounted: (ref: any) => {
						refs.map = ref;
					}
				});
			}
		}
	}),
	withGoogleMap
)((props: any) => (
	//@ts-ignore
	<GoogleMap
		defaultZoom={10}
		defaultCenter={{ lat: props.lat, lng: props.lon }}
		ref={props.onMapMounted}
		options={{
			fullscreenControl: false,
			scrollwheel: false
		}}
		// zoomControl={true}
		onClick={() => !props.fromPopup && props.onMarkerClick()}
	>
		<Marker
			position={{ lat: props.lat, lng: props.lon }}
			title={props.title}
			icon={markerIcon}
			onClick={(event: any) => {
				!props.fromPopup && props.onMarkerClick();
			}}
		/>
	</GoogleMap>
));

export default MapWithAMarker;
