import { FadeInSection } from 'components/commonComponents/fadeInSection/fadeInSection';
import React from 'react';
import { modalNames, primaryColor, sortOrder } from 'utils/constants';
import getLocalizeText from 'utils/getLocalizeText';

interface IShortProps {
	sortObj: any;
	changeSorting: (item: any) => void;
	changeSortingType: (order: string) => void;
	toggleSortMenu: (toggle: boolean) => void;
	setWrapperRefForSortMenu: (node: any) => void;
	visibleModal: string;
	topResult?: boolean;
}

const SortComponent: React.FC<IShortProps> = (props) => {
	const { sortObj, topResult } = props;
	const sortList: any[] = topResult ? sortObj.sortList : sortObj.sortList.filter((i: any) => i.value !== 'score');

	return (
		<div
			className='sort-div pointer'
			style={props.visibleModal === modalNames.SORT_MENU ? { borderColor: primaryColor, boxShadow: 'none' } : {}}
			ref={props.setWrapperRefForSortMenu}
		>
			<FadeInSection
				hide={props.visibleModal !== modalNames.SORT_MENU}
				className='sort-list'
				style={{ top: sortObj.top, left: sortObj.left }}
			>
				<div>
					{sortList &&
						sortList.map((item, index) => (
							<li
								className={props.sortObj.selectedSortName === item.value ? 'active' : ''}
								value={item.value}
								key={index}
								onClick={() => props.changeSorting(item)}
							>
								{getLocalizeText(item.label)}
							</li>
						))}
				</div>
			</FadeInSection>

			<div
				className='sort-container'
				onClick={() => props.toggleSortMenu(props.visibleModal === modalNames.SORT_MENU ? false : true)}
			>
				<input
					className='sort-menu-div cursor-pointer'
					value={getLocalizeText(sortObj.selectedSortLabel)}
					placeholder='Bitte wählen'
					readOnly
				/>
				<i className='fa fa-caret-down' aria-hidden='true' />
			</div>
			<div className='arrow-outer flex align__items--center'>
				<div
					className={sortObj.selectedSortType === sortOrder.ASC ? 'arrow-div-asc' : 'arrow-div-desc'}
					onClick={() =>
						props.changeSortingType(
							sortObj.selectedSortType === sortOrder.ASC ? sortOrder.DESC : sortOrder.ASC
						)
					}
				/>
			</div>
		</div>
	);
};

SortComponent.defaultProps = {
	topResult: true
};

export default SortComponent;
