import React from 'react';
import { useNavigate } from 'react-router-dom';
import { service } from '../utils/constants';

export const Services = () => {
	const navigation = useNavigate();
	return (
		<section className='Services'>
			<div>
				<h1 className='location-tittle text--center cursor-pointer'>Mehr Service</h1>
				<div className='flex justify__content--around  align__items--center text-center service-wrapper'>
					{service.map((data, index) => (
						<div
							key={index}
							className='cursor-pointer services-hover-effect'
							onClick={() => {
								navigation(data.navigate);
								window.scrollTo(0, 0);
							}}
						>
							{data.icon}
							<h4 className='title'>{data.title}</h4>
							<p className='subtitle'>{data.subtitle}</p>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};
