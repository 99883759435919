import React, { FC, InputHTMLAttributes, ReactNode } from 'react';
import { FormComponentProps } from 'components/profile/utils/form.interface';

interface IInputField {
	type: string;
	className?: string;
	isReadOnly?: boolean;
	addOn?: string | TrustedHTML;
}

const InputField: FC<FormComponentProps & InputHTMLAttributes<HTMLInputElement> & IInputField> = (props) => {
	const { label, asterisk = false, error, type, className, value, isReadOnly = false, addOn, ...rest } = props;
	return (
		<div className='input-field-div'>
			<div className='flex align__items--center'>
				{label && <label className='form-label'>{label}</label>}
				{asterisk && <span className='form-asterisk'>&nbsp;*</span>}
			</div>
			<div className='flex flex--column position-relative'>
				<input
					{...rest}
					className={`form-input ${error ? 'error-border' : ''} ${className || ''} ${
						isReadOnly ? 'not-allowed' : ''
					}`}
					type={type}
					value={value || ''}
					readOnly={isReadOnly}
					onFocus={(e) =>
						e.target.addEventListener(
							'wheel',
							(e) => {
								e.preventDefault();
							},
							{ passive: false }
						)
					}
				/>
				{addOn && <span className='add-on position-absolute' dangerouslySetInnerHTML={{ __html: addOn }} />}
				{error && <span className='error-message'>{error.message}</span>}
			</div>
		</div>
	);
};

export default InputField;
