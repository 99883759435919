import React, { FC, useEffect, useState } from 'react';

import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import PersonalInformation from 'components/profile/components/personalInformation';
import AddressInformation from 'components/profile/components/addressInformation';
import MoreInformation from 'components/profile/components/moreInformation';
import PropertyButtonDiv from './propertyButtonDiv';
import {
	contactTabRole,
	countriesArray,
	defaultRule,
	GENDER_OPTIONS,
	ProfessionalBrokerRequiredFields,
	PropertyOwnerRequiredFields,
	rules
} from 'components/profile/utils/constants';
import CustomRadioButton from 'components/commonComponents/form/radioButton/radioButton';
import { contactTabRadioButtonOptions, propertyTab } from '../utils/constants';
import { UserProfileFormData } from 'components/profile/utils/form.interface';
import { notify } from 'components/commonComponents/notification/notification';

import { findRules, getSelectedOption, mergeDeep, ProfileRootState, removeEmptyStrings } from 'utils';

import { API_CONFIG, HttpService } from 'services';

interface ContactTabProps {
	gotoBack: (backTab: propertyTab) => void;
	changeTab: (step: 'next' | 'back') => void;
	selectedValue: string;
	isLoading: boolean;
	currentTab: string;
	objectId: string;
}

const ContactTab: FC<ContactTabProps> = ({ gotoBack, changeTab, isLoading, currentTab, objectId }) => {
	const profileDetail = useSelector((state: ProfileRootState) => state.profileDetailReducer);
	const dispatch = useDispatch();

	const methods = useForm({
		defaultValues: {
			gender: '',
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			street: '',
			postalCode: '',
			city: '',
			land: '',
			moreInformation: '',
			companyName: '',
			imprint: '',
			taxIdentifier: ''
		}
	});

	const [selectedValue, setSelectedValue] = useState(contactTabRole.propertyOwner);
	const [isProfileLoading, setIsProfileLoading] = useState(false);

	const resetForm = (data: any) => {
		return methods.reset({
			...data,
			gender: getSelectedOption(GENDER_OPTIONS, data.gender),
			land: getSelectedOption(countriesArray, data.land)
		});
	};

	useEffect(() => {
		setIsProfileLoading(true);
		if (profileDetail.data) {
			resetForm(profileDetail.data);
		}
		if (profileDetail.status === 'success') {
			setIsProfileLoading(false);
			const { roleName } = profileDetail.data;
			if (roleName === contactTabRole.professionalBroker) {
				setSelectedValue(profileDetail.data.roleName as contactTabRole);
			}
		}
	}, [profileDetail]);

	useEffect(() => {
		const errorFields = document.getElementsByClassName('error-message');
		if (errorFields && errorFields.length > 0) {
			errorFields[0].scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'start'
			});
		}
	}, [methods.formState]);

	const getProps = () => {
		let fields = ProfessionalBrokerRequiredFields;
		if (selectedValue === contactTabRole.propertyOwner) {
			fields = PropertyOwnerRequiredFields;
		}
		return {
			requiredFields: fields,
			rules: mergeDeep(findRules(fields, rules), defaultRule)
		};
	};

	const handleChangeRadioButton = (selectedLabel: string) => {
		setSelectedValue(selectedLabel as contactTabRole);
	};
	const onSubmit = (data: UserProfileFormData) => {
		let detail = {};
		detail = {
			gender: data.gender?.value || '',
			firstName: data.firstName || '',
			lastName: data.lastName || '',
			email: data.email || '',
			phone: data.phone || '',
			street: data.street || '',
			postalCode: parseInt(data.postalCode) || '',
			city: data.city || '',
			land: data.land?.value || '',
			moreInformation: data.moreInformation || ''
		};
		if (selectedValue === contactTabRole.professionalBroker) {
			detail = {
				...detail,
				companyName: data.companyName || '',
				imprint: data.imprint || '',
				taxIdentifier: data.taxIdentifier || '',
				privacyPolicy: data.privacyPolicy || ''
			};
		}
		handleSaveDetail(removeEmptyStrings(detail));
	};

	const handleSaveDetail = (data: any) => {
		setIsProfileLoading(true);
		let userRollURL = API_CONFIG.path.userBroker;
		if (selectedValue === contactTabRole.propertyOwner) {
			userRollURL = API_CONFIG.path.userOwner;
		}
		const modifiedData = { ...data };
		const isEmailChange = profileDetail.data.email !== data.email;
		if (!isEmailChange) {
			delete modifiedData.email;
		}

		if (Object.keys(methods.formState.dirtyFields).length === 0) {
			changeTab('next');
			return;
		}

		HttpService.put(userRollURL, modifiedData)
			.then((response) => {
				notify(`${response.message}`, 'success');
				dispatch({
					type: 'PROFILE_DETAILS_FULFILLED',
					payload: { data: response }
				});
				// changeTab("next");
				userProperty(response.data.email, response.data.companyName);
				setIsProfileLoading(false);
			})
			.catch((err) => {
				notify(`${err.response.data.message}`, 'error');
				setIsProfileLoading(false);
			});
	};

	const userProperty = (email: string, companyName: string) => {
		setIsProfileLoading(true);
		HttpService.post(API_CONFIG.path.newUserProperty, {
			providerEmailAddress: email,
			providerCompanyName: companyName,
			steps: currentTab,
			objectId
		})
			.then((response) => {
				changeTab('next');
				setIsProfileLoading(false);
			})
			.catch((err) => {
				notify(`${err.response.data.message}`, 'error');
				setIsProfileLoading(false);
			});
	};
	return (
		<div>
			<FormProvider {...methods}>
				<form>
					<p className='contact-tab-title text--center'>
						Bitte überprüfe deine Daten und aktualisiere Sie falls nötig.
					</p>
					<PersonalInformation {...getProps()} displayChangePassword={false} isReadOnly={true} />
					<AddressInformation {...getProps()} />
					<p className='form-label'>
						Bieten Sie diese Immobilie als Privatperson oder als Makler an?
						<span className='form-asterisk'> *</span>
					</p>
					<div className='contact-roll-wrapper'>
						<CustomRadioButton
							options={contactTabRadioButtonOptions}
							handleChange={handleChangeRadioButton}
							selectedValue={selectedValue}
							className={'flex align__items--center justify__content--evenly user-roll-div '}
						/>
					</div>
					<MoreInformation isRealtor={selectedValue === contactTabRole.professionalBroker} {...getProps()} />
					<PropertyButtonDiv
						backButton={true}
						showSpinner={isProfileLoading || isLoading}
						handleSaveClick={methods.handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
						buttonType={'button'}
						goBack={() => gotoBack(propertyTab.detail)}
					/>
				</form>
			</FormProvider>
		</div>
	);
};

export default ContactTab;
