import { propertyOptions } from "utils";

export const FILTERS = [
    {
        name: 'Objekt',
        value: 'objectType',
        options: [
            { label: 'Wohnung', value: 'Wohnung', isChecked: false },
            { label: 'Hause', value: 'Hause', isChecked: false },
            { label: 'Grundstück', value: 'Grundstück', isChecked: false }
        ]
    },
    {
        name: 'Objektart',
        value: 'marketingType',
        options: [
            { label: 'zu verkaufen', value: propertyOptions.SELL, isChecked: false },
            { label: 'zu vermieten', value: propertyOptions.RENT, isChecked: false }
        ]
    },
    {
        name: 'Status',
        value: 'status',
        options: [
            { label: 'Aktiv', value: 'Active', isChecked: false },
            { label: 'Inaktiv', value: 'Inactive', isChecked: false }
        ]
    }
];

export const sortList = [
    {
        label: "Datum der Änderung",
        value: "updated_at"
    },
    {
        label: "Kaufpreis",
        value: "price"
    },
    {
        label: "Price per m2",
        value: "pricePerSQM"
    },
    {
        label: "Fläche",
        value: "livingSpace"
    },
    {
        label: "Räume",
        value: "rooms"
    },

]