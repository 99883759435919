import React from 'react';

interface InputProps {
	value?: any;
	handleFilterChange: (name: string, value: any) => void;
}

const userInput = [
	{
		label: 'Mietpreis bis',
		name: 'fromPrice',
		userValue: 'EUR'
	},
	{
		label: 'Wohnfläche ab',
		name: 'fromAreaLiving',
		userValue: 'qm'
	}
];

const UserInput = (props: InputProps) => {
	return (
		<>
			{userInput.map((item, index) => (
				<div className='cost-filter  text--center' key={index}>
					<span className=' user-input-wrapper'>{item.label} </span>
					<input
						className='cost-button  user-input'
						type='number'
						name={item.name}
						aria-label='name'
						value={props.value[item.name]}
						aria-describedby='basic-addon1'
						onChange={(e) => {
							let value = parseInt(e.target.value);
							if (item.name === 'fromAreaLiving') {
								if (value > 200) {
									return;
								}
							}
							props.handleFilterChange(e.target.name, e.target.value);
						}}
					/>

					<span className='user-input-wrapper'>{item.userValue}</span>
				</div>
			))}
		</>
	);
};

export default UserInput;
