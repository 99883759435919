import React, { FC } from 'react';
import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { ArrowLeft, EditIcon } from 'components/commonComponents/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { setPlaceholderImg } from 'utils';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface Props {
	objectPictures: any;
	setImageLoadedList?: (id: string) => void;
	id: string;
	hoveredId: string;
	isFullMapScreen: boolean;
	isdisable: boolean;
	isEdit?: boolean;
	handleEdit?: (e: any) => void;
	isShowExtraFeatures?: boolean;
}

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const SwiperGallery: FC<Props> = ({
	objectPictures,
	setImageLoadedList,
	id,
	hoveredId,
	isFullMapScreen,
	isdisable,
	isEdit = false,
	handleEdit,
	isShowExtraFeatures = false
}) => {
	const breakpoints = {
		1920: {
			width: 508
		},
		1600: {
			width: 483
		},
		1440: {
			width: 412
		},
		1366: {
			width: 449
		},
		1280: {
			width: 426
		},
		1120: {
			width: 396
		},
		1024: {
			width: 345
		}
	};

	return (
		<div
			className={
				objectPictures.length === 1
					? `swiper-wrapper-div hide-bullets-hide hide-arrow`
					: `swiper-wrapper-div 
        ${hoveredId === id && !isdisable && 'hide-bullets'}  ${isdisable && 'hide-arrow'}  `
			}
		>
			<Swiper
				spaceBetween={0}
				slidesPerView={1}
				slidesPerGroup={1}
				pagination={{
					clickable: true,
					dynamicBullets: true
				}}
				onError={() => setImageLoadedList && setImageLoadedList(id)}
				navigation={{
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}}
				onProgress={() => setImageLoadedList && setImageLoadedList(id)}
				key={id}
				centeredSlides={true}
				width={isFullMapScreen ? 508 : undefined}
				updateOnWindowResize={true}
				breakpoints={isFullMapScreen ? breakpoints : undefined}
				allowSlideNext={!isdisable}
				allowSlidePrev={!isdisable}
				observer={true}
				observeParents={true}
			>
				{objectPictures.map((img: string, index: number) => (
					<SwiperSlide key={index}>
						<img src={img} alt='' onError={setPlaceholderImg} />
					</SwiperSlide>
				))}
				<div className='swiper-button-prev'>
					<ArrowLeft />
				</div>
				<div className='swiper-button-next'>
					<ArrowLeft />
				</div>
				{isEdit && !isShowExtraFeatures && (
					<div className='property-edit-icon' onClick={handleEdit}>
						<EditIcon className='edit-icon' />
					</div>
				)}
			</Swiper>
		</div>
	);
};

export default SwiperGallery;
