import React from 'react';

interface IProps {
	name: string;
	label: string;
	placeholder: string;
	type: string;
	pattern: any;
	register: any;
	errors: any;
	requiredErrors: string;
	patternErrors: string;
}

const InputComponent = (props: IProps) => {
	return (
		<div>
			<label>{props.label}</label>
			<input
				{...props.register(`${props.name}`, {
					required: true,
					pattern: props.pattern
				})}
				placeholder={props.placeholder}
				type={props.type}
				className={`${props.errors?.email?.type ? 'error-border' : ''}`}
			/>
			{props.errors?.email?.type === 'required' && <span className='form-error'>{props.requiredErrors}</span>}

			{props.errors?.email?.type === 'pattern' && <span className='form-error'>{props.patternErrors}</span>}
		</div>
	);
};

export default InputComponent;
