import React from 'react';
import MetaDecorator from 'shared/meta/metaDecorator';
import TitleImageSection from '../components/titleImageSection';
import imprintImage from '../../../assets/images/imprint-image.png';

const TermsAndConditions = () => {
	return (
		<div className='data-protection-page public-page-font'>
			<MetaDecorator title='Impressum - Immoweb | Immobilien, Wohnungen und Häuser bei immoweb' />
			<TitleImageSection backgroundImage={imprintImage} titleText={'AGB & rechtliche Hinweise'} />
			<section className='description-section section-width'>
				<div className='description-tittle'>
					<p>
						Willkommen auf der Website der immoweb AG (Aktiengesellschaft), Dortmunder Straße 5, 44536
						Lünen, vertreten durch den Vorstandsvorsitzender Herrn Thomas Schlüchter, eingetragen im
						Handelsregister des AG Dortmund HRB 21043.
					</p>
					<p>
						Nachfolgend finden Sie die allgemeinen Geschäftsbedingungen von der immoweb AG. Diese
						allgemeinen Geschäftsbedingungen regeln das Vertragsverhältnis zwischen der immoweb AG und
						sowohl den natürlichen und als auch juristischen Personen, die Teledienste der
						„immoweb“-Websites nutzen („Nutzer“) und betreffen die Nutzung der Websites „
						<a href='https://staging.immoweb.de/' target={'_blank'}>
							immoweb.de
						</a>
						“ sowie aller zu diesen Domains gehörenden Subdomains.
					</p>
					<p>
						Die allgemeinen Geschäftsbedingungen finden ebenfalls Anwendung, wenn Sie die „immoweb“-Websites
						oder Teile davon über andere Websites nutzen, welche den Zugang zu den „immoweb“-Websites
						teilweise oder insgesamt ermöglichen. Sie gelten ab dem Zeitpunkt der Registrierung für alle
						Nutzer, die sich unter Zustimmung zu diesen allgemeinen Geschäftsbedingungen registriert haben.
					</p>
					<p>
						Mit dem Anklicken des Feldes „Ich stimme zu“ akzeptieren Sie die allgemeinen
						Geschäftsbedingungen der immoweb AG und schließen mit dieser einen Vertrag über die Nutzung der
						„immoweb“-Websites, auf der Sie selbständig Objekte zum Verkauf oder zur Vermietung anbieten
						können und/oder auf die Sie selbst bieten können. Weitergehende Informationen, auch im Hinblick
						auf die Kosten, die für die Nutzung der „immoweb“-Websites anfallen, können Sie hier „
						<a href='http://www.immoweb.de/plattform/preisliste' target={'_blank'}>
							http://www.immoweb.de/plattform/preisliste
						</a>
						“ finden.
					</p>
					<p>
						Sie haben die Möglichkeit, Ihre Zustimmung zum Nutzungsverhältnis und zu diesen allgemeinen
						Geschäftsbedingungen innerhalb von zwei Wochen nach Erklärung schriftlich zu widerrufen.
						Schreiben Sie im Falle des Widerrufs an die immoweb AG, Dortmunder Straße 5, 44536 Lünen oder
						einer E-Mail an „info@immoweb.de“.
					</p>
					<p>
						Sollten Sie Beanstandungen haben oder möchten Sie sich beschweren, so richten Sie Ihre
						Mitteilungen bitte ebenfalls an die immoweb AG, Dortmunder Straße 5, 44536 Lünen oder per E-Mail
						an „info@immoweb.de“.
					</p>
					<h3>§ 1 Einführung</h3>
					<p>
						Die immoweb AG stellt einen elektronischen Handelsplatz zur Nutzung zur Verfügung. Dieser
						Handelsplatz wird im Nachfolgenden als „Plattform“ bezeichnet. Die Nutzer können die Plattform
						nach den Regeln dieses Nutzungsverhältnisses für den eigenen Handelsverkehr einsetzen.
					</p>
					<p>
						Die Nutzer können auf der Plattform als Anbieter oder Bieter von Objekten auftreten. Ihre
						Rechtsverhältnisse untereinander richten sich nach den gesetzlich erlaubten Formen des
						Handelsverkehrs unter Berücksichtigung der dafür geltenden Regeln (z.B. Handels-, Wettbewerbs-
						und Kartellrecht, Vergaberecht u.s.w.).
					</p>
					<h3>§ 2 Nutzungsverhältnis</h3>
					<p>
						(1) Zur Nutzung der Plattform bedarf es der vorherigen Anmeldung, Registrierung und
						Freischaltung des Nutzers durch die immoweb AG. Ein Anspruch auf Anmeldung, Registrierung und
						Freischaltung zur „immoweb“-Website besteht nicht. Zur Registrierung übermittelt der Interessent
						an die immoweb AG ein vollständig ausgefülltes, in vertretungsberechtigter Weise unterzeichnetes
						Formular. Die Freischaltung des Nutzers setzt eine schriftliche Annahmebestätigung durch die
						immoweb AG voraus. Unrichtige oder unvollständige Angaben berechtigen die immoweb AG zur
						sofortigen Vertragsbeendigung. Änderungen der angegebenen Daten sind der immoweb AG unverzüglich
						schriftlich mitzuteilen. Gehen Erklärungen durch Telefax oder E-Mail zu gelten sie als
						schriftliche Erklärungen; die immoweb AG kann jedoch auch die Übermittlung des Schriftstücks mit
						Originalunterschrift(en) verlangen.
					</p>
					<p>
						(2) Die Anmeldung ist nur juristischen Personen und unbeschränkt geschäftsfähigen natürlichen
						Personen erlaubt. Insbesondere ist Minderjährigen eine Anmeldung nicht gestattet.
					</p>
					<p>
						(3) Bei der Anmeldung wählt der Nutzer einen Benutzernamen. Die immoweb AG teilt sodann jedem
						Nutzer ein erstes Kennwort zu. Der Benutzername darf nicht aus einer E-Mail- oder
						Internetadresse bestehen, nicht Rechte Dritter, insbesondere keine Marken- oder Namensrechte
						verletzen oder gegen die guten Sitten verstoßen.
					</p>
					<p>
						(4) Benutzername und Kennwort sind vor unberechtigtem Zugriff Dritter besonders zu schützen.
						Kennworte sind regelmäßig (mindestens alle drei Monate) zu ändern, um die Gefahr eines
						unberechtigten Zugriffs Dritter zu reduzieren. Die immoweb AG wird dieses Kennwort nicht an
						Dritte weitergeben.
					</p>
					<p>
						(5) Die immoweb AG behält sich vor, ist aber nicht verpflichtet, Angaben und Bonität des Nutzers
						und eines Unternutzungsberechtigten durch Einholung von Auskünften zu prüfen.
					</p>
					<p>
						(6) Das Nutzungsverhältnis kann beiderseits jederzeit ohne Angabe von Gründen gekündigt werden.
						Die Kündigung bedarf der Schriftform. Im Falle der Kündigung ist die immoweb AG berechtigt, den
						Benutzernamen und Kennworte zu sperren und Teilnehmerdaten zu löschen, soweit dem nicht
						gesetzliche Aufbewahrungspflichten entgegenstehen.
					</p>
					<p>
						(7) Jeder Nutzer darf nur ein Nutzerkonto eröffnen. Der Missbrauch des Nutzerkontos ist
						verboten. Das Nutzerkonto ist nicht übertragbar.
					</p>
					<h3>§ 3 Inhalt und Umfang des Nutzungsverhältnisses</h3>
					<p>
						(1) Jeder Nutzer ist berechtigt, die in diesem Nutzungsverhältnis unter § 1 beschriebenen
						Einrichtungen in Anspruch zu nehmen, und ist verpflichtet, alle in diesem Nutzungsverhältnis
						vereinbarten Nutzungsbeschränkungen zu beachten.
					</p>
					<p>
						(2) Gegenstand des Vertrages ist die Nutzung der von der immoweb AG auf der Plattform zur
						Verfügung gestellten Systeme nur im Rahmen des aktuellen Standes der Technik. Dazu ermöglicht
						die immoweb AG dem Nutzer den Zugriff auf die Oberfläche der Plattform und stellt im Rahmen der
						Plattform die elektronische Kommunikation mit anderen Nutzern zur Verfügung. Zeitweilige
						Beschränkungen können sich durch technische Störungen ergeben. Darüber hinausgehende
						Einrichtungen oder Leistungen schuldet die immoweb AG nicht.
					</p>
					<p>
						(3) Die immoweb AG kann die auf der Plattform für jedes System geltenden Regeln festlegen, an
						die der Nutzer gebunden ist. Zur Zeit wird folgendes System angeboten:
					</p>
					<p>Bieterwettbewerb</p>
					<p>
						Anbieter laden Bieter zu einem Bieterwettbewerb ein, bei dem die Bieter bis zum festgelegten
						Ende des Bieterwettbewerbs Angebote abgeben können. Der Anbieter entscheidet am Ende des
						Bieterwettbewerbes, mit welchem Bieter er in Vertragsverhandlungen treten möchte; er ist
						insoweit frei.
					</p>
					<p>
						(4) Für die Zwecke eines Bieterwettbewerb werden die Angaben und Erklärungen der Nutzer nach den
						jeweils geltenden Regeln durch die Nutzer in die Plattform eingestellt. Auf Wunsch und Risiko
						eines Nutzers kann die immoweb AG behilflich sein, Daten des Nutzers von dessen Datenträgern
						oder aus dessen elektronisch übermittelten Dateien in das für die Einstellung in die Plattform
						benötigte Format zu transformieren. Für Veränderungen oder Verluste von Nutzerdaten, die im
						Zusammenhang mit diesem Transformationsvorgang und der anschließenden Einstellung in die
						Plattform auftreten können, haftet die immoweb AG nicht. Der Nutzer hat in seinem eigenen
						Interesse die Richtigkeit und Vollständigkeit der an die immoweb AG übermittelten , von der
						immoweb AG auf seinen Wunsch transformierten und in die Plattform eingestellten Daten
						unverzüglich zu prüfen und ggf. Korrekturen zu veranlassen. Dazu hat der Nutzer die von ihm
						genutzte Plattform selbst zu überwachen.
					</p>
					<p>
						(5) Die immoweb AG kann einen Bieterwettbewerb zurückweisen und einen Nutzer sperren, wenn in
						die Plattform nach der Beurteilung von der immoweb AG rechtswidrige Inhalte oder Erklärungen
						eingestellt werden oder die Plattform missbräuchlich genutzt wird. Die immoweb AG ist zur
						Prüfung von Inhalten berechtigt, aber nicht verpflichtet. Dem Nutzer ist es untersagt, in die
						Plattform rechtswidrige Inhalte einzustellen. Sollten Inhalte so gestaltet sein, dass diese
						ausschließlich dem Zweck dienen, die Aufmerksamkeit von Interessenten zu gewinnen, werden diese
						als Missbrauch betrachtet und demzufolge gelöscht. Derartige missbräuchliche Gestaltungen sind
						beispielsweise die falsche und / oder fehlerhafte Angabe der Objektadresse, der Gebrauch eines
						Exposés als Werbefläche für das eigene Unternehmen, Interessenten durch falsche Angaben auf ein
						Angebot aufmerksam zu machen oder die Weitervermittlung von Interessenten zu entgeltlichen
						Internet- oder Telefondiensten. Solche Angaben stellen eine Manipulation der Plattform dar.
						Interessenten werden durch diese Art des Vorgehens verwirrt bzw. getäuscht.
					</p>
					<p>
						(6) Die immoweb AG gewährt dem Nutzer keine Rechte an der Plattform selbst, weder an der damit
						verbundenen Software noch der Dokumentation oder irgendwelchen Nutzungen, die über den Zweck
						dieses Vertrags hinausgehen. Sämtliche Urheber-, Namens-, Marken- oder sonstige Schutzrechte von
						der immoweb AG, auch an den gegenwärtigen und zukünftigen Websites von immoweb AG, der immoweb
						AG-Software und allen zugehörigen Dokumentationen und Schriftwerken, bleiben der immoweb AG
						vorbehalten.
					</p>
					<p>
						(7) Die immoweb AG weist darauf hin, dass die Inhalte der Plattform durch Rechte Dritter,
						insbesondere Rechte von Nutzern oder Rechte an gehandelten Waren oder Dienstleistungen,
						geschützt sein können. Diese Rechte bleiben dem jeweiligen Rechteinhaber vorbehalten.
					</p>
					<p>
						(8) Ein Nutzer hat keinen Anspruch auf Konkurrenzschutz. Die immoweb AG ist in der Auswahl der
						Nutzer nicht beschränkt.
					</p>
					<h3>§ 4 Leistungsänderungen und -störungen, Haftung</h3>
					<p>
						(1) Die immoweb AG ist berechtigt, seine Einrichtungen an die Bedürfnisse des Handelsverkehrs
						und/oder den Stand der Technik anzupassen.
					</p>
					<p>
						(2) Die immoweb AG kann ihre zur Nutzung verfügbaren Einrichtungen sperren oder den Zugang
						einschränken, wenn die Plattform oder ihre elektronischen Einrichtungen technisch überlastet
						oder gestört sind. In diesem Fall ist die immoweb AG bemüht, die Überlastung oder Störung so
						schnell wie möglich abzustellen.
					</p>
					<p>(3) Wird die Nutzung durch Ereignisse höherer Gewalt gestört, ist immoweb AG insoweit frei.</p>
					<p>
						(4) Wird die Nutzung durch Verschulden von der immoweb AG gestört, haftet die immoweb AG für
						einen dadurch verursachten Schaden nur dann, wenn der Schaden durch ein grob fahrlässiges oder
						vorsätzliches Handeln oder Unterlassen verursacht wurde. Ansonsten ist die Haftung auf den
						typischerweise vorhersehbaren Schaden beschränkt.
					</p>
					<h3>§ 5 Das Handelsverhältnis unter Nutzern, Freistellung von der immoweb AG</h3>
					<p>
						(1) Für das Handelsverhältnis unter den Nutzern weist die immoweb AG zur Klarstellung auf
						folgendes hin:
					</p>
					<p>
						Alle Ansprüche der Nutzer in Bezug auf das über die Plattform angebahnte und/oder abgewickelte
						Geschäfte richten sich ausschließlich nach den Verhältnissen und Erklärungen der Nutzer und den
						dafür geltenden gesetzlichen Bestimmungen. Die immoweb AG nimmt an diesen Rechtsbeziehungen in
						keiner Weise teil. Die immoweb AG steht folglich weder für Erklärungen der Nutzer noch für die
						Erfüllung ihrer Verpflichtungen ein.
					</p>
					<p>
						Die im Bieterverfahren von Nutzern angebotenen Objekte sind rechtlich in der Regel keine
						Angebote. Sie sind freibleibende Aufforderungen zur Abgabe eines Angebots.
					</p>
					<p>
						Die von den Nutzern über die Plattform angebahnten Geschäfte sind von den Nutzern nach eigener
						Prüfung vertraglich außerhalb der Plattform abzuschließen und abzuwickeln.
					</p>
					<p>
						(Zum Zweck des Vertragsabschlusses können Erklärungen der Nutzer auf eigenes Risiko auch über
						die Plattform abgegeben werden. Die immoweb AG stellt dafür – ohne jede eigene Haftung – seine
						Einrichtungen zur Verfügung. Eine solche Mitwirkung von der immoweb AG bei der Übermittlung oder
						Entgegennahme von rechtsgeschäftlichen Erklärungen durch Einstellung in die Plattform ist nicht
						Gegenstand oder Nebenpflicht des Nutzungsverhältnisses und wird von dem Nutzer ausdrücklich auf
						eigenes Risiko in Anspruch genommen. Die immoweb AG haftet nicht für Übertragungsmängel oder
						Fehlleitungen, soweit diese nicht von der immoweb AG vorsätzlich oder grob fahrlässig
						herbeigeführt werden.)
					</p>
					<p>
						Die immoweb AG kann nicht mit Sicherheit ausschließen, dass ein als Anbieter oder als Bieter
						teilnehmender Nutzer tatsächlich überhaupt nicht oder mit einer anderen als der vorgegebenen
						Identität existiert. Nutzer handeln daher hinsichtlich der Existenz oder der Identität des
						Vertragspartners auf eigene Gefahr und prüfen diese selbst.
					</p>
					<p>
						Die immoweb AG kann nicht mit Sicherheit ausschließen, dass ein Plattformzugang durch
						Kennwortmissbrauch erschlichen wird. Auch diese Gefahr trägt jeder Nutzer selbst und prüft vor
						jedem Vertragsabschluss die Vertragspartneridentität.
					</p>
					<p>
						Die immoweb AG haftet nicht für den Inhalt der Plattform. Die immoweb AG ist gegenüber den
						Nutzern in Bezug auf deren Handelsverkehr weder zur Beratung noch zur Überwachung verpflichtet.
					</p>
					<p>
						(2) Der Nutzer ist mit den vorstehenden Regelungen und Haftungsausschlüssen zugunsten der
						immoweb AG einverstanden.
					</p>
					<p>
						(3) Zwischen Nutzer und der immoweb AG wird ferner vereinbart: Sollte die immoweb AG von Dritten
						(einschließlich eines Nutzers) wegen des Inhalts oder der Art der von Nutzern über die Plattform
						abgewickelten Handelsgeschäfte auf Unterlassung und/oder Schadensersatz in Anspruch genommen
						werden, ist die immoweb AG nach eigenem Ermessen berechtigt, dem Unterlassungsanspruch zu
						entsprechen, die Leistungen zur Anbahnung oder Abwicklung der Handelsgeschäfte von Nutzern über
						die Plattform einzustellen und/oder nach der Beurteilung von der immoweb AG alles Erforderliche
						oder Nützliche zu unternehmen, diese Unterlassungs- und/oder Schadensersatzansprüche abzuwehren.
						Der oder die Nutzer, die den Unterlassungs- und/oder Schadensersatzanspruch durch ihren
						Handelsverkehr über die Plattform verursacht haben, sind der immoweb AG gegenüber verpflichtet,
						die immoweb AG auf erstes Anfordern von allen damit zusammenhängenden Kosten und Schäden
						freizustellen und auf Anforderung von der immoweb AG die Verteidigung gegen derartige Ansprüche
						zu übernehmen. Die immoweb AG wird den oder die Nutzer zu diesem Zweck mit allen erforderlichen
						Informationen versehen. Vorstehendes gilt nicht, soweit die Rechtsverletzung durch den Nutzer
						nicht zu vertreten ist.
					</p>
					<h3>§ 6 Angebotsgebühren, Zusatzgebühren und Provisionen</h3>
					<p>
						(1) Die Anmeldung als Nutzer bei „immoweb“ ist kostenlos. Es werden auch keine Gebühren für die
						Abgabe von Geboten verlangt.
					</p>
					<p>
						(2) Für das Anbieten von Objekten erhebt die immoweb AG von dem Anbieter eine Angebotsgebühr.
						Für zusätzliche Leistungen von der immoweb AG, soweit verfügbar, insbesondere für die
						Hervorhebung einzelner Angebote, hat der Anbieter Zusatzgebühren zu zahlen. Kommt es zwischen
						einem Anbieter und einem Bieter zum Abschluss eines Kaufvertrages, so ist eine einmalige
						Provision in Höhe von 1,00 % zuzüglich der gesetzlichen Umsatzsteuer aus dem Gesamtentgelt für
						das Objekt von dem Erwerber an die immoweb AG zu zahlen. Kommt es zwischen einem Anbieter und
						einem Bieter zum Abschluss eines Mietvertrages, so ist eine einmalige Provision in Höhe von 1,00
						% zuzüglich der gesetzlichen Umsatzsteuer aus dem Jahreskaltmietzins für das Objekt von dem
						Vermieter an die immoweb AG zu zahlen.
					</p>
					<p>
						(3) Die Höhe der einzelnen Gebühren sowie der Zuschlagsgebühr richtet sich nach der jeweils
						aktuellen Preisliste. Diese ist unter der Adresse „
						<a href='http://www.immoweb.de/plattform/preisliste' target={'_blank'}>
							http://www.immoweb.de/plattform/preisliste
						</a>
						“ abrufbar.
					</p>
					<p>
						(4) Die einzelnen Gebühren sind sofort, die Provision mit Abschluss des Vertrages zur Zahlung
						fällig. Als Vertragsabschluss gilt bei Grundstückserwerb die Beurkundung des Kaufvertrags. Die
						Gebühren können mittels Kreditkartenzahlung oder Abbuchungsauftrag beglichen werden. Schlägt der
						Forderungseinzug fehl, so hat der Nutzer der immoweb AG die dafür anfallenden Mehrkosten zu
						erstatten, sofern der Nutzer das Fehlschlagen zu vertreten hat.
					</p>
					<p>
						(5) Der Nutzer darf gegen die Gebühren und/oder die Provisionen der immoweb AG mit Forderungen
						aus noch nicht erteilten Gutschriften und mit fälligen und/oder zukünftigen Forderungen nur dann
						aufrechnen, wenn diese Forderungen entweder rechtskräftig festgestellt oder unbestritten sind.
					</p>
					<h3>§ 7 Allgemeine Grundsätze</h3>
					<p>
						(1) Der Nutzer ist verpflichtet, bei jeglicher Nutzung der „immoweb“-Website die geltenden
						Gesetze und behördlichen Vorschriften zu befolgen.
					</p>
					<p>
						(2) Die von dem Nutzer eingestellten Angebote dürfen nicht in Widerspruch zu diesen allgemeinen
						Geschäftsbedingungen oder dem geltendem Recht stehen. Die immoweb AG behält sich vor, die gegen
						geltendes Recht oder diese allgemeinen Geschäftsbedingungen verstoßende Angebote zu löschen.
					</p>
					<p>
						(3) Es ist den Nutzern untersagt, mit den durch die Nutzung der Plattform erhaltenen Adressen,
						Kontaktdaten und E-Mail-Adressen kommerzielle Werbung zu betreiben oder unerwünscht Werbung
						zuzusenden (z.B. Spam).
					</p>
					<p>
						(4) Die immoweb AG behält sich das Recht vor, innerhalb seiner Grundsätze die Ordnung auf dem
						Marktplatz im Rahmen der Billigkeit zu ändern, soweit dies den Nutzern unter Berücksichtigung
						berechtigter Interessen zumutbar ist.
					</p>
					<h3>§ 8 Haftungsbeschränkung</h3>
					<p>
						(1) Gegenüber Unternehmern haftet die immoweb AG für Schäden, außer im Falle der Verletzung
						wesentlicher Vertragspflichten nur dann, wenn und soweit der immoweb AG, ihren gesetzlichen
						Vertretern oder leitenden Angestellten, Vorsatz oder grobe Fahrlässigkeit zur Last fällt. Für
						sonstige Erfüllungsgehilfen haftet die immoweb AG nur bei Vorsatz und soweit diese wesentliche
						Vertragspflichten vorsätzlich oder grob fahrlässig verletzen. Außer bei Vorsatz oder grober
						Fahrlässigkeit der gesetzlichen Vertreter bzw. leitenden Angestellten oder vorsätzlichem
						Verhalten sonstiger Erfüllungsgehilfen von der immoweb AG besteht keine Haftung für den Ersatz
						mittelbarer Schäden, insbesondere für entgangenen Gewinn. Außer bei Vorsatz und grober
						Fahrlässigkeit der immoweb AG, deren gesetzlichen Vertretern und leitenden Angestellten, ist die
						Haftung auf den Ersatz des bei Vertragsschluss vorhersehbaren, typischerweise eintretenden
						Schadens begrenzt.
					</p>
					<p>
						(2) Gegenüber Verbrauchern haftet die immoweb AG nur für Vorsatz und grobe Fahrlässigkeit. Im
						Falle der Verletzung wesentlicher Vertragspflichten, des Schuldnerverzuges oder der von der
						immoweb AG zu vertretenden Unmöglichkeit der Leistungserbringung haftet die immoweb AG jedoch
						für jedes schuldhafte Verhalten ihrer Mitarbeiter und Erfüllungsgehilfen. Außer bei Vorsatz
						und/oder grober Fahrlässigkeit von gesetzlichen Vertretern, Mitarbeitern und sonstigen
						Erfüllungsgehilfen ist die Haftung von der immoweb AG der Höhe nach auf den Ersatz der bei
						Vertragsschluss vorhersehbaren, typischerweise eintretenden Schäden begrenzt.
					</p>
					<p>
						(3) Die vorgenannten Haftungsausschlüsse und Beschränkungen gegenüber Unternehmern oder
						Verbrauchern gelten nicht im Falle der Übernahme ausdrücklicher Garantien durch die immoweb AG
						und für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit sowie im Falle
						zwingender gesetzlicher Regelungen, insbesondere des Produkthaftungsgesetzes.
					</p>
					<h3>§ 9 Datensicherheit, Datenschutz</h3>
					<p>
						(1) Die Server von der immoweb AG werden regelmäßig gesichert. Das Risiko des unbefugten
						Zugriffs Dritter lässt sich trotz gängiger Verschlüsselungsverfahren nicht vollständig
						ausschließen. Die immoweb AG haftet nicht für Schäden aus unbefugtem Zugriff Dritter, soweit
						dieser nicht durch grob fahrlässiges oder vorsätzliches Verhalten von der immoweb AG ermöglicht
						wird.
					</p>
					<p>
						(2) Die Identifizierungsdaten des Nutzers sowie die im Zusammenhang mit den Handelsgeschäften
						auf der Plattform eingestellten Daten werden von der immoweb AG ganz oder teilweise in
						maschinenlesbarer Form gespeichert. Der Nutzer ist damit einverstanden. Die Daten werden von der
						immoweb AG nur zu gesetzlichen Zwecken verwandt.
					</p>
					<p>
						(3) Die immoweb AG ist berechtigt, Identifizierungsdaten von Nutzern, Daten bezüglich
						abgeschlossener Vorgänge und Daten über Erklärungen der Nutzer in Statistiken einzustellen.
						Diese Statistiken dürfen von der immoweb AG sowohl intern als auch extern genutzt werden. Bei
						externer Nutzung von Statistiken werden Name und Adresse des Kunden nicht genannt.
					</p>
					<h3>§ 10 Schriftform, anwendbares Recht und Gerichtsstand</h3>
					<p>
						(1) Sämtliche Erklärungen, die im Rahmen des mit der immoweb AG abzuschließenden
						Nutzungsverhältnisses übermittelt werden, müssen in Schriftform oder als E-Mail erfolgen. Die
						E-Mail-Adresse von der immoweb AG ist “ info@immoweb.de „. Die postalische Anschrift von der
						immoweb AG ist: immoweb AG, Dortmunder Straße 5, 44536 Lünen . Die postalische Anschrift sowie
						die E-Mail-Adresse eines Nutzers sind diejenigen, die als aktuelle Kontaktdaten im Nutzerkonto
						des Nutzers von diesem angegeben worden sind. Die immoweb AG behält sich vor, die vorstehend
						genannte E-Mail-Adresse nach eigenem Ermessen zu ändern. In diesem Fall informiert die immoweb
						AG den Nutzer über die Änderung der E-Mail-Adresse.
					</p>
					<p>
						(2) Soweit der Nutzer Unternehmer ist, unterliegt das Nutzungsverhältnis einschließlich dieser
						allgemeinen Geschäftsbedingungen dem Recht der Bundesrepublik Deutschland unter Ausschluss des
						Rechts der Vereinten Nationen über den internationalen Warenkauf. Soweit der Nutzer Verbraucher
						ist, unterliegt das Nutzungsverhältnis einschließlich dieser allgemeinen Geschäftsbedingungen
						dem Recht der Bundesrepublik Deutschland, sofern dem keine zwingenden gesetzlichen Vorschriften,
						insbesondere keine Vorschriften des Verbraucherschutzes entgegenstehen.
					</p>
					<p>
						(3) Soweit es sich bei dem Nutzer um einen Kaufmann im Sinne des Handelsgesetzbuchs (HGB), ein
						öffentlich-rechtliches Sondervermögen oder eine juristische Person des öffentlichen Rechts
						handelt oder dieser keinen Gerichtsstand im Inland hat, ist Dortmund ausschließlicher
						Gerichtsstand für alle aus dem Nutzungsverhältnis und diesen allgemeinen Geschäftsbedingungen
						entstehenden Streitigkeiten. Die immoweb AG ist auch berechtigt, am allgemeinen Gerichtsstand
						des Nutzers zu klagen.
					</p>
					<h3>§ 11 Schlussbestimmungen</h3>
					<p>
						(1) Die Regelungen der eCommerce-Richtlinie der Europäischen Gemeinschaften bzw. des diese
						umsetzenden deutschen Rechts über die Informationspflichten und über den Vertragsabschluss sind
						auf dieses Nutzungsverhältnis nicht anwendbar. Sie sind von den Nutzern im Rahmen der zwischen
						ihnen abgewickelten Handelsgeschäften zu beachten.
					</p>
					<p>
						(2) Diese allgemeinen Geschäftsbedingungen der immoweb AG gelten ausschließlich.
						Entgegenstehende oder von den allgemeinen Geschäftsbedingungen der immoweb AG abweichende
						Bedingungen von Nutzungsinteressenten oder Nutzern erkennt die immoweb AG nicht an. Die immoweb
						AG behält sich vor, diese allgemeinen Geschäftsbedingungen jederzeit und ohne Nennung von
						Gründen zu ändern. Die geänderten Bedingungen werden dem Nutzer per E-Mail zwei Wochen vor ihrem
						Inkrafttreten zugesandt. Widerspricht der Nutzer der Geltung der neuen allgemeinen
						Geschäftsbedingungen nicht innerhalb von zwei Wochen nach Empfang der E-Mail, gelten die
						geänderten allgemeinen Geschäftsbedingungen als angenommen. Die immoweb AG wird den Nutzer in
						der E-Mail, welche die geänderten Bedingungen enthält, auf die Bedeutung dieser Zweiwochenfrist
						gesondert hinweisen.
					</p>
					<p>
						(3) Sollte eine Bestimmung dieses Vertrages unwirksam sein oder werden, so bleibt der Vertrag im
						Übrigen davon unberührt. Die unwirksame Bestimmung ist so umzudeuten, zu ergänzen oder zu
						ersetzen, dass der in der unwirksamen Bedingung beabsichtigte Sinn und Zweck soweit wie möglich
						erreicht wird. Dasselbe gilt, wenn bei Durchführung des Vertrages eine ergänzungsbedürftige
						Lücke offenbar wird.
					</p>
					<p>18. Oktober 2004</p>
				</div>
			</section>
		</div>
	);
};

export default TermsAndConditions;
