import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Layout from './layout';
import ObjectDetails from 'components/objectDetails/container/objectDetails';
import HomeContainer from 'components/home/container/homeContainer';
import Contact from 'components/contact/container/contactContainer';
import Dashboard from 'components/finder/container/finder';
import UserProfile from 'components/profile/container/userProfile';
import PropertyPage from 'components/myPropertyPage/container/propertyPage';
import Aboutus from 'components/publicPage/container/aboutus';
import Impressum from 'components/publicPage/container/Impresasupage';
import EnergyCertificate from 'components/publicPage/container/energyCertificate';
import HelpPage from 'components/publicPage/container/helpPage';
import PriceAdvice from 'components/publicPage/container/priceAdvice';
import OurTeam from 'components/publicPage/container/ourTeam';
import AppraisePage from 'components/publicPage/container/appraisePage';
import DataProtection from 'components/publicPage/container/dataProtection';
import TermsAndConditions from 'components/publicPage/container/termsAndConditions';

const Router = () => {
	const isLogin = useSelector((state: any) => state.auth.isLogin);
	return (
		<Layout>
			<Routes>
				{isLogin ? (
					<>
						<Route path='/user-profile' element={<UserProfile />} />
						<Route path='/user-property' element={<PropertyPage />} />
						<Route path='/' element={<HomeContainer />} />
					</>
				) : (
					[
						'/',
						'/register',
						'/login',
						'/forgot-password',
						'/reset-password/:token',
						'/activation/:token'
					].map((path, index) => <Route path={path} element={<HomeContainer />} key={index} />)
				)}

				{['/objekt/:id', '/user-object/:id'].map((path, index) => (
					<Route path={path} element={<ObjectDetails />} key={index} />
				))}
				<Route path='/suche' element={<Dashboard />} />
				<Route path='/kontakt' element={<Contact />} />
				<Route path='/ueber-uns' element={<Aboutus />} />
				<Route path='/impressum' element={<Impressum />} />
				<Route path='/hilfe' element={<HelpPage />} />
				<Route path='/energieausweis' element={<EnergyCertificate />} />
				<Route path='/kaufpreisberatung' element={<PriceAdvice />} />
				<Route path='/franchise' element={<OurTeam />} />
				<Route path='/gutachten' element={<AppraisePage />} />
				<Route path='/datenschutz' element={<DataProtection />} />
				<Route path='/agb' element={<TermsAndConditions />} />
				<Route path='*' element={<HomeContainer />} />
			</Routes>
		</Layout>
	);
};

export default Router;
