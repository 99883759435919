import React, { FC } from 'react';
interface IPasswordChangeSuccessfullyProps {
	onSubmit: () => void;
	btnText: string;
}

const PasswordChangeSuccessfully: FC<IPasswordChangeSuccessfullyProps> = (props) => {
	return (
		<div className='login-register-wrapper position-relative'>
			<div className='login-div login-register-div'>
				<div className='login-tittle-wrapper'>
					<p>Ihr Passwort wurde erfolgreich geändert.</p>
				</div>
				<form className='login-form-wrapper'>
					<button
						type='button'
						value='register'
						className={`login-button common-button`}
						onClick={props.onSubmit}
					>
						{props.btnText}
					</button>
				</form>
			</div>
		</div>
	);
};

export default PasswordChangeSuccessfully;
