import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { BackButtonIcon } from 'components/commonComponents/icons';
import InputComponent from '../components/inputComponent';
import { AuthClassProps } from '../interface/auth';
import { popupName } from 'components/layout/constants';
import { notify } from 'components/commonComponents/notification/notification';
import CustomSpinner from 'components/commonComponents/customSpinner';

import { API_CONFIG, HttpService } from 'services';

import '../auth.scss';

interface forgotProps {
	forgotPasswordBack: string;
}
const ForgotPassword: React.FC<AuthClassProps & forgotProps> = (props) => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		defaultValues: {
			email: ''
		}
	});
	const [loading, setLoading] = useState(false);

	const onSubmit = (data: any) => {
		setLoading(true);
		HttpService.post(API_CONFIG.path.forgot, {
			email: data.email
		})
			.then((response) => {
				notify(`${response.message}`, 'success');
				props.handlePopup(popupName.forgotEmailPopup);
				setLoading(false);
			})
			.catch((err) => {
				notify(`${err.response.data.message}`, 'error');
				setLoading(false);
			});
	};
	return (
		<div className='login-register-wrapper position-relative'>
			<div
				className='text--right position-absolute close-btn cursor-pointer'
				onClick={() => props.handlePopup(props.forgotPasswordBack)}
			>
				<BackButtonIcon />
			</div>

			<div className='login-div login-register-div forgot-password-div'>
				<div className='login-tittle-wrapper '>
					<h3 className='login-register-tittle'>PASSWORT VERGESSEN?</h3>
				</div>
				<div className='forgot-password-tex-wrapper'>
					<p className='text--center'>
						Geben Sie hier Ihre bei uns geführte E-Mail-Adresse ein. Sie erhalten dann eine E-Mail mit Ihrem
						Benutzernamen und einem Link, über den Sie das Passwort zurücksetzen können.
					</p>
				</div>
				<form onSubmit={handleSubmit((data) => onSubmit(data))} className='login-form-wrapper'>
					<div className='login-input'>
						<InputComponent
							name='email'
							label='E-Mail oder Benutzername: '
							placeholder='beispiel@adresse.de'
							type='text'
							pattern={
								//eslint-disable-next-line
								/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
							}
							register={register}
							errors={errors}
							requiredErrors='Bitte geben Sie die E-Mail-Adresse ein, die Sie bei der Registrierung angegeben haben.'
							patternErrors='You have entered an invalid email address!'
						/>
					</div>

					<button type='submit' value='register' className='login-button common-button' disabled={loading}>
						{loading ? (
							<CustomSpinner animation='border' className='spinner-border-sm' />
						) : (
							<>Passwort zurücksetzen</>
						)}
					</button>
				</form>
			</div>
		</div>
	);
};

export default ForgotPassword;
