import React from 'react';
import { store } from 'store/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Routers from './router';

import 'rheostat/initialize';
import ErrorHandler from 'shared/withErrorHandler/errorHandler';

function App() {
	return (
		<div className='App'>
			<Provider store={store}>
				<ErrorHandler />
				<BrowserRouter>
					<Routers />
				</BrowserRouter>
			</Provider>
		</div>
	);
}

export default App;
