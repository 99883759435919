import React, { InputHTMLAttributes } from "react";
import { FieldErrors } from "react-hook-form";
import "./checkbox.scss";

interface Props {
  label: string;
  className?: string;
  checked: boolean;
  customOnChange?: (isChecked: boolean) => void;
  error?: any;
}

export const CustomCheckBox = (
  props: Props & InputHTMLAttributes<HTMLInputElement>
) => {
  const {
    label,
    className,
    customOnChange,
    checked,
    onChange,
    error,
    ...rest
  } = props;

  return (
    <div className={className || ""}>
      <label className="custom-checkbox-container">
        <input
          {...rest}
          type="checkbox"
          checked={checked || false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (customOnChange) {
              customOnChange(e.target.checked);
              return;
            }
            if (onChange) {
              onChange(e);
            }
          }}
        />
        <span className="checkmark"></span>
        <span className="check-box-label">{label}</span>
      </label>
      {error && <span className="error-message">{error.message}</span>}
    </div>
  );
};
