import React, { FC } from 'react';
import BackButton from 'components/commonComponents/backbutton';

interface IProps {
	backButton: boolean;
	handleSaveClick?: () => void;
	goBack?: () => void;
	showSpinner?: boolean;
	isDisabled?: boolean;
	buttonType?: 'button' | 'reset' | 'submit' | undefined;
	buttonTxt?: string;
}

const PropertyButtonDiv: FC<IProps> = ({
	backButton,
	handleSaveClick,
	goBack,
	showSpinner,
	isDisabled = false,
	buttonType = 'submit',
	buttonTxt = 'Nächster Schritt'
}) => (
	<div className='property-button-div flex align__items--center  justify__content--center'>
		{backButton && (
			<div onClick={goBack}>
				<BackButton />
			</div>
		)}
		<button
			className={`next-step-button common-button flex align__items--center justify__content--center ${
				isDisabled ? 'disable' : ''
			}`}
			type={buttonType}
			onClick={handleSaveClick}
			disabled={isDisabled || showSpinner}
		>
			{buttonTxt}
			{showSpinner && (
				<span className='spinner-border spinner-border-sm ml-1' role='status' aria-hidden='true'></span>
			)}
		</button>
	</div>
);

export default PropertyButtonDiv;
