import React from 'react';
import MetaDecorator from 'shared/meta/metaDecorator';
import TitleImageSection from '../components/titleImageSection';
import imprintImage from '../../../assets/images/imprint-image.png';

const DataProtection = () => {
	return (
		<div className='data-protection-page public-page-font'>
			<MetaDecorator title='Impressum - Immoweb | Immobilien, Wohnungen und Häuser bei immoweb' />
			<TitleImageSection backgroundImage={imprintImage} titleText={'Datenschutzerklärung'} />
			<section className='description-section section-width'>
				<div className='description-tittle'>
					<h3>Verantwortliche Stelle für die Verarbeitung nach DS-GVO</h3>
					<p>
						Verantwortlicher im Sinne der Datenschutz-Grundverordnung und sonstiger in den Mitgliedstaaten
						der Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit
						datenschutzrechtlichem Charakter ist die:
					</p>
					<p>Immoweb AG</p>
					<p>Dortmunder Straße 5</p>
					<p>44536 Lünen</p>
					<p>+49 (0) 231 / 398197-0</p>
					<p>info@immoweb.de</p>
					<h3>Datenschutzerklärung</h3>
					<p>
						Wir begrüßen Sie auf unseren Webseiten und freuen uns über Ihr Interesse. Der Schutz Ihrer
						personenbezogenen Daten ist uns ein wichtiges Anliegen. Deshalb betreiben wir unsere Aktivitäten
						in Übereinstimmung mit den anwendbaren Rechtsvorschriften zum Schutz von personenbezogenen Daten
						und zur Datensicherheit. Wir möchten Sie nachfolgend darüber informieren, welche Daten Ihres
						Besuchs zu welchen Zwecken verwendet werden. Sollten darüber hinaus noch Fragen zum Umgang mit
						Ihren personenbezogenen Daten offen bleiben, können Sie sich gerne an unseren
						Datenschutzbeauftragten wenden:
					</p>
					<h3>1. Was sind personenbezogene Daten?</h3>
					<p>
						Der Begriff der personenbezogenen Daten ist im Bundesdatenschutzgesetz und in der EU-DS-GVO
						definiert. Danach sind dies Einzelangaben über persönliche oder sachliche Verhältnisse einer
						bestimmten oder bestimmbaren natürlichen Person. Darunter fallen also beispielsweise Ihr
						bürgerlicher Name, Ihre Anschrift, Ihre Telefonnummer oder Ihr Geburtsdatum.
					</p>
					<h3>2. Umfang der anonymen Datenerhebung und Datenverarbeitung</h3>
					<p>
						Soweit in den folgenden Abschnitten nicht anderweitig dargestellt, werden bei Nutzung unserer
						Webseiten grundsätzlich keine personenbezogenen Daten erhoben, verarbeitet oder genutzt. Wir
						erfahren aber über den Einsatz von Analyse- und Trackingtools bestimmte technische Informationen
						anhand der von Ihrem Browser übermittelten Daten (beispielsweise Browsertyp/-version,
						verwendetes Betriebssystem, bei uns besuchte Webseiten inkl. Verweildauer, zuvor besuchte
						Webseite). Diese Informationen werten wir nur zu statistischen Zwecken aus.
					</p>
					<h3>3. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h3>
					<p>
						Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen
						Person einholen, dient Art. 6 Abs. 1 lit. a EU-Datenschutzgrundverordnung (DS-GVO) als
						Rechtsgrundlage für die Verarbeitung personenbezogener Daten.
					</p>
					<p>
						Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen
						Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DS-GVO
						als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung
						vorvertraglicher Maßnahmen erforderlich sind.
					</p>
					<p>
						Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung
						erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DS-GVO als
						Rechtsgrundlage.
					</p>
					<p>
						Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen
						natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6
						Abs. 1 lit. d DS-GVO als Rechtsgrundlage.
					</p>
					<p>
						Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
						Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des
						Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 lit. f DS-GVO als
						Rechtsgrundlage für die Verarbeitung.
					</p>
					<h3>4. Verwendung von Cookies</h3>
					<p>
						Die Internetseiten der Immoweb AG verwenden Cookies. Bei Cookies handelt es sich um Daten, die
						vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Die Cookies können
						beim Aufruf einer Seite an diese übermittelt werden und ermöglichen somit eine Zuordnung des
						Nutzers. Cookies helfen dabei, die Nutzung von Internetseiten für die Nutzer zu vereinfachen. In
						diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der personenbezogenen
						Daten nach Art. 6 Abs. 1 lit. f DS-GVO. Wir verwenden auf unserer Website darüber hinaus
						Cookies, die eine Analyse des Surfverhaltens der Nutzer ermöglichen.
					</p>
					<p>(1) Eingegebene Suchbegriffe</p>
					<p>(2) Häufigkeit von Seitenaufrufen</p>
					<p>(3) Inanspruchnahme von Website-Funktionen</p>
					<p>
						Es ist jederzeit möglich der Setzung von Cookies durch entsprechende Änderung der Einstellung im
						Internetbrowser zu widersprechen. Gesetzte Cookies können gelöscht werden. Es wird darauf
						hingewiesen, dass bei Deaktivierung von Cookies möglicherweise nicht alle Funktionen unserer
						Internetseite vollumfänglich genutzt werden können. Die auf diese Weise erhobenen Daten der
						Nutzer werden durch technische Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung der Daten
						zum aufrufenden Nutzer nicht mehr möglich. Die Daten werden nicht gemeinsam mit sonstigen
						personenbezogenen Daten der Nutzer gespeichert.
					</p>
					<p>
						Beim Aufruf unserer Website werden die Nutzer durch einen Infobanner über die Verwendung von
						Cookies zu Analysezwecken informiert und auf diese Datenschutzerklärung verwiesen. Es erfolgt in
						diesem Zusammenhang auch ein Hinweis darauf, wie die Speicherung von Cookies in den
						Browsereinstellungen unterbunden werden kann.
					</p>
					<p>
						Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung technisch
						notweniger Cookies ist Art. 6 Abs. 1 lit. f DS-GVO. Die Rechtsgrundlage für die Verarbeitung
						personenbezogener Daten unter Verwendung von Cookies zu Analysezwecken ist bei Vorliegen einer
						diesbezüglichen Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DS-GVO.
					</p>
					<h3>5. Erstellung von Logfiles</h3>
					<p>
						Bei jedem Aufruf der Internetseite erfasst die Immoweb AG durch ein automatisiertes System Daten
						und Informationen. Diese werden in den Logfiles des Servers gespeichert. Die Daten werden
						ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen
						mit anderen personenbezogenen Daten des Nutzers findet nicht statt.
					</p>
					<p>Folgende Daten können hierbei erhoben werden:</p>
					<p>(1) Informationen über den Browsertyp und die verwendete Version</p>
					<p>(2) Das Betriebssystem des Nutzers</p>
					<p>(3) Den Internet-Service Provider des Nutzers</p>
					<p>(4) Die IP-Adresse des Nutzers</p>
					<p>(5) Datum und Uhrzeit des Zugriffs</p>
					<p>(6) Webseiten, von denen das System des Nutzers auf unsere Internetseite gelangt (Referrer)</p>
					<p>(7) Webseiten, die vom System des Nutzers über unsere Webseite aufgerufen werden</p>
					<h3>6. Registrierung auf unserer Internetseite</h3>
					<p>
						Nutzt die betroffene Person die Möglichkeit, sich auf der Internetseite des für die Verarbeitung
						Verantwortlichen unter Angabe von personenbezogenen Daten zu registrieren, so werden die Daten
						in der jeweiligen Eingabemaske an den für die Verarbeitung Verantwortlichen übermittelt. Die
						Daten werden ausschließlich zum Zweck der internen Verwendung beim für die Verarbeitung
						Verantwortlichen gespeichert. Die Daten werden gelöscht, sobald sie für die Erreichung des
						Zweckes ihrer Erhebung nicht mehr erforderlich sind.
					</p>
					<p>
						Bei der Registrierung werden die IP-Adresse des Nutzers sowie Datum und Uhrzeit der
						Registrierung gespeichert. Dies dient dazu, einen Missbrauch der Dienste zu verhindern. Eine
						Weitergabe der Daten an Dritte erfolgt nicht. Eine Ausnahme besteht dann, wenn eine gesetzliche
						Verpflichtung zur Weitergabe besteht.
					</p>
					<p>
						Die Registrierung der Daten ist für die Bereitstellung von Inhalten oder Leistungen
						erforderlich. Registrierte Personen haben jederzeit die Möglichkeit die gespeicherten Daten
						löschen oder abzuändern zu lassen. Die betroffene Person erhält jederzeit Auskunft über ihre
						gespeicherten personenbezogenen Daten.
					</p>
					<h3>7. Newsletter</h3>
					<p>
						Wird der Newsletter unseres Unternehmens abonniert, so werden die Daten in der jeweiligen
						Eingabemaske an den für die Verarbeitung Verantwortlichen übermittelt. Die Anmeldung zu unserem
						Newsletter erfolgt in einem sog. Double-Opt-In-Verfahren. D.h. Sie erhalten nach der Anmeldung
						eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden. Diese Bestätigung ist
						notwendig, damit sich niemand mit fremden E-Mailadressen anmelden kann.
					</p>
					<p>
						Bei der Anmeldung zum Newsletter werden die IP-Adresse des Nutzers sowie Datum und Uhrzeit der
						Registrierung gespeichert. Dies dient dazu, einen Missbrauch der Dienste oder der E-Mail-Adresse
						der betroffenen Person zu verhindern. Eine Weitergabe der Daten an Dritte erfolgt nicht. Eine
						Ausnahme besteht dann, wenn eine gesetzliche Verpflichtung zur Weitergabe besteht.
					</p>
					<p>
						Die Daten werden ausschließlich für den Versand des Newsletters verwendet. Das Abonnement des
						Newsletters kann durch die betroffene Person jederzeit gekündigt werden. Ebenso kann die
						Einwilligung in die Speicherung der personenbezogenen Daten jederzeit widerrufen werden. Zu
						diesem Zweck findet sich in jedem Newsletter ein entsprechender Link.
					</p>
					<p>
						Rechtsgrundlage für die Verarbeitung der Daten nach Anmeldung zum Newsletter durch den Nutzer
						ist bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DS-GVO. Rechtsgrundlage
						für den Versand des Newsletters infolge des Verkaufs von Waren oder Dienstleistungen ist § 7
						Abs. 3 UWG.
					</p>
					<h3>8. Möglichkeiten zur Kontaktaufnahme</h3>
					<p>
						Auf den Internetseiten von Immoweb AG ist ein Kontaktformular vorhanden, das für die
						elektronische Kontaktaufnahme genutzt werden kann. Alternativ ist eine Kontaktaufnahme über die
						bereitgestellte E-Mail-Adresse möglich. Nimmt die betroffene Person über einen dieser Kanäle
						Kontakt mit dem für die Verarbeitung Verantwortlichen auf, so werden die von der betroffenen
						Person übermittelten personenbezogenen Daten automatisch gespeichert. Die Speicherung dient
						allein zu Zwecken der Bearbeitung oder der Kontaktaufnahme zur betroffenen Person. Eine
						Weitergabe der Daten an Dritte findet nicht statt. Rechtsgrundlage für die Verarbeitung der
						Daten ist bei Vorliegen einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DS-GVO.
					</p>
					<p>
						Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail
						übermittelt werden, ist Art. 6 Abs. 1 lit. f DS-GVO. Zielt der E-Mail-Kontakt auf den Abschluss
						eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b
						DS-GVO.
					</p>
					<p>
						Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr
						erforderlich sind. Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und
						diejenigen, die per E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige
						Konversation mit dem Nutzer beendet ist. Beendet ist die Konversation dann, wenn sich aus den
						Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist.
					</p>
					<p>
						Die während des Absende-Vorgangs zusätzlich erhobenen personenbezogenen Daten werden spätestens
						nach einer Frist von sieben Tagen gelöscht.
					</p>
					<h3>9. Routinemäßige Löschung und Sperrung von personenbezogenen Daten</h3>
					<p>
						Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene Daten der
						betroffenen Person nur solange, wie dies zur Erreichung des Speicherungszwecks erforderlich ist.
						Eine Speicherung kann darüber hinaus dann erfolgen, soweit dies durch den europäischen oder
						nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften,
						denen der für die Verarbeitung verantwortliche unterliegt, vorgesehen wurde.
					</p>
					<p>
						Sobald der Speicherungszweck entfällt oder eine durch die genannten Vorschriften vorgeschriebene
						Speicherfrist abläuft, werden die personenbezogenen Daten routinemäßig gesperrt oder gelöscht.
					</p>
					<h3>10. Rechte der betroffenen Person</h3>
					<p>
						Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DS-GVO und es
						stehen Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:
					</p>
					<h3>10.1 Auskunftsrecht</h3>
					<p>
						Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene
						Daten, die Sie betreffen, von uns verarbeitet werden.
					</p>
					<p>
						Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende
						Informationen Auskunft verlangen:
					</p>
					<ol>
						<li>die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</li>
						<li>die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</li>
						<li>
							die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen Ihre personenbezogenen
							Daten offengelegt wurden oder noch offengelegt werden;
						</li>
						<li>
							die geplante Dauer der Speicherung Ihrer personenbezogenen Daten oder, falls konkrete
							Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
						</li>
						<li>
							das Bestehen eines Rechts auf Berichtigung oder Löschung Ihrer personenbezogenen Daten,
							eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines
							Widerspruchsrechts gegen diese Verarbeitung;
						</li>
						<li>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</li>
						<li>
							alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten
							nicht bei der betroffenen Person erhoben werden;
						</li>
						<li>
							das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art.
							22 Abs. 1 und 4 DS-GVO und - zumindest in diesen Fällen - aussagekräftige Informationen über
							die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen
							Verarbeitung für die betroffene Person.
						</li>
					</ol>
					<p>
						Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob Ihre personenbezogenen Daten in ein
						Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang
						können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DS-GVO im Zusammenhang mit der
						Übermittlung unterrichtet zu werden.
					</p>
					<h3>10.2 Recht auf Berichtigung</h3>
					<p>
						Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen,
						sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder
						unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
					</p>
					<h3>10.3 Recht auf Einschränkung der Verarbeitung</h3>
					<p>
						Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung Ihrer
						personenbezogenen Daten verlangen:
					</p>
					<ol>
						<li>
							wenn Sie die Richtigkeit Ihrer personenbezogenen Daten für eine Dauer bestreiten, die es dem
							Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;
						</li>
						<li>
							die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen
							und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;
						</li>
						<li>
							der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger
							benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von
							Rechtsansprüchen benötigen, oder
						</li>
						<li>
							wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DS-GVO eingelegt haben und
							noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegüber Ihren Gründen
							überwiegen.
						</li>
					</ol>
					<p>
						Wurde die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt, dürfen diese Daten - von
						ihrer Speicherung abgesehen - nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
						Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
						juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder
						eines Mitgliedstaats verarbeitet werden.
					</p>
					<p>
						Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie
						von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
					</p>
					<h3>10.4 Recht auf Löschung</h3>
					<p>
						<span>10.4.1</span> Sie können von dem Verantwortlichen verlangen, dass Ihre personenbezogenen
						Daten unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet diese Daten
						unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:
					</p>
					<ol>
						<li>
							Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder
							auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
						</li>
						<li>
							Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a
							oder Art. 9 Abs. 2 lit. a DS-GVO stützte, und es fehlt an einer anderweitigen
							Rechtsgrundlage für die Verarbeitung.
						</li>
						<li>
							Sie legen gem. Art. 21 Abs. 1 DS-GVO Widerspruch gegen die Verarbeitung ein und es liegen
							keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die Sie legen gem. Art.
							21 Abs. 2 DS-GVO Widerspruch gegen die Verarbeitung ein.
						</li>
						<li>Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</li>
						<li>
							Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer
							rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
							erforderlich, dem der Verantwortliche unterliegt.
						</li>
						<li>
							Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
							Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.
						</li>
					</ol>
					<p>
						<span>10.4.2</span>Hat der Verantwortliche Ihre personenbezogenen Daten öffentlich gemacht und
						ist er gem. Art. 17 Abs. 1 DS-GVO zu deren Löschung verpflichtet, so trifft er unter
						Berücksichtigung der verfügbaren Technologie und der Implementierungskosten angemessene
						Maßnahmen, auch technischer Art, um für die Datenverarbeitung Verantwortliche, die die
						personenbezogenen Daten verarbeiten, darüber zu informieren, dass Sie als betroffene Person von
						ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von Kopien oder
						Replikationen dieser personenbezogenen Daten verlangt hat.
					</p>
					<p>
						<span>10.4.3</span>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich
						ist
					</p>
					<ol>
						<li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
						<li>
							zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union
							oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur
							Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher
							Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
						</li>
						<li>
							aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9
							Abs. 2 lit. h und i sowie Art. 9 Ab. 3 DS-GVO;
						</li>
						<li>
							ür im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische
							Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DS-GVO, soweit das in Abs.
							1 genannte Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich
							macht oder ernsthaft beeinträchtigt, oder
						</li>
						<li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</li>
					</ol>
					<h3>10.5 Recht auf Unterrichtung</h3>
					<p>
						Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem
						Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie
						betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der
						Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
						unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden.
					</p>
					<p>
						Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu
						werden.
					</p>
					<h3>10.6 Recht auf Datenübertragbarkeit</h3>
					<p>
						Sie haben das das Recht, Ihre personenbezogenen Daten, die Sie dem Verantwortlichen
						bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu
						erhalten. Außerdem haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne
						Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden,
						zu übermitteln, sofern
					</p>
					<ol>
						<li>
							die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DS-GVO oder Art. 9 Abs. 2
							lit. a DS-GVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DS-GVO beruht und
						</li>
						<li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
					</ol>
					<p>
						In Ausübung dieses Rechts haben Sie ferner das Recht zu erwirken, dass Ihre personenbezogenen
						Daten direkt von einem Verantwortlichen zu einem anderen Verantwortlichen übermittelt werden,
						soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht
						beeinträchtigt werden.
					</p>
					<p>
						Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die
						für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in
						Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
					</p>
					<h3>10.7 Widerspruchsrecht</h3>
					<p>
						Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit
						gegen die Verarbeitung Ihrer personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder
						f DS-GVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen
						gestütztes Profiling.
					</p>
					<p>
						Der Verantwortliche verarbeitet Ihre personenbezogenen Daten nicht mehr, es sei denn, er kann
						zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
						Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
						Verteidigung von Rechtsansprüchen.
					</p>
					<p>
						Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das
						Recht, jederzeit Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten zum Zwecke
						derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
						Direktwerbung in Verbindung steht.
					</p>
					<p>
						Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden Ihre
						personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
					</p>
					<p>
						Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der
						Informationsgesellschaft - ungeachtet der Richtlinie 2002/58/EG - Ihr Widerspruchsrecht mittels
						automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
					</p>
					<h3>10.8 Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h3>
					<p>
						Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen.
						Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis
						zum Widerruf erfolgten Verarbeitung nicht berührt.
					</p>
					<h3>10.9 Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h3>
					<p>
						Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung -
						einschließlich Profiling - beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber
						rechtliche Wirkung entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt
						nicht, wenn die Entscheidung
					</p>
					<ol>
						<li>
							für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen
							erforderlich ist,
						</li>
						<li>
							aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
							Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen
							zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder
						</li>
						<li>mit Ihrer ausdrücklichen Einwilligung erfolgt.</li>
					</ol>
					<p>
						Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten
						nach Art. 9 Abs. 1 DS-GVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder g gilt und angemessene
						Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen
						wurden.
					</p>
					<p>
						Hinsichtlich der in a. und c. genannten Fälle trifft der Verantwortliche angemessene Maßnahmen,
						um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das
						Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des
						eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
					</p>
					<h3>10.10 Recht auf Beschwerde bei einer Aufsichtsbehörde</h3>
					<p>
						Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht
						Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
						Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie
						der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen die DS-GVO verstößt.
					</p>
					<p>
						Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den
						Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit
						eines gerichtlichen Rechtsbehelfs nach Art. 78 DS-GVO.
					</p>
					<h3>11. Weitergabe der Daten an Dritte</h3>
					<h3>11.1 Google Analytics und Conversion Tracking</h3>
					<p>
						Seit der Abstimmung des Hamburgischen Beauftragten für Datenschutz und Informationsfreiheit mit
						Google auf Grundlage des Beschlusses des Düsseldorfer Kreises zur datenschutzkonformen
						Ausgestaltung von Analyseverfahren zur Reichweitenmessung bei Internet-Angeboten ist eine
						datenschutzkonforme und beanstandungsfreie Nutzung von Google Analytics unter bestimmten
						Bedingungen möglich. An diese Voraussetzungen halten wir uns. Insbesondere weisen wir darauf
						hin, dass auf dieser Webseite Google Analytics um den Code „gat._anonymizeIp();“ erweitert
						wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten.
					</p>
					<p>Bitte beachten Sie auch die nachfolgenden Informationen zum Einsatz von Google Analytics:</p>
					<p>
						Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“).
						Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert
						werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen.
					</p>
					<p>
						Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der
						Regel an einen Server von Google in den USA übertragen und dort gespeichert.
					</p>
					<p>
						Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird Ihre IP-Adresse von
						Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
						Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in
						Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und
						dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen,
						um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten
						zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene
						Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.
					</p>
					<p>
						Zusätzlich nutzen wir im Zusammenhang mit Google Analytics das Google Conversion Tracking. Dies
						ermöglicht uns das Verhalten von unseren Webseitenbesucher zu erfassen. Zum Beispiel wird uns
						angezeigt, wie viele PDF’s auf unserer Webseite gedownloadet oder wie oft das Kontaktformular
						ausgefüllt wurde. Ebenso wird uns angezeigt, wie viele Klicks auf Werbeanzeigen von externen
						Quellen (AdWords, LinkedIn, Xing, Facebook, Pinterest, Instagram usw.) auf unsere Webseite
						geführt haben.
					</p>
					<p>
						Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
						anderen Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine
						entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin,
						dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
						werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und
						auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
						Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link
						(https://tools.google.com/dlpage/gaoptout?hl=de) verfügbare Browser-Plugin herunterladen und
						installieren.
					</p>
					<p>
						Sie können die Erfassung durch Google Analytics verhindern, indem Sie auf folgenden Link
						klicken. Es wird ein Opt-Out-Cookie gesetzt, der die zukünftige Erfassung Ihrer Daten beim
						Besuch dieser Website verhindert: Google Analytics deaktivieren
					</p>
					<p>
						Weitergehende Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter&nbsp;
						<a href='https://www.google.com/analytics/terms/de.html' target='_blank'>
							https://www.google.com/analytics/terms/de.html
						</a>
					</p>
					<p>
						bzw. unter&nbsp;
						<a href='https://www.google.de/intl/de/policies/' target='_blank'>
							https://www.google.de/intl/de/policies/
						</a>
					</p>
					<p>
						Wir nutzen Google Analytics zudem dazu, Daten aus AdWords und dem Double-Click-Cookie zu
						statistischen Zwecken auszuwerten. Sollten Sie dies nicht wünschen, können Sie dies über den
						Anzeigenvorgaben-Manager&nbsp;
						<a href='https://www.google.com/settings/ads/anonymous?hl=de' target={'_blank'}>
							https://www.google.com/settings/ads/anonymous?hl=de
						</a>
						&nbsp; deaktivieren.
					</p>
					<h3>11.2 Google Adwords und Conversion-Tracking</h3>
					<p>
						Um auf unsere aktuellen Projekte und Entwicklungen, geplante Aktivitäten und Dienstleistungen
						aufmerksam zu machen, schalten wir Google-AdWords-Anzeigen und nutzen im Rahmen dessen das
						Google Conversion-Tracking. Diese Anzeigen werden nach Suchanfragen auf Webseiten des
						Google-Werbenetzwerkes eingeblendet. Wir haben die Möglichkeit unsere Anzeigen mit bestimmten
						Suchbegriffen zu kombinieren. Daneben nutzen wir AdWords-Remarketing-Listen für Suchanzeigen.
						Dies ermöglicht uns die Anpassung von Suchanzeigenkampagnen für Nutzer, die unsere Website schon
						einmal besucht haben. Durch die Dienste haben wir die Möglichkeit, unsere Anzeigen mit
						bestimmten Suchbegriffen zu kombinieren oder für vorherige Besucher Anzeigen zu schalten, in
						denen z.B. Dienstleistungen beworben werden, welche sich die Besucher auf unserer Website
						angesehen haben.
					</p>
					<p>
						Für interessenbezogene Angebote ist eine Analyse des Online-Nutzerverhaltens notwendig. Zur
						Durchführung dieser Analyse setzt Google Cookies ein. Beim Klick auf eine Anzeige oder beim
						Besuch auf unserer Website wird auf dem Computer des Nutzers durch Google ein Cookie gesetzt.
						Diese Informationen werden genutzt, um den Besucher bei einer späteren Suchanfrage gezielt
						ansprechen zu können.
					</p>
					<p>
						Weitergehende Informationen zur eingesetzten Cookie-Technologie finden Sie auch bei den
						Hinweisen von Google zu den Website-Statistiken und in den Datenschutzbestimmungen.
					</p>
					<p>
						Mit Hilfe dieser Technologie erhalten Google und wir als Kunde Informationen darüber, dass ein
						Nutzer auf eine Anzeige geklickt hat und zu unseren Webseiten weitergeleitet wurde, um mit uns
						über das Kontaktformular Kontakt aufzunehmen. Ebenso erhalten Google und wir als Kunde mithilfe
						von Google-Weiterleitungsnummern Informationen darüber, dass ein Nutzer im Internet auf eine
						Telefonnummer von uns geklickt und telefonisch Kontakt zu uns aufgenommen hat. Die hierbei
						erlangten Informationen werden ausschließlich für eine statistische Auswertung zur
						Anzeigenoptimierung genutzt. Wir erhalten keine Informationen, mit denen sich Besucher
						persönlich identifizieren lassen. Die uns von Google zur Verfügung gestellten Statistiken
						beinhalten die Gesamtzahl der Nutzer, die auf eine unserer Anzeigen geklickt haben, und ggf., ob
						dieser zu einer mit einem Conversion-Tag versehenen Seite unseres Webauftrittes weitergeleitet
						wurden. Anhand dieser Statistiken können wir nachvollziehen, bei welchen Suchbegriffen besonders
						oft auf unsere Anzeige geklickt wurde und welche Anzeigen zu einer Kontaktaufnahme über das
						Kontaktformular oder über das Telefon durch den Nutzer führen. In Bezug auf die telefonische
						Kontaktaufnahme durch Interessenten oder Kunden beinhalten die von Google zur Verfügung
						gestellten Statistiken die Startzeit, das Ende, den Status (entgangen oder erhalten),
					</p>
					<p>die Dauer (Sekunden), die Ortsvorwahl des Anrufers, die Telefonkosten sowie den Anruftypen.</p>
					<p>
						Wenn Sie das nicht möchten, können Sie das Speichern des für diese Technologien erforderlichen
						Cookies bspw. über die Einstellungen Ihres Browsers unterbinden. In diesem Fall fließt Ihr
						Besuch nicht in die Nutzerstatistiken ein.
					</p>
					<p>
						Sie haben zudem die Möglichkeit über die Anzeigeneinstellung die Typen von Google Anzeigen
						auszuwählen bzw. interessenbezogene Anzeigen auf Google zu deaktivieren. Alternativ können Sie
						der Verwendung von Cookies durch Drittanbieter deaktivieren, indem Sie die Deaktivierungshilfe
						der Netzwerkwerbeinitiative aufrufen.
					</p>
					<p>
						Wir und Google erhalten allerdings weiterhin die statistische Information, wie viele Nutzer wann
						diese Seite besucht haben. Wenn Sie auch in diese Statistik nicht aufgenommen werden möchten,
						können Sie dies mit Hilfe von zusätzlichen Programmen für Ihren Browser (bspw. mit dem Add-on
						Ghostery) unterbinden.
					</p>
					<h3>11.3 Google Fonts</h3>
					<p>
						Auf diesen Internetseiten werden externe Schriften, Google Fonts verwendet. Google Fonts ist ein
						Dienst der Google Inc. („Google“). Die Einbindung dieser Web Fonts erfolgt durch einen
						Serveraufruf, in der Regel ein Server von Google in den USA. Hierdurch wird an den Server
						übermittelt, welche unserer Internetseiten Sie besucht haben. Auch wird die IP-Adresse des
						Browsers des Endgerätes des Besuchers dieser Internetseiten von Google gespeichert. Nähere
						Informationen finden Sie in den Datenschutzhinweisen von Google, die Sie hier abrufen können:
					</p>
					<p>www.google.com/fonts#AboutPlace:about</p>
					<p>
						<a href='www.google.com/policies/privacy/' target={'_blank'}>
							www.google.com/policies/privacy/
						</a>
					</p>
					<h3>11.4 Google Maps</h3>
					<p>
						Diese Webseite verwendet Google Maps API, um geographische Informationen visuell darzustellen.
						Bei der Nutzung von Google Maps werden von Google auch Daten über die Nutzung der
						Maps-Funktionen durch Besucher der Webseiten erhoben, verarbeitet und genutzt. Nähere
						Informationen über die Datenverarbeitung durch Google können Sie den Datenschutzhinweisen von
						Google entnehmen. Dort können Sie im Datenschutzcenter auch Ihre Einstellungen verändern, so
						dass Sie Ihre Daten verwalten und schützen können.
					</p>
					<p>
						Hier finden Sie weitergehende Anleitungen zum Verwalten der eigenen Daten im Zusammenhang mit
						Google-Produkten:
					</p>
					<p>
						<a href='https://support.google.com/accounts/answer/3024190' target={'_blank'}>
							https://support.google.com/accounts/answer/3024190
						</a>
					</p>
					<h3>12. Einbindung von übrigen Diensten und Inhalten Dritter</h3>
					<p>
						Es kann vorkommen, dass innerhalb dieses Onlineangebotes Inhalte Dritter, wie zum Beispiel
						Videos von YouTube, Kartenmaterial von Google-Maps, RSS-Feeds oder Grafiken von anderen
						Webseiten eingebunden werden. Dies setzt immer voraus, dass die Anbieter dieser Inhalte
						(nachfolgend bezeichnet als „Dritt-Anbieter“) die IP-Adresse der Nutzer wahrnehmen. Denn ohne
						die IP-Adresse, könnten sie die Inhalte nicht an den Browser des jeweiligen Nutzers senden. Die
						IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche
						Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der
						Inhalte verwenden. Jedoch haben wir keinen Einfluss darauf, falls die Dritt-Anbieter die
						IP-Adresse z.B. für statistische Zwecke speichern. Soweit dies uns bekannt ist, klären wir die
						Nutzer darüber auf.
					</p>
					<h3>13. Dauer der Speicherung personenbezogener Daten</h3>
					<p>
						Personenbezogene Daten werden für die Dauer der jeweiligen gesetzlichen Aufbewahrungsfrist
						gespeichert. Nach Ablauf der Frist erfolgt eine routinemäßige Löschung der Daten, sofern nicht
						eine Erforderlichkeit für eine Vertragsanbahnung oder die Vertragserfüllung besteht.
					</p>
					<h3>14. Karriere (Ausbildung & Stellenangebote)</h3>
					<p>
						Sie können sich auch auf elektronischem Wege bei unserem Unternehmen bewerben. Ihre Angaben
						werden wir selbstverständlich ausschließlich zur Bearbeitung Ihrer Bewerbung verwenden und nicht
						an Dritte weitergeben. Bitte beachten Sie, dass unverschlüsselt übersandte E-Mails nicht
						zugriffsgeschützt übermittelt werden.
					</p>
					<h3>15. Sicherheit</h3>
					<p>
						Wir haben umfangreiche technische und betriebliche Schutzvorkehrungen getroffen, um Ihre Daten
						vor zufälligen oder vorsätzlichen Manipulationen, Verlust, Zerstörung oder dem Zugriff
						unberechtigter Personen zu schützen. Unsere Sicherheitsverfahren werden regelmäßig überprüft und
						dem technologischen Fortschritt angepasst. Darüber hinaus wird der Datenschutz bei uns
						fortlaufend gewährt, durch ständige Auditierung und Optimierung der Datenschutzorganisation.
					</p>
					<h3>16. Einbindung von Google Tag Manager</h3>
					<p>
						Auf dieser Webseite wird der Google Tag Manager eingesetzt. Der Google Tag Manager ist eine
						Lösung von Google LLC, mit der Unternehmen Website-Tags über eine Oberfläche verwalten können.
						Bei dem Google Tag Manager handelt es sich um eine cookielose Domain, die keine
						personenbezogenen Daten erfasst. Der Google Tag Manager sorgt für die Auslösung anderer Tags,
						die ihrerseits unter Umständen Daten erfassen. Darauf weisen wir hiermit gesondert hin. Der
						Google Tag Manager greift nicht auf diese Daten zu. Sofern auf Domain- oder Cookie-Ebene eine
						Deaktivierung von dem Nutzer vorgenommen wurde, bleibt diese für alle Tracking-Tags bestehen,
						die mit Google Tag Manager implementiert werden.
					</p>
					<p>
						Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre
						Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um
						weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem
						Websitebetreiber zu erbringen. Dazu verwendet Google Pseudonyme. Die im Rahmen von Google
						Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
						zusammengeführt.
					</p>
					<p>
						Mehr Informationen zum Google Tag Manager finden Sie unter:&nbsp;
						<a href='https://www.google.com/intl/tagmanager/' target={'_blank'}>
							https://www.google.com/intl/tagmanager/
						</a>
					</p>
					<p>
						Immoweb AG behält sich sämtliche Rechte vor um Änderungen und Aktualisierungen dieser
						Datenschutzerklärung vorzunehmen. Diese Datenschutzerklärung wurde erstellt am 17.07.2018.
					</p>
				</div>
			</section>
		</div>
	);
};

export default DataProtection;
