import React from 'react';
import TitleImageSection from '../components/titleImageSection';
import PriceAdviceImage from '../../../assets/images/price-advice-image.png';
import sellerView from '../../../assets/images/seller-view.png';
import buyerView from '../../../assets/images/buyer-view.png';
import taxOfficeView from '../../../assets/images/tax-office-view.png';
import bankView from '../../../assets/images/bank-view.png';
import MetaDecorator from 'shared/meta/metaDecorator';

const PriceAdvice = () => {
	return (
		<div className='price-advice-section public-page-font'>
			<MetaDecorator title='Kaufpreisberatung - Immoweb | Immobilien, Wohnungen und Häuser bei immoweb' />
			<TitleImageSection backgroundImage={PriceAdviceImage} titleText={'Kaufpreisberatung'} />
			<section className='section-width information-section'>
				<h1 className='text--center'>
					Sichere vor dem Kauf deine
					<br /> Immobilie ab.
				</h1>
				<p className='text--center'>
					Sie sind fündig geworden und wollen jetzt den endgültigen Schritt wagen? In den meisten
					Kaufverträgen ist die Gewährleistung für eine Immobilie ausgeschlossen. Umgangssprachlich spricht
					man von „Gekauft wie gesehen“. Viele Mängel sind für den Laien auf den ersten Blick nicht sichtbar
					oder müssen nicht akut sein. Durch falsche Isolationen am Dach oder Keller können Mängel erst Jahre
					später entstehen. Diese verursachen erhebliche Kosten auf die Sie vorbereitet sein sollten. Durch
					knappe Finanzierungen sind schon viele Immobilienbesitzer in finanzielle Schieflage gekommen.
					Sichern Sie sich ab! Unsere Gutachter begleiten Sie dabei, sich bei der wichtigsten Entscheidungen
					in Ihrem Leben sicher zu fühlen.
				</p>
			</section>
			<section className='view-of-property-section'>
				<div className='section-width'>
					<h1>Die Sicht auf eine Immobilie hängt immer vom Auge des Betrachters ab.</h1>
					<div className='property-view flex justify__content--between'>
						<div className='property-div'>
							<img src={sellerView} alt='sellerView' />
							<h3>
								Das sieht <br />
								der Verkäufer.
							</h3>
						</div>
						<div className='property-div'>
							<img src={buyerView} alt='buyerView' />
							<h3>
								Das sieht <br />
								der Käufer.
							</h3>
						</div>
					</div>
					<div className='property-view flex justify__content--between'>
						<div className='property-div'>
							<img src={taxOfficeView} alt='taxOfficeView' />
							<h3>
								Das sieht <br />
								das Finanzamt
							</h3>
						</div>
						<div className='property-div'>
							<img src={bankView} alt='bankView' />
							<h3>
								Das sieht <br />
								die Bank.
							</h3>
						</div>
					</div>
				</div>
			</section>
			<section className='section-width information-section advised-section'>
				<h1 className='text--center'>So wirst du beraten.</h1>
				<p className='text--center m-b-15'>
					Zu Beginn vereinbaren wir einen Termin um die Immobilie zu „untersuchen“. Unsere Gutachter nutzen
					dazu Hilfsmittel, wie z.B. ein digitales Messgerät oder ein Feuchtemessgerät. Dem geschulten Auge
					unserer Mitarbeiter entgeht kein baulicher Mangel. Durch die Ermittlung der Ursache eines Mangels
					verstehen Sie besser, welche Konsequenz dieser haben kann.
				</p>
				<p className='text--center m-b-15'>
					Wir vermitteln Ihnen einen anderen Blickwinkel für das Gebäude. In der Vergangenheit haben wir
					verschiedene Erfahrungen nach einer Besichtigung gemacht. Oft ist es erfreulich, den geforderten
					Preis zu bestätigen. In dieser Win-win-Situation ist der Preis fair verhandelt worden. Sie als
					Käufer, der Verkäufer und der Makler machen ein gutes Geschäft.
				</p>
				<p className='text--center m-b-15'>
					Anders ergibt sich die Situation wenn Mängel festgestellt werden, die vorher nicht im Preis
					inbegriffen waren. Durch die aufgezeigten Mängel der Gutachter haben sie die Möglichkeit darzulegen,
					dass der Wert des Hauses nicht dem geforderten Preis entspricht.
				</p>
				<p className='text--center m-b-15'>
					Sollten Sie sich aufgrund der Mängel oder eines zu hohen Kaufpreises gegen den Kauf der Immobilie
					entscheiden, lassen Sie nicht im Regen stehen. Die Preise für mehrere Kaufpreisberatungen bieten wir
					gestaffelt an. Je mehr Häuser wir begutachten, desto günstiger wird es für Sie. Es besteht also auch
					die Möglichkeit, dass wir mehrere Immobilien begutachten um diese dann zu vergleichen. Den Preis für
					mehrere Kaufpreisberatungen bekommen Sie auf Anfrage bei unserem Partner der DG Deutsche Gutachten
					AG (
					<a href='https://www.deutsche-gutachten.de/kaufberatung' target='_blank'>
						https://www.deutsche-gutachten.de/kaufberatung
					</a>
					).
				</p>
			</section>
		</div>
	);
};

export default PriceAdvice;
