import React from 'react';
// components
import OfficeLocation from '../components/officeLocation';
import { TopObjects } from '../components/topObjects';
// functions
import { Services } from '../components/services';
// assets
import '../home.scss';
import { FilterSection } from '../components/filterSection';

const HomeContainer = () => {
	// const [showData, setShowData] = useState("");
	// useEffect(() => {
	//   const token = localStorage.getItem("token");
	//   setShowData(token as any);
	// }, []);
	return (
		<div className='home-container'>
			<hr className='common-hr' />
			<section className='teaser-wrapper'>
				<div className='teaser-image-wrapper flex justify__content--around align__items--center flex--column '>
					<div className='teaser-title-wrapper  text--center'>
						<h1 className='teaser-title'>
							Zuhause ist.
							<br />
							Wo Du bist.
						</h1>
					</div>

					<div className='teaser-subtitle-wrapper text--center'>
						<h4 className='teaser-subtitle '>
							Jetzt online <br />
							mieten, kaufen oder
							<br />
							verkaufen.
						</h4>
					</div>
				</div>
			</section>
			<FilterSection />
			<OfficeLocation />
			<TopObjects />
			<hr className='common-hr' />
			<Services />
		</div>
	);
};
export default HomeContainer;
