import React, { useEffect, useRef } from 'react';
import { setRef } from 'services';

const Register = () => {
	const footerRef: any = useRef();
	// const {
	// 	register,
	// 	formState: { errors },
	// 	setValue,
	// 	handleSubmit
	// } = useForm();

	useEffect(() => {
		setRef(footerRef, 'registerRef');
	}, []);
	// const onSubmit = () => {
	// 	setValue('email', '');
	// };

	return (
		<section /* className='register-section' */ ref={footerRef}>
			{/* <div className='text-center'>
				<p className='register-text'>Jetzt für den Newsletter anmelden und immer auf dem neusten Stand sein.</p>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='flex justify__content--center align__items--center register-form'>
						<input
							className={`register-email ${errors.email?.type && 'register-email-error'}`}
							type='text'
							placeholder='Deine E-Mail'
							aria-label='user email'
							aria-describedby='basic-addon1'
							{...register('email', {
								required: true,
								pattern:
									/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
							})}
						/>

						<button type='submit' className='send-button'>
							SENDEN
						</button>
					</div>
				</form>
			</div> */}
		</section>
	);
};

export default Register;
