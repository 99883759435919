import React, { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { myPropertyRules, propertyOption, propertyTab, requiredFields } from '../utils/constants';
import PropertyButtonDiv from './propertyButtonDiv';
import InputField from 'components/commonComponents/form/inputText';
import InputSelect from 'components/commonComponents/form/inputSelect';
import { CustomCheckBox } from 'components/commonComponents/form/checkBox/checkbox';
import { CrossIcon } from 'components/commonComponents/icons';
import { PropertyTabProps } from '../utils/myProperty.interface';

interface LocationTabForm {
	adrRaw: string;
	adrCity: string;
	adrPostalCode: number;
	adrCountry: { id: string; value: string; label: string };
	showAddress: boolean;
}
const LocationTab: FC<PropertyTabProps> = ({ gotoBack, isLoading, selectedValue }) => {
	const {
		control,
		formState: { errors }
	} = useFormContext<LocationTabForm>();
	const [isActiveInfo, setIsActiveInfo] = useState(false);

	return (
		<div>
			<div className='address-field-wrapper'>
				{selectedValue !== propertyOption.land && (
					<Controller
						name='adrRaw'
						control={control}
						rules={myPropertyRules.adrRaw}
						render={({ field: { ref, ...rest } }) => (
							<div className='input-field-wrapper'>
								<InputField
									label='Straße und Hausnummer'
									asterisk={requiredFields.includes('adrRaw')}
									error={errors.adrRaw}
									type={'text'}
									{...rest}
								/>
							</div>
						)}
					/>
				)}
				<Controller
					name='adrCity'
					control={control}
					rules={myPropertyRules.adrCity}
					render={({ field: { ref, ...rest } }) => (
						<div className='input-field-wrapper'>
							<InputField
								label='Stadt/Ort'
								asterisk={requiredFields.includes('adrCity')}
								error={errors.adrCity}
								type={'text'}
								{...rest}
							/>
						</div>
					)}
				/>
				<Controller
					name='adrPostalCode'
					control={control}
					rules={myPropertyRules.adrPostalCode}
					render={({ field: { ref, ...rest } }) => (
						<div className='input-field-wrapper'>
							<InputField
								label='Postleitzahl'
								asterisk={requiredFields.includes('adrPostalCode')}
								error={errors.adrPostalCode}
								type={'number'}
								{...rest}
							/>
						</div>
					)}
				/>
				<Controller
					name='adrCountry'
					control={control}
					rules={myPropertyRules.adrCountry}
					defaultValue={
						{
							label: 'Deutschland',
							value: 'Deutschland',
							id: 'Deutschland'
						} as LocationTabForm['adrCountry']
					}
					render={({ field: { ref, ...rest } }) => (
						<div className='input-field-wrapper'>
							<InputSelect
								label='Land'
								placeholder='Bitte wählen'
								defaultValue={{
									label: 'Deutschland',
									value: 'Deutschland',
									id: 'Deutschland'
								}}
								isDisabled
								asterisk={requiredFields.includes('adrCountry')}
								className={'disabled-section'}
								error={errors.adrCountry}
								{...rest}
							/>
						</div>
					)}
				/>
			</div>
			{selectedValue !== propertyOption.land && (
				<Controller
					name='showAddress'
					control={control}
					render={({ field: { ref, value, onChange, ...rest } }) => {
						return (
							<div className='flex align__items--center'>
								<CustomCheckBox
									checked={value}
									label='Adresse im Immobilieninserat anzeigen'
									className={'flex justify__content--between location-tab-check-box'}
									customOnChange={(e) => {
										onChange(e);
										setIsActiveInfo(value);
									}}
									{...rest}
								/>
								{isActiveInfo && (
									<div className='info-div position-relative'>
										<div className='tooltip-text'>
											<div
												className='position-absolute close-icon cursor-pointer'
												onClick={() => setIsActiveInfo(false)}
											>
												<CrossIcon />
											</div>
											<span>
												Wenn Sie die genaue Adresse anzeigen, wird Ihre Immobilie eher gefunden,
												weil die genaue Lage ein entscheidendes Kriterium für Interessenten ist.
											</span>
										</div>
									</div>
								)}
							</div>
						);
					}}
				/>
			)}

			<PropertyButtonDiv
				backButton={true}
				showSpinner={isLoading}
				goBack={() => gotoBack(propertyTab.generally)}
			/>
		</div>
	);
};

export default LocationTab;
