import { Action } from "utils/globalInterface";

const INITIAL_STATE = {
  response: [],
};

export const addUpdateReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case "ADD_UPDATE_OBJECT_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "ADD_UPDATE_OBJECT_PENDING":
      return {
        status: "pending",
      };

    case "ADD_UPDATE_OBJECT_REJECTED":
      return {
        status: "fail",
        data: action.payload.data,
      };

    case "CLEAR_ADD_UPDATE_OBJECT":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};
