import React from 'react';
import mapImage from '../../../assets/images/mapimage.png';

const OfficeLocation = () => {
	const showCityData = (data: any) => {
		return (
			<div className='city-name'>
				{data.map((value: any, index: number) => (
					<a key={index} href={value.href} target='_blank' rel='noreferrer'>
						{value.city}
					</a>
				))}
			</div>
		);
	};

	return (
		<section className='location-section'>
			<div className='location-wrapper'>
				<div className='location-text'>
					<h1 className='location-tittle text--center'>Wir sind für Dich vor Ort.</h1>
					<p className='location-subtittle text--center'>
						Mit zahlreichen Büros in ganz Deutschland ist immoweb.de auch ganz persönlich für Dich da.
					</p>
				</div>
				<div className='city-name-div justify__content--center align__items--center text-center'>
					{showCityData(cityName1)}

					<div className='text-center map-wrapper'>
						<img src={mapImage} alt='mapimage.png' />
					</div>

					{showCityData(cityName2)}
				</div>
			</div>
		</section>
	);
};

export default OfficeLocation;

export const cityName1 = [
	{ city: 'Kiel', href: 'http://buero.immoweb.de/kiel' },
	{ city: 'Schwerin', href: 'http://buero.immoweb.de/schwerin' },
	{ city: 'Hamburg', href: 'http://buero.immoweb.de/hamburg/' },
	{
		city: 'Krakow am See',
		href: 'http://buero.immoweb.de/mecklenburgische-schweiz'
	},
	{ city: 'Lüneburg', href: 'http://buero.immoweb.de/lueneburg' },
	{ city: 'Potsdam', href: 'http://buero.immoweb.de/potsdam' }
];

export const cityName2 = [
	{ city: 'Hamm', href: 'http://buero.immoweb.de/hamm' },
	{ city: 'Bochum', href: 'http://buero.immoweb.de/bochum' },
	{ city: 'Dortmund', href: 'http://www.maklerdortmund.de/' },
	{ city: 'Essen', href: 'http://buero.immoweb.de/essen' },
	{ city: 'Lünen', href: 'http://buero.immoweb.de/luenen' },
	{ city: 'Niederbayern', href: 'http://buero.immoweb.de/dingolfing/' }
];
