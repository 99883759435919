import React from 'react';
import { components } from 'react-select';
import { EnergyIcon, DocumentsIcon, FrameIcon, FranchiseIcon, CaretDownIcon } from 'components/commonComponents/icons';
import { propertyOptions } from 'utils';
import englishText from 'languages/english';

export const filterdropdownData = {
	Radius: 'Radius',
	Mieten: 'Mieten',
	Wohnung: 'Wohnung'
};

export const dropdownRadius = [
	{ id: 'radius', label: '5 KM', value: 5 },
	{ id: 'radius', label: '10 KM', value: 10 },
	{ id: 'radius', label: '15 KM', value: 15 },
	{ id: 'radius', label: '20 KM', value: 20 },
	{ id: 'radius', label: '25 KM', value: 25 },
	{ id: 'radius', label: '30 KM', value: 30 },
	{ id: 'radius', label: '35 KM', value: 35 },
	{ id: 'radius', label: '40 KM', value: 40 },
	{ id: 'radius', label: '45 KM', value: 45 },
	{ id: 'radius', label: '50 KM', value: 50 }
];
export const dropdownMieten = [
	{ id: 'demandType', label: 'Kaufen', value: propertyOptions.BUY },
	{ id: 'demandType', label: 'Mieten', value: propertyOptions.RENT }
];

export const dropdownWohnung = [
	{ id: 'objectType', label: 'Wohnung', value: 'Apartment' },
	{ id: 'objectType', label: 'Haus', value: 'House' },
	{ id: 'objectType', label: 'Grundstück', value: 'Plot' }
];

export const roomFilter = [
	{ id: 'fromNumberOfRooms', label: '1', value: '1' },
	{ id: 'fromNumberOfRooms', label: '2+', value: '2' },
	{ id: 'fromNumberOfRooms', label: '3+', value: '3' },
	{ id: 'fromNumberOfRooms', label: '4+', value: '4' },
	{ id: 'fromNumberOfRooms', label: '5+', value: '5' },
	{ id: 'fromNumberOfRooms', label: '6+', value: '6' }
];
export const costFilter = [
	{ id: 'Mietpreis bis', value: 'Mietpreis bis 500 EUR', perameeter: 'EUR' },
	{ id: 'Wohnfläche ab', value: 'Wohnfläche ab 100 qm', perameeter: 'qm' }
];

export const service = [
	{
		icon: <EnergyIcon className='service-icons' />,
		title: 'Energieausweis',
		subtitle: 'mehr erfahren',
		navigate: '/energieausweis'
	},
	{
		icon: <DocumentsIcon className='service-icons' />,
		title: 'Gutachten ',
		subtitle: 'mehr erfahren',
		navigate: '/gutachten'
	},
	{
		icon: <FrameIcon className='service-icons' />,
		title: 'Kaufpreisberatung ',
		subtitle: 'mehr erfahren',
		navigate: '/kaufpreisberatung'
	},
	{
		icon: <FranchiseIcon className='service-icons' />,
		title: 'Franchise',
		subtitle: 'mehr erfahren',
		navigate: '/franchise'
	}
];

export const filterData = [
	{
		placeholder: 'Radius',
		options: dropdownRadius,
		isMulti: false,
		name: 'radius'
	},
	{
		placeholder: 'Bitte wählen',
		options: dropdownMieten,
		isMulti: true,
		name: 'objectType'
	},
	{
		placeholder: 'Bitte wählen',
		options: dropdownWohnung,
		isMulti: true,
		name: 'propertyType'
	}
];

export const roomData = [{ placeholder: '1', options: roomFilter }];

export const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			<CaretDownIcon />
		</components.DropdownIndicator>
	);
};

export const resetPasswordInput = [
	{
		name: 'password',
		label: 'Neues Passwort',
		placeholder: 'Neues Passwort',
		type: 'password'
	},
	{
		name: 'confirmPwd',
		label: 'Bestätige Passwort',
		placeholder: 'Bestätige Passwort',
		type: 'password'
	}
];

export const loginRegisterInputs = [
	{
		name: 'email',
		label: 'E-Mail oder Benutzername    ',
		placeholder: 'beispiel@email.com',
		type: 'text',
		pattern:
			//eslint-disable-next-line
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
		requiredErrors: 'Bitte geben Sie die E-Mail-Adresse ein, die Sie bei der Registrierung angegeben haben.',
		patternErrors: 'Sie haben eine ungültige Email-Adresse eingegeben !'
	}
];

export const SEARCH_FILTERS = [
	{ name: 'price', label: englishText.PRICE, value: 'fromPrice', className: 'price-div-de' },
	{ name: 'space', label: englishText.SPACE, value: 'fromAreaLiving', className: 'area-div-de' },
	{ name: 'rooms', label: englishText.ROOMS, value: 'fromNumberOfRooms', className: 'rooms-div-de' }
];
