import React from 'react';
import { DropdownIndicator } from '../utils/constants';
import Select, { StylesConfig } from 'react-select';

interface CustomInputProps {
	placeholder?: string;
	option?: any;
	style: any;
	changeValue: (event: any) => void;
	defaultValue?: any;
	isDisabled?: boolean;
	tooltipTitle?: string;
}

const DropdownInput = (props: CustomInputProps) => {
	const { changeValue, defaultValue, isDisabled = false, tooltipTitle = '' } = props;
	return (
		<div className='cursor-pointer' title={tooltipTitle}>
			<Select
				components={{ DropdownIndicator }}
				className='react-select-container z-index--3'
				classNamePrefix='react-select'
				styles={props.style as StylesConfig}
				options={props.option}
				name={props.placeholder}
				isSearchable={false}
				placeholder={props.placeholder}
				onChange={(event) => changeValue(event)}
				defaultValue={defaultValue}
				isDisabled={isDisabled}
				// menuIsOpen={true}
			/>
		</div>
	);
};

export default DropdownInput;
