import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { setPlaceholderImg } from 'utils';
import * as imageGalleryInterface from '../../interface/objectDetails';

import { CloseIcon } from 'components/commonComponents/icons';

const ImageGallary = (props: imageGalleryInterface.IImageGalleryProps) => {
	const [imageList, setImageList] = useState<any[]>([]);

	useEffect(() => {
		if (imageList.length === 0) {
			const list: {}[] = [];
			props.imgList.forEach((item: any) => {
				list.push({
					original: item.original,
					thumbnail: item.original
				});
			});
			setImageList(list);
		}
	}, [imageList.length, props.imgList]);

	return (
		<div className={`image-gallery-popup carousel ${imageList && imageList.length === 1 && 'one-image-slider'}`}>
			<div className='close-btn-div' onClick={() => props.closeGallery()}>
				<CloseIcon />
			</div>
			<ImageGallery
				showBullets={false}
				items={imageList}
				showPlayButton={false}
				showFullscreenButton={false}
				showThumbnails={true}
				startIndex={props.startIndex}
				onImageError={setPlaceholderImg}
				onThumbnailError={setPlaceholderImg}
			/>
		</div>
	);
};

export default ImageGallary;
