import englishText from "./en";

const objectDetailGermanText = {
  [englishText.PROPERTY_DETAILS]: "Objekt Details",
  [englishText.FINANCIAL_DETAILS]: "Finanzielle Details",
  [englishText.MONTHLY_COST_EST]: "Monatliche Kosten (voraus.)",
  [englishText.PLOT_AREA]: "Grundstücksfläche",
  [englishText.FLOORS]: "Etagen",
  [englishText.CONDITION]: "Gebäude-Zustand",
  [englishText.PRICE_TREND]: "Preistrend 10 Jahre",
  [englishText.VACANT_FROM]: "Verfügbar ab",
  [englishText.ENERGY_EFF]: "Energieeffizient",
  [englishText.LOCATION_INFO]: "Wohnlage / Karte",
  [englishText.ONLINE_SINCE]: "Online seit",
  [englishText.OBJ_DESC]: "Objektbeschreibung",
  [englishText.ENERGY_CERTIFICATE]: "Energiezertifikat",
  [englishText.ENERGY_CLASS]: "Energieeffizienzklasse",
  [englishText.UNDER_CONSTRUCTION]: "Im Bau",
  [englishText.FACTS]: "Daten",
  [englishText.FINANCES]: "Finanzen",
  [englishText.DETAILS]: "Details",
  [englishText.FURNISHING]: "Möbliert",
  [englishText.BEDROOMS]: "Schlafzimmer",
  [englishText.BATHROOMS]: "Badezimmer",
  [englishText.CONSTRUCTION_PHASE]: "Konstruktionsphase",
  [englishText.PURCHASE_PRICE]: "Kaufpreis",
  [englishText.PURCHASE_PRICE_M]: "Preis pro m²",
  [englishText.ADDITIONAL_COST]: "Zzgl. Kaufkosten",
  [englishText.TOTAL_COSTS]: "Gesamtkosten",
  [englishText.KITCHEN_FITTED]: "Einbauküche",
  [englishText.SHOPPING]: "Einkaufsmöglichkeit",
  [englishText.PRIMARY_SCHOOL]: "Grundschule",
  [englishText.UNIVERSITY]: "Universität",
  [englishText.PUBLIC_TRANSPORT]: "Öffentl. Nahverkehr",
  [englishText.PRICE_PER_METER]: "Preis pro m²",
  [englishText.SHOW_LESS]: "Zeige weniger",
  [englishText.READ_MORE]: "Weiterlesen...",
  [englishText.POPULATION]: "Einwohner",
  [englishText.PROP_SUB_TYPE]: "Bestimmter Immobilientyp",
  [englishText.BALCONY]: "Balkon / Terrasse",
  [englishText.GARDEN]: "Garten",
  [englishText.GUEST_TOILET]: "Gästetoilette",
  [englishText.QUARTER]: "Stadtgebiet",
  [englishText.CELLER]: "Keller",
  [englishText.PLATFORM]: "Plattform",
  [englishText.PROPERTY_DISABLED]: "Die Immobilie ist nicht mehr verfügbar",
  [englishText.LOCATION_INFORMATION]: "Umgebung",
  [englishText.POPULATION]: "Einwohner",
  [englishText.LOCAL_SUPPLY]: "Versorgung",
  [englishText.ROOMS_OP]: "Zimmer",
  [englishText.ROOM]: "Zimmer",
  [englishText.LIVING_SPACE]: "Wohnfläche",
  [englishText.OTHER]: "Sonstiges",
  [englishText.SEARCH_LOCATION]: "Suche Ort",
  [englishText.CLEAR]: "Leeren",
  [englishText.NOTIFICATION]: "Benachrichtigung",
  [englishText.YES]: "Ja",
  [englishText.NO]: "Nein",
  [englishText.NEW]: "Neu! ",
  [englishText.HELP_TEXT_1]:
    "Interessiert dich diese Immobilie? Wir würden dir gerne weiterhelfen und mit Rat und Tat bei Seite stehen.",
  [englishText.HELP_TEXT_2]:
    "Lass uns jetzt kostenlos und ganz unverbindlich dich unterstützen!",
  [englishText.NO_EXTRA]: "Nicht vorhanden",
  [englishText.CONTINUE]: "Weiter",

  // House Types
  [englishText.DETACHED_HOUSE]: "Freistehendes Haus",
  [englishText.SEMI_DETECHED_HOUSE]: "Doppelhaushälfte",
  [englishText.BUNGLOW]: "Bungalow",
  [englishText.MID_TERRACE_HOUSE]: "Mittleres Reihenhaus",
  [englishText.VILLA]: "Villa",
  [englishText.PENTHOUSE]: "Penthouse",
  [englishText.FLAT]: "Wohnung",
  [englishText.Apartment]: "Wohnung",
  [englishText.ATTIC_FLOOR]: "Dachgeschoss",
  [englishText.GROUND_FLOOR]: "Erdgeschosswohnung",
  [englishText.MAISON]: "Maisonette-Wohnung",
  [englishText.RAISED_FLOOR]: "Hochparterre",
  [englishText.TERRACE_APT]: "Wohnung mit Terasse",
  [englishText.BASEMENT]: "Souterrain",
  [englishText.LOFT]: "Loft",
  [englishText.OTHER_1]: "Sonstiges",
  [englishText.SEMI_DETECTHED]: "Doppelhaushälfte",
  [englishText.DETACHED_HOUSE_2]: "Freistehendes Haus",
  [englishText.END_HOUSE]: "End-Reihenhaus",
  [englishText.BUNGLOW_1]: "Bungalow",
  [englishText.SPCL_PROPERTY]: "Sondereigentum",
  [englishText.APT_BLOCK]: "Mehrfamilienhaus",
  [englishText.MID_TERRACE_HOUSE_1]: "Mittleres Reihenhaus",
  [englishText.FREE_HOLD_FLAT]: "Eigentumswohnung",
  [englishText.RES_AND_OFFICE]: "Wohn- und Bürogebäude",
  [englishText.HALL_STORAGE]: "Hallenlager",
  [englishText.MISC]: "Sonstiges",
  [englishText.COM_UNIT]: "Gewerbliche Einheit",
  [englishText.RESIDEN_COMPLEX]: "Wohnkomplex",
  [englishText.SHOPSALE]: "Laden/Verkaufsbereich",
  [englishText.COM_PROPERTY]: "Gewerbeimmobilie",
  [englishText.SPECIAL_PROPERTY]: "Besondere Immobilie",
  [englishText.APART_BLOCK]: "Mehrfamilienhaus",
  [englishText.FARMHOUSE]: "Bauernhaus",
  [englishText.DOUBLE_TOWNHOUSE]: "Doppel- oder Reihenhaus",
  [englishText.APAARTMENT]: "Eigentumswohnung",
  [englishText.DETACHED_HOUSE_1]: "Freistehendes Haus",
};

export default objectDetailGermanText;
