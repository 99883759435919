import React from 'react';
import TitleImageSection from '../components/titleImageSection';
import imprintImage from '../../../assets/images/imprint-image.png';
import MetaDecorator from 'shared/meta/metaDecorator';

const Impressum = () => {
	return (
		<div className='imprint-wrapper public-page-font'>
			<MetaDecorator title='Impressum - Immoweb | Immobilien, Wohnungen und Häuser bei immoweb' />
			<TitleImageSection backgroundImage={imprintImage} titleText={'Impressum'} />
			<section className='description-section section-width'>
				<div className='description-tittle'>
					<div className='description-subtittle'>
						<h4>immoweb Aktiengesellschaft</h4>
						<p>Dortmunder Straße 544536 Lünen, Deutschland</p>
						<p>Tel:+49 (0)231 / 398197-0</p>
						<p>Fax:+49 (0)2306 / 2048456</p>
						<p>
							Web:&nbsp;
							<a href='https://immoweb.de/' target='_blank' rel='noreferrer'>
								www.immoweb.de
							</a>
							&nbsp;E-Mail:&nbsp;
							<a href='mailto:info@immoweb.de' target='_blank' rel='noreferrer'>
								info@immoweb.de
							</a>
						</p>
					</div>

					<div className='description-subtittle'>
						<h4>Vorstand</h4>
						<p>Dipl.-Ing. Thomas Schlüchter</p>
					</div>

					<div className='description-subtittle'>
						<h4>Aufsichtsrat</h4>
						<p>Dipl.-Ing. Stephan Reiter (Vorsitzender)</p>
						<p>Dipl.-Ing. Arch. Viktor Nachtigall</p>
						<p>Dipl.-Oec. Marc Krzewinski-Jakobs</p>
					</div>
					<div className='description-tittle'>
						<h3>Unternehmensdaten</h3>
						<div className='description-subtittle'>
							<h4>Registergericht </h4>
							<p>Dortmund</p>
						</div>

						<div className='description-subtittle'>
							<h4>Registernummer </h4>
							<p>HRB 21043</p>
						</div>
						<div className='description-subtittle'>
							<h4>Umsatzsteuer-Identifikationsnummer</h4>
							<p>DE813927585</p>
						</div>

						<div className='description-subtittle'>
							<p>
								Eine Erlaubnis nach §34c der Gewerbeordnung in der Fassung vom 22.02.1999 (BGBl. I S
								1999 S. 202) liegt seit dem 02.08.2004 vor. Eine Erlaubnis nach §34b Abs. 1 der
								Gewerbeordnung in der Fassung vom 24.04.2003 (Versteigererverordnung, VerstV) liegt seit
								dem 25.07.2008 vor.
							</p>
						</div>

						<div className='description-subtittle'>
							<p>Inhaltlich Verantwortlicher gemäß § 6 MDStV: Dipl.-Ing. Thomas Schlüchter</p>
						</div>
					</div>

					<div className='description-tittle'>
						<h3>Haftungshinweis</h3>
						<p>
							Trotz sorgfältiger Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links.
							Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
						</p>
					</div>

					<div className='description-tittle'>
						<h3>Bildnachweis</h3>
						<p>
							<a
								href='https://www.shutterstock.com/'
								target='_blank'
								className='text-decoration-none'
								rel='noreferrer'
							>
								shutterstock.com
							</a>
						</p>
					</div>

					<div className='description-tittle'>
						<h3>Zuständiges Gewerbeamt nach §34c GewO</h3>
						<h4>Berufsaufsichtsbehörde</h4>
						<p>Ordnungsamt Kreis Unna</p>
						<p>Friedrich-Ebert-Straße 17</p>
						<p>59425 Unna</p>
						<p>Fon 0 23 03 / 27-0 (Zentrale)</p>
						<p>Fax 0 23 03 / 27-13 99 (zentral)</p>
						<p>
							<a href='mailto:post@kreis-unna.de' target='_blank' rel='noreferrer'>
								post@kreis-unna.de
							</a>
						</p>
						<p>
							<a href='https://www.kreis-unna.de/' target='_blank' rel='noreferrer'>
								www.kreis-unna.de
							</a>
						</p>
					</div>

					<div className='description-tittle'>
						<h3>EU-Streitschlichtung</h3>
						<p>
							Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
							bereit:&nbsp;
							<a
								href='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage'
								target='_blank'
								rel='noreferrer'
							>
								https://ec.europa.eu/consumers/odr
							</a>
							.Unsere E-Mail-Adresse finden Sie oben im Impressum.
						</p>
					</div>

					<div className='description-tittle'>
						<h3>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h3>
						<p>
							Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
							Verbraucherschlichtungsstelle teilzunehmen.
						</p>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Impressum;
