import React, { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
	currencyField,
	marketingTypeOption,
	myPropertyRules,
	propertyOption,
	propertyTab,
	requiredFields,
	verkaufCurrencyField
} from '../utils/constants';
import InputSelect from 'components/commonComponents/form/inputSelect';
import PropertyButtonDiv from './propertyButtonDiv';
import InputField from 'components/commonComponents/form/inputText';
import { PropertyTabProps } from '../utils/myProperty.interface';
import { propertyOptions } from 'utils';

interface FinanceTabForm {
	marketingType: { id: string; value: string; label: string };
	totalRent: number;
	depositCost: number;
	costsCommissionPercent: string;
	costsCommissionAmount: string;
	costsCommission: boolean;
}

type Commission = 'costsCommissionPercent' | 'costsCommissionAmount';

type Currency = 'totalRent' | 'depositCost';
const FinanceTab: FC<PropertyTabProps & { handleDelete: () => void }> = ({
	gotoBack,
	selectedValue,
	isLoading,
	handleDelete
}) => {
	const {
		control,
		clearErrors,
		setValue,
		getValues,
		formState: { errors }
	} = useFormContext<FinanceTabForm>();
	const [selectedMarketingType, setSelectedMarketingType] = useState('');
	const [fieldOption, setFieldOption] = useState(currencyField);
	const [commission, setCommission] = useState('costsCommissionPercent');
	const [isProvisionEnabled, setProvisionEnabled] = useState(false);
	const [isDisabled, setIssDisabled] = useState(true);

	const Amount = getValues('costsCommissionAmount');
	const isCommission = getValues('costsCommission');
	useEffect(() => {
		if (Amount) {
			setCommission('costsCommissionAmount');
		}
		if (isCommission) {
			setProvisionEnabled(isCommission);
		}
		if (selectedValue === propertyOption.land) {
			setFieldOption(verkaufCurrencyField);
		}
	}, []);

	return (
		<div className='finance-tab'>
			<Controller
				name='marketingType'
				control={control}
				rules={myPropertyRules.marketingType}
				defaultValue={
					selectedValue === propertyOption.land
						? (marketingTypeOption[1] as FinanceTabForm['marketingType'])
						: undefined
				}
				render={({ field: { ref, onChange, value, ...rest } }) => {
					if (value?.value === propertyOptions.SELL) {
						setFieldOption(verkaufCurrencyField);
						setSelectedMarketingType(value.value);
					}
					return (
						<InputSelect
							label='Vermieten oder verkaufen?'
							placeholder='Bitte wählen'
							isDisabled={selectedValue === propertyOption.land}
							className={selectedValue === propertyOption.land && 'disabled-section'}
							defaultValue={
								selectedValue === propertyOption.land
									? (marketingTypeOption[1] as FinanceTabForm['marketingType'])
									: undefined
							}
							options={marketingTypeOption}
							value={value}
							asterisk={requiredFields.includes('marketingType')}
							error={errors.marketingType}
							onChange={(e: any) => {
								onChange(e);
								setSelectedMarketingType(e.value);
								if (e.value === propertyOptions.SELL) {
									setFieldOption(verkaufCurrencyField);
								} else {
									setFieldOption(currencyField);
								}
							}}
							{...rest}
						/>
					);
				}}
			/>

			<div
				className={`${
					selectedMarketingType === propertyOptions.SELL || selectedValue === propertyOption.land
						? ''
						: 'finance-tab-currency-field'
				}`}
			>
				{fieldOption.map((item) => (
					<Controller
						name={item.name as Currency}
						control={control}
						rules={myPropertyRules[item.name]}
						key={item.label}
						render={({ field: { ref, ...rest } }) => (
							<InputField
								label={item.label}
								asterisk={requiredFields.includes(item.name)}
								error={errors[item.name as Currency]}
								type={'number'}
								addOn={'Euro'}
								className={'pr-5'}
								placeholder={item.placeholder}
								{...rest}
							/>
						)}
					/>
				))}
			</div>

			<div className='finance-tab-currency-field'>
				<Controller
					name='costsCommission'
					control={control}
					defaultValue={false}
					render={({ field: { ref, onChange, value, ...rest } }) => {
						return (
							<div className='flex justify__content--between commission-div'>
								<label className='form-label'>Gibt es eine Provision?</label>
								<label className='switch'>
									<input
										type='checkbox'
										checked={value}
										onChange={(e) => {
											setProvisionEnabled(!isProvisionEnabled);
											onChange(e);
										}}
										{...rest}
									/>
									<span className='slider round'></span>
								</label>
							</div>
						);
					}}
				/>
				{isProvisionEnabled && (
					<Controller
						name={commission as Commission}
						rules={myPropertyRules[commission]}
						control={control}
						render={({ field: { ref, value, ...rest } }) => {
							return (
								<div className='input-field-div'>
									<div className='flex align__items--center'>
										<label className='form-label'>Courtage/Provision</label>
										<span className='form-asterisk'>&nbsp;*</span>
									</div>
									<div className='flex flex--column position-relative'>
										<input
											className={`form-input`}
											type='number'
											value={value || getValues(commission as Commission)}
											{...rest}
										/>
										<select
											name='commission'
											id='commission'
											className='position-absolute commission-option'
											onChange={(event) => {
												const section = event.target.value;
												if (section === 'EUR') {
													clearErrors('costsCommissionPercent');
													setCommission('costsCommissionAmount');
													setValue('costsCommissionPercent', '');
												} else {
													clearErrors('costsCommissionAmount');
													setCommission('costsCommissionPercent');
													setValue('costsCommissionAmount', '');
												}
											}}
											value={commission === 'costsCommissionAmount' ? 'EUR' : 'percent'}
										>
											<option value='percent'>%</option>
											<option value='EUR'>EUR</option>
										</select>
									</div>
									{errors.costsCommissionPercent && (
										<span className='error-message'>{errors.costsCommissionPercent.message}</span>
									)}
									{errors.costsCommissionAmount && (
										<span className='error-message'>{errors.costsCommissionAmount.message}</span>
									)}
								</div>
							);
						}}
					/>
				)}
			</div>
			<div className='confirm-wrapper'>
				<label className='confirm-container'>
					<p>
						Ich bestätige, dass ich die
						<span style={{ color: '#FFB000' }}> AGBs </span> und
						<span style={{ color: '#FFB000' }}> Datenschutzrichtlinien</span> von{' '}
						<span style={{ color: '#FFB000' }}> immoweb.de </span>
						gelesen und verstanden habe und diese hiermit akzeptiere.
					</p>
					<input type='checkbox' onChange={() => setIssDisabled(!isDisabled)} />
					<span className='checkmark'></span>
				</label>
			</div>
			<div className='flex align__items--center justify__content--between'>
				<PropertyButtonDiv
					backButton={true}
					showSpinner={isLoading}
					isDisabled={isDisabled}
					goBack={() => gotoBack(propertyTab.contact)}
					buttonTxt={'veröffentlichen'}
				/>
				<button className='common-button cancel-popup-button' type='button' onClick={handleDelete}>
					Abbrechen
				</button>
			</div>
		</div>
	);
};

export default FinanceTab;
