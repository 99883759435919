const objectDetailEnglishText = {
  PROPERTY_DETAILS: "Property Details",
  FINANCIAL_DETAILS: "Financial Details",
  MONTHLY_COST_EST: "Monthly (estimated) cost",
  PLOT_AREA: "Plot area",
  FLOORS: "Number of floors",
  CONDITION: "Condition of the house",
  PRICE_TREND: "Price trend 10 yrs",
  VACANT_FROM: "Vacant from",
  ENERGY_EFF: "Energy effieciency",
  LOCATION_INFO: "Location/Map",
  ONLINE_SINCE: "Online since",
  OBJ_DESC: "Object description",
  ENERGY_CERTIFICATE: "Energy Certificate",
  ENERGY_CLASS: "Energy efficiency class",
  UNDER_CONSTRUCTION: "Building under construction",
  FACTS: "Facts",
  FINANCES: "Finances",
  DETAILS: "Details",
  FURNISHING: "Furnishing",
  BEDROOMS: "Bedrooms",
  BATHROOMS: "Bathrooms",
  CONSTRUCTION_PHASE: "Construction Phase",
  PURCHASE_PRICE: "Purchase Price",
  PURCHASE_PRICE_M: "Purchase price per m2",
  ADDITIONAL_COST: "Additional purchase costs",
  TOTAL_COSTS: "Total costs",
  KITCHEN_FITTED: "Kitchen fitted",
  SHOPPING: "Shopping",
  PRIMARY_SCHOOL: "Primary School",
  UNIVERSITY: "University",
  PUBLIC_TRANSPORT: "Public transport",
  PRICE_PER_METER: "Price per m2",
  SHOW_LESS: "Show less",
  READ_MORE: "Read more...",
  PROP_SUB_TYPE: "Detailed property type",
  BALCONY: "Balcony / Terrace",
  GARDEN: "Garden",
  GUEST_TOILET: "Guest Toilet",
  QUARTER: "Quarter",
  CELLER: "Celler",
  PLATFORM: "Platform",
  PROPERTY_DISABLED: "The property is no longer available",
  LOCATION_INFORMATION: "Location Information",
  POPULATION: "Population",
  LOCAL_SUPPLY: "Local Supply",
  ROOMS_OP: "rooms",
  ROOM: "Room",
  LIVING_SPACE: "Living space",
  OTHER: "Other",
  SEARCH_LOCATION: "Search location",
  CLEAR: "Clear",
  NOTIFICATION: "Notification",
  YES: "Yes",
  NO: "No",
  NEW: "New! ",
  HELP_TEXT_1:
    "Are you interested in this property? We would like to help you and be at your side with words and deeds.",
  HELP_TEXT_2: "Let us support you now free of charge and with no obligation!",
  NO_EXTRA: "No extras",
  CONTINUE: "CONTINUE ",

  // House types
  DETACHED_HOUSE: "Detached house",
  SEMI_DETECHED_HOUSE: "Semi-detached house",
  BUNGLOW: "Bungalow",
  MID_TERRACE_HOUSE: "Mid-terrace house",
  VILLA: "Villa",
  PENTHOUSE: "Penthouse",
  FLAT: "Flat",
  Apartment: "Apartment",
  ATTIC_FLOOR: "Attic floor",
  GROUND_FLOOR: "Ground floor",
  MAISON: "Maisonette",
  RAISED_FLOOR: "Raised ground floor",
  TERRACE_APT: "Terrace apartment",
  BASEMENT: "Basement",
  LOFT: "Loft",
  OTHER_1: "other",
  SEMI_DETECTHED: "semi-detached-house",
  DETACHED_HOUSE_2: "detached-house",
  END_HOUSE: "End-terrace house",
  BUNGLOW_1: "bungalow",
  SPCL_PROPERTY: "special-property",
  APT_BLOCK: "apartment-block",
  MID_TERRACE_HOUSE_1: "Mid-terrace house",
  FREE_HOLD_FLAT: "Free hold flat",
  RES_AND_OFFICE: "residential-and-office-building",
  HALL_STORAGE: "hallstorage",
  MISC: "miscellaneous",
  COM_UNIT: "commercial-unit",
  RESIDEN_COMPLEX: "residential-complex",
  SHOPSALE: "shopsales-area",
  COM_PROPERTY: "commercial-property",
  SPECIAL_PROPERTY: "Special property",
  APART_BLOCK: "Apartment block",
  FARMHOUSE: "Farmhouse",
  DOUBLE_TOWNHOUSE: "double_or_townhouse",
  APAARTMENT: "apartment",
  DETACHED_HOUSE_1: "detached_house",
};

export default objectDetailEnglishText;
