import callAPI from 'services/callApi';

export const recordPerPage = 24;
export const demandType = 'Buy';
export const objectType = 'Apartment';

export const getSearchResultsAction = (payload: any = { recordPerPage, demandType, objectType }) => ({
	type: 'SEARCH_RESULT',
	payload: callAPI(`v1/object`, 'POST', payload)
});

export const changeCity = (city: string) => ({
	type: 'CHNAGE_CITY',
	payload: callAPI(`v1/object/searchcity/${city}`, 'PUT', {})
});

export const getSuggestedLocation = () => ({
	type: 'AUTOSUGGESTION_LIST',
	payload: callAPI(`v1/object/locations`, 'GET', {})
});

export const getProfilDetails = () => ({
	type: 'PROFILE_DETAILS',
	payload: callAPI(`v1/profile`, 'GET', {})
});
