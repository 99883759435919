export const layoutLinks = [
	{ title: 'Über uns', path: '/ueber-uns' },
	{ title: 'Kontakt', path: '/kontakt' },
	{ title: 'Hilfe', path: '/hilfe' }
];

export const aboutImmowebLink = [
	{ info: 'Über Uns', href: '/ueber-uns' },
	{ info: 'Franchise ', href: '/franchise' },
	{ info: 'Impressum', href: '/impressum' },
]

export const serviceLinks = [
	{ info: 'Kontakt', href: '/kontakt' },
	{ info: 'Hilfe', href: '/hilfe' },
	{ info: 'Kaufpreisberatung ', href: '/kaufpreisberatung' },
	{ info: 'Gutachten ', href: '/gutachten' },
	{ info: 'Energieausweis', href: '/energieausweis' },

];

export const privacyLinks = [
	{ info: 'Datenschutz', href: '/datenschutz' },
	{ info: 'AGB', href: '/agb' },
];

export const popupName = {
	loginRegister: 'loginRegister',
	register: 'register',
	login: 'login',
	forgotpassword: 'forgotpassword',
	resetPassword: 'resetPassword',
	changePassword: 'changePassword',
	activation: 'activation',
	userVerification: 'userVerification',
	profile: 'profile',
	menuList: 'menuList',
	forgotEmailPopup: 'forgotEmailPopup',
	passwordResetSuccessfully: 'passwordResetSuccessfully',
	passwordChangeSuccessfully: 'passwordChangeSuccessfully'
};
