import React from 'react';
import { strengthIndicator, strengthColor } from './passwordFunctions';
import getLocalizeText from 'utils/getLocalizeText';
import * as passMeterInterface from '../interface/auth';

import '../auth.scss';
import { PasswordNotVisibleIcon, PasswordVisibleIcon } from 'components/commonComponents/icons';

export default function PasswordInput(props: passMeterInterface.IPasswordMeterProps) {
	const strength = strengthIndicator(props.value);
	const color = props.value ? strengthColor(strength) : '#d8d8d8';

	const colorObj: any = colorArray_2.find((i) => i[color]);

	const countIndex: number = colorObj && colorObj[color ? color : '#d8d8d8'];

	return (
		<>
			<div className='position-relative'>
				<input
					// id="inputID"
					type={props.showPassword ? 'text' : 'password'}
					value={props.value}
					name={props.name}
					className={`${
						props.from === 'signup' || props.from === 'reset'
							? 'login-input-box'
							: (props.errorFields && !props.errorFields.includes('password')) || !props.errorFields
							? 'password-input'
							: 'password-input error-border'
					} ${props.disabled ? 'not-allowed' : ''}`}
					placeholder={getLocalizeText(props.placeholder ? props.placeholder : '')}
					onChange={props.handleChanges}
					onKeyDown={props.onKeyDown}
					disabled={props.disabled}
				/>
				<span className='form-bar' onClick={(event) => props.toggleShowPwd(event)}>
					{props.showPassword ? (
						<PasswordVisibleIcon width='22px' height='14' />
					) : (
						<PasswordNotVisibleIcon width='22px' height='14' />
					)}
				</span>
			</div>

			<div
				className=' align-items-center justify-content-between'
				style={{
					marginTop: '8px',
					display: props.from === 'reset' ? 'none' : 'flex'
				}}
			>
				{colorArray.map((item, index) =>
					index < countIndex ? (
						<div
							key={index}
							className='pwd-div'
							style={{
								backgroundColor: color
							}}
						></div>
					) : index === 0 ? (
						<div
							key={index} // Add a unique key prop
							className='pwd-div'
							style={{
								backgroundColor: '#d8d8d8',
								marginLeft: '17px'
							}}
						></div>
					) : (
						<div
							key={index} // Add a unique key prop
							className='pwd-div'
							style={{
								backgroundColor: '#d8d8d8'
							}}
						></div>
					)
				)}
			</div>
			<div
				style={{
					display: props.from === 'reset' ? 'none' : 'flex',
					height: props.from === 'reset' ? '0px' : '10px'
				}}
			>
				{props.value && strength === 0 && <p className='password-text'>nicht ausreichend</p>}
				{props.value && strength === 4 && <p className='password-text'>gut</p>}
			</div>
		</>
	);
}

const colorArray = [{ '#d11b1b': 1 }, { '#ff9a00': 2 }, { '#ffc100': 3 }, { '#58b32d': 4 }];

const colorArray_2 = [{ '#d11b1b': 1 }, { '#ff9a00': 2 }, { '#ffc100': 3 }, { '#58b32d': 4 }, { '#d8d8d8': 5 }];
