import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const MetaDecorator: FC<{ title: string }> = ({ title }) => {
	return (
		<Helmet>
			<title>{title}</title>
			{/* <meta name='description' content='Helmet application' /> */}
		</Helmet>
	);
};

export default MetaDecorator;
