import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LOGIN_SUCCESS } from 'reducers/actionTypes';

import {
	BackButtonIcon,
	ClosePopup,
	SecurityIcon,
	UserEmailIcon,
	WarningIcon
} from 'components/commonComponents/icons';
import { popupName } from 'components/layout/constants';
import PasswordInput from './passwordMeter';
import { notify } from 'components/commonComponents/notification/notification';
import CustomSpinner from 'components/commonComponents/customSpinner';

import { clearStorage, createAction } from 'utils';
import { AuthClassProps } from '../interface/auth';

import { API_CONFIG, HttpService, setAuthData } from 'services';

import '../auth.scss';

interface UserEmail {
	userEmail: string;
	component: string;
	setUserEmail?: React.Dispatch<React.SetStateAction<string>>;
	setForgotPasswordBack: React.Dispatch<React.SetStateAction<string>>;
	showErrorPopup: boolean;
	setShowErrorPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const Login: React.FC<AuthClassProps & UserEmail> = (props) => {
	const { register, handleSubmit } = useForm({
		defaultValues: {
			remainSignin: false,
			Password: ''
		}
	});
	const [password, setPassword] = useState('');
	const [showPassword, setshowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onSubmit = (data: any, e: any) => {
		e.preventDefault();
		clearStorage();
		setLoading(true);
		HttpService.post(API_CONFIG.path.login, {
			email: props.userEmail,
			password: password,
			remain_signin: data.remainSignin
		})
			.then((response) => {
				const token = response.data.token;
				setAuthData(token);
				dispatch(createAction(LOGIN_SUCCESS, true));
				setLoading(false);
				props.closePopup();
				notify(`${response.message}`, 'success');
				navigate('/');
			})
			.catch((err) => {
				notify(`${err.response.data.message}`, 'error');
				setLoading(false);
			});
	};
	const onKeyDown = (event: any) => {};
	const toggleShowPwd = () => {
		setshowPassword(!showPassword);
	};
	return (
		<div className='login-register-wrapper position-relative'>
			<div
				className='text--right position-absolute close-btn cursor-pointer'
				onClick={() =>
					props.component === popupName.activation
						? (props.closePopup(), props.setShowErrorPopup(false))
						: props.handlePopup(popupName.loginRegister)
				}
			>
				{props.component === popupName.activation ? <ClosePopup /> : <BackButtonIcon />}
			</div>
			<div className='login-div login-register-div'>
				<div className='login-tittle-wrapper '>
					<h3 className='login-register-tittle'>Anmelden</h3>
				</div>

				{props.showErrorPopup && (
					<div className='error-popup-wrapper flex'>
						<div className='error-popup-icon flex justify__content--center'>
							<WarningIcon width='20px' height='20px' className='warning-icon' />
						</div>
						<div className='error-popup-text'>
							<h1>Die E-Mail-Adresses ist bereits bestatigt.</h1>
							<p>Sie konnen sich direkt anmelden.</p>
						</div>
					</div>
				)}
				<form onSubmit={handleSubmit((data, e) => onSubmit(data, e))} className='login-form-wrapper'>
					<div className='flex align__items--start user-email-wrapper mt-2'>
						<UserEmailIcon />
						<h6 className='user-email'>{props.userEmail}</h6>
					</div>
					<div className='login-input'>
						<label>Passwort</label>
						<PasswordInput
							handleChanges={(event) => setPassword(event.target.value)}
							value={password}
							name={'password'}
							onKeyDown={onKeyDown}
							placeholder={''}
							showPassword={showPassword}
							toggleShowPwd={toggleShowPwd}
							className='login-input-box'
							errorFields={[]}
							from='reset'
							disabled={props.showErrorPopup}
						/>
					</div>

					<button
						type='submit'
						value='register'
						className={`login-button  ${!password ? 'disabled-btn' : 'common-button'}`}
						disabled={!password || loading}
					>
						{loading ? <CustomSpinner animation='border' className='spinner-border-sm' /> : 'Anmelden'}
					</button>
					<div className='flex align__items--center justify__content--between pt-4 pb-4'>
						<div className='remain-signedIn-wrapper '>
							<label className=' flex flex--row-reverse align__items--center'>
								<p>Angemeldet bleiben</p>
								<input type='checkbox' {...register('remainSignin')} />
								<span className='checkmark'></span>
							</label>
						</div>
						<button
							className='forgotPassword p-0'
							type='button'
							onClick={() => {
								props.handlePopup(popupName.forgotpassword);
								props.setForgotPasswordBack(popupName.login);
							}}
						>
							Passwort vergessen?
						</button>
					</div>
				</form>
				<div className='flex align__items--start justify__content--center security-wrapper '>
					<SecurityIcon width='10px' height='12px' />
					<h6>Diese Verbindung ist sicher.</h6>
				</div>
			</div>
		</div>
	);
};

export default Login;
