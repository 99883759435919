import React, { useEffect, useState } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import PersonalInformation from './personalInformation';
import AddressInformation from './addressInformation';
import MoreInformation from './moreInformation';
import {
	countriesArray,
	defaultRule,
	GENDER_OPTIONS,
	ProfessionalBrokerRequiredFields,
	PropertyOwnerRequiredFields,
	PropertySeekerRequiredFields,
	rules,
	userRole
} from '../utils/constants';
import ConfirmSection from './confirmSection';
import { API_CONFIG, HttpService } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	clearStorage,
	createAction,
	findRules,
	getSelectedOption,
	mergeDeep,
	ProfileRootState,
	removeEmptyStrings
} from 'utils';
import UserRegister from 'components/home/auth/components/register';
import { popupName } from 'components/layout/constants';
import Modal from '../../modal/index';
import PasswordChangeSuccessfully from 'components/home/auth/components/passwordChangeSuccessfully';
import VerifyUser from 'components/home/auth/components/verifyUser';
import { UserProfileFormData } from '../utils/form.interface';
import ConformationPopup from './conformationPopup';
import { LOGOUT_SUCCESS } from 'reducers/actionTypes';
import { clearReducer } from 'actions/clearAction';
import { notify } from 'components/commonComponents/notification/notification';
import CustomSpinner from 'components/commonComponents/customSpinner';
import ModalComponent from 'components/commonComponents/modalComponent';

interface IUserDetailForm {
	selectedValue: string;
}

const UserDetailForm = (props: IUserDetailForm) => {
	const { selectedValue } = props;
	const profileDetail = useSelector((state: ProfileRootState) => state.profileDetailReducer);
	const [showPopup, setShowPopup] = useState(false);
	const [showSuccessPopup, setShowSuccessPopup] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isEmailChange, setIsEmailChange] = useState(false);
	const [showConformation, setShowConformation] = useState(false);
	const [showDeleteConform, setShowDeleteConform] = useState(false);

	const resetForm = (data: any) => {
		return methods.reset({
			...data,
			gender: getSelectedOption(GENDER_OPTIONS, data.gender),
			land: getSelectedOption(countriesArray, data.land)
		});
	};

	const methods = useForm({
		defaultValues: {
			gender: '',
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			street: '',
			postalCode: '',
			city: '',
			land: '',
			moreInformation: '',
			companyName: '',
			imprint: '',
			taxIdentifier: '',
			isConfirm: false,
			privacyPolicy: ''
		}
	});
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		setIsLoading(true);
		if (profileDetail.data) {
			resetForm(removeEmptyStrings(profileDetail.data));
		}
		if (profileDetail.status === 'success') {
			setIsLoading(false);
		}
	}, [profileDetail]);

	useEffect(() => {
		methods.clearErrors();
		methods.reset();
	}, [selectedValue, methods]);

	const onSubmit = (data: UserProfileFormData) => {
		let detail = {};
		detail = {
			gender: data.gender?.value || '',
			firstName: data.firstName || '',
			lastName: data.lastName || '',
			email: data.email || '',
			phone: data.phone || '',
			street: data.street || '',
			postalCode: parseInt(data.postalCode) || '',
			city: data.city || '',
			land: data.land?.value || '',
			moreInformation: data.moreInformation || ''
		};
		if (selectedValue === userRole.professionalBroker) {
			detail = {
				...detail,
				companyName: data.companyName || '',
				imprint: data.imprint || '',
				taxIdentifier: data.taxIdentifier || '',
				privacyPolicy: data.privacyPolicy || ''
			};
		}
		handleSaveDetail(removeEmptyStrings(detail));
	};

	const getProps = () => {
		let fields = PropertySeekerRequiredFields;
		if (selectedValue === userRole.propertyOwner) {
			fields = PropertyOwnerRequiredFields;
		} else if (selectedValue === userRole.professionalBroker) {
			fields = ProfessionalBrokerRequiredFields;
		}
		return {
			requiredFields: fields,
			rules: mergeDeep(findRules(fields, rules), defaultRule)
		};
	};

	const handleSaveDetail = (data: any) => {
		setIsLoading(true);
		let userRollURL = API_CONFIG.path.userBroker;
		if (selectedValue === userRole.propertySeeker) {
			userRollURL = API_CONFIG.path.userSeeker;
		} else if (selectedValue === userRole.propertyOwner) {
			userRollURL = API_CONFIG.path.userOwner;
		}
		const isEmailChange = profileDetail.data.email !== data.email;
		if (!isEmailChange) {
			delete data.email;
		}
		HttpService.put(userRollURL, data)
			.then((response) => {
				if (isEmailChange) {
					setIsEmailChange(true);
				}
				dispatch({
					type: 'PROFILE_DETAILS_FULFILLED',
					payload: { data: response }
				});
				notify(`${response.message}`, 'success');
				setIsLoading(false);
			})
			.catch((err) => {
				notify(`${err.response.data.message}`, 'error');
				setIsLoading(false);
			});
	};

	const handleDeleteProfile = () => {
		setIsLoading(true);
		HttpService.put(API_CONFIG.path.deactivateUser)
			.then((response) => {
				clearStorage();
				clearReducer('CLEAR_SEARCH_CRESET_STATE');
				dispatch(createAction(LOGOUT_SUCCESS, false));
				setShowDeleteConform(false);
				notify(`${response.message}`, 'success');
				setIsLoading(false);
				navigate('/');
			})
			.catch((err) => {
				notify(`${err.response.data.message}`, 'error');
				setIsLoading(false);
			});
	};
	return (
		<>
			<FormProvider {...methods}>
				<form onSubmit={methods.handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
					<PersonalInformation setShowPopup={setShowPopup} {...getProps()} displayChangePassword={true} />
					<AddressInformation {...getProps()} />
					<MoreInformation isRealtor={selectedValue === userRole.professionalBroker} {...getProps()} />
					<ConfirmSection
						resetForm={() => setShowConformation(true)}
						profileDelete={() => setShowDeleteConform(true)}
						{...getProps()}
					/>
				</form>
			</FormProvider>
			{isLoading && (
				<Modal className={'flex align__items--center justify__content--center transparent-spinner'}>
					<CustomSpinner
						animation='border'
						className='spinner-border'
						style={{ width: '3rem', height: '3rem' }}
					/>
				</Modal>
			)}
			{showPopup && !showSuccessPopup && (
				<Modal closeModal={() => setShowPopup(false)} className={'user-register'} closeOnOutsideClick={false}>
					<UserRegister
						handlePopup={() => {}}
						closePopup={() => setShowPopup(false)}
						component={popupName.changePassword}
						userEmail={''}
						showSuccessPopup={() => {
							setShowSuccessPopup(true);
							setShowPopup(false);
						}}
					/>
				</Modal>
			)}
			{showSuccessPopup && (
				<>
					<Modal closeModal={() => setShowSuccessPopup(false)} className={'user-register'}>
						<PasswordChangeSuccessfully btnText={'OK'} onSubmit={() => setShowSuccessPopup(false)} />
					</Modal>
				</>
			)}
			{isEmailChange && (
				<Modal closeModal={() => setIsEmailChange(false)} className={'user-register'}>
					<VerifyUser closePopup={() => setIsEmailChange(false)} userEmail={''} component={'emailVerify'} />
				</Modal>
			)}
			{showConformation && (
				<ModalComponent
					title={'Alle Änderungen verwerfen und zur Seite zurückkehren?'}
					closeModal={() => setShowConformation(false)}
					handleYes={() => {
						methods.reset();
						setShowConformation(false);
					}}
					btnTextConform={'Änderungen verwerfen'}
					btnTextCancel={'Abbrechen'}
					className={'property-tab-verification-popup'}
				/>
			)}
			{showDeleteConform && (
				<Modal
					closeModal={() => setShowDeleteConform(false)}
					className={'user-register conformation-popup'}
					closeOnOutsideClick={false}
				>
					<ConformationPopup
						handleClick={handleDeleteProfile}
						handleClose={() => setShowDeleteConform(false)}
						titleText={
							'Möchten Sie wirklich Ihr Profil und ALLE IHRE DATEN von der Immoweb-Plattform löschen?'
						}
						conformButtonText={'Ja'}
						closeButtonText={'Nein'}
					/>
				</Modal>
			)}
		</>
	);
};

export default UserDetailForm;
