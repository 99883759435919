import React from 'react';
import { ApartmentIcon, HouseIcon, PropertyIcon } from 'components/commonComponents/icons';

export const requiredFields = [
	'objectTypeSub',
	'adrRaw',
	'adrCity',
	'adrPostalCode',
	'adrCountry',
	'areaLiving',
	'areaPlot',
	'noOfRooms',
	'constructionYear',
	'marketingType',
	'totalRent',
	'depositCost'
];

export const apartmentOptions = {
	ATTIC_APARTMENT: 'Dachgeschosswohnung',
	MAISONETTE_APARTMENT: 'Maisonette-Wohnung',
	LOFT_STUDIO_STUDIO: 'Loft, Studio, Atelier',
	PENTHOUSE: 'Penthouse',
	TERRACE_APARTMENT: 'Terassenwohnung',
	APARTMENT: 'Etagenwohnung',
	GROUND_FLOOR_APARTMENT: 'Erdgeschosswohnung',
	BASEMENT_APARTMENT: 'Souterrainwohnung'
};

export const houseOptions = {
	TOWN_HOUSE: 'Reihenhaus',
	DETACHED_HOUSE: 'Einfamilienhaus',
	TWO_FAMILY_HOUSE: 'Zweifamilienhaus',
	SEMI_DETACHED_HOUSE: 'Doppelhaushälfte',
	APARTMENT_BUILDING: 'Mehrfamilienhaus',
	TOWNHOUSE: 'Stadthaus',
	BUNGALOW: 'Bungalow',
	VILLA: 'Villa'
};

export const landOptions = {
	RESIDENTIAL_PROPERTY: 'Wohngrundstück',
	COMMERCIAL_PROPERTY: 'Gewerbegrundstück',
	MIXED_PROPERTY: 'Gemischtes Grundstück',
	FOREST: 'Forst',
	AGRICULTURE: 'Landwirtschaft',
	GRASSLAND: 'Grünland'
};

const errorMessage = 'Bitte füllen Sie das Feld aus';
const validInputMessage = 'Bitte eine gültige Eingabe machen';

export const positiveNumberStringRegex = /^[a-zA-Z0-9\säöüÄÖÜßẞ]+$/;
export const addressFieldRegex = /^[0-9A-Za-z\s,'äöüÄÖÜßẞ-]+$/;
export const textFieldRegex = /^[-_\sa-zA-ZäöüÄÖÜßẞ]+$/;
export const postalCodeRegex = /^[0-9]{1,6}$/;
export const numberFieldRegex = /^(0|[1-9]\d*|\d*\.\d+)$/;
export const numberRange500Regex = /^(?:[1-9][0-9]{0,2}(?:\.[5])?|499(?:\.5)?)$/;
export const yearRangeRegex = /^(?:1[89]\d\d|2[0-9]\d\d|3[0-9]\d\d|4000)$/;
export const percentFieldRegex = /^(?:0|[1-9]?\d(?:\.\d{1,2})?|100)$/;
export const emailFieldRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-ZäöüÄÖÜßẞ]{2,15}$/;
export const nameFieldRegex = /^[a-zA-ZäöüÄÖÜßẞ\s_-]{1,20}$/;
export const privacyPolicyRegex = /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm;
/* eslint-enable */

export const myPropertyRules: { [key: string]: any } = {
	objectTypeSub: {
		required: { value: true, message: errorMessage }
	},
	typePropertyUse: {
		required: { value: true, message: errorMessage }
	},
	adrRaw: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: addressFieldRegex,
			message: validInputMessage
		}
	},
	adrCity: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: textFieldRegex,
			message: validInputMessage
		}
	},
	adrPostalCode: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: postalCodeRegex,
			message: validInputMessage
		}
	},
	adrCountry: {
		required: { value: true, message: errorMessage }
	},
	showAddress: {
		required: { value: true, message: errorMessage }
	},
	areaLiving: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: numberFieldRegex,
			message: validInputMessage
		}
	},
	areaPlot: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: numberFieldRegex,
			message: validInputMessage
		}
	},
	noOfRooms: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: numberRange500Regex,
			message: validInputMessage
		}
	},
	constructionYear: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: yearRangeRegex,
			message: validInputMessage
		}
	},
	marketingType: {
		required: { value: true, message: errorMessage }
	},
	totalRent: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: numberFieldRegex,
			message: validInputMessage
		}
	},
	depositCost: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: numberFieldRegex,
			message: validInputMessage
		}
	},
	costsCommissionPercent: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: percentFieldRegex,
			message: 'Zeichenbeschränkung überschritten'
		}
	},
	costsCommissionAmount: {
		required: { value: true, message: errorMessage },
		pattern: {
			value: numberFieldRegex,
			message: 'Zeichenbeschränkung überschritten'
		}
	}
};

export const wohnungAccommodationOption = [
	{
		id: 'Dachgeschosswohnung',
		value: apartmentOptions.ATTIC_APARTMENT,
		label: apartmentOptions.ATTIC_APARTMENT
	},
	{
		id: 'Maisonette-Wohnung',
		value: apartmentOptions.MAISONETTE_APARTMENT,
		label: apartmentOptions.MAISONETTE_APARTMENT
	},
	{
		id: 'Loft, Studio, Atelier',
		value: apartmentOptions.LOFT_STUDIO_STUDIO,
		label: apartmentOptions.LOFT_STUDIO_STUDIO
	},
	{
		id: 'Penthouse',
		value: apartmentOptions.PENTHOUSE,
		label: apartmentOptions.PENTHOUSE
	},
	{
		id: 'Terassenwohnung',
		value: apartmentOptions.TERRACE_APARTMENT,
		label: apartmentOptions.TERRACE_APARTMENT
	},
	{
		id: 'Etagenwohnung',
		value: apartmentOptions.APARTMENT,
		label: apartmentOptions.APARTMENT
	},
	{
		id: 'Erdgeschosswohnung',
		value: apartmentOptions.GROUND_FLOOR_APARTMENT,
		label: apartmentOptions.GROUND_FLOOR_APARTMENT
	},
	{
		id: 'Souterrainwohnung',
		value: apartmentOptions.BASEMENT_APARTMENT,
		label: apartmentOptions.BASEMENT_APARTMENT
	}
];

export const hausAccommodationOption = [
	{
		id: 'Reihenhaus',
		value: houseOptions.TOWN_HOUSE,
		label: houseOptions.TOWN_HOUSE
	},
	{
		id: 'Einfamilienhaus',
		value: houseOptions.DETACHED_HOUSE,
		label: houseOptions.DETACHED_HOUSE
	},
	{
		id: 'Zweifamilienhaus',
		value: houseOptions.TWO_FAMILY_HOUSE,
		label: houseOptions.TWO_FAMILY_HOUSE
	},
	{
		id: 'Doppelhaushälfte',
		value: houseOptions.SEMI_DETACHED_HOUSE,
		label: houseOptions.SEMI_DETACHED_HOUSE
	},
	{
		id: 'Mehrfamilienhaus',
		value: houseOptions.APARTMENT_BUILDING,
		label: houseOptions.APARTMENT_BUILDING
	},
	{
		id: 'Stadthaus',
		value: houseOptions.TOWNHOUSE,
		label: houseOptions.TOWNHOUSE
	},
	{
		id: 'Bungalow',
		value: houseOptions.BUNGALOW,
		label: houseOptions.BUNGALOW
	},
	{
		id: 'Villa',
		value: houseOptions.VILLA,
		label: houseOptions.VILLA
	}
];
export const grundstückAccommodationOption = [
	{
		id: 'Wohngrundstück',
		value: landOptions.RESIDENTIAL_PROPERTY,
		label: landOptions.RESIDENTIAL_PROPERTY
	},
	{
		id: 'Gewerbegrundstück',
		value: landOptions.COMMERCIAL_PROPERTY,
		label: landOptions.COMMERCIAL_PROPERTY
	},
	{
		id: 'Gemischtes Grundstück',
		value: landOptions.MIXED_PROPERTY,
		label: landOptions.MIXED_PROPERTY
	},
	{
		id: 'Forst',
		value: landOptions.FOREST,
		label: landOptions.FOREST
	},
	{
		id: 'Landwirtschaft',
		value: landOptions.AGRICULTURE,
		label: landOptions.AGRICULTURE
	},
	{
		id: 'Grünland',
		value: landOptions.GRASSLAND,
		label: landOptions.GRASSLAND
	}
];

export enum propertyOption {
	apartment = 'Wohnung',
	house = 'Haus',
	land = 'Grundstück'
}
export const propertyTabRadioButtonOption = [
	{
		label: propertyOption.apartment,
		value: propertyOption.apartment,
		icon: <ApartmentIcon />
	},
	{
		label: propertyOption.house,
		value: propertyOption.house,
		icon: <HouseIcon />
	},
	{
		label: propertyOption.land,
		value: propertyOption.land,
		icon: <PropertyIcon />
	}
];

export enum propertyTab {
	addPropertyTab = 'addPropertyTab',
	generally = 'Allgemein',
	location = 'Lage der Immobile',
	locationLand = 'Lage des Grundstücks',
	detail = 'Details der Immobilie',
	contact = 'Kontaktdaten',
	finance = 'Finanzen'
}

export const generallyTabCheckBox = [{ label: 'Wohnen' }, { label: 'Gewerbe' }];

export const apartmentDetailTabAreaField = [
	{
		label: 'Wohnfläche',
		name: 'areaLiving',
		placeholder: 'Wohnfläche'
	},
	{
		label: 'Anzahl Zimmer',
		name: 'noOfRooms',
		placeholder: 'Anzahl zimmer'
	},
	{
		label: 'Baujahr',
		name: 'constructionYear',
		placeholder: 'Baujahr'
	},
	{
		label: 'Bezugsfrei ab',
		name: 'vacantFrom',
		placeholder: ''
	}
];
export const houseDetailTabAreaField = [
	{
		label: 'Wohnfläche',
		name: 'areaLiving',
		placeholder: 'Wohnfläche'
	},
	{
		label: 'Anzahl Zimmer',
		name: 'noOfRooms',
		placeholder: 'Anzahl zimmer'
	},
	{
		label: 'Baujahr',
		name: 'constructionYear',
		placeholder: 'Baujahr'
	},
	{
		label: 'Bezugsfrei ab',
		name: 'vacantFrom',
		placeholder: ''
	},
	{
		label: 'Grundstücksgröße',
		name: 'areaPlot',
		placeholder: 'Grundstücksgröße'
	}
];
export const landDetailTabAreaField = [
	{
		label: 'Grundstücksgröße',
		name: 'areaPlot',
		placeholder: 'Grundstücksgröße'
	}
];

export const furnishingOption = {
	BALCONY: 'Balkon',
	GARDEN: 'Garten',
	WINTER_GARDEN: 'Wintergarten',
	BASEMENT_CELLAR: 'Keller',
	EQUIPPED_KITCHEN: 'Einbauküche',
	CENTRAL_HEATING: 'Zentralheizung',
	ROOF_TERRACE: 'Dachterasse',
	GUEST_TOILET: 'Guest-WC',
	TERRACE: 'Terrasse'
};
export const ausstattungField = [
	{ label: furnishingOption.BALCONY, name: 'equipBalcony' },

	{ label: furnishingOption.GARDEN, name: 'equipGarden' },

	{ label: furnishingOption.WINTER_GARDEN, name: 'equipWinterGarden' },

	{ label: furnishingOption.BASEMENT_CELLAR, name: 'objCellar' },

	{ label: furnishingOption.EQUIPPED_KITCHEN, name: 'equipKitchen' },

	{ label: furnishingOption.CENTRAL_HEATING, name: 'objHeatingType' },

	{ label: furnishingOption.ROOF_TERRACE, name: 'equipRoofTerrace' },

	{ label: furnishingOption.GUEST_TOILET, name: 'equipGuestToilet' },

	{ label: furnishingOption.TERRACE, name: 'equipTerrace' }
];

export enum contactTabRole {
	propertyOwner = 'PROPERTY_OWNER',
	professionalBroker = 'USER_REALTOR'
}
export const contactTabRadioButtonOptions = [
	{ label: 'Immobilienbesitzer', value: contactTabRole.propertyOwner },
	{ label: 'Professioneller Makler', value: contactTabRole.professionalBroker }
];

export const marketingTypeOption = [
	{
		id: 'Vermietung',
		value: 'Rent',
		label: 'Vermietung'
	},
	{
		id: 'Verkauf',
		value: 'Sell',
		label: 'Verkauf'
	}
];

export const currencyField = [
	{
		label: 'Mietpreis',
		name: 'totalRent',
		placeholder: 'Mietpreis'
	},
	{
		label: 'Wie hoch ist die Kaution?',
		name: 'depositCost',
		placeholder: 'Wie hoch ist die Kaution?'
	}
];

export const verkaufCurrencyField = [
	{
		label: 'Kaufpreis',
		name: 'totalRent',
		placeholder: 'Kaufpreis'
	}
];
export const pdfFields = [
	{
		label: 'Grundriss',
		name: 'propFloorPlan',
		id: 'layout-input'
	},
	{ label: 'Energieausweis', name: 'energyCertificate', id: 'expose-input' }
];
export const landPdfFields = [
	{
		label: 'Grundriss',
		name: 'propFloorPlan',
		id: 'layout-input'
	}
];
