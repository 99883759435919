import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import UserDetailForm from '../components/userDetailForm';
import CustomRadioButton from 'components/commonComponents/form/radioButton/radioButton';
import { radioButtonOptions, userRole } from '../utils/constants';
import ConformationPopup from '../components/conformationPopup';
import Modal from 'components/modal';
import { ProfileRootState } from 'utils';

const UserProfile = () => {
	const profileDetail = useSelector((state: ProfileRootState) => state.profileDetailReducer);
	const [selectedValue, setSelectedValue] = useState(userRole.propertySeeker);
	const [conformationRole, setConformationRole] = useState<userRole>();
	const [showConformation, setShowConformation] = useState(false);

	useEffect(() => {
		if (profileDetail?.data?.roleName) {
			setSelectedValue(profileDetail.data.roleName as userRole);
		}
	}, [profileDetail]);
	const handleChangeRadioButton = (selectedLabel: string) => {
		if (selectedLabel === userRole.propertySeeker) {
			setConformationRole(selectedLabel as userRole);
			setShowConformation(true);
		} else {
			setSelectedValue(selectedLabel as userRole);
		}
	};
	const handleConform = () => {
		setSelectedValue(conformationRole as userRole);
		setShowConformation(false);
	};
	const handleClose = () => {
		setShowConformation(false);
	};
	return (
		<div className='user-profile-wrapper'>
			<hr className='common-hr' />
			<div className='profile-detail-wrapper'>
				<div className='profile-detail-div'>
					<h2 className='section-title text--center'>MEIN PROFIL</h2>
					<p className='section-text text--center'>
						Die mit * markierten Felder sind Pflichtfelder und müssen ausgefüllt werden.
					</p>
					<div className='user-detail-wrapper'>
						<div className='user-detail-div'>
							<h6 className='text--center user-detail-tittle'>Ich melde mich als</h6>
							<div className='user-roll-wrapper'>
								<CustomRadioButton
									options={radioButtonOptions}
									handleChange={handleChangeRadioButton}
									selectedValue={selectedValue}
									className={'flex align__items--center justify__content--between user-roll-div '}
								/>
							</div>
							<UserDetailForm selectedValue={selectedValue} />
						</div>
					</div>
				</div>
			</div>

			{showConformation && (
				<Modal
					closeModal={handleClose}
					className={'user-register conformation-popup'}
					closeOnOutsideClick={false}
				>
					<ConformationPopup
						handleClick={handleClose}
						handleClose={handleConform}
						titleText={'Sie ändern die Rolle Ihres Benutzers.'}
						subTitleText={
							'Wenn Sie die Änderungen bestätigen, werden alle Ihre Immobilien nicht mehr in der Galerie angezeigt.'
						}
						description={'Möchten Sie die Rolle in einen Immobiliensuchenden ändern?'}
						conformButtonText={'Änderungen abbrechen '}
						closeButtonText={'ja'}
					/>
				</Modal>
			)}
		</div>
	);
};

export default UserProfile;
