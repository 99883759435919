import React, { FC } from 'react';
import { SecurityIcon } from 'components/commonComponents/icons';
import { Controller, useFormContext } from 'react-hook-form';
import { rules } from '../utils/constants';
interface IConfirmSectionProps {
	resetForm: () => void;
	profileDelete: () => void;
}
type ConfirmSectionForm = {
	isConfirm: boolean;
};
const ConfirmSection: FC<IConfirmSectionProps> = (props) => {
	const {
		control,
		formState: { errors }
	} = useFormContext<ConfirmSectionForm>();
	const { resetForm, profileDelete } = props;
	return (
		<>
			<div className='confirm-section'>
				<div className='confirm-wrapper user-detail-confirm-wrapper'>
					<label className='confirm-container'>
						<p>
							Ich bestätige, dass ich die
							<span style={{ color: '#FFB000' }}> AGBs </span> und
							<span style={{ color: '#FFB000' }}>Datenschutzrichtlinien</span>
							von <span style={{ color: '#FFB000' }}> immoweb.de </span>
							gelesen und verstanden habe und diese hiermit akzeptiere.
							<span style={{ color: 'red' }}>*</span>
						</p>

						<Controller
							name='isConfirm'
							control={control}
							rules={rules.isConfirm}
							render={({ field: { ref, onChange, value, ...rest } }) => {
								return (
									<>
										<input {...rest} type='checkbox' checked={value} onChange={onChange} />
										<span className='checkmark'></span>
									</>
								);
							}}
						/>
					</label>
					{errors.isConfirm && <span className='error-message'>{errors.isConfirm?.message}</span>}
				</div>
				<div className='flex align__items--center justify__content--between'>
					<button type='submit' className='common-button save-button'>
						SPEICHERN
					</button>
					<button type='button' className='button Cancel-changes-btn' onClick={resetForm}>
						Änderungen abbrechen
					</button>
				</div>
			</div>
			<div>
				<button type='button' className='button delete-btn' onClick={profileDelete}>
					Profil löschen
				</button>
			</div>
			<div className='flex align__items--baseline justify__content--center security-wrapper '>
				<SecurityIcon width='10px' height='12px' />
				<h6>Diese Verbindung ist sicher.</h6>
			</div>
		</>
	);
};

export default ConfirmSection;
