import { CSSProperties } from 'react';

export const searchSelectStyles = {
	option: (base: CSSProperties, state: any) => ({
		...base,
		margin: '5px 0',
		padding: '0px 10px',
		color: ' #2C344E',
		fontSize: '11px',
		lineHeight: '23px',
		fontWeight: state.isSelected ? '700' : '400',
		width: '100%',
		border: 'none',
		backgroundColor: state.isSelected
			? 'rgba(217, 217, 217, 0.37)'
			: !state.isSelected && state.isFocused
			? 'transparent'
			: 'transparent',
		cursor: 'pointer',
		'@media only screen and (max-width: 1440px)': {
			fontSize: '9px'
		},
		':hover': {
			backgroundColor: !state.isSelected ? 'rgba(217, 217, 217, 0.37)' : 'rgba(217, 217, 217, 0.37)',
			opacity: 0.8,
			borderRadius: '4px',
			transition: 'all 0.2s linear'
		},
		':active': {
			backgroundColor: 'rgba(217, 217, 217, 0.37)',
			fontWeight: state.isSelected ? '700' : '400'
		}
	}),

	menu: (base: CSSProperties) => ({
		...base,
		zIndex: 3,
		backgroundColor: '#FFF',
		border: '0.5px solid #AEAEAE',
		borderRadius: '8px',
		width: '139px',
		boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
		padding: '9px',
		right: '0px',
		top: '16px'
	}),

	menuList: (base: CSSProperties) => ({
		...base,
		padding: 0,
		backgroundColor: '#FFF',
		maxHeight: '157px'
	}),
	clearIndicator: (base: CSSProperties) => ({
		...base,
		cursor: 'pointer'
	}),
	dropdownIndicator: (base: CSSProperties, state: any) => ({
		...base,
		cursor: 'pointer',
		transition: 'all 0.2s ease',
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'none',
		color: state.selectProps.menuIsOpen && '#2C344E',
		padding: '0 10px ',

		':hover': {
			color: '#2C344E'
		}
	}),
	indicatorSeparator: () => ({
		width: '0'
	}),
	control: () => ({
		// none of react-selects styles are passed to <View />
		display: 'flex',
		border: '0.5px solid #AEAEAE',
		backgroundColor: '#FFF',
		width: '140px',
		minheight: '22px',
		cursor: 'pointer',
		fontSize: '14px',
		borderRadius: '3px',
		'.css-319lph-ValueContainer': {
			padding: '2px 0 2px 10px'
		}
	}),

	input: (base: CSSProperties, state: any) => ({
		...base,
		color: '#2C344E',
		fontSize: '9px',
		fontWeight: '400',
		lineHeight: '23px'
	}),
	placeholder: (base: CSSProperties, state: any) => ({
		...base,
		color: '#2C344E',
		fontSize: '12px',
		lineHeight: '23px',
		fontWeight: '400',
		'@media only screen and (max-width: 1440px)': {
			fontSize: '9px'
		}
	}),
	singleValue: (base: CSSProperties, state: any) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return {
			...base,
			opacity: opacity,
			transition: transition,
			color: '#2C344E',
			fontSize: '12px',
			fontWeight: '400',
			lineHeight: '23px',
			'@media only screen and (max-width: 1440px)': {
				fontSize: '9px'
			}
		};
	}
};

export const radiusSelectStyles = {
	...searchSelectStyles,
	control: (searchSelectStyles: CSSProperties, state: any) => ({
		...searchSelectStyles,
		backgroundColor: 'transparent',
		border: 'none',
		outline: 'none',
		boxShadow: 'none',
		minHeight: '22px',
		opacity: state.isDisabled ? '0.5' : '1'
	}),
	valueContainer: () => ({
		padding: '2px 0 2px 10px',
		display: 'flex'
	}),
	placeholder: () => ({
		color: '#FFF',
		fontSize: '12px',
		lineHeight: '26px',
		fontWeight: '400',
		'@media only screen and (max-width: 1440px)': {
			fontSize: '9px'
		}
	}),
	singleValue: (searchSelectStyles: CSSProperties, state: any) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return {
			...searchSelectStyles,
			opacity: opacity,
			transition: transition,
			color: '#FFF',
			fontSize: '12px',
			fontWeight: '400',
			lineHeight: '23px',
			width: '38px',
			'@media only screen and (max-width: 1440px)': {
				fontSize: '9px'
			}
		};
	}
};

export const whereAreSelectStyles = {
	...searchSelectStyles,
	menu: (searchSelectStyles: CSSProperties) => ({
		...searchSelectStyles,
		width: '258px',
		border: 'none',
		padding: '18px 9px 0 5px',
		marginTop: '-12px',
		right: '5px'
	}),
	control: (searchSelectStyles: CSSProperties) => ({
		...searchSelectStyles,
		width: '268px',
		minHeight: '22px',
		outline: 'none',
		boxShadow: 'none',
		'.css-319lph-ValueContainer': {
			padding: '0 8px'
		}
	})
};

export const inputFieldStyle = {
	width: '100%',
	height: '28px',
	border: '1px solid #fff',
	borderRadius: '3px',
	fontSize: '12px',
	fontWeight: '$font-regular',
	lineHeight: '60px',
	padding: '0  32px 0 10px',
	display: 'inline-block',
	outline: 'none'
};

export const menuFieldStyle: React.CSSProperties = {
	borderRadius: '3px',
	boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
	background: 'white',
	padding: '5px 8px 8px 0',
	position: 'absolute',
	zIndex: '10',
	fontFamily: 'Arial',
	color: 'rgb(33, 33, 33)',
	fontSize: '12px',
	lineHeight: '30px',
	top: '28px',
	left: '0px'
};
