import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import { ProfileRootState, removeEmptyStrings } from 'utils';
import { filterData, roomData } from '../utils/constants';
import { reactSelectStyles, roomsSelectStyles } from '../utils/reactSelectStyles';

import DropdownInput from './select';
import UserInput from './userInput';

import { RootState } from 'reducers/rootReducer';
import { getSearchResultsAction, getSuggestedLocation, recordPerPage } from 'actions/searchActions';

import 'bootstrap/dist/css/bootstrap.css';

export const INIT_FILTERS = {
	locationId: '',
	radius: '',
	objectType: '',
	demandType: '',
	fromPrice: '',
	fromAreaLiving: '',
	fromNumberOfRooms: '',
	recordPerPage: 24,
	zipcode: 0
};
interface ILocationOptions {
	id: string;
	name: string;
	pinCode: string;
}
export const FilterSection = () => {
	const profileDetail = useSelector((state: ProfileRootState) => state.profileDetailReducer);

	const [userFilter, setUserFilter] = useState(INIT_FILTERS);
	// const [locationLabel, setlocationLabel] = useState('');
	const [mainLocationArr, setMainLocationArr] = useState([] as ILocationOptions[]);
	// const [tempLocationArr, setTempLocationArr] = useState([] as any[]);
	// const [searchText, setSearchTxt] = useState('');

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const listFromReducer = useSelector((state: RootState) => state.searchResultReducer);
	const locationData = useSelector((state: any) => state.suggestedLocationReducer);

	const userID = useMemo(() => profileDetail?.data?.userId, [profileDetail]);

	useEffect(() => {
		dispatch(getSuggestedLocation());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	setTempLocationArr(mainLocationArr);
	// }, [mainLocationArr]);

	useEffect(() => {
		if (locationData && locationData.status === 'success' && locationData.data) {
			// const mergeDataPinCode = locationData.data.map((item: any) => ({
			// 	id: item.id,
			// 	name: `${item.name}-${item.pinCode}`
			// }));

			setMainLocationArr(locationData.data);
		}
	}, [locationData]);

	useEffect(() => {
		if (listFromReducer && listFromReducer.data && listFromReducer.status === 'success') {
			navigate('/suche');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listFromReducer]);

	const handleFilterChange = (name: string, value: number) => {
		setUserFilter({
			...userFilter,
			[name]: value
		});
	};

	const changeDropDownValue = (event: any) => {
		let filterValue;
		let filterKey;

		if (Array.isArray(event)) {
			let tempArray: string[] = [];
			event.forEach((option: any) => {
				tempArray.push(option.value);
			});
			filterValue = tempArray;
			filterKey = event[0].id;
		} else {
			filterValue = event.value;
			filterKey = event.id;
		}

		let value = filterValue;

		setUserFilter({
			...userFilter,
			[filterKey]:
				event.id === 'demandType' || event.id === 'objectType'
					? value
					: event.id === 'fromNumberOfRooms'
					? value
					: parseInt(value)
		});
	};

	const setUserFilterData = () => {
		const userSerchData = removeEmptyStrings(userFilter);
		localStorage.setItem('filterData', JSON.stringify(userSerchData));
		// localStorage.setItem(
		//     'filterData',
		//     JSON.stringify({
		//         fromAreaLiving: userFilter.fromAreaLiving,
		//         fromNumberOfRooms: userFilter.fromNumberOfRooms,
		//         fromPrice: userFilter.fromPrice,
		//         locationId: userFilter.locationId,
		//         // locationLabel: locationLabel,
		//         radius: userFilter.radius,
		//         demandType: userFilter.demandType,
		//         objectType: userFilter.objectType,
		//     })
		// );

		dispatch(
			getSearchResultsAction({
				...userSerchData,
				recordPerPage,
				userId: userID
			})
		);
	};

	const userLocation = (event: any) => {
		const zipcode = Number(event.name.match(/\d+/g));
		setUserFilter({
			...userFilter,
			locationId: event.id,
			zipcode
		});
		// setlocationLabel(event.name);
	};

	// const selectResult = (place: any, ref: any) => {
	//   setlocationLabel(ref.value);

	//   setUserFilter({
	//     ...userFilter,
	//     latitude: place?.geometry?.location.lat(),
	//     longitude: place?.geometry?.location.lng(),
	//   });
	// };

	// const handleSearch = (e: any) => {
	// 	return mainLocationArr.filter((item) => item.name.includes(e));
	// };

	const formatResult = (item: any) => {
		return (
			<div className='result-wrapper' key={item.name}>
				<span className='result-span'>{item.name}</span>
			</div>
		);
	};

	return (
		<div className='filter-section'>
			<div className='filter-container'>
				<div className='flex justify__content--center align__items--center city-detail-wrapper'>
					<div className='location-search'>
						<ReactSearchAutocomplete
							items={mainLocationArr}
							onSelect={userLocation}
							// onSearch={handleSearch}
							onClear={() =>
								setUserFilter({
									...userFilter,
									locationId: '',
									radius: ''
								})
							}
							autoFocus
							showIcon={false}
							placeholder='Wo? Ort, Straße, PLZ, ...'
							formatResult={formatResult}
							styling={{
								height: '42px',
								fontSize: '16px',
								border: 'none',
								backgroundColor: 'white',
								boxShadow: 'none',
								placeholderColor: '#8B8B8B',
								zIndex: 0
							}}
							showNoResultsText='Keine Ergebnisse'
							className='cursor-pointer'
						/>
					</div>
					<div className='flex filter-option'>
						{filterData.map((filter, index) => {
							return (
								<DropdownInput
									key={index}
									placeholder={filter.placeholder}
									option={filter.options}
									style={reactSelectStyles}
									// defaultValue={filter.options[0]}
									isDisabled={
										filter.name === 'radius' &&
										(!userFilter.locationId || userFilter.locationId === '')
									}
									changeValue={(event: any) => changeDropDownValue(event as Array<any>)}
									tooltipTitle={
										filter.name === 'radius' &&
										(!userFilter.locationId || userFilter.locationId === '')
											? 'Bitte wählen Sie den Standort aus'
											: ''
									}
								/>
							);
						})}
					</div>
				</div>
				<div className='flex align__items--center user-cost-space-wrapper'>
					<UserInput value={userFilter} handleFilterChange={handleFilterChange} />
					<div className='flex align__items--center justify__content--center  romm-data-wrapper'>
						<p>Zimmer ab </p>
						{roomData.map((room, index) => (
							<DropdownInput
								key={index}
								placeholder={room.placeholder}
								option={room.options}
								style={roomsSelectStyles}
								changeValue={(event: any) => changeDropDownValue(event as Array<any>)}
							/>
						))}
					</div>
				</div>

				<div className='flex justify__content--center align__items--center'>
					<button type='button' className='btn search-btn' onClick={setUserFilterData}>
						SUCHEN
					</button>
				</div>
			</div>
		</div>
	);
};
