import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { setRef } from 'services';
import { serviceLinks, privacyLinks, aboutImmowebLink } from './constants';
import logo from 'assets/images/footer-logo.png';
import './layout.scss';

const LinksArr = [aboutImmowebLink, serviceLinks, privacyLinks];
const titles = ['Über Immoweb', 'Services', 'Sicherheit'];
const HomeFooter = () => {
	const footerRef: any = useRef();
	const navigation = useNavigate();
	useEffect(() => {
		setRef(footerRef, 'footerRef');
	}, []);

	const handleNavigation = (url: string) => {
		navigation(url);
		window.scrollTo(0, 0);
	};
	return (
		<div className='main-footer flex justify__content--around'>
			<div className='footer-logo-div'>
				<div className='cursor-pointer footer-logo-wrapper' onClick={() => handleNavigation('/')}>
					<img src={logo} alt='logo' />
				</div>
			</div>
			<div className='flex justify__content--between footer-link-wrapper'>
				{LinksArr.map((Arr, index) => (
					<div className='flex flex--column footer-link' key={index}>
						<h6 className='footer-link-title'>{titles[index]}</h6>
						{Arr.map((value, index) => (
							<div
								key={index}
								onClick={() => handleNavigation(value.href)}
								className='footer-link-text pointer'
							>
								<p>{value.info}</p>
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};

export default HomeFooter;
