/* axios with abort */
import axios, { AxiosError } from "axios";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import englishText from "languages/english";
import { notify } from "components/commonComponents/notification/notification";
import { createAction, lang } from "utils";
import getLocalizeText from "utils/getLocalizeText";
import * as actionTypes from "reducers/actionTypes";
import { store } from 'store/store'

import { getAuthData } from "./referance.service";
import { BASE_URL } from "./baseUrl";


export const callAPI = (
  apiURL: string,
  apiMethod: any,
  apiData: any = {},
  resolveDataAsReponse = false,
  contentType = "application/json"
) => {
  const language = lang.GR;
  let headers = {
    // Authorization: localStorage.getItem("token") || "",
    "x-access-token": getAuthData() || "",
    "Content-Type": contentType,
    language: language,
  };

  return new Promise((resolve, reject) => {
    axios({
      url: `${BASE_URL}${apiURL}`,
      method: apiMethod,
      data: apiData,
      headers,
    })
      .then((response) => {
        if (resolveDataAsReponse) {
          resolve(apiData);
        } else {
          resolve(response);
        }
      })
      .catch((err: any) => {

        if (err.response && err.response.data) {
          if (err.response.status && err.response.status === 401) {
            store.dispatch(createAction(actionTypes.LOGOUT_SUCCESS, false));
          };
          const msg =
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : getLocalizeText(englishText.LOGIN_AGAIN_ERROR);
          // window.alert(msg);
          notify(msg, 'error')
          resolve(err.response);

          if (!axios.isCancel(err) && err.response) {
            reject(err.response);
          }
        }
      });
  });
};

export default callAPI;


