import { combineReducers } from "redux";
import * as searchReducers from "./objectReducers/searchReducer";
import * as objectDetailReducers from "./objectReducers/objectDetailReducer";
import * as onboardReducers from "./onboardingReducers/questionListReducer";
import * as suggestedLocationReducers from "./objectReducers/suggestedLocationReducer";
import { addUpdateReducer } from "./objectReducers/customObjectReducer";
import { profileDetailReducer } from "./objectReducers/profileDetailReducer";
import authReducer from "./objectReducers/authReducer";

const rootReducer: any = combineReducers({
  addUpdateReducer: addUpdateReducer,
  searchResultReducer: searchReducers.searchResultReducer,
  mapFilterReducer: objectDetailReducers.mapFilterReducer,
  summaryReducer: onboardReducers.summaryReducer,
  suggestedLocationReducer: suggestedLocationReducers.suggestedLocationReducer,
  profileDetailReducer: profileDetailReducer,
  auth: authReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
