import React from 'react';
import { compose, withProps, lifecycle } from 'recompose';
import { withGoogleMap, GoogleMap } from 'react-google-maps';
import pin from 'assets/images/pin.png';
import pinHighlight from 'assets/images/pin-highlight.png';
import multihover from 'assets/images/multi-hover.png';
import * as mapFunctions from '../components/mapData';
import multiIcon from 'assets/images/purple.png';
import SingleMapWindow from '../components/singleMapWindow';
import MultiMapWindow from '../components/multiMapWindow';
import CustomSpinner from 'components/commonComponents/customSpinner';
import { IMapLifeCycle, IMapProps } from '../interface/map.interface';

const MyMapComponent = compose<{}, IMapProps>(
	withProps({
		loadingElement: (
			<div style={{ height: `100%`, marginTop: '40%', textAlign: 'center' }}>
				<CustomSpinner animation='border' variant='info' />
			</div>
		),
		containerElement: <div style={{ height: `100%` }} />,
		mapElement: <div style={{ height: `100%` }} />
	}),
	lifecycle<IMapLifeCycle, {}>({
		componentDidMount() {
			const refs: any = {};
			this.setState({
				onMapMounted: (ref: any) => {
					refs.map = ref;
					maprefs = refs.map;

					const { mapData, multiMapData } = this.props;
					isFromSearch = this.props.isFromSearch || false;

					//show single pin type of map data
					if (maprefs && mapData && mapData.length > 0) {
						showSinglePins(this.props);
					}

					//show multi pin type of map data
					if (maprefs && multiMapData && multiMapData.length > 0) {
						showMultiPins(this.props);
					}
				},
				// zoom: 8,
				markers: []
			});
			const { closeInfo } = this.props;
			closeInfo();
		},
		componentDidUpdate(prevProps) {
			const { showType, removeMarker, mapData, multiMapData, pageChanged, addHighLightId, removeHighlightId } =
				this.props;

			// to show location pin highlighted on hover
			if (addHighLightId === prevProps.addHighLightId) {
				updateHighlights(addHighLightId);
			}

			//remove highlighted id
			if (addHighLightId === prevProps.addHighLightId && addHighLightId !== '') {
				this.props.clearAdd();
			}

			// to remove location pin highlighted on hover
			if (removeHighlightId === prevProps.removeHighlightId) {
				removeHighlights(removeHighlightId);
			}

			//remove removeID
			if (removeHighlightId === prevProps.removeHighlightId && removeHighlightId !== '') {
				this.props.clearRemove();
			}

			// update single pin type of data for objects
			if (prevProps.mapData !== mapData && pageChanged) {
				updateSinglePins(this.props);
			}

			// update multiple pin type of data for objects
			if (prevProps.multiMapData !== multiMapData && pageChanged) {
				updateMultiPins(this.props);
			}

			//remove checkboxes data : schools, hospitals etc.
			if (removeMarker) {
				markerArray[showType].length > 0 &&
					markerArray[showType].forEach((marker: any) => {
						marker.setMap(null);
					});
				markerArray[showType] = [];
			}
		},

		componentWillUnmount() {
			//clear single pins data
			clearAllMapData();
		}
	}),
	withGoogleMap
)((props: any) => (
	//@ts-ignore
	<GoogleMap
		zoom={7}
		defaultCenter={{
			lat: 52.520008,
			lng: 13.404954
		}}
		ref={props.onMapMounted}
		options={{
			disableDoubleClickZoom: props.isOpen || props.isOpenMulti,
			scrollwheel: false,
			fullscreenControl: false,
			zoomControlOptions: {
				/* eslint-disable no-undef */
				position: google.maps.ControlPosition.RIGHT_CENTER
			}
		}}
	>
		{/* Single Marker's infowindow */}
		<SingleMapWindow dataObj={props} />

		{/* Multiple Marker's infowindow */}
		<MultiMapWindow dataObj={props} />
	</GoogleMap>
));

let maprefs: any = '';
let markerArray: any = {
	schools: [],
	supermarkets: [],
	transports: [],
	medicals: [],
	parks: []
};
let markerClusterArray: any = null;
let markerClusterArrayMulti: any = null;
let mapArray: any = [];
let multiMapArray: any = [];
let isFromSearch = true;

// Show single markers on first load
const showMultiPins = (props: any) => {
	const { multiMapData, addHighLightClass, removeHighlight } = props;
	multiMapData.forEach((map: any, index: number) => {
		if (index < 25) {
			mapFunctions.createMultiMarker(
				map,
				props.onToggleMultiOpen,
				addHighLightClass,
				removeHighlight,
				maprefs,
				multiMapArray,
				index
			);
		}
	});
	// eslint-disable-next-line no-unused-vars
	markerClusterArrayMulti = mapFunctions.createCluster(multiMapArray, maprefs);
	props.pageChanged && props.resetPageChange();
};

// Show multiple type of markers on first load
const showSinglePins = (props: any) => {
	const { mapData, onToggleOpen, addHighLightClass, removeHighlight } = props;
	mapData.forEach((map: any, index: number) => {
		if (index < 25) {
			mapFunctions.createSingleMarker(
				maprefs,
				onToggleOpen,
				addHighLightClass,
				removeHighlight,
				mapArray,
				map,
				index
			);
		}
	});
	// eslint-disable-next-line no-unused-vars
	markerClusterArray = mapFunctions.createCluster(mapArray, maprefs);
};

// Update multiple markers when page/filters changes
const updateMultiPins = (props: any) => {
	const { closeInfo, multiMapData, addHighLightClass, removeHighlight } = props;

	//clear old makrers and cluster array
	multiMapArray.length > 0 &&
		multiMapArray.forEach((marker: any) => {
			marker.setMap(null);
		});
	multiMapArray = [];
	if (markerClusterArrayMulti) {
		markerClusterArrayMulti.clearMarkers();
		markerClusterArrayMulti.setMap(null);
		markerClusterArrayMulti = null;
	}
	closeInfo();

	if (maprefs && multiMapData && multiMapData.length > 0) {
		multiMapData.forEach((map: any, index: number) => {
			if (index < 25) {
				mapFunctions.createMultiMarker(
					map,
					props.onToggleMultiOpen,
					addHighLightClass,
					removeHighlight,
					maprefs,
					multiMapArray,
					index
				);
			}
		});

		markerClusterArrayMulti = mapFunctions.createCluster(multiMapArray, maprefs);
	}
	props.resetPageChange();
};

// Update single markers when page/filters changes
const updateSinglePins = (props: any) => {
	const { closeInfo, mapData, addHighLightClass, removeHighlight } = props;

	//clear old makrers and cluster array
	mapArray.length > 0 &&
		mapArray.forEach((marker: any) => {
			marker.setMap(null);
		});
	mapArray = [];
	if (markerClusterArray) {
		markerClusterArray.clearMarkers();
		markerClusterArray.setMap(null);
		markerClusterArray = null;
	}
	closeInfo();

	if (maprefs && mapData && mapData.length > 0) {
		mapData.forEach((map: any, index: number) => {
			mapFunctions.createSingleMarker(
				maprefs,
				props.onToggleOpen,
				addHighLightClass,
				removeHighlight,
				mapArray,
				map,
				index
			);
		});

		markerClusterArray = mapFunctions.createCluster(mapArray, maprefs);
	}
};

// Update highlighted icon on map
const updateHighlights = (addHighLightId: string) => {
	const mark = mapArray.find((i: any) => i.metadata && i.metadata.id === addHighLightId);

	if (mark) {
		let markerIcon = {
			url: isFromSearch ? mark.metadata.highlightIcon : pinHighlight,
			origin: new google.maps.Point(0, 0)
		};
		mark.setIcon(markerIcon);
	} else {
		const isMultiMarker = multiMapArray.find((i: any) => i.metadata.id.includes(addHighLightId));

		if (isMultiMarker) {
			let markerIcon = {
				url: multihover,
				origin: new google.maps.Point(0, 0),
				labelOrigin: new google.maps.Point(25, 62)
			};

			let index = isMultiMarker.metadata.id.findIndex((i: any) => i === addHighLightId);

			if (index >= 0) {
				isMultiMarker.setIcon(markerIcon);
			}
		}
	}
};

// Remove highlighted icon on map
const removeHighlights = (removeHighlightId: string) => {
	let oldmark = mapArray.find((i: any) => i.metadata && i.metadata.id === removeHighlightId);

	oldmark && oldmark.setIcon(isFromSearch ? oldmark.metadata.icon : pin);

	if (!oldmark) {
		oldmark = multiMapArray.find((i: any) => i.metadata.id.includes(removeHighlightId));
		let markerIcon = {
			url: multiIcon,
			origin: new google.maps.Point(0, 0),
			labelOrigin: new google.maps.Point(20, 45)
		};
		oldmark && oldmark.setIcon(markerIcon);
	}
};

// Clear all markers | filters | clusters on map unmount
const clearAllMapData = () => {
	for (let i = 0; i < mapArray.length; i++) {
		mapArray[i].setMap(null);
	}

	//clear multi pins data
	for (let i = 0; i < multiMapArray.length; i++) {
		multiMapArray[i].setMap(null);
	}
	mapArray = [];
	multiMapArray = [];
};

export default MyMapComponent;
