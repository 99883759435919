import { Action } from 'utils';

export const INITIAL_STATE = {
	response: []
};

export const searchResultReducer = (state = INITIAL_STATE, action: Action) => {
	switch (action.type) {
		case 'SEARCH_RESULT_FULFILLED':
			return {
				status: 'success',
				data: action.payload.data.data || []
			};

		case 'SEARCH_RESULT_PENDING':
			return {
				status: 'pending'
			};

		case 'CLEAR_SEARCH_RESULT':
			return {
				status: '',
				data: []
			};
		case 'CLEAR_SEARCH_CRESET_STATE':
			return {
				...INITIAL_STATE
			};
		default:
			return {
				...state
			};
	}
};
