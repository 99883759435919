import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Scrollbar } from 'swiper/modules';
// components
import SwiperGallery from 'components/finder/components/swiperGallery';
import englishText from 'languages/english';

// functions
import getLocalizeText from 'utils/getLocalizeText';
import { formatCurrency, propertyOptions, searchPropertyTypes } from 'utils';
import { API_CONFIG, HttpService } from 'services';
import { setMyProperties } from 'components/finder/utils/commonFunctions';

import 'swiper/css'; // Import the main Swiper styles
import 'swiper/css/scrollbar'; // Import the scrollbar styles

export const TopObjects = () => {
	SwiperCore.use([Scrollbar]);
	const [propertyList, setPropertyList] = useState([]);
	const [isLoading, setLoding] = useState(true);
	const [hoveredId, setHoverId] = useState('');

	useEffect(() => {
		getPropertyData();
	}, []);

	const getPropertyData = async () => {
		HttpService.get(API_CONFIG.path.publicObject, { objectCount: 3 })
			.then((response) => {
				if (response.data) {
					const combinedResult = setMyProperties(response.data);
					setPropertyList(combinedResult);
					setLoding(false);
				}
			})
			.catch((err) => {
				setLoding(false);
			});
	};
	return (
		<section className='top-property-object'>
			<div className='property-wrapper'>
				<h1 className='location-tittle text--center'>Unsere Top Objekte</h1>
				<div>
					<div className='landing-page-wrapper property-card-wrapper d-flex home-card'>
						{propertyList.length > 0 &&
							!isLoading &&
							propertyList.map((listItem: any, index) => {
								return (
									<div
										className='column-wrapper w--25 property-card-responsive'
										key={index}
										onMouseEnter={() => setHoverId(listItem.id)}
										onMouseLeave={() => setHoverId('')}
									>
										<Link target='_blank' rel='noopener noreferrer' to={`/objekt/${listItem.id}`}>
											<div className='property-card d-flex pointer'>
												<div className='carousel-wrapper'>
													<div className={'search-img1'}>
														<SwiperGallery
															objectPictures={listItem.objectPictures}
															id={listItem.id}
															hoveredId={hoveredId}
															isFullMapScreen={false}
															isdisable={false}
														/>
														{listItem.marketingType && (
															<div
																className={`property-type top-property-type position-absolute ${
																	listItem.marketingType === propertyOptions.SELL
																		? 'property-sell'
																		: ''
																} `}
															>
																<p>{searchPropertyTypes[listItem.marketingType]}</p>
															</div>
														)}
														<div className='justify-content-between'>
															<p className='ml-3 adr-label text-truncate'>
																{listItem.propertyType
																	? `${getLocalizeText(listItem.propertyType)}  
                                								${listItem.adrPostalCode && ' in ' + listItem.adrPostalCode}${
																			listItem.adrQuarter
																				? ' ' + listItem.adrQuarter
																				: ''
																	  }${
																			listItem.adrQuarter !== listItem.adr_city
																				? ', ' + listItem.adr_city
																				: ''
																	  }`
																	: `${listItem.adrStreet || '-'}`}
															</p>
															<div className='d-flex justify-content-between'>
																<div className='d-flex'>
																	<p className='location font-bold mb-0'>
																		<li>{`${
																			listItem.areaLiving
																				? Math.round(listItem.areaLiving) +
																				  ' m²'
																				: '-'
																		} `}</li>
																	</p>
																	<p className='location font-bold mb-0'>
																		<li>
																			{listItem.propRoomsAmount || '-'}{' '}
																			{getLocalizeText(englishText.ROOM)}
																		</li>
																	</p>
																</div>
																<div>
																	<p className='price-label pt-0'>
																		{listItem.formatedPrice ||
																			formatCurrency(listItem.totalRent) ||
																			'-'}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</Link>
									</div>
								);
							})}
					</div>
					<Swiper
						spaceBetween={50}
						slidesPerView={1}
						scrollbar={{ draggable: true }}
						className='landing-page-wrapper property-card-wrapper home-swiper-card d-none'
					>
						{propertyList.length > 0 &&
							!isLoading &&
							propertyList.map((listItem: any, index) => (
								<SwiperSlide key={index}>
									<div
										className='column-wrapper w--25 property-card-responsive'
										onMouseEnter={() => setHoverId(listItem.id)}
										onMouseLeave={() => setHoverId('')}
									>
										<Link
											target='_blank'
											rel='noopener noreferrer'
											to={`/objekt/${listItem.id}`}
											className='flex align__items--center justify__content--center'
										>
											<div className='property-card d-flex pointer'>
												<div className='carousel-wrapper'>
													<div className={'search-img1'}>
														<SwiperGallery
															objectPictures={listItem.objectPictures || ['']}
															id={listItem.id}
															hoveredId={hoveredId}
															isFullMapScreen={false}
															isdisable={false}
														/>
														{listItem.marketingType && (
															<div
																className={`property-type top-property-type position-absolute ${
																	listItem.marketingType === propertyOptions.SELL
																		? 'property-sell'
																		: ''
																} `}
															>
																<p>{searchPropertyTypes[listItem.marketingType]}</p>
															</div>
														)}
														<div className='justify-content-between'>
															<p className='ml-3 adr-label text-truncate'>
																{listItem.propertyType
																	? `${getLocalizeText(listItem.propertyType)}  
                                								${listItem.adrPostalCode && ' in ' + listItem.adrPostalCode}${
																			listItem.adrQuarter
																				? ' ' + listItem.adrQuarter
																				: ''
																	  }${
																			listItem.adrQuarter !== listItem.adr_city
																				? ', ' + listItem.adr_city
																				: ''
																	  }`
																	: `${listItem.adrStreet || '-'}`}
															</p>
															<div className='d-flex justify-content-between'>
																<div className='d-flex'>
																	<p className='location font-bold mb-0'>
																		<li>{`${
																			listItem.areaLiving
																				? Math.round(listItem.areaLiving) +
																				  ' m²'
																				: '-'
																		} `}</li>
																	</p>
																	<p className='location font-bold mb-0'>
																		<li>
																			{listItem.propRoomsAmount || '-'}{' '}
																			{getLocalizeText(englishText.ROOM)}
																		</li>
																	</p>
																</div>
																<div>
																	<p className='price-label pt-0'>
																		{listItem.formatedPrice ||
																			formatCurrency(listItem.totalRent) ||
																			'-'}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</Link>
									</div>
								</SwiperSlide>
							))}
					</Swiper>
				</div>
			</div>
		</section>
	);
};
