import React from 'react';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import ImageGallery from 'react-image-gallery';
import getLocalizeText from 'utils/getLocalizeText';
import englishText from 'languages/english';
import tipTile from 'assets/images/placeholder.png';
import { setPlaceholderImg } from 'utils';
import { openObjectDetail } from 'components/finder/utils/commonFunctions';
import { IMapWindowProps } from '../interface/map.interface';

const MultiMapWindow = (props: IMapWindowProps) => {
	const { dataObj } = props;
	return (
		<div>
			{dataObj.isOpenMulti &&
				dataObj.mapMulti &&
				dataObj.mapMulti.id.map((m: string, index: number) => (
					//@ts-ignore
					<InfoBox
						key={index}
						defaultPosition={
							// eslint-disable-next-line no-undef
							new google.maps.LatLng(parseFloat(dataObj.mapMulti.lat), parseFloat(dataObj.mapMulti.lon))
						}
						options={{ closeBoxURL: ``, enableEventPropagation: true }}
						visible={index === dataObj.visibleIndex}
					>
						<div
							className='infowin-container map-img-gallary  pointer'
							ref={dataObj.setWrapperRefForInfowindow}
						>
							<div className='pagination-div d-flex justify-content-between'>
								<button
									className='image-gallery-icon image-gallery-left-nav'
									style={{
										top: '12px',
										position: 'relative',
										filter: 'drop-shadow(0 1px 1px grey)'
									}}
									disabled={dataObj.visibleIndex === 0}
								>
									<svg
										className='image-gallery-svg'
										viewBox='6 0 12 24'
										fill='none'
										stroke='currentColor'
										strokeWidth='1'
										strokeLinecap='round'
										strokeLinejoin='round'
										style={{
											width: '23px',
											height: '23px',
											color: '#A4A4A4'
										}}
										onClick={() => dataObj.handlePrev(index + 1)}
									>
										<polyline points='15 18 9 12 15 6'></polyline>
									</svg>
								</button>
								<div className='text-center mt-2'>
									<li className='multi-label'>{getLocalizeText(englishText.MULTIPLE_PROPERTIES)}</li>
									<li className='page-no-label'>
										{dataObj.visibleIndex + 1}/{dataObj.mapMulti.id.length}
									</li>
								</div>
								<button
									className='image-gallery-icon image-gallery-left-nav'
									style={{
										top: '12px',
										position: 'relative',
										filter: 'drop-shadow(0 1px 1px grey)'
									}}
									disabled={dataObj.visibleIndex === dataObj.mapMulti.id.length - 1}
								>
									<svg
										className='image-gallery-svg'
										viewBox='6 0 12 24'
										fill='none'
										stroke='currentColor'
										strokeWidth='1'
										strokeLinecap='round'
										strokeLinejoin='round'
										style={{
											width: '23px',
											height: '23px',
											color: '#A4A4A4'
										}}
										onClick={() => dataObj.handleNext(dataObj.mapMulti.id.length, index + 1)}
									>
										<polyline points='9 18 15 12 9 6'></polyline>
									</svg>
								</button>
							</div>
							<div
								className='card-img'
								onClick={(event) => openObjectDetail(event, dataObj.mapMulti.id[dataObj.visibleIndex])}
							>
								{dataObj.mapMulti.imgList[dataObj.visibleIndex] &&
								dataObj.mapMulti.imgList[dataObj.visibleIndex].length === 1 ? (
									<img
										className='infowin-div-left single-img'
										src={dataObj.mapMulti.coverImg[dataObj.visibleIndex] || tipTile}
										onError={setPlaceholderImg}
										alt=''
									/>
								) : (
									<ImageGallery
										items={dataObj.mapMulti.imgList[dataObj.visibleIndex]}
										showPlayButton={false}
										showThumbnails={false}
										showFullscreenButton={false}
										lazyLoad={true}
										disableKeyDown={true}
										onImageError={setPlaceholderImg}
									/>
								)}
							</div>

							<div
								className='d-flex justify-content-between map-label box-shadow  pointer'
								onClick={(event) => openObjectDetail(event, dataObj.mapMulti.id[dataObj.visibleIndex])}
							>
								<p className='map-label-adr ml-2'>
									<li>{dataObj.mapMulti.propertyType[dataObj.visibleIndex] || '-'}</li>
									<li>{dataObj.mapMulti.adr[dataObj.visibleIndex]}</li>
								</p>
								<p className='mr-2'>
									<li className='map-label-price'>{dataObj.mapMulti.price[dataObj.visibleIndex]}</li>
									<li className='sub-label-map'>
										{dataObj.mapMulti.priceLabel[dataObj.visibleIndex]}
									</li>
								</p>
							</div>
						</div>
					</InfoBox>
				))}
		</div>
	);
};

export default MultiMapWindow;
