import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { ClosePopup } from 'components/commonComponents/icons';
import Modal from 'components/modal';
import { propertyOption, propertyTab } from '../utils/constants';
import { TABS } from '../container/myProperty';
import ModalComponent from 'components/commonComponents/modalComponent';
interface IProps extends PropsWithChildren {
	closeModal: () => void;
	setCurrentTab: React.Dispatch<React.SetStateAction<propertyTab>>;
	currentTab: propertyTab;
	isActive: (tab: propertyTab) => boolean;
	selectedValue: string;
	isEdit: boolean;
}
const AddPropertyModal: FC<IProps> = ({
	closeModal,
	children,
	setCurrentTab,
	currentTab,
	isActive,
	selectedValue,
	isEdit
}) => {
	const currentIndex = useMemo(() => TABS.indexOf(currentTab), [currentTab]);

	return (
		<Modal closeModal={closeModal} className={'modal-position'} closeOnOutsideClick={false}>
			<div className='property-popup-wrapper position-relative'>
				<div className='property-popup-header'>
					<h1 className='property-header-text text--center'>
						{isEdit ? 'INSERAT BEARBEITEN' : 'INSERAT ANLEGEN'}
					</h1>
					<div className='position-absolute close-button pointer' onClick={closeModal}>
						<ClosePopup />
					</div>
					{currentIndex > 0 && (
						<>
							<div className='active-tab-text flex align__items--center justify__content--between'>
								<h4 className='property-name'>
									{selectedValue === propertyOption.land && currentTab === propertyTab.location
										? propertyTab.locationLand
										: currentTab}
								</h4>
								<p className='tab-index'>
									Schritt <span>{currentIndex}</span> von 5
								</p>
							</div>
							<ProgressBar>
								{TABS.map((tab, i) => {
									if (i === 0) {
										return;
									}
									return (
										<ProgressBar
											className={`progress-bar-color pointer ${
												isActive(tab) ? 'active-popup' : ''
											} ${currentTab === tab ? 'current-popup' : ''}`}
											now={20}
											key={i}
											onClick={() => isActive(tab) && setCurrentTab(tab)}
										/>
									);
								})}
							</ProgressBar>
						</>
					)}
				</div>
				<div className='children-div'>{children}</div>
			</div>
		</Modal>
	);
};
export default AddPropertyModal;
