import React, { FC } from 'react';
import CustomRadioButton from 'components/commonComponents/form/radioButton/radioButton';
import { propertyTabRadioButtonOption } from '../utils/constants';
import PropertyButtonDiv from './propertyButtonDiv';

interface IProps {
	selectedValue: string;
	setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
	resetForm: () => void;
	isLoading: boolean;
}
const AddPropertyTab: FC<IProps> = ({ selectedValue, setSelectedValue, resetForm, isLoading }) => {
	const handleChangeRadioButton = (selectedLabel: string) => {
		setSelectedValue(selectedLabel);
		resetForm();
	};
	return (
		<div className='add-property-tab'>
			<p>
				Du kannst eigene Immobilien bei <span>immoweb.de</span> hinzufügen und verwalten. Diese Immobilien sind
				nur für dich sichtbar.
			</p>
			<h5>Wählen Sie zunächst einen Immobilientyp aus</h5>
			<div>
				<CustomRadioButton
					options={propertyTabRadioButtonOption}
					handleChange={handleChangeRadioButton}
					selectedValue={selectedValue}
					className={
						'flex align__items--center justify__content--around full-width add-property-tab-radio-button'
					}
				/>
			</div>
			<PropertyButtonDiv backButton={false} showSpinner={isLoading} isDisabled={!selectedValue} />
		</div>
	);
};

export default AddPropertyTab;
