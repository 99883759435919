import de from "languages/german";
import { lang } from "./constants";

const getMessage = (message: string, defaultLanguage = lang.GR) => {
  if (defaultLanguage === "de") {
    return de[message] || message;
  }

  return message;
};

export default getMessage;
