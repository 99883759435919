import React from 'react';
//@ts-ignore
import Skeleton from 'react-loading-skeleton';

interface ISkeletonProps {
	className: string;
}

export default function SkeletonLoading(props: ISkeletonProps) {
	const { className } = props;
	return (
		<>
			{[1, 2, 3, 4, 5, 6, 7, 8].map((key) => (
				<div className={className} key={key}>
					<div className='property-card'>
						<Skeleton height={'80%'} style={{ lineHeight: 'unset' }} />

						<div className='property-detail-wrapper'>
							<Skeleton width={'80%'} height={20} style={{ lineHeight: 'unset' }} />

							<div className='d-flex justify-content-space-between align-items-center'>
								<div className='area-room-spec'>
									<Skeleton
										height={20}
										inline
										count={2}
										width={'40%'}
										style={{ lineHeight: 'unset', marginRight: '10px' }}
									/>
								</div>

								<div className='price-spec'>
									<Skeleton
										height={20}
										width={'80%'}
										style={{ lineHeight: 'unset', float: 'right' }}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			))}
		</>
	);
}
