import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ClosePopup, HomepageIcon } from 'components/commonComponents/icons';
import { popupName } from 'components/layout/constants';
import { notify } from 'components/commonComponents/notification/notification';

import { AuthClassProps } from '../interface/auth';
import { HttpService } from 'services';
interface ActivationUserProps {
	setUserEmail: React.Dispatch<React.SetStateAction<string>>;
	setShowErrorPopup: React.Dispatch<React.SetStateAction<boolean>>;
	setComponent: React.Dispatch<React.SetStateAction<string>>;
}

const ActivationUser: React.FC<AuthClassProps & ActivationUserProps> = (props) => {
	const [token, setToken] = useState('');
	const navigation = useNavigate();

	useEffect(() => {
		setToken(getToken());
	}, []);

	const getToken = () => {
		let token = '';
		if (window.location.pathname !== '') {
			token = window.location.pathname.split('/activation/')[1];
		}
		return token;
	};
	const handleActivation = () => {
		HttpService.put(`v1/profile/activation/${token}`)
			.then((response) => {
				props.setUserEmail?.(response.data);
				props.handlePopup(popupName.login);
				props.setComponent(popupName.activation);
				// User activated successfully
				localStorage.setItem('userVerified', 'true');
				notify(`${response.message}`, 'success');
				navigation('/');
			})
			.catch((err) => {
				const errorMessage = err.response ? err.response.data.message : '';
				props.setShowErrorPopup(true);
				props.handlePopup(popupName.login);
				props.setComponent(popupName.activation);

				if (
					errorMessage === 'Your account is already verified.' ||
					errorMessage === 'Dein Konto ist bereits verifiziert.'
				) {
					localStorage.setItem('userVerified', 'true');
				}
				notify(`${err.response.data.message}`, 'error');
			});
	};
	return (
		<div className='activation-user-popup-wrapper login-register-wrapper position-relative'>
			<div className='text--right position-absolute close-btn cursor-pointer' onClick={props.closePopup}>
				<ClosePopup />
			</div>
			<div className='login-div login-register-div'>
				<div className='logo-wrapper'>
					<HomepageIcon />
				</div>
				<div className='login-tittle-wrapper '>
					<h3 className='login-register-tittle'>WILLKOMMEN!</h3>
				</div>

				<p className='verify-subtitle text--center '>
					Wir freuen uns, dass du da bist.
					<br /> Dein Account ist aktiviert. Viel Spaß und Erfolg!
				</p>

				<div className='text--center'>
					<button className={`verify-butten mail-send-btn `} onClick={handleActivation} formTarget='_blank'>
						SUCHE STARTEN
					</button>
				</div>
			</div>
		</div>
	);
};

export default ActivationUser;
