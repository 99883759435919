import React from 'react';
import { FieldError } from 'react-hook-form';

const CommonFormBtn = (props: {
	setShowPopup?: React.Dispatch<React.SetStateAction<boolean>>;
	labelText: string;
	buttonText: string;
	buttonClassName?: string;
	asterisk?: boolean;
	error?: FieldError | undefined;
}) => {
	const { setShowPopup, labelText, buttonText, buttonClassName, asterisk = false, error } = props;
	return (
		<div>
			<div className='flex'>
				<label className='form-label'>{labelText}</label>
				{asterisk && <span className='form-asterisk'>&nbsp;*</span>}
			</div>
			<div className='flex flex--column align__items--start'>
				<button
					type='button'
					className={`common-button ${buttonClassName || ''}`}
					onClick={() => setShowPopup && setShowPopup(true)}
				>
					{buttonText}
				</button>
				{error && <span className='error-message'>{error.message}</span>}
			</div>
		</div>
	);
};

export default CommonFormBtn;
