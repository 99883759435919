import englishText from 'languages/english';
import { formatCurrency, propertyType } from 'utils';
import getLocalizeText from 'utils/getLocalizeText';

export const getFacts = (propertyDetail: any, objectDetails: any) => {
	const isPlot = propertyDetail.propertyType && propertyDetail.propertyType === 'Plot';
	return [
		{
			label: englishText.PROP_SUB_TYPE,
			value: isPlot ? propertyType.PLOT : propertyDetail.propertyType || '-'
		},
		{
			label: englishText.YEAR_BUILT,
			value: propertyDetail.yearConstructed || '-',
			color: '#fff'
		},
		{
			label: englishText.PLOT_AREA,
			value: objectDetails.Plot_area_approx
				? objectDetails.Plot_area_approx.toString().replace(',', '.') + ' m²'
				: '-'
		},
		{
			label: englishText.LIVING_SPACE,
			value:
				!isPlot && propertyDetail.areaLiving
					? propertyDetail.areaLiving.toString().replace(',', '.') + ' m²'
					: '-'
		},
		{
			label: englishText.CONDITION,
			value:
				(propertyDetail.objectCondition &&
					getLocalizeText(propertyDetail.objectCondition).replace(/[_]/g, ' ')) ||
				'-'
		},
		{
			label: englishText.FURNISHING,
			value: '-',
			color: '#fff'
		},
		{
			label: englishText.BEDROOMS,
			value: objectDetails.Sleeping_rooms || '-'
		},
		{
			label: englishText.ROOMS,
			value: propertyDetail.propRoomsAmount ? propertyDetail.propRoomsAmount : '-'
		},
		{
			label: englishText.BATHROOMS,
			value: objectDetails.Bathrooms || '-',
			color: '#fff'
		},

		{
			label: englishText.FLOORS,
			value: objectDetails.Total_floor || '-',
			color: '#fff'
		},
		{
			label: englishText.CONSTRUCTION_PHASE,
			value: objectDetails.constructionPhase || '-',
			color: '#fff'
		},
		{
			label: englishText.VACANT_FROM,
			value: objectDetails.Vacant || '-'
		}
	];
};

const getIcon = (value: number) => {
	if (value <= 33) {
		return '#be3131';
	} else if (value > 33 && Math.round(value) <= 67) {
		return '#ffbb00';
	} else {
		return '#65c239';
	}
};

export const getFinance = (propertyDetail: any) => [
	{
		label: englishText.PURCHASE_PRICE,
		value: propertyDetail.formatedPrice
			? `${propertyDetail.formatedPrice}`
			: propertyDetail.totalRent
			? formatCurrency(propertyDetail.totalRent)
			: '-',
		color: getIcon(propertyDetail.financialScore)
	},

	{
		label: englishText.PURCHASE_PRICE_M,
		value: propertyDetail.pricePerSqm || '-',
		color: getIcon(propertyDetail.financialScore)
	},
	// {
	// 	label: englishText.ADDITIONAL_COST,
	// 	value: propertyDetail.additionalCost || '-'
	// },
	// {
	// 	label: englishText.TOTAL_COSTS,
	// 	value: propertyDetail.totalCost || '-'
	// },
	{
		label: englishText.MONTHLY_COST_EST,
		value: propertyDetail.monthlyCost || '-',
		color: getIcon(propertyDetail.financialScore)
	},
	{
		label: englishText.PRICE_TREND,
		value: propertyDetail.priceTrend || '-',
		color: getIcon(propertyDetail.financialScore)
	}
];

export const getRentFinance = (propertyDetail: any) => [
	{
		label: 'Mietpreis',
		value: formatCurrency(propertyDetail.totalRent) || '-',
		color: getIcon(propertyDetail.financialScore)
	},
	// {
	// 	label: englishText.ADDITIONAL_COST,
	// 	value: propertyDetail.additionalCost || '-',
	// 	color: getIcon(propertyDetail.financialScore)
	// },
	{
		label: 'Kaution',
		value: formatCurrency(propertyDetail.depositCost) || '-',
		color: getIcon(propertyDetail.financialScore)
	}
];

export const getDetail = (propertyDetail: any, objectDetails: any) => [
	{
		label: englishText.GARDEN,
		value: propertyDetail.equipGarden ? englishText.YES : englishText.NO
	},
	{
		label: englishText.BALCONY,
		value: propertyDetail.equipBalcony ? englishText.YES : englishText.NO
	},
	{
		label: englishText.PARKING,
		value: objectDetails.Garage ? englishText.YES : englishText.NO
	},
	{
		label: englishText.KITCHEN_FITTED,
		value: objectDetails.equipKitchenFitted ? englishText.YES : englishText.NO
	},
	{
		label: englishText.ENERGY_CLASS,
		value: propertyDetail.energyEffenciencyClass || '-'
	},
	{
		label: englishText.GUEST_TOILET,
		value: propertyDetail.equipBathGuestToilet ? englishText.YES : englishText.NO
	}
];

export const getLocalSupplyArray = (propertyDetail: any) => [
	{
		label: englishText.SHOPPING,
		value: propertyDetail.closestDistance.shopping || '-'
	},
	{
		label: englishText.PRIMARY_SCHOOL,
		value: propertyDetail.closestDistance.primary_schools || '-'
	},
	{
		label: englishText.UNIVERSITY,
		value: propertyDetail.closestDistance.university || '-'
	},
	{
		label: englishText.PARKS,
		value: propertyDetail.closestDistance.parks || '-'
	},
	{
		label: englishText.PUBLIC_TRANSPORT,
		value: propertyDetail.closestDistance.transports || '-'
	}
];

export const getLocalInfoArray = (propertyDetail: any) => [
	{ label: englishText.QUARTER, value: propertyDetail.adrQuarter || '-' },
	{ label: englishText.POPULATION, value: propertyDetail.population || '-' },
	{
		label: englishText.AREA,
		value: propertyDetail.areakm ? propertyDetail.areakm + ' km² ' : '-'
	}
];

export const charLimit = 600;

export const getDescription = (propertyDetail: any, showReadMore: boolean) => {
	if (propertyDetail.objectDescription.length > charLimit && !showReadMore) {
		const a = propertyDetail.objectDescription.substring(0, charLimit);
		return a.split('<br />');
	}
	return propertyDetail.objectDescription.split('<br />');
};

export const currencyInput = [
	{
		label: 'Preis',
		defaultValue: '200.000',
		suffix: '€',
		Percentage: ''
	},
	{
		label: 'Maklercourtage',
		defaultValue: '25',
		suffix: '€',
		Percentage: '%'
	},
	{
		label: 'Kaufnebenkosten',
		defaultValue: '25.0',
		suffix: '€',
		Percentage: ''
	}
];
