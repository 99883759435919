import React, { useState, useEffect, useRef } from 'react';
import { Trans } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Rheostat from 'rheostat';
import PropTypes from 'prop-types';
import getLocalizeText from 'utils/getLocalizeText';
import englishText from 'languages/english';
import { getSinglefilterParameters } from 'utils/functions';
import { primaryColor } from 'utils/constants';
import HttpService from 'services/http.service';
import { API_CONFIG } from 'services/api';

const SearchFilters = (props: any) => {
	const result = getSinglefilterParameters(props.menuName);
	const inputRefMin: any = useRef();
	const inputRefMax: any = useRef();

	const [graph, setGraph] = useState<any>(null);
	const [yArray, setY] = useState<number[]>([]);
	const [xArray, setX] = useState<number[]>([]);
	const [btnActive, setActive] = useState(false);
	const [focused, setFocused] = useState('');

	useEffect(
		() => {
			setGrpah();
		},
		// eslint-disable-next-line
		[]
	);

	const setGrpah = async () => {
		const name = result.graphLabel;
		if (props[name]) {
			setGraphData(setGraph, props[name]);
		} else {
			HttpService.get(`${API_CONFIG.path.graph}/${props.menuName}`, {})
				.then((response) => {
					const data = response.data.array;
					setGraphData(setGraph, data);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	const setGraphData = (setGraph: any, data: any) => {
		setGraph(data);
		let maxStep = Math.max(...data);
		let yArray: number[] = [];
		let xArray: number[] = [];
		data.forEach((value: any, index: number) => {
			if (parseFloat(value) > 0) {
				let a = parseFloat(value) * 100;
				a = a / maxStep;
				yArray.push(a);
			} else {
				yArray.push(0);
			}
			let b = index * (100 / data.length);
			xArray.push(b);
		});
		setX(xArray);
		setY(yArray);
	};

	useEffect(
		() => () => {
			setGraph(null);
		},
		[]
	);

	// on change venet for slider
	const onSmallSliderChange = (event: any) => {
		!btnActive && setActive(true);
		if (props.currentValueMin !== event.values[0]) {
			inputRefMin.current.focus();
			inputRefMax.current.blur();
		} else if (props.currentValueMax !== event.values[1]) {
			inputRefMax.current.focus();
			inputRefMin.current.blur();
		}
		props.onSmallSliderChange(event);
	};

	const handleFilterApplyClick = (props: any) => {
		props.applyFilterSingle();
	};

	return (
		<div
			className={`small-filter-modal ${props.className} ${props.show ? 'open fade-in' : 'close-model fade-out'}`}
			style={{ height: '290px' }}
			ref={props.setWrapperRef}
		>
			<div className='container-fluid'>
				<div
					className='range-item mb-4 d-flex align-items-center mt-3'
					style={{
						width: '80%',
						margin: '0 auto'
					}}
				>
					<Form.Group className='mb-0 w-100 mt-2' controlId='formBasicRange'>
						{/* Range slider  */}
						<div
							className='mb-4'
							style={props.menuName === 'rooms' ? { width: '60%', margin: '0 auto' } : {}}
						>
							<svg className='graph-container' style={{ display: 'block', maxHeight: '60px' }}>
								{graph &&
									yArray.length > 0 &&
									xArray.length > 0 &&
									graph.map((g: any, index: number) => {
										return (
											<rect
												key={index}
												title={g}
												fill={
													props.currentValueMin * result.step <= xArray[index] &&
													props.currentValueMax * result.step > xArray[index]
														? primaryColor
														: '#cecece'
												}
												width={`${result.widthValue / graph.length}%`}
												rx={'4.3'}
												y={`${100 - yArray[index]}%`}
												x={`${xArray[index]}%`}
												className='Histogram-bar'
												data-testid={`histogram-bar-${index + 1}`}
												height={'100%'}
												style={{
													// @ts-ignore
													'--element-svg-y-height': `${100 - yArray[index]}%`
												}}
											></rect>
										);
									})}
							</svg>

							<Rheostat
								min={props.menuName === 'rooms' ? 1 : 0}
								max={props.menuName === 'rooms' ? 8.1 : result.maxValue + result.step}
								values={[
									parseFloat(props.currentValueMin),
									props.currentValueMax ? parseFloat(props.currentValueMax) : result.maxValue
								]}
								onChange={onSmallSliderChange}
								onValuesUpdated={onSmallSliderChange}
							/>
						</div>

						{/* input boxes */}
						<>
							<Form.Group
								className='mb-0 d-flex align-items-center justify-content-between'
								style={{
									width: '85%',
									margin: '0 auto'
								}}
							>
								<div
									className={`inputbox-div position-relative ${focused === 'min' && 'focused'}`}
									onFocus={() => setFocused('min')}
									onBlur={() => setFocused('')}
								>
									<span className='hover-title'>{`${getLocalizeText(props.menuName)} min.`}</span>
									<Form.Control
										ref={inputRefMin}
										className={`mt-2 minmax focus-visible ${
											props.menuName !== 'rooms' && 'show-pad'
										}`}
										type='number'
										value={props.currentValueMin}
										onChange={props.onSmallSliderInputChange('currentValueMin')}
										name={props.menuName}
										max={props.currentValueMax}
										min={props.menuName === 'rooms' ? 1 : 0}
										step={result.valueStep}
									/>
									<p className='symbol-left'>{getLocalizeText(result.symbol)}</p>
								</div>
								<span className='dash-filter'>-</span>
								<div
									className={`inputbox-div position-relative ${focused === 'max' && 'focused'}`}
									onFocus={() => setFocused('max')}
									onBlur={() => setFocused('')}
								>
									<span className='hover-title'>{`${getLocalizeText(props.menuName)} max.`}</span>
									<Form.Control
										ref={inputRefMax}
										className={`mt-2 minmax ${props.menuName !== 'rooms' && 'show-pad'}`}
										type='number'
										value={
											(props.currentValueMax > result.maxValue
												? result.maxValue
												: props.currentValueMax) || result.maxValue
										}
										onChange={props.onSmallSliderInputChange('currentValueMax')}
										name={props.menuName}
										min={props.currentValueMin || 0}
										max={result.maxValue + 1}
										step={result.valueStep}
									/>
									{props.currentValueMax > result.maxValue && (
										<p
											className='symbol-right-plus'
											style={{ right: result.rightMargin || '60px' }}
										>
											+
										</p>
									)}
									<p className='symbol-left'>{getLocalizeText(result.symbol)}</p>
								</div>
							</Form.Group>
						</>
					</Form.Group>
				</div>
				<div className='w-100 text-center bottom-btn'>
					<button
						className={`button-filter ${btnActive && 'active-btn'}`}
						style={{ marginLeft: '100px' }}
						onClick={() => handleFilterApplyClick(props)}
					>
						<Trans i18nKey={englishText.APPLY} defaultMessage={englishText.APPLY} />
					</button>
					<p
						className='clear-btn'
						style={{ marginRight: '20px', marginLeft: '40px' }}
						onClick={() => props.clearSmallFilter()}
					>
						<Trans i18nKey={englishText.CLEAR} defaultMessage={englishText.CLEAR} />
					</p>
				</div>
			</div>
		</div>
	);
};

SearchFilters.propTypes = {
	className: PropTypes.string.isRequired,
	currentValueMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	currentValueMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onSmallSliderChange: PropTypes.func.isRequired,
	applyFilterSingle: PropTypes.func.isRequired,
	onSmallSliderInputChange: PropTypes.func.isRequired,
	setWrapperRef: PropTypes.func.isRequired,
	clearSmallFilter: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	menuName: PropTypes.string,
	spaceGraph: PropTypes.array,
	priceGraph: PropTypes.array,
	roomsGraph: PropTypes.array
};

SearchFilters.defaultProps = { show: false };

export default SearchFilters;
