import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import InputField from 'components/commonComponents/form/inputText';
import InputSelect from 'components/commonComponents/form/inputSelect';
import PhoneNumber from 'components/commonComponents/form/phoneNumber';
import { IFormProps } from '../utils/form.interface';
import CommonFormBtn from './commonFormBtn';
import { GENDER_OPTIONS } from '../utils/constants';

type PersonalInformationForm = {
	gender: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
};

const PersonalInformation = (
	props: IFormProps & {
		setShowPopup?: React.Dispatch<React.SetStateAction<boolean>>;
		displayChangePassword: boolean;
	}
) => {
	const {
		control,
		formState: { errors }
	} = useFormContext<PersonalInformationForm>();

	const { requiredFields, rules, displayChangePassword = false, isReadOnly = false, setShowPopup } = props;
	return (
		<>
			<Controller
				name='gender'
				control={control}
				rules={rules.gender}
				render={({ field: { ref, ...rest } }) => {
					return (
						<InputSelect
							label='Anrede'
							placeholder='Bitte wählen'
							options={GENDER_OPTIONS}
							asterisk={requiredFields.includes('gender')}
							error={errors.gender}
							{...rest}
						/>
					);
				}}
			/>
			<Controller
				name='firstName'
				control={control}
				rules={rules.firstName}
				render={({ field: { ref, ...rest } }) => (
					<InputField
						label='Vorname'
						asterisk={requiredFields.includes('firstName')}
						error={errors.firstName}
						type={'text'}
						{...rest}
					/>
				)}
			/>
			<Controller
				name='lastName'
				control={control}
				rules={rules.lastName}
				render={({ field: { ref, ...rest } }) => (
					<InputField
						asterisk={requiredFields.includes('lastName')}
						label='Nachname'
						error={errors.lastName}
						type={'text'}
						{...rest}
					/>
				)}
			/>
			<Controller
				name='email'
				control={control}
				rules={rules.email}
				render={({ field: { ref, ...rest } }) => {
					return (
						<InputField
							asterisk={requiredFields.includes('email')}
							label='Deine E-Mail Adresse'
							error={errors.email}
							type={'text'}
							isReadOnly={isReadOnly}
							{...rest}
						/>
					);
				}}
			/>
			{displayChangePassword && (
				<CommonFormBtn
					setShowPopup={setShowPopup}
					labelText={'Mein Passwort Andern'}
					buttonText={'Passwort ändern'}
					buttonClassName={'save-button'}
				/>
			)}
			<Controller
				name='phone'
				control={control}
				rules={rules.phone}
				render={({ field: { ref, ...rest } }) => (
					<PhoneNumber
						asterisk={requiredFields.includes('phone')}
						label='Telefon/Mobil'
						error={errors.phone}
						{...rest}
					/>
				)}
			/>
		</>
	);
};

export default PersonalInformation;
