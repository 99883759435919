import englishText from "./en";

const dashboardGermanText = {
  [englishText.MAP]: "Karte",
  [englishText.RESET_FILTER]: "Filter zurücksetzen",
  [englishText.PRICE]: "Preis",
  [englishText.SPACE]: "Wohnfläche",
  [englishText.ROOMS]: "Räume",
  [englishText.MORE_FILTERS]: "Mehr Filter",
  [englishText.AREA]: "Fläche",
  [englishText.YEAR_BUILT]: "Baujahr",
  [englishText.RES_COMPLEX]: "Wohnkomplex",
  [englishText.PARKING]: "Parkplatz / Garage",
  [englishText.YEAR_OF_CONSTRUCTION]: "Baujahr",
  [englishText.APPLY]: "Anwenden",
  [englishText.PLOT]: "Grundstück",
  [englishText.ALL]: "Alle",
  [englishText.SEARCH_BY_RADIUS]: "Umkreissuche",
  [englishText.SEARCH_BY_ZIP]: "Detaillierter Standort / PLZ",
  [englishText.SAERCH_LOCATION]: "Standortsuche",
  [englishText.HIDE_FORECLOSURE]: "Keine Zwangsversteigerungen",
  [englishText.SCORE_MIN]: "Min. Score",
  [englishText.SCORE_MAX]: "Max. Score",
  [englishText.SHOW_ALL]: "Alle Anzeigen",
  [englishText.SHOW_ON_MAP]: "Auf Karte anzeigen",
  [englishText.RESULTS]: "Objekte",
  [englishText.OUT_OF]: "von",
  [englishText.PAGE_RESULTS]: "Objekten",
  [englishText.SCHOOLS]: "Schulen",
  [englishText.TRANSPORTS]: "Nahverkehr",
  [englishText.MEDICLES]: "Ärzte",
  [englishText.PARKS]: "Grünanlagen",
  [englishText.SUPERMARKETS]: "Supermarkt",
  [englishText.NO_RESULT_FOUND_TRY_OTHER]:
    "Keine Ergebnisse gefunden. Bitte passe die Filter an.",
  [englishText.TYPES_OF_BUILDING]: "Gebäudetypen",
  [englishText.HOUSES]: "Häuser",
  [englishText.ADVERT_AGE]: "Inseratsalter in Tagen",
  [englishText.YEAR_MIN]: "Jahr min.",
  [englishText.YEAR_MAX]: "Jahr max.",
  [englishText.DEACTIVATED]: "Schon vergeben",
  [englishText.FULL_SCREEN]: "Vollbild",
  [englishText.EXIT_FULL_SCREEN]: "Beenden Sie den Vollbildmodus",
  [englishText.SIZE]: "Fläche",
  [englishText.OBJECT_DISABLED_ERROR]:
    "Leider ist diese Immobilie schon vergeben!",
  [englishText.CANCEL]: "Stornieren",
  [englishText.CONTACT]: "Kontakt",
  [englishText.LOGIN_AGAIN_ERROR]:
    "Ihre Anmeldesitzung ist abgelaufen. Bitte melden Sie sich erneut an",
  [englishText.OTHER_FEATURES]: "Sonstige Merkmale",
  [englishText.STANDARD_SORTING]: "Neu",
  [englishText.PRICE_FILTER]: "Kaufpreis",
  [englishText.NEW_CONSTRUCTION]: "Neu, geplant oder im Bau",
  [englishText.PARKING_SPACE]: "Parkplatz",
  [englishText.GARDEN_BALCONY]: "Garten / Balkon",
  [englishText.NO_COMMISSION]: "Keine Maklercourtage",
};

export default dashboardGermanText;
