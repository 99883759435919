import axios, { AxiosResponse, Method } from 'axios';
import { lang, ResponseObj } from 'utils';
import { getUrl } from './api';
import { getAuthData } from './referance.service';

const axiosInstance = axios.create();
const CancelToken = axios.CancelToken;

const language = lang.GR;

let cancel_req: any;

export { cancel_req };

export interface AxiosParams extends MiscellaneousRequestParams {
	method: Method | undefined;
	url: string;
	data?: any;
	contentType?: string;
	isLogin?: boolean;
}

export interface MiscellaneousRequestParams {
	contentType?: string;
	responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
}

/**
 * get method
 * @param request object containing axios params
 */
const get = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
	commonAxios({ method: 'GET', url: getUrl(url, params), ...otherData });

/**
 * post method
 * @param request object containing axios params
 */
const post = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
	commonAxios({ method: 'POST', url: getUrl(url), data: params, ...otherData });

/**
 * put method
 * @param request object containing axios params
 */
const put = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
	commonAxios({ method: 'PUT', url: getUrl(url), data: params, ...otherData });

/**
 * deleteRequest method
 * @param request object containing axios params
 */
const deleteRequest = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
	commonAxios({
		method: 'DELETE',
		url: getUrl(url),
		data: params,
		...otherData
	});

/**
 * patch method
 * @param request object containing axios params
 */
const patch = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
	commonAxios({
		method: 'PATCH',
		url: getUrl(url),
		data: params,
		...otherData
	});

/**
 * commonAxios
 * @param object containing method, url, data, access token, content-type, isLogin
 */
const commonAxios = ({
	method,
	url,
	data,
	contentType = 'application/json',
	responseType
}: AxiosParams): Promise<any> => {
	const headers: any = {
		'Content-Type': contentType,
		language: language
	};

	const token = getAuthData();

	if (token) {
		headers['x-access-token'] = `${token}`;
	}

	let body: any = null;

	if (contentType === 'application/json') {
		body = JSON.stringify(data);
	} else {
		body = data;
	}

	return new Promise((resolve, reject) => {
		axiosInstance({
			method: method,
			url: url,
			cancelToken: new CancelToken(function executor(c) {
				// An executor function receives a cancel function as a parameter
				cancel_req = c;
			}),
			headers: headers,
			data: body,
			responseType
		})
			.then((response: AxiosResponse<ResponseObj<any>>) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export { axiosInstance };

export const HttpService = {
	get: get,
	post: post,
	put: put,
	deleteRequest: deleteRequest,
	patch: patch
};

export default HttpService;
