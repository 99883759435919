/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
//@ts-ignore
// functions
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { clearReducer } from 'actions/clearAction';
import { getRef } from 'services';
import { initialState } from '../utils/searchFunctions';
import getLocalizeText from 'utils/getLocalizeText';
import { propertyOptions, searchPropertyTypes } from 'utils';
// components
import MyMapComponent from '../../map/container/mapContainer';
import SkeletonLoading from './skeletonLoading';
import PropertyCard from './propertyCard';
// style files
import '../search.scss';
import 'react-loading-skeleton/dist/skeleton.css';
import 'font-awesome/css/font-awesome.min.css';

interface IProps {
	searchList: any[];
	totalRecords: number;
	mapData: [];
	multiMapData: [];
	totalPages: number;
	currentPage: number;
	showSpinner?: boolean;
	pageChanged: boolean;
	showResultSpinner: boolean;
	isFullMapScreen: boolean;
	onboarding: boolean;
	emptyListError?: string;
	currentView: string;
	showModalName?: string;
	showBg: boolean;
	scrollY: number;
	selectedCity: string;
	clearReducer: (action: string) => void;
	onPageChange: (page: number) => void;
	resetPageChange: () => void;
	from: string;
	showBanner: boolean;
}

class SearchResult extends Component<IProps> {
	state: any = {
		selectedResultId: '',
		showType: null,
		iconFile: null,
		addHighLightId: '',
		removeHighlightId: '',
		scrollTop: 0,
		footerOffset: 0,
		hoveredId: '',
		showFullMap: false,
		imageLoadedList: [],
		animationStartPosition: { x: 0, y: 0 },
		...initialState
	};
	wrapperRefMa: any;
	wrapperRefMap: any;

	componentDidMount() {
		this.setState({ checkedType: [] });
		this.setFooterRef();
		window.addEventListener('scroll', this.onScroll);
		document.addEventListener('mousedown', this.handleClickOutsideForMap);
	}

	componentDidUpdate(prevProps: any) {
		const { isFullMapScreen } = this.props;

		if (isFullMapScreen !== prevProps.isFullMapScreen && !isFullMapScreen && this.state.showFullMap) {
			this.setState({ showFullMap: false });
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutsideForMap);
		window.removeEventListener('scroll', this.onScroll);
	}

	changeScrollTop = () => {
		const { scrollTop } = this.state;
		if (this.props.isFullMapScreen) {
			window.scrollTo(0, scrollTop - 50);
			this.setState({ scrollTop: scrollTop - 50 });
		}
	};

	render() {
		const {
			searchList,
			totalRecords,
			mapData,
			multiMapData,
			totalPages,
			showSpinner,
			currentPage,
			resetPageChange,
			pageChanged,
			showResultSpinner,
			isFullMapScreen,
			emptyListError,
			showBg
		} = this.props;
		const {
			iconFile,
			showType,
			removeMarker,
			visibleIndex,
			isOpen,
			map,
			isOpenMulti,
			mapMulti,
			highlightclass,
			addHighLightId,
			removeHighlightId,
			hoveredId,
			scrollTop,
			footerOffset,
			imageLoadedList,
			showFullMap
		} = this.state;

		return (
			<>
				{/* Search result list section */}
				{(showSpinner || showResultSpinner) && (
					<div
						style={{
							backgroundColor: !showBg ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
							minHeight: isFullMapScreen ? 'calc(100vh + 100px)' : 'calc(100vh - 100px)'
						}}
					>
						<div>
							<div
								className={`property-card-wrapper d-flex flex-wrap justify-content-start ${
									isFullMapScreen ? 'map-screen' : ''
								}`}
								style={{
									maxWidth: isFullMapScreen ? (window.innerWidth < 1050 ? '33%' : '29%') : '100%',
									pointerEvents: !showBg ? 'none' : 'all',
									padding: '10px 20px 0 10px'
								}}
							>
								<SkeletonLoading
									className={`column-wrapper w--25 property-card-responsive ${
										this.props.isFullMapScreen ? 'column-w-100' : ''
									} skeleton-property-card`}
								/>
							</div>
						</div>
					</div>
				)}
				{searchList && searchList.length > 0 && (
					<div
						style={{
							backgroundColor: !showBg ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
							minHeight: isFullMapScreen ? 'calc(100vh + 100px)' : 'calc(100vh - 100px)'
						}}
					>
						<div>
							<div
								className={`property-card-wrapper d-flex flex-wrap justify-content-start ${
									isFullMapScreen ? 'map-screen' : ''
								}`}
								style={{
									maxWidth: isFullMapScreen ? (window.innerWidth < 1050 ? '33%' : '29%') : '100%',
									pointerEvents: !showBg ? 'none' : 'all',
									padding: '10px 20px 0 10px'
								}}
							>
								{!showSpinner &&
									searchList.length > 0 &&
									!showFullMap &&
									searchList.map((listItem: any, index: number) => {
										return (
											<LazyLoadComponent visibleByDefault={true} key={index} delayTime={2000}>
												<div
													className={`w--25 position-relative property-card-responsive ${
														isFullMapScreen ? 'full-width' : ''
													}`}
												>
													<PropertyCard
														isFullMapScreen={isFullMapScreen}
														listItem={listItem}
														highlightclass={highlightclass}
														addHighLightClass={this.addHighLightClass}
														removeHighlight={this.removeHighlight}
														imageLoadedList={imageLoadedList}
														index={index}
														hoveredId={hoveredId}
														setImageLoadedList={this.setImageLoadedList}
														changeScrollTop={this.changeScrollTop}
													/>
													{listItem.marketingType && (
														<div
															className={`property-type position-absolute ${
																listItem.marketingType === propertyOptions.SELL ||
																listItem.marketingType === propertyOptions.BUY
																	? 'property-sell'
																	: ''
															}`}
														>
															<p>{searchPropertyTypes[listItem.marketingType]}</p>
														</div>
													)}
												</div>
											</LazyLoadComponent>
										);
									})}

								{/* Map section */}
								<div
									className={`map-wrapper ${showFullMap ? 'sticky-full-map' : ''} ${
										isFullMapScreen
											? `${scrollTop <= 280 ? '' : 'sticky'} ${
													footerOffset <= window.innerHeight && scrollTop >= 149
														? `sticky-footer`
														: ''
											  }`
											: 'no-show'
									}`}
									style={showFullMap ? { width: '100%', maxWidth: '1920px' } : {}}
								>
									<div
										className='map-content'
										onMouseEnter={() => {
											if (this.state.addHighLightId !== '') {
												this.setState({
													removeHighlightId: addHighLightId,
													highlightclass: '',
													addHighLightId: ''
												});
											}
										}}
										onMouseLeave={() => {
											if (this.state.removeHighlightId !== '') {
												this.setState({
													removeHighlightId: ''
												});
											}
										}}
									>
										<div className={searchList.length > 0 ? 'map-box' : 'map-box mapborder'}>
											{isFullMapScreen && (
												<MyMapComponent
													mapData={mapData}
													showType={showType}
													iconFile={iconFile}
													removeMarker={removeMarker}
													multiMapData={multiMapData}
													visibleIndex={visibleIndex}
													handleNext={this.handleNext}
													handlePrev={this.handlePrev}
													isOpen={isOpen}
													isOpenMulti={isOpenMulti}
													onToggleOpen={this.onToggleOpen}
													onToggleMultiOpen={this.onToggleMultiOpen}
													mapMulti={mapMulti}
													closeInfo={this.closeInfo}
													setWrapperRefForInfowindow={this.setWrapperRefForInfowindow}
													map={map}
													lat={this.state.lat}
													lon={this.state.lon}
													addHighLightId={addHighLightId}
													removeHighlightId={removeHighlightId}
													resetPageChange={resetPageChange}
													pageChanged={pageChanged}
													isFromSearch={true}
													addHighLightClass={this.addHighLightClass}
													removeHighlight={this.removeHighlight}
													clearAdd={() => this.setState({ addHighLightId: '' })}
													clearRemove={() => this.setState({ removeHighlightId: '' })}
													selectedCity={this.props.selectedCity}
												/>
											)}
										</div>
									</div>
								</div>
							</div>
							{!showSpinner && !showFullMap && searchList.length > 0 && totalRecords > 1 && (
								<>
									{totalPages > 1 && (
										<div
											style={{
												width: isFullMapScreen ? (window.innerWidth < 1050 ? '33%' : '28%') : ''
											}}
										>
											<div
												className={`page-bottom-fixed pagination-width mt-4 ${
													isFullMapScreen && 'map-pagination'
												}`}
											>
												<ReactPaginate
													previousLabel={
														<i
															className='fa fa-caret-left next-page-icon'
															aria-hidden='true'
														></i>
													}
													previousClassName={`paginate-btn ${
														currentPage === 1 ? 'prev-class' : ''
													}`}
													nextClassName={`paginate-btn ${
														currentPage === totalPages ? 'prev-class' : ''
													}`}
													nextLabel={
														<i
															className='fa fa-caret-right next-page-icon'
															aria-hidden='true'
														></i>
													}
													breakLabel={'...'}
													breakClassName={'break-me'}
													pageCount={totalPages}
													marginPagesDisplayed={2}
													pageRangeDisplayed={isFullMapScreen ? 1 : 5}
													onPageChange={this.onPageChange}
													containerClassName={isFullMapScreen ? `pagination ` : 'pagination'}
													// subContainerClassName={"pages pagination"}
													activeClassName={'active'}
													initialPage={0}
													disableInitialCallback={true}
												/>
											</div>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				)}
				{emptyListError && emptyListError !== '' && (
					<h5 style={{ padding: '15%', textAlign: 'center' }}>{getLocalizeText(emptyListError)}</h5>
				)}
			</>
		);
	}

	setImageLoadedList = (id: string) => {
		const a = this.state.imageLoadedList;
		a.push(id);
		this.setState({ imageLoadedList: a });
	};

	// change map height on scroll
	onScroll = () => {
		try {
			const scrollY = window.scrollY; //Don't get confused by what's scrolling - It's not the window
			const registerRef = getRef('registerRef').getBoundingClientRect().top;
			this.setState({
				scrollTop: scrollY,
				footerOffset: registerRef
			});
		} catch (error) {
			console.log('some error accoured');
		}
	};

	setFooterRef = () => {
		try {
			const registerRef = getRef('registerRef').getBoundingClientRect().top;
			this.setState({
				footerOffset: registerRef
			});
		} catch (error) {
			console.log('some error accoured');
		}
	};

	// Rate window open/close handling in search list
	toggleRatebox = (value: string) => {
		const { showModalName } = this.props;

		if (showModalName === '') {
			if (value) {
				this.setState({ selectedResultId: value });
			} else {
				this.setState({ selectedResultId: '' });
			}
		}
	};

	// handle next click for map property in info window
	handleNext = (total: number, currentIndex: number) => {
		const { visibleIndex } = this.state;
		if (currentIndex < total) {
			this.setState({ visibleIndex: visibleIndex + 1 });
		}
	};

	// handle previous click for map property in info window
	handlePrev = (currentIndex: number) => {
		const { visibleIndex } = this.state;
		if (currentIndex !== 1) {
			this.setState({ visibleIndex: visibleIndex - 1 });
		}
	};

	// for single map property close/open handling
	onToggleOpen = (event: any, lat: string, lon: string, map: { id: string }) => {
		if (this.state.lat !== lat && this.state.lon !== lon && this.state.isOpen) {
			this.setState({ isOpen: false }, () => {
				this.removeHighlight(map.id, this.state.openId === map.id);
			});
		}
		if (this.state.lat === lat && this.state.lon === lon && this.state.isOpen) {
			this.setState({ isOpen: false, visibleIndex: 0, lat: '', lon: '' }, () => {
				this.removeHighlight(map.id, this.state.openId === map.id);
			});
		} else {
			this.addHighLightClass(map.id);
			this.setState({
				isOpen: true,
				lat,
				lon,
				map,
				isOpenMulti: false,
				openId: map.id
			});
		}
	};

	// for multiple map property close/open handling
	onToggleMultiOpen = (event: any, lat: number, lon: number, mapMulti: any) => {
		if (this.state.lat !== lat && this.state.lon !== lon && this.state.isOpenMulti) {
			this.setState({ isOpenMulti: false, visibleIndex: 0 }, () => {
				this.removeHighlight(mapMulti.id[0], this.state.openId === mapMulti.id[0]);
			});
		}
		if (this.state.lat === lat && this.state.lon === lon && this.state.isOpenMulti) {
			this.setState({ isOpenMulti: false, visibleIndex: 0 }, () => {
				this.removeHighlight(mapMulti.id[0], this.state.openId === mapMulti.id[0]);
			});
		} else {
			this.addHighLightClass(mapMulti.id[0]);
			this.setState({
				isOpenMulti: true,
				lat,
				lon,
				mapMulti,
				isOpen: false,
				openId: mapMulti.id[0]
			});
		}
	};

	// close property infor window
	closeInfo = () => {
		this.removeHighlight(this.state.openId, true);
		this.setState({
			isOpen: false,
			isOpenMulti: false,
			lat: '',
			lon: '',
			openId: ''
		});
	};

	//highlight map property on hover, add id to highlight
	addHighLightClass = (id: string) => {
		if (this.props.currentView === 'map') {
			this.setState({ addHighLightId: id, highlightclass: id });
		}
		this.setState({ hoveredId: id });
	};

	//highlight map property on hover, remove id
	removeHighlight = (id: string, fromClose: boolean) => {
		if ((this.props.currentView === 'map' && this.state.openId !== id) || fromClose) {
			this.setState({ removeHighlightId: id, highlightclass: '' });
			fromClose && this.setState({ openId: '' });
		}
		this.setState({ hoveredId: '' });
	};

	onPageChange = (page: { selected: number }) => {
		this.setState({ imageLoadedList: [] });
		this.props.onPageChange(page.selected);
	};

	setWrapperRefForInfowindow = (node: any) => {
		const { isOpen, isOpenMulti } = this.state;
		if (!this.wrapperRefMa && this.wrapperRefMap !== node) {
			if (isOpen) {
				this.wrapperRefMap = node;
			}
			if (isOpenMulti) {
				setTimeout(() => {
					this.wrapperRefMap = node;
				}, 100);
			}
		}
	};

	// to close info window on click outside
	handleClickOutsideForMap = (event: any) => {
		const { isOpen, isOpenMulti } = this.state;

		if (isOpen && this.wrapperRefMap && !this.wrapperRefMap.contains(event.target)) {
			this.setState({ isOpen: false, lat: '', lon: '' });
			this.wrapperRefMap = null;
			this.removeHighlight(this.state.openId, true);
		}

		if (isOpenMulti && this.wrapperRefMap && !this.wrapperRefMap.innerHTML.includes(event.target.innerHTML)) {
			this.setState({ isOpenMulti: false, lat: '', lon: '', visibleIndex: 0 });
			this.wrapperRefMap = null;
			this.removeHighlight(this.state.openId, true);
		}
	};
}

const mapDispatchToProps = (dispatch: any) => ({
	clearReducer: (name: string) => {
		dispatch(clearReducer(name));
	}
});

export default connect(null, mapDispatchToProps)(SearchResult);
