import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ClosePopup, DeleteIcon, ImageUploadeIcon } from 'components/commonComponents/icons';
import CustomSpinner from 'components/commonComponents/customSpinner';
import { landPdfFields, pdfFields, propertyOption } from '../utils/constants';
import { API_CONFIG, HttpService } from 'services';

type FiledNames = 'propFloorPlan' | 'energyCertificate';
interface IProps {
	setUploadImage: React.Dispatch<React.SetStateAction<string[]>>;
	selectedValue: string;
}
interface UploadPDFForm {
	propFloorPlan: string[];
	energyCertificate: string[];
	adPicturesUrls: string[];
}
const UploadPDF: FC<IProps> = ({ setUploadImage, selectedValue }) => {
	const { control, getValues, setValue } = useFormContext<UploadPDFForm>();
	const [documentField, setDocumentField] = useState<
		{
			label: string;
			name: string;
			id: string;
		}[]
	>(pdfFields);
	// ------------image
	const [imageFields, setImageFields] = useState<Array<string>>([]); // file
	const [imageObj, setImageObj] = useState<Array<Blob | MediaSource>>([]); // fileObj
	const [isImageUploading, setImageUploading] = useState(false); // showSpinner
	const [imageErr, setImageErr] = useState(''); // imageErr
	const [showSubmit, setShowSubmit] = useState(false); // showSubmit
	const picturesUrls = getValues('adPicturesUrls') || [];
	const propFloorPlanUrls = getValues('propFloorPlan') || [];
	const energyCertificateUrls = getValues('energyCertificate') || [];

	const [fields, setLayout] = useState({
		propFloorPlan: { file: {} as File, link: '', uploading: false },
		energyCertificate: { file: {} as File, link: '', uploading: false }
	});
	const setDocField = useCallback(
		(field: FiledNames, url: string) => {
			if (url) {
				const fileName = url.split('/').slice(-2, -1)[0];
				setLayout((prevFields) => ({
					...prevFields,
					[field]: {
						file: new File([], fileName, { type: 'application/pdf' }),
						uploading: false,
						link: url
					}
				}));
			}
		},
		[setLayout]
	);

	useEffect(() => {
		if (picturesUrls.length > 0) {
			setUploadImage(picturesUrls);
		}

		if (propFloorPlanUrls.length > 0) {
			setDocField('propFloorPlan', propFloorPlanUrls[0]);
		}

		if (energyCertificateUrls.length > 0) {
			setDocField('energyCertificate', energyCertificateUrls[0]);
		}
	}, []);

	useEffect(() => {
		if (selectedValue === propertyOption.land) {
			setDocumentField(landPdfFields);
		}
	}, [selectedValue]);

	// handle pdf upload
	const handlePdf = (event: any, fieldName: FiledNames) => {
		const file = event.target.files[0];
		if (!file) {
			return;
		}
		if (file.type.includes('pdf')) {
			setFields(fieldName, { file: file, uploading: true, link: '' });
			const callback = (a: string | string[]) => {
				setFields(fieldName, {
					file: file,
					uploading: false,
					link: a as string
				});
				setValue(fieldName, [a as string]);
			};
			uploadFiles([file], 'pdf', callback);
		} else {
			// @ts-ignore
			document.getElementById(event.target.id).value = '';
			const msg = 'Only PDF files allowed';
			alert(msg);
		}
	};
	const uploadFiles = (fileObj: any, type: string, callback: (link: string | string[]) => void) => {
		const timestamp = new Date().getTime();
		const isImage = type === 'png';
		const promises: string[] = [];
		for (let i = 0; i < fileObj.length; i++) {
			const file = fileObj[i];
			const fileName = `${file.name}-${timestamp}.${type}`;
			const headers: any = {
				'Content-Type': file.type || '*/*'
			};
			const requestOptions = {
				method: 'PUT',
				headers: headers,
				body: file
			};
			HttpService.post(API_CONFIG.path.avatar, {
				userObject: fileName
			})
				.then((response) => {
					fetch(response.data.url, requestOptions)
						.then((response) => {
							promises.push(response.url.split('?')[0]);
							if (promises.length >= fileObj.length) {
								if (type === 'pdf') {
									callback(promises[0]);
								} else {
									callback([...picturesUrls, ...promises]);
								}
							}
						})
						.catch((error) => {});
				})
				.catch((err) => {
					console.log(err);
				});
		}
		if (isImage) {
			setShowSubmit(false);
		}
	};
	const setFields = (name: FiledNames, value: { file: File; uploading: boolean; link: string }) => {
		setLayout({ ...fields, [name]: value });
	};
	//remove pdf
	const clearInputBox = (name: string, id: string) => {
		// @ts-ignore
		document.getElementById([id]).value = '';
	};
	//--------------------------image
	// Send all files to upload
	const uploadMultipleFiles = (e: any) => {
		let fileObj = imageObj;
		const length = imageFields.length + e.target.files.length + picturesUrls.length;
		const isValid = validateImages(e, length);
		if (isValid) {
			let fileArray = JSON.parse(JSON.stringify(imageFields));
			let startIndex = imageFields.length === 0 ? 0 : imageFields.length;
			fileObj.push(...e.target.files);
			for (let i = startIndex; i < fileObj.length; i++) {
				fileArray.push(URL.createObjectURL(fileObj[i]));
			}
			setImageFields(fileArray);
			setImageObj(fileObj);
			setImageErr('');
			setShowSubmit(true);
		}
	};
	// Remove uploaded images
	const handleRemoveAttachment = (index: number) => {
		const tempArr = [...imageFields];
		const fileObjArr = [...imageObj];
		tempArr.splice(index, 1);
		fileObjArr.splice(index, 1);
		setImageFields(tempArr);
		setImageObj(fileObjArr);
	};
	// check if given images are of proper type and format
	// Should be count < 10, type of png or jpg, size < 512mb
	const validateImages = (e: any, length: number) => {
		let isSizeExceeded = false;
		let isNotImg = false;
		const temp = e.target.files;
		const supportedTypes = ['image/jpg', 'image/jpeg', 'image/png'];
		if (temp.length > 0) {
			for (let i = 0; i < temp.length; i++) {
				if (temp[i].size / 1024 / 1024 > 0.5) {
					isSizeExceeded = true;
					break;
				}
				if (!supportedTypes.includes(temp[i].type)) {
					isNotImg = true;
					break;
				}
			}
		}
		if (length > 9) {
			setImageErr(
				'Das Fotolimit wurde überschritten. Um ein neues Foto hinzuzufügen, löschen Sie eines der hochgeladenen.'
			);
			return false;
		} else if (isSizeExceeded) {
			setImageErr('Please select an attachment which is less than 512 KB');
			return false;
		} else if (isNotImg) {
			setImageErr('Only JPEG, JPG and PNG formats are supported');
			return false;
		}
		return true;
	};
	return (
		<div className='document-div'>
			{/* Floorplan and expose */}
			{documentField.map((document, i) => {
				const name = document.name as FiledNames;
				const current = fields[name];
				return (
					<Controller
						name={name as FiledNames}
						control={control}
						key={i}
						render={({ field: { ref, onChange, value, ...rest } }) => {
							return (
								<div className='pdf-section'>
									<label className='form-label'>{document.label}</label>
									<div className='flex align__items--center'>
										<label
											className={`document-label-placeholder ${
												current.file.name ? 'document-label' : ''
											}`}
											htmlFor={document.id}
										>
											{current.file.name || 'keine Auswahl'}
										</label>
										<input
											type='file'
											hidden
											className='form-control'
											onChange={(e) => {
												handlePdf(e, name);
											}}
											accept={'application/pdf'}
											name={name}
											id={document.id}
											disabled={current.file && Object.keys(current.file).length > 0}
										/>
										{!current.uploading && current.link && (
											<div className='upload-svg flex align__items--center'>
												<a
													target='_blank'
													rel='noopener noreferrer'
													href={current.link}
													className=''
												>
													<i className=' fa fa-file-pdf-o pdf' aria-hidden='true'></i>
												</a>
												<div
													className='cursor-pointer'
													onClick={() => {
														setFields(name, {
															file: {} as File,
															uploading: false,
															link: ''
														});
														setValue(name, ['']);
														clearInputBox(name, document.id);
													}}
												>
													<DeleteIcon width='22px' height='22px' className='ml-1' />
												</div>
											</div>
										)}
										{!current.link && (
											<label htmlFor={document.id} className='cursor-pointer upload-svg'>
												<ImageUploadeIcon width='22px' height='22px' />
											</label>
										)}

										{current.uploading && (
											<div className='pt-3'>
												<CustomSpinner className='spiner' animation='border' variant='info' />
											</div>
										)}
									</div>
								</div>
							);
						}}
					/>
				);
			})}
			{/* Pictures */}
			<Controller
				name='adPicturesUrls'
				control={control}
				render={({ field: { ref, onChange, value, ...rest } }) => (
					<div className='flex align__items--center picture-wrapper position-relative'>
						<div className='fileinput pictures-section'>
							<div className='flex'>
								<label className='form-label'>Bilder</label>
								<div
									className={`upload-attachments-wrapper flex ${
										imageFields.length > 0 && 'selected-images'
									}`}
								>
									{!isImageUploading &&
										imageFields.length > 0 &&
										showSubmit &&
										imageFields.map((attachment, index) => (
											<div key={index} className='text-center'>
												<a href={attachment} target='_blank' rel='noopener noreferrer'>
													<img
														src={attachment}
														alt='...'
														key={index}
														className='preview-img'
													/>
												</a>
												<button
													type='button'
													disabled={isImageUploading}
													onClick={() => handleRemoveAttachment(index)}
													style={{
														position: 'relative',
														top: '-7px',
														right: '12px'
													}}
												>
													<ClosePopup width='15px ' height='15px' />
												</button>
											</div>
										))}
								</div>
							</div>
							{imageFields.length < 10 && (
								<div className='upload-image flex justify__content--between '>
									<label htmlFor='select-attachment' className='text-center'>
										{imageFields.length === 0 && (
											<label title={'Select File'} className={'document-label-placeholder'}>
												keine Auswahl
												<i />
											</label>
										)}
									</label>
									<input
										hidden
										type='file'
										onChange={uploadMultipleFiles}
										id='select-attachment'
										accept={'image/png,image/jpeg'}
										placeholder='keine Auswahl'
										multiple
										name='file'
									/>
									{!isImageUploading && (
										<label
											htmlFor='select-attachment'
											className='cursor-pointer upload-svg'
											onClick={() => setImageUploading(false)}
										>
											<ImageUploadeIcon width='22px' height='22px' />
											{!imageErr && (
												<span className={`image-note-text`}>
													Du kannst bis zu 9 Dateien hochladen (jeweils 512 kb.) Formate:
													jpg., png., jpeg.
												</span>
											)}
										</label>
									)}
									{isImageUploading && (
										<div className='pt-3'>
											<CustomSpinner className='spiner' animation='border' variant='info' />
										</div>
									)}
								</div>
							)}
						</div>
						<div className='w--50 pictures-note-div'>
							<div className='attachments-note flex align__items--center'>
								{imageErr && (
									<span className={`note-text ${imageErr ? 'note-error' : ''}`}>{imageErr}</span>
								)}
							</div>
							{showSubmit && imageFields.length > 0 && (
								<>
									<button
										className='color-yellow submit-pictures-btn'
										onClick={() => {
											setImageUploading(true);
											uploadFiles(imageObj, 'png', (links: string | string[]) => {
												setUploadImage(links as string[]);
												setValue('adPicturesUrls', links as string[]);
												setImageUploading(false);
												setImageFields([]);
												setImageObj([]);
											});
										}}
										style={{ height: '20px' }}
									>
										Submit pictures
									</button>
								</>
							)}
						</div>
					</div>
				)}
			/>
		</div>
	);
};
export default UploadPDF;
