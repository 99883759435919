import { Action } from "utils";

export const INITIAL_STATE = {
  response: [],
};

export const summaryReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case "GET_SUMMARY_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "GET_SUMMARY_PENDING":
      return {
        status: "pending",
      };

    case "GET_SUMMARY_REJECTED":
      return {
        status: "fail",
        data: action.payload,
      };

    case "CLEAR_SUMMARY":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};
