import React from 'react';

import { ClosePopup } from 'components/commonComponents/icons';
import { popupName } from 'components/layout/constants';

import '../auth.scss';
interface VerifyUserProps {
	closePopup: () => void;
	userEmail: string;
	component: string;
}
export default function VerifyUser(props: VerifyUserProps) {
	return (
		<div className='welcome-popup-wrapper'>
			<div className='login-register-wrapper position-relative'>
				<div className='text--right position-absolute close-btn cursor-pointer' onClick={props.closePopup}>
					<ClosePopup />
				</div>
				<div className='login-div login-register-div'>
					<div className='login-tittle-wrapper '>
						<h3 className='login-register-tittle'>
							{props.component === popupName.userVerification
								? 'Willkommen!'
								: 'E-Mail-Adresse erfolgreich geändert'}
						</h3>
					</div>

					<h4 className='text--center'>Bitte bestätigen Sie zum Abschluss noch Ihre E‑Mail‑Adresse</h4>
					<p className='verify-subtitle text--center '>
						{props.component === popupName.userVerification
							? `Um Ihre Registrierung erfolgreich abzuschließen, klicken Sie bitte
            					auf den Bestätigungslink, den wir an Ihre E-Mail-Adresse`
							: 'Um die neue E-Mail-Adresse zu bestätigen, klicken Sie bitte auf den Bestätigungslink, den wir an die von Ihnen eingegebene E-Mail-Adresse gesendet haben.'}
						{props.component === popupName.userVerification && (
							<>
								&nbsp;<span>{props.userEmail}</span>&nbsp;gesendet haben.
							</>
						)}
					</p>

					<div className='mt-4 mb-2'>
						<button
							className={`verify-butten mail-send-btn `}
							onClick={(e) => {
								window.location.href = 'https://mail.google.com/';
								e.preventDefault();
							}}
							formTarget='_blank'
						>
							Direkt zu Gmail
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
