import queryString from 'query-string';
import { BASE_URL } from './baseUrl';

export const API_CONFIG = {
	// eslint-disable-next-line no-undef
	baseUrl: `${BASE_URL}`,
	path: {
		// v1 APIs
		login: 'v1/user/auth/login',
		logout: 'v1/logout',
		s3: 'v1/profile/s3',
		forgot: 'v1/password/forgot',
		reset: 'v1/password/reset',
		changePassword: 'v1/profile/password/change',
		request: 'v1/contactus/request',
		register: 'v1/user/auth/register',
		addPaasword: 'v1/user/auth/add-password',
		profile: 'v1/profile',
		guestContactPopup: 'v1/openapi/guestContactPopup',
		avatar: 'v1/profile/upload',
		objects: 'v1/object',
		publicObject: 'v1/openapi/objects',
		location: 'v1/object/locationsOptimize',
		objectDetails: 'v1/object/details',
		realtorObjectDetails: 'v1/object/realtor/details',
		graph: 'v1/object/minmaxgraph',
		// objectLocations: "v1/object/locations",
		verificationMail: 'v1/profile/sendVerificationMail',
		userProperty: 'v1/object/userProperty',
		userSeeker: 'v1/profile/user-seeker',
		userOwner: 'v1/profile/property-owner',
		userBroker: 'v1/profile/user-realtor',
		deactivateUser: 'v1/profile/deactivateUser',
		changeEmail: 'v1/profile/email',
		newUserProperty: 'v1/object/newUserProperty',
		draftListNewUserProperty: 'v1/object/newUserProperty',
		deleteNewUserProperty: 'v1/object/newUserProperty',
		userPropertyDetail: 'v1/object/user-property',
		userPropertyStatus: 'v1/object/newUserProperty/status',
		// v4 APIs
		getLandingPageData: 'v4/openapi/objects'
	}
};

export const getUrl = (url: string, params: any = {}): string => {
	Object.keys(params).forEach((key) => (params[key] == null || params[key] === '') && delete params[key]);
	if (url.includes('http')) {
		return url;
	}
	let urlString = `${BASE_URL}${url}`;

	if (params && params !== '' && Object.keys(params).length > 0) {
		urlString += `?${queryString.stringify(params)}`;
	}
	return urlString;
};

// beta: "https://api.immozy.de/api/"
// staging url: "http://staging-api.immozy.de/api/";
