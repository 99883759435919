import React from 'react';
import ImageGallery from 'react-image-gallery';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import tipTile from 'assets/images/placeholder.png';
import { setPlaceholderImg } from 'utils';
import { openObjectDetail } from 'components/finder/utils/commonFunctions';
import { IMapWindowProps } from '../interface/map.interface';

const SingleMapWindow = (props: IMapWindowProps) => {
	const { dataObj } = props;

	return (
		<>
			{dataObj.isOpen && dataObj.map && (
				//@ts-ignore
				<InfoBox
					onCloseClick={dataObj.closeInfo}
					defaultPosition={
						// eslint-disable-next-line no-undef
						new google.maps.LatLng(parseFloat(dataObj.lat), parseFloat(dataObj.lon))
					}
					options={{ closeBoxURL: ``, enableEventPropagation: true }}
					zIndex={10}
				>
					<div
						className='infowin-container  pointer'
						ref={dataObj.setWrapperRefForInfowindow}
						onClick={(event) => openObjectDetail(event, dataObj.map.id)}
					>
						<div className='card-img map-img-gallary'>
							{dataObj.map.imgList && dataObj.map.imgList.length <= 1 ? (
								<img
									className='infowin-div-left single-img'
									src={dataObj.map.coverImg || tipTile}
									onError={setPlaceholderImg}
									alt='img'
								/>
							) : (
								<ImageGallery
									items={dataObj.map.imgList}
									showPlayButton={false}
									showThumbnails={false}
									showFullscreenButton={false}
									lazyLoad={true}
									disableKeyDown={true}
									onImageError={setPlaceholderImg}
								/>
							)}
						</div>
						<div
							className='d-flex justify-content-between map-label box-shadow pointer'
							onClick={(event) => openObjectDetail(event, dataObj.map.id)}
						>
							<p className='map-label-adr ml-2'>
								<li>{dataObj.map.propertyType || '-'}</li>
								<li>{dataObj.map.adr}</li>
							</p>
							<p className='mr-2'>
								<li className='map-label-price'> {dataObj.map.price}</li>
								<li className='sub-label-map'>{dataObj.map.priceLabel}</li>
							</p>
						</div>
					</div>
				</InfoBox>
			)}
		</>
	);
};

export default SingleMapWindow;
