import { ClosePopup, SecurityIcon } from 'components/commonComponents/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { loginRegisterInputs } from 'components/home/utils/constants';
import { popupName } from 'components/layout/constants';
import { notify } from 'components/commonComponents/notification/notification';
import CustomSpinner from 'components/commonComponents/customSpinner';
import InputComponent from './inputComponent';

import { AuthClassProps } from '../interface/auth';
import { API_CONFIG, HttpService } from 'services';

interface UserEmail {
	setUserEmail: React.Dispatch<React.SetStateAction<string>>;
	setForgotPasswordBack: React.Dispatch<React.SetStateAction<string>>;
}
const LoginRegister: React.FC<AuthClassProps & UserEmail> = (props) => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({
		defaultValues: {
			email: ''
		}
	});

	const [loading, setLoading] = useState(false);
	const onSubmit = (data: any) => {
		props.setUserEmail(data.email);
		setLoading(true);
		HttpService.post(API_CONFIG.path.register, {
			email: data.email
		})
			.then((response) => {
				switch (response.message) {
					case 'Successfully added the user':
					case 'Success! User found with null passwordHash and passwordSalt.':
						props.handlePopup(popupName.register);
						break;
					case 'User already exists. Please go to the login page.':
						props.handlePopup(popupName.login);
						break;
					default:
						setLoading(false);
				}
			})
			.catch((err) => {
				notify(`${err.response.data.message}`, 'error');
				setLoading(false);
			});
	};

	return (
		<div className='login-register-wrapper position-relative'>
			<div className='text--right position-absolute close-btn cursor-pointer' onClick={props.closePopup}>
				<ClosePopup />
			</div>
			<div className='login-div login-register-div'>
				<div className='login-tittle-wrapper '>
					<h3 className='login-register-tittle'>Anmelden oder registrieren</h3>
				</div>
				<form
					onSubmit={handleSubmit((data, e) => onSubmit(data))}
					className='login-form-wrapper login-register-form'
				>
					{loginRegisterInputs.map((value, index) => (
						<div className='login-input' key={index}>
							<InputComponent
								name={value.name}
								label={value.label}
								placeholder={value.placeholder}
								type={value.type}
								pattern={value?.pattern}
								register={register}
								errors={errors}
								requiredErrors={value.requiredErrors}
								patternErrors={value.patternErrors}
							/>
						</div>
					))}

					<button type='submit' value='register' className='login-button common-button' disabled={loading}>
						{loading ? <CustomSpinner animation='border' className='spinner-border-sm' /> : 'Weiter'}
					</button>
					<div>
						<button
							className='forgotPassword'
							type='button'
							onClick={() => {
								props.handlePopup(popupName.forgotpassword);
								props.setForgotPasswordBack(popupName.loginRegister);
							}}
						>
							Passwort vergessen?
						</button>
					</div>
				</form>

				<div className='flex align__items--start justify__content--center security-wrapper '>
					<SecurityIcon width='10px' height='12px' />
					<h6>Diese Verbindung ist sicher.</h6>
				</div>
			</div>
		</div>
	);
};

export default LoginRegister;
