import React from 'react';
import {
	AccessMarketingIcon,
	ManagementSoftwareIcon,
	PlacementIcon,
	TrainingSystemIcon
} from 'components/commonComponents/icons';
import appraise1 from 'assets/images/appraise-1.jpg';
import appraise2 from 'assets/images/appraise-2.png';
import appraise3 from 'assets/images/appraise-3.jpg';

export const features = [
	{ icon: <TrainingSystemIcon width='99px' height='98px' />, text: 'Kostenloses Fachschulungssystem' },
	{ icon: <AccessMarketingIcon width='99px' height='98px' />, text: 'Zugang zu Marketing Material' },
	{ icon: <ManagementSoftwareIcon width='99px' height='98px' />, text: 'Verwaltungssoftware mit Schnittstellen' },
	{ icon: <PlacementIcon width='99px' height='98px' />, text: 'Leadervermittlung aus der Zentrale' }
];

export const aboutCard = [
	{
		title: 'Deutsche Gutachten AG',
		discretion:
			'Die Deutsche Gutachten AG erstellt Energieausweise, Wert-und Bauschadensgutachten. Mit ihrer Expertise und Erfahrung unterstützt sie dich dabei, umfangreiche und präzise Informationen über Immobilien zu erhalten. Dadurch kannst du potenziellen Kunden einen verlässlichen und transparenten Service bieten.'
	},
	{
		title: 'Immoinvest AG',
		discretion:
			'Die immoinvestAG steht für einen unkomplizierten und schnellen Ankauf von Immobilien aller Art. Mit ihrer Unterstützung kannst du deinen Kunden eine zusätzliche Option bieten.'
	},
	{
		title: 'westimmobilia AG',
		discretion:
			'Als erfahrener Partner in der Hausverwaltung im Ruhrgebiet unterstützt die Westimmobilia AG dich bei der Miet- und WEG-Verwaltung. Sie übernehmen die zeitaufwendigen Aufgaben der Eigentümer, damit sie ihre gewonnene Freizeit optimal nutzen können.'
	},
	{
		title: 'BW Bauwerk NRW GmbH',
		discretion:
			'Wenn es um Bauelemente, insbesondere Fenster und Türen, sowie allgemeine Handwerksdienstleistungen und Renovierungen geht, ist die BW Bauwerk NRW GmbH dein kompetenter Partner. Sie bieten alles aus einer Hand, sodass du deinen Kunden eine umfassende Lösung für ihre Immobilienbedürfnisse bieten kannst. Mit ihrem Fachwissen und ihrer Qualitätssicherung stellen sie sicher, dass du nur die besten Produkte verbaut werden.'
	},
	{
		title: 'immoweb-finanz.de',
		discretion:
			'Die Finanzierungsabteilung immoweb-finanz der immoweb AG ist dein unabhängiger Bau-und Immobilienfinanzierungsberater, der mit über 200 Banken zusammenarbeitet.Mit ihrer Expertise und ihrem weitreichenden Netzwerk unterstützen sie dich dabei, die Finanzierungshürden für deine Kunden zu überwinden und erfolgreiche Immobiliengeschäfte abzuschließen.'
	}
];

export const brokerAndFranchise = [
	{
		title: 'Homeoffice-Makler',
		discretion: [
			{
				title: 'Bequem von Zuhause.',
				subTitle: [
					'<p>Als Homeoffice-Makler profitieren Sie von attraktiven Provisionen und einer starken Partnerschaft. Arbeiten Sie bequem von Ihrem Homeoffice aus, nutzen Sie Ihr Mobiltelefon und Ihr Kraftfahrzeug. Mit einem monatlichen Marketingzuschuss von 99€ haben Sie Zugang zu erstklassigen Marketingaktivitäten. Treten Sie unserer erfolgreichen Gemeinschaft bei und maximieren Sie Ihr Einkommen. Kontaktieren Sie uns für weitere Informationen und starten Sie Ihre Erfolgsgeschichte als Homeoffice-Makler.</p>'
				]
			},
			{
				title: 'Arbeit wird belohnt.!',
				subTitle: [
					'<p>Sie starten mit einem Provisionserlös von 50% der gesamten Provision.</p>',
					'<p>Dieser lässt sich von 50% Provision bei einem Umsatz von 50.000 Euro pro Jahr </p>',
					'<p><span>bis zu 65% Provision bei einem Umsatz von 125.000 Euro pro Jahr</span> erhöhen.</p>',
					'<p>Zudem wird die Staffelung mit in das Folgejahr übernommen.</p>'
				]
			},
			{
				title: 'Vertriebserlöse.',
				subTitle: [
					'<p>Über unser Vertriebssystem ist es Ihnen auch möglich passives Einkommen zu generieren, solange Sie Mitglied von immoweb sind.Als Homeoffice Makler erhalten Sie bei Weiterempfehlung 3%, 2% oder 1% des Jahresumsatzes seines empfohlenen Maklers.</p>'
				]
			}
		]
	},
	{
		title: 'Franchsie-Partner',
		discretion: [
			{
				title: 'Vorteil Netzwerk.',
				subTitle: [
					'<p>Als Franchise-Partner genießen Sie die Vorteile einer etablierten Struktur und agieren als eigenständiger Unternehmer. Werden Sie Teil unseres Franchise-Netzwerks und erreichen Sie Ihre unternehmerischen Ziele.</p>'
				]
			},
			{
				title: 'Arbeit wird belohnt.!',
				subTitle: [
					'<p>Mit einer <span>Gewinnbeteiligung von 94%</span> und einem starken Partner an Ihrer Seite maximieren Sie Ihren Erfolg.</p>',
					'<p>Geringe Umsatzbeteiligung von 6% der Einnahmen, davon 1% für den Werbetopf, wobei mntl. 500€, bereits auf die Umsatzbeteiligung angerechnet werden. </p>'
				]
			}
		]
	}
];

export const marketReport = [
	{
		title: 'Objektive Wertermittlung:',
		subTitle:
			'Ein Gutachten basiert auf fundierten Analysen und Daten. Es hilft, den tatsächlichen Wert Ihrer Immobilie unabhängig und objektiv zu ermitteln, was Ihnen eine realistische Preisvorstellung für den Verkauf gibt.'
	},
	{
		title: 'Zeitersparnis:',
		subTitle:
			'Ein Gutachten kann den Verkaufsprozess beschleunigen, da potenzielle Käufer durch klare Fakten und Zahlen schneller eine Entscheidungtreffen können.'
	},
	{
		title: 'Vertrauen der Käufer:',
		subTitle:
			'Käufer neigen dazu, einem professionellen Gutachtenmehr zu vertrauen als einer subjektivenSchätzung. Ein Gutachten erhöht die Glaubwürdigkeit und Transparenz des Verkaufsprozesses.'
	},
	{
		title: 'Fundierte Verhandlungsbasis:',
		subTitle:
			'Miteinem Immobiliengutachten in der Hand können Sie potenziellen Käufernden Wert Ihrer Immobilie auf eine klareund überzeugende Weise vermitteln.Dies schafft eine solide Grundlage für Verhandlungen.'
	},
	{
		title: 'Rechtssicherheit:',
		subTitle:
			'Ein professionelles Gutachten minimiert das Risiko von Rechtsstreitigkeiten, da es aufobjektiven Bewertungsmethoden basiert und nachvollziehbare Gründe für den ermittelten Wert liefert.'
	}
];

export const aboutUsCards = [
	{
		title: 'Breites Immobilienangebot:',
		subTitle: `Bei immoweb finden Sie eine vielfältige Auswahl an Immobilien. Von gemütlichen Wohnungen über 
			geräumige Einfamilienhäuser bis hin zu attraktiven Gewerbeimmobilien – wir bieten für jeden Bedarf 
			das passende Objekt.`
	},
	{
		title: 'Einfache und effektive Suche:',
		subTitle: `Unsere benutzerfreundliche Suchfunktion ermöglicht es Ihnen, schnell und gezielt nach Ihrer 
			Traumimmobilie zu suchen. Nutzen Sie unsere Filteroptionen, um Ihre Suche nach Preis, Größe, 
			Ausstattung und Lage zu verfeinern.`
	},
	{
		title: 'Detaillierte Exposés:',
		subTitle: `Wir legen großen Wert auf transparente und umfassende Informationen. Jede Immobilie auf 
			immoweb wird mit detaillierten Beschreibungen, hochwertigen Fotos und allen relevanten Angaben 
			präsentiert. So erhalten Sie einen klaren Eindruck und können fundierte Entscheidungen treffen.`
	},
	{
		title: 'Energieeffizienz im Blick:',
		subTitle: `Auf unserer Plattform finden Sie bei vielen Immobilien einen aktuellen Energieausweis gemäß den 
			Anforderungen des Gebäudeenergiegesetzes (GEG). So können Sie die energetische Qualität der 
			Immobilien auf einen Blick beurteilen.`
	},
	{
		title: 'Kostenlose Inserate:',
		subTitle: `Bei immoweb sind Inserationen für alle kostenlos. Egal, ob Sie eine Immobilie verkaufen oder 
			vermieten möchten – Sie können Ihre Objekte ohne Gebühren auf unserer Plattform präsentieren.`
	},
	{
		title: 'Service und Unterstützung:',
		subTitle: `Unser engagiertes Team steht Ihnen bei Fragen und Anliegen jederzeit zur Verfügung. Ob Sie 
		Unterstützung bei der Immobiliensuche oder beim Erstellen eines Inserats benötigen – wir sind für 
		Sie da.`
	}
];

export const energyPageCards = [
	{
		title: 'Transparenz:',
		subTitle: `Ein Energieausweis gibt Ihnen einen klaren Überblick über die energetische Beschaffenheit einer 
		Immobilie. So können Sie besser einschätzen, welche Energiekosten auf Sie zukommen und welche 
		Maßnahmen zur Verbesserung der Energieeffizienz sinnvoll sein könnten.`
	},
	{
		title: 'Gesetzliche Pflicht',
		subTitle: `In Deutschland ist der Energieausweis bei Verkauf, Vermietung oder Neubau von Immobilien 
		gesetzlich vorgeschrieben. Dies wird durch das Gebäudeenergiegesetz (GEG) geregelt. Zum Beispiel 
		sind gemäß § 80 GEG Eigentümer von Immobilien verpflichtet, bei Verkauf oder Vermietung einen 
		Energieausweis vorzulegen. Zudem muss gemäß § 87 GEG der Energieausweis bereits in der 
		Immobilienanzeige erwähnt werden.`
	},
	{
		title: 'Umweltbewusstsein:',
		subTitle: `Energieeffiziente Immobilien schonen nicht nur den Geldbeutel, sondern auch die Umwelt. Ein guter 
		Energieausweis ist daher ein wichtiger Schritt in Richtung nachhaltiges Wohnen und Bauen. `
	}
];

export const appraisePhotos = [
	{
		title: 'Immobiliengutachten',
		image: appraise1
	},
	{
		title: 'Kaufberatung',
		image: appraise2
	},
	{
		title: 'Energieausweis',
		image: appraise3
	}
];
