import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from "reducers/actionTypes";
import { Action, getAuthToken } from "utils";


export const INITIAL_STATE = {
    isLogin: getAuthToken() || false,
};


const authReducer = (state = INITIAL_STATE, action: Action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                isLogin: true
            };

        case LOGOUT_SUCCESS:
            localStorage.removeItem("token");
            return {
                isLogin: false
            };

        default:
            return {
                ...state,
            };
    }
};

export default authReducer;
