import React from "react";
import "./radioButton.scss";

interface Options {
  label: string;
  value: string;
  icon?: React.JSX.Element;
}

interface ICustomRadioButtonProps {
  options: Options[];
  selectedValue: string;
  handleChange: (key: string) => void;
  className?: string;
}

const CustomRadioButton = (props: ICustomRadioButtonProps) => {
  const { options, selectedValue, handleChange } = props;
  return (
    <div className={props.className || ""}>
      {options.map((option, index: number) => {
        return (
          <label
            className={`radio-button-wrapper ${
              option.value == selectedValue ? "active-radio-button" : ""
            }`}
            key={index}
          >
            {option.icon}
            {option.label}
            <input
              type="radio"
              checked={option.value === selectedValue}
              name="radio"
              onChange={() => handleChange(option.value)}
            />
            <span className="checkmark"></span>
          </label>
        );
      })}
    </div>
  );
};

export default CustomRadioButton;
