import { FormComponentProps } from "components/profile/utils/form.interface";
import React, { FC } from "react";

interface IInputTextarea extends FormComponentProps {
  cols: number;
  rows: number;
  value: string;
}

const InputTextarea: FC<IInputTextarea> = (props) => {
  const { label, cols, rows, asterisk = false, error, value, ...rest } = props;
  return (
    <div>
      <div className="flex align__items--center">
        {label && <label className="form-label">{label}</label>}
        {asterisk && <span className="form-asterisk">&nbsp;*</span>}
      </div>
      <textarea
        className={`form-input ${error ? "error-border" : ""}`}
        cols={cols}
        rows={rows}
        value={value || ""}
        {...rest}
      />
      {error && <span className="error-message">{error.message}</span>}
    </div>
  );
};

export default InputTextarea;
