import React from 'react';
import TitleImageSection from '../components/titleImageSection';
import helpImage from '../../../assets/images/help-page-image.png';
import MetaDecorator from 'shared/meta/metaDecorator';

const HelpPage = () => {
	return (
		<div className='help-section public-page-font'>
			<MetaDecorator title='Hilfe - Immoweb | Immobilien, Wohnungen und Häuser bei immoweb' />
			<TitleImageSection backgroundImage={helpImage} titleText={'Hilfe'} />
			<section className='description-section section-width'>
				<div className='description-tittle'>
					<h3>Datensicherheit?</h3>
					<p>
						Ihre Daten werden bei uns mit großer Sorgfalt gesichert. Eine Weitergabe an Dritte ist nahezu
						ausgeschlossen. Näheres dazu finden Sie in unseren Allgemeinen Geschäftsbedingungen §9.
					</p>
					<p>§ 9 Datensicherheit, Datenschutz</p>
					<p>
						(1) Die Server von der immoweb AG werden regelmäßig gesichert. Das Risiko des unbefugten
						Zugriffs Dritter lässt sich trotz gängiger Verschlüsselungsverfahren nicht vollständig
						ausschließen. Die immoweb AG haftet nicht für Schäden aus unbefugtem Zugriff Dritter, soweit
						dieser nicht durch grob fahrlässiges oder vorsätzliches Verhalten von der immoweb AG ermöglicht
						wird.
					</p>
					<p>
						(2) Die Identifizierungsdaten des Nutzers sowie die im Zusammenhang mit den Handelsgeschäften
						auf der Plattform eingestellten Daten werden von der immoweb AG ganz oder teilweise in
						maschinenlesbarer Form gespeichert. Der Nutzer ist damit einverstanden. Die Daten werden von der
						immoweb AG nur zu gesetzlichen Zwecken verwandt.
					</p>
					<p>
						(3) Die immoweb AG ist berechtigt, Identifizierungsdaten von Nutzern, Daten bezüglich
						abgeschlossener Vorgänge und Daten über Erklärungen der Nutzer in Statistiken einzustellen.
						Diese Statistiken dürfen von der immoweb AG sowohl intern als auch extern genutzt werden. Bei
						externer Nutzung von Statistiken werden Name und Adresse des Kunden nicht genannt.
					</p>
					<h3>Benutzername, Passwort vergessen?</h3>
					<p>
						Auf der Login-Seite befindet sich unter den Eingabefeldern „Benutzername oder Passwort
						vergessen?“, bitte klicken Sie auf den Link „vergessen“. Erst dann haben Sie die Möglichkeiten
						sich den Benutzername oder das neu generierte Passwort zu zuschicken.
					</p>
					<h3>Wo findet die Beurkundigung statt?</h3>
					<p>
						Die Wahl eines Notars obliegt üblicherweise dem Käufer. Der Notar ist beiden Parteien gegenüber
						neutral in seinem Amt. Wir empfehlen einen Notar in der Nähe des Objekts zu wählen.
					</p>
					<p>Wer kümmert sich um die Abwicklung bei Grundbuchamt und Behörden?</p>
					<p>Der den Kaufvertrag beurkundende Notar.</p>
					<h3>Wann erfolgt die Übergabe?</h3>
					<p>
						In der Regel erfolgt die Übergabe zum Ersten des Folgemonats nach vertragsgemäßer Hinterlegung
						des Kaufpreises.
					</p>
					<h3>Probleme bei der Anmeldung?</h3>
					<p>
						Sollten Probleme bei Anmeldung oder Freischaltung auftreten, bitten wir Sie Ihre Angaben
						zunächst genau zu überprüfen. Erhalten Sie innerhalb weniger Minuten keine Anmeldungsbestätigung
						per Email, deutet dies meist auf einen Fehler der Emailadresse hin. Sollten Sie nach
						wiederholten Anmeldeversuchen scheitern, können Sie uns unter{' '}
						<a href='mailto:info@immoweb.de' target='_blank'>
							info@immoweb.de
						</a>{' '}
						erreichen.
					</p>
					<h3>Wo bekomme ich Informationen zu den Partnermodellen von immoweb?</h3>
					<p>
						Informationen zu den Partnermodellen erhalten Sie auf unserer Website. Darüber hinaus können Sie
						auch weitere Informationen direkt bei uns anfordern.
					</p>
					<h3>Welche Kosten fallen für mich an?</h3>
					<p>Die Nutzung unserer Website ist für jeden kostenlos.</p>
				</div>
			</section>
		</div>
	);
};

export default HelpPage;
