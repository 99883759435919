import React, { FC, PropsWithChildren } from 'react';

interface IModalProps extends PropsWithChildren {
	closeModal?: () => void;
	className?: string;
	closeOnOutsideClick?: boolean;
}

const Modal: FC<IModalProps> = ({ className, children, closeOnOutsideClick = false, closeModal }) => {
	return (
		<div
			className='modal-wrapper'
			onClick={() => {
				closeOnOutsideClick && closeModal && closeModal();
			}}
		>
			<div className={`${className} modal-container`} onClick={(e) => e.stopPropagation()}>
				<div className='body-wrapper'>{children}</div>
			</div>
		</div>
	);
};

export default Modal;
