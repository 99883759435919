const hasNumber = (value: string) => new RegExp(/[0-9]/).test(value);

const hasMixed = (value: string) => new RegExp(/[a-z]/).test(value) && new RegExp(/[A-Z]/).test(value);

const hasSpecial = (value: string) => new RegExp(/[!#@$%^&*)(+=._-]/).test(value);

export const strengthColor = (count: number) => {
	if (count < 2) return '#d11b1b';

	if (count < 3) return '#ff9a00';

	if (count < 4) return '#ffc100';

	if (count < 6) return '#58b32d';

	return '#d8d8d8';
};

export const strengthIndicator = (value: string) => {
	let strengths = 0;

	if (value.length > 5) strengths++;

	if (value.length > 7) strengths++;

	if (hasNumber(value)) strengths++;

	if (hasSpecial(value)) strengths++;

	if (hasMixed(value)) strengths++;

	return strengths;
};
