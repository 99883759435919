import ModalComponent from 'components/commonComponents/modalComponent';
import { notify } from 'components/commonComponents/notification/notification';
import { countriesArray } from 'components/profile/utils/constants';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { API_CONFIG, HttpService } from 'services';
import { getSelectedOption, removeEmptyStrings } from 'utils';
import AddPropertyModal from '../components/addPropertyModal';
import AddPropertyTab from '../components/addPropertyTab';
import ContactTab from '../components/contactTab';
import DetailTab from '../components/detailTab';
import FinanceTab from '../components/financeTab';
import GenerallyTab from '../components/generallyTab';
import LocationTab from '../components/locationTab';
import {
	grundstückAccommodationOption,
	hausAccommodationOption,
	marketingTypeOption,
	propertyOption,
	propertyTab,
	wohnungAccommodationOption
} from '../utils/constants';
import { PropertyDetail } from '../utils/myProperty.interface';
interface IProps {
	closeModal: () => void;
	objectDetail?: any;
	resetFilter?: () => void;
}
export const TABS = [
	propertyTab.addPropertyTab,
	propertyTab.generally,
	propertyTab.location,
	propertyTab.detail,
	propertyTab.contact,
	propertyTab.finance
];
const MyProperty: FC<IProps> = ({ closeModal, objectDetail, resetFilter }) => {
	const methods = useForm({
		defaultValues: {
			showAddress: true,
			typePropertyUse: []
		}
	});
	const isFormEdit = methods.formState.isDirty;
	const [currentTab, setCurrentTab] = useState(TABS[0]);
	const [activeTabs, setActiveTabs] = useState<string[]>([]);
	const [selectedValue, setSelectedValue] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [objectId, setObjectId] = useState('');
	const [isEdit, setIsEdit] = useState(false);
	const [showAbortModal, setShowAbortModal] = useState({
		isShow: false,
		message: '',
		conform: '',
		cancel: '',
		isBtnLoading: false
	});

	useEffect(() => {
		if (objectDetail && Object.keys(objectDetail).length > 0) {
			resetForm(objectDetail);
			setIsEdit(true);
		}
		if (selectedValue) {
			setIsEdit(true);
		}
	}, [objectDetail]);
	useEffect(() => {
		const errorFields = document.getElementsByClassName('error-message');
		if (errorFields && errorFields.length > 0) {
			errorFields[0].scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'start'
			});
		}
	}, [methods.formState]);

	const formData = (data: any) => {
		const detail = {
			objectId: data.objectId,
			steps: data.stepsReached,
			objectType: data.objectType,
			typePropertyUse: data.typePropertyUse,
			adrRaw: data.adrRaw,
			adrCity: data.adrCity,
			adrPostalCode: data.adrPostalCode,
			showAddress: data.showAddress,
			areaLiving: data.areaLiving && data.areaLiving.toString(),
			areaPlot: data.areaPlot,
			noOfRooms: data.noOfRooms,
			constructionYear: data.constructionYear,
			vacantFrom: data.vacantFrom,
			equipBalcony: data.equipBalcony,
			equipWinterGarden: data.equipWinterGarden,
			equipTerrace: data.equipTerrace,
			equipRoofTerrace: data.equipRoofTerrace,
			equipKitchen: data.equipKitchen,
			equipGarden: data.equipGarden,
			objCellar: data.objCellar,
			objHeatingType: data.objHeatingType,
			equipGuestToilet: data.equipGuestToilet,
			propFloorPlan: data.propFloorPlan,
			energyCertificate: data.energyCertificate,
			objectTitlePicture: data.objectTitlePicture,
			adPicturesUrls: data.adPicturesUrls,
			providerCompanyName: data.providerCompanyName,
			providerEmailAddress: data.providerEmailAddress,
			totalRent: data.totalRent,
			depositCost: data.depositCost,
			costsCommission: data.costsCommission,
			costsCommissionPercent: data.costsCommissionPercent,
			costsCommissionAmount: data.costsCommissionAmount
		};
		return detail;
	};

	const resetForm = (objectDetail: any) => {
		const { objectType, objectId, stepsReached, objectTypeSub, description, marketingType, adrCountry, id } =
			objectDetail;

		setSelectedValue(objectType);
		setObjectId(objectId || id);

		let index = TABS.indexOf(stepsReached);
		if (stepsReached !== propertyTab.finance) {
			index++;
		}
		setCurrentTab(TABS[1]);
		if (index !== -1) {
			let arr = TABS.slice(1, index + 1);
			setActiveTabs(arr);
		}

		let objectTypeSubOption: {
			id: string;
			value: string;
			label: string;
		}[];

		switch (objectType) {
			case propertyOption.apartment:
				objectTypeSubOption = wohnungAccommodationOption;
				break;
			case propertyOption.house:
				objectTypeSubOption = hausAccommodationOption;
				break;
			default:
				objectTypeSubOption = grundstückAccommodationOption;
		}

		const selectedCountry = getSelectedOption(countriesArray, adrCountry);
		const selectedMarketingType = getSelectedOption(marketingTypeOption, marketingType);
		const selectedObjectTypeSub = getSelectedOption(objectTypeSubOption, objectTypeSub);
		const parsedDescription = description && description[0] ? JSON.parse(description[0]).text : '';
		const objectDetailFormData = removeEmptyStrings({
			...formData(objectDetail),
			adrCountry: selectedCountry,
			marketingType: selectedMarketingType,
			objectTypeSub: selectedObjectTypeSub,
			description: parsedDescription
		});
		return methods.reset({
			...objectDetailFormData
		});
	};

	const onSubmit = (data: FieldValues) => {
		let step = currentTab;
		let pricePerMeter = '';
		if (
			isEdit &&
			objectDetail.stepsReached &&
			TABS.indexOf(objectDetail.stepsReached) >= TABS.indexOf(currentTab)
		) {
			step = objectDetail.stepsReached;
		}
		if (data.totalRent && (data.areaLiving || data.areaPlot)) {
			let area = data.areaLiving;
			if (selectedValue === propertyOption.land) {
				area = data.areaPlot;
			}
			if (!area) {
				pricePerMeter = '';
			}
			pricePerMeter = Math.round(data.totalRent / parseInt(area)).toString();
		}

		const Details = {
			...data,
			objectId,
			objectType: selectedValue,
			steps: step,
			adrCountry: data.adrCountry?.value,
			marketingType: data.marketingType?.value,
			objectTypeSub: data.objectTypeSub?.value,
			description: [data.description || ''],
			adrPostalCode: parseInt(data.adrPostalCode) || '',
			noOfRooms: Number(data.noOfRooms) || '',
			constructionYear: parseInt(data.constructionYear) || '',
			totalRent: parseInt(data.totalRent) || '',
			depositCost: Number(data.depositCost) || '',
			costsM2: pricePerMeter || ''
		};
		submitPropertyDetail(removeEmptyStrings(Details));
	};

	const submitPropertyDetail = (Details: PropertyDetail) => {
		// the API call popup first step and last step
		if (currentTab === propertyTab.finance || currentTab === propertyTab.addPropertyTab) {
			setIsLoading(true);
			HttpService.post(API_CONFIG.path.newUserProperty, Details)
				.then((response) => {
					changeTab('next');
					setObjectId(response.data.objectId);
					if (currentTab === propertyTab.finance) {
						notify(`${response.message}`, 'success');
						resetFilter && resetFilter();
					}
					if (showAbortModal.isShow) {
						closeModal();
					}
					setIsLoading(false);
				})
				.catch((err) => {
					notify(`${err.response.data.message}`, 'error');
					setIsLoading(false);
				});
		} else {
			changeTab('next');
		}
	};

	const changeTab = useCallback(
		(step: 'next' | 'back') => {
			const currentIndex = TABS.indexOf(currentTab);
			const nextTab = TABS[currentIndex + 1];
			const backTab = TABS[currentIndex - 1];
			const isNext = step === 'next';
			const currentActiveTabs = Array.isArray(activeTabs) ? activeTabs : [];
			// close on last tab
			if (isNext && currentIndex === TABS.length - 1) {
				closeModal();
				return;
			}
			// set progress
			if (isNext && !currentActiveTabs.includes(nextTab)) {
				setActiveTabs([...currentActiveTabs, nextTab]);
			}
			setCurrentTab(isNext ? nextTab : backTab);
		},
		[activeTabs, currentTab]
	);
	const onBack = useCallback(() => {
		changeTab('back');
	}, [changeTab]);

	const defaultProps = useMemo(
		() => ({
			selectedValue: selectedValue,
			gotoBack: onBack,
			isLoading: isLoading
		}),
		[onBack, selectedValue, isLoading]
	);

	const handleDelete = () => {
		setShowAbortModal({
			...showAbortModal,
			isBtnLoading: true
		});
		if (objectId) {
			HttpService.deleteRequest(`${API_CONFIG.path.deleteNewUserProperty}/${objectId}`)
				.then((response) => {
					closeModal();
					setShowAbortModal({
						...showAbortModal,
						isBtnLoading: false
					});
				})
				.catch((err) => {
					setShowAbortModal({
						...showAbortModal,
						isBtnLoading: false
					});
				});
		} else {
			closeModal();
			setShowAbortModal({
				...showAbortModal,
				isBtnLoading: false
			});
		}
	};
	return (
		<FormProvider {...methods}>
			<AddPropertyModal
				closeModal={() => {
					!isFormEdit && (isEdit || selectedValue.length === 0)
						? closeModal()
						: setShowAbortModal({
								isShow: true,
								message: 'Alle Änderungen verwerfen und zur Seite zurückkehren?',
								conform: 'Änderungen verwerfen',
								cancel: 'Abbrechen',
								isBtnLoading: false
						  });
				}}
				setCurrentTab={setCurrentTab}
				currentTab={currentTab}
				isActive={(tab) => activeTabs.includes(tab)}
				selectedValue={selectedValue}
				isEdit={isEdit}
			>
				<form onSubmit={methods.handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
					{currentTab === propertyTab.addPropertyTab && (
						<AddPropertyTab
							selectedValue={selectedValue}
							setSelectedValue={setSelectedValue}
							resetForm={methods.reset}
							isLoading={isLoading}
						/>
					)}
					{currentTab === propertyTab.generally && <GenerallyTab {...defaultProps} />}
					{currentTab === propertyTab.location && <LocationTab {...defaultProps} />}
					{currentTab === propertyTab.detail && <DetailTab {...defaultProps} />}
				</form>
				{currentTab === propertyTab.contact && (
					<ContactTab {...defaultProps} changeTab={changeTab} currentTab={currentTab} objectId={objectId} />
				)}
				<form onSubmit={methods.handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
					{currentTab === propertyTab.finance && (
						<FinanceTab
							{...defaultProps}
							handleDelete={() =>
								setShowAbortModal({
									isShow: true,
									message: 'Alle Änderungen verwerfen und zur Seite zurückkehren?',
									conform: 'Änderungen verwerfen',
									cancel: 'Abbrechen',
									isBtnLoading: false
								})
							}
						/>
					)}
				</form>
			</AddPropertyModal>
			{showAbortModal.isShow && (
				<ModalComponent
					title={showAbortModal.message}
					closeModal={() => setShowAbortModal({ ...showAbortModal, isShow: false })}
					handleYes={isEdit ? closeModal : handleDelete}
					btnTextConform={showAbortModal.conform}
					btnTextCancel={showAbortModal.cancel}
					className={'property-tab-verification-popup'}
					isBtnLoading={showAbortModal.isBtnLoading}
				/>
			)}
		</FormProvider>
	);
};
export default MyProperty;
