import React from 'react';
import TitleImageSection from '../components/titleImageSection';

import ourTeamImage from '../../../assets/images/our-team-image.png';
import cooperationPartnerImage from '../../../assets/images/cooperation-partner-image.png';
import partnerModelsImage from '../../../assets/images/partner-models-image.png';
import { aboutCard, brokerAndFranchise, features } from '../utils/constants';
import MetaDecorator from 'shared/meta/metaDecorator';

const OurTeam = () => {
	return (
		<div className='our-team-wrapper public-page-font'>
			<MetaDecorator title='Franchise - Immoweb | Immobilien, Wohnungen und Häuser bei immoweb' />
			<TitleImageSection backgroundImage={ourTeamImage} titleText={'Werde jetzt Teil des \nimmoweb.de Teams'} />
			<section className='section-width information-section'>
				<h1 className='text--center'>Profitiere von uns.</h1>
				<p className='text--center'>
					Werde Teil unseres erfolgreichen Immobilienmaklerteams! Bist du ein enthusiastischer und ehrgeiziger
					Immobilienliebhaber? Liebst du es, Menschen bei der Suche nach ihrem Traumhaus oder der perfekten
					Investition zu helfen? Dann suchen wir genau dich!
				</p>
				<p className='text--center'>
					Als wachsendes Immobilienunternehmen bieten wir eine spannende Möglichkeit, deine Karriere als
					Immobilienmakler auf das nächste Level zu bringen. Hier sind einige Gründe, warum du dich bei uns
					bewerben solltest:
				</p>
			</section>
			<section className='features-section'>
				<div className='align__items--center justify__content--center features'>
					{features.map((item) => (
						<div key={item.text} className='text--center feature-div'>
							{item.icon}
							<h3 className='text--center'>{item.text}</h3>
						</div>
					))}
				</div>
			</section>
			<section className='section-width information-section cooperation-partner-section'>
				<h1 className='text--center'>
					Kooperationspartner zu <br />
					Deinem Vorteil.
				</h1>
				<p className='text--center'>
					Werde Teil unseres erfolgreichen Immobilienmaklerteams! Bist du ein enthusiastischer und ehrgeiziger
					Immobilienliebhaber? Liebst du es, Menschen bei der Suche nach ihrem Traumhaus oder der perfekten
					Investition zu helfen? Dann suchen wir genau dich!
				</p>
				<p className='text--center'>
					Als wachsendes Immobilienunternehmen bieten wir eine spannende Möglichkeit, deine Karriere als
					Immobilienmakler auf das nächste Level zu bringen. Hier sind einige Gründe, warum du dich bei uns
					bewerben solltest:
				</p>
				<div className='text--center cooperation-partner-image'>
					<img src={cooperationPartnerImage} alt='cooperationPartnerImage' />
				</div>
			</section>
			<section className='team-about-us-section'>
				<div className='card-wrapper'>
					{aboutCard.map((item) => (
						<div className='about-us-card' key={item.title}>
							<div className='card-title-wrapper flex align__items--center justify__content--center'>
								<h1>{item.title}</h1>
							</div>
							<p>{item.discretion}</p>
						</div>
					))}
				</div>
			</section>
			<section className='partner-models-section'>
				<div className='partner-models-image'>
					<img src={partnerModelsImage} alt='partnerModelsImage' />
				</div>
				<div className='section-width information-section'>
					<h1 className='text--center'>Unsere Partnermodelle.</h1>
					<p className='text--center'>
						Unser Unternehmen bietet zwei verschiedene Partnermodelle an, die es Interessierten ermöglichen,
						mit uns zusammenzuarbeiten und von den Vorteilen unserer etablierten Marke zu profitieren. Diese
						beiden Modelle sind der "Homeoffice-Makler" und der "Franchise-Partner"
					</p>
				</div>

				<div className='broker-and-franchise'>
					{brokerAndFranchise.map((item) => (
						<div key={item.title}>
							<div className='title-text-wrapper flex align__items--center justify__content--center'>
								<h1>{item.title}</h1>
							</div>
							<div
								className={`discretion-wrapper ${
									item.title === 'Franchsie-Partner' ? 'franchise-partner' : ''
								}`}
							>
								{item.discretion.map((discretionItem) => (
									<div className='discretion' key={discretionItem.title}>
										<h4>{discretionItem.title}</h4>
										{discretionItem.subTitle.map((subTitleItem) => (
											<div
												dangerouslySetInnerHTML={{ __html: subTitleItem }}
												key={subTitleItem}
											></div>
										))}
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			</section>
		</div>
	);
};

export default OurTeam;
