import React from "react";
import { CloseDropdown } from "./icons";

const BackButton = () => (
  <button className="close-dropdown" type="button">
    <CloseDropdown width="22  " height="22" />
  </button>
);

BackButton.defaultProps = {
  zIndex: 1,
  disabled: false,
  emptyFields: [],
};

export default BackButton;
