import React, { FC } from 'react';

interface IModalProps {
	className?: string;
	closeModal: () => void;
	handleYes?: () => void;
	btnTextCancel?: string;
	title: string;
	btnTextConform?: string;
	isBtnLoading?: boolean;
}

const ModalComponent: FC<IModalProps> = ({
	className,
	closeModal,
	handleYes,
	btnTextCancel,
	title,
	btnTextConform,
	isBtnLoading = false
}) => {
	return (
		<div className='confirmation-modal-container' onClick={closeModal}>
			<div className={`modal ${className || ''}`} onClick={(e) => e.stopPropagation()}>
				<header className='modal_header'>
					<h2 className='modal_header-title  mb-2'>{title}</h2>
				</header>

				<footer className='modal_footer'>
					<button className='common-button ' onClick={closeModal}>
						{btnTextCancel}
					</button>
					<button className='common-button mr-2' onClick={handleYes} disabled={isBtnLoading}>
						{btnTextConform}
					</button>
				</footer>
			</div>
		</div>
	);
};

export default ModalComponent;
