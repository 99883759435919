import React, { FC } from 'react';
interface IConformationPopup {
	handleClick: () => void;
	handleClose: () => void;
	titleText?: string;
	subTitleText?: string;
	description?: string;
	conformButtonText: string;
	closeButtonText: string;
	className?: string;
}

const ConformationPopup: FC<IConformationPopup> = ({
	handleClick,
	handleClose,
	titleText,
	subTitleText,
	description,
	conformButtonText,
	closeButtonText,
	className
}) => {
	return (
		<div className={`conformation-popup-wrapper position-relative ${className || ''}`}>
			<div className='conformation-popup-div'>
				<div className='tittle-wrapper text--center'>
					{titleText && <h3 className='tittle'>{titleText}</h3>}
					{subTitleText && <p>{subTitleText}</p>}
					{description && <p>{description}</p>}
				</div>
				<div className='flex justify__content--center m-t-32'>
					<button className='common-button conform-button' onClick={handleClick}>
						{conformButtonText}
					</button>
					<button className='conform-close-button ml--30' onClick={handleClose}>
						{closeButtonText}
					</button>
				</div>
			</div>
		</div>
	);
};

export default ConformationPopup;
