import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useDispatch, useSelector } from 'react-redux';

import { LOGOUT_SUCCESS } from 'reducers/actionTypes';
import { HomepageIcon, MenuIcon } from 'components/commonComponents/icons';
import { layoutLinks, popupName } from './constants';
import UserRegister from 'components/home/auth/components/register';
import Login from 'components/home/auth/components/login';
import ForgotPassword from 'components/home/auth/components/forgotPassword';
import LoginRegister from 'components/home/auth/components/loginRegister';
import ForgotPasswordEmailPopup from 'components/home/auth/components/forgotPasswordEmailPopup';
import CustomProperties from 'components/myProperty/container/customPropertiesList';
import VerifyUser from 'components/home/auth/components/verifyUser';
import PasswordChangeSuccessfully from 'components/home/auth/components/passwordChangeSuccessfully';
import { getProfilDetails } from 'actions/searchActions';
import ActivationUser from 'components/home/auth/components/activationUser';
import { notify } from 'components/commonComponents/notification/notification';
import Modal from 'components/modal';
import { getSearchResultsAction } from 'actions/searchActions';
import { INIT_FILTERS } from 'components/home/components/filterSection';

import { clearReducer } from 'actions/clearAction';
import { clearStorage, createAction, ProfileRootState, removeEmptyStrings } from 'utils';

import './layout.scss';

const HomeHeader = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const pathName = location.pathname;
	const token = localStorage.getItem('token');
	const [showData, setShowData] = useState(token as any);
	const [showProfileDropDown, setShowProfileDropDown] = useState(false);
	const [showAuthPopup, setShowAuthPopup] = useState({
		url: ['reset-password', 'activation'].includes(pathName.split('/')[1]),
		value: '',
		showPopup: false
	});
	const [userEmail, setUserEmail] = useState('');
	const [forgotPasswordBack, setForgotPasswordBack] = useState('');
	const [showErrorPopup, setShowErrorPopup] = useState(false);
	const [component, setComponent] = useState(popupName.login);

	const profileDetail = useSelector((state: ProfileRootState) => state.profileDetailReducer);
	const userID = useMemo(() => profileDetail?.data?.userId, [profileDetail]);

	const handlePopup = (name: any) => {
		setShowAuthPopup({
			...showAuthPopup,
			value: (showAuthPopup.value = name),
			showPopup: true
		});
		setShowProfileDropDown(false);
		document.body.style.overflow = 'hidden';
	};
	const handleClickOutside = () => {
		setShowProfileDropDown(false);
	};
	const innerRef = useDetectClickOutside({ onTriggered: handleClickOutside });

	useEffect(() => {
		getValue();
		if (token) {
			dispatch(getProfilDetails());
		}
		setShowData(token as any);
		// eslint-disable-next-line
	}, [token]);
	useEffect(() => {
		if (location.search === '?loginRegister') {
			setShowAuthPopup({
				...showAuthPopup,
				value: popupName.loginRegister,
				showPopup: true
			});
		}
	}, [location]);

	const getValue = () => {
		const route = pathName.substring(pathName.indexOf('/') + 1, pathName.lastIndexOf('/'));
		if (route === 'reset-password') {
			setShowAuthPopup({
				...showAuthPopup,
				showPopup: true,
				value: popupName.resetPassword
			});
		}
		if (route === 'activation') {
			setShowAuthPopup({
				...showAuthPopup,
				showPopup: true,
				value: popupName.activation
			});
		}
	};

	const handleDropdown = () => {
		setShowProfileDropDown(!showProfileDropDown);
	};
	const handleLogout = () => {
		clearStorage();
		clearReducer('CLEAR_SEARCH_CRESET_STATE');
		dispatch(createAction(LOGOUT_SUCCESS, false));
		navigate('/');
		notify('Ausloggen', 'success');
	};

	const closeProfile = () => {
		setShowAuthPopup({ ...showAuthPopup, showPopup: false, value: '' });
		setShowProfileDropDown(false);
		if (showAuthPopup.url) {
			navigate('/');
		}
		if (location.search === '?loginRegister') {
			navigate({
				search: ''
			});
		}
		document.body.style.overflowY = 'scroll';
	};

	const goToHomePage = () => {
		if (pathName === '/') {
			return;
		}
		localStorage.removeItem('filterData');
		const timeout = setTimeout(() => {
			clearTimeout(timeout);
			navigate('/');
		}, 500);
	};
	const handleSearch = () => {
		const userSerchData = removeEmptyStrings(INIT_FILTERS);
		localStorage.setItem('filterData', JSON.stringify(userSerchData));
		dispatch(
			getSearchResultsAction({
				...userSerchData,
				userId: userID
			})
		);
		navigate('/suche');
	};
	return (
		<>
			<div className='home-header d-flex align-items-center'>
				{!showData && <div className='w--33 profile-icon' />}
				{showData && (
					<div className='profile-icon w--33' ref={innerRef}>
						<div onClick={() => handleDropdown()}>
							<span>Menü</span>
							<MenuIcon className='cursor-pointer' />
						</div>
					</div>
				)}
				<div className='w--33 text-center cursor-pointer header-icon' onClick={goToHomePage}>
					<HomepageIcon className='home-icon' />
				</div>
				<div className='w--33 text-right flex justify__content--end align__items--center publick-page-links'>
					{layoutLinks.map((data, index) => (
						<NavLink
							key={index}
							to={data.path}
							className={`navlink-data ${pathName === data.path ? 'active-page' : ''}`}
						>
							{data.title}
						</NavLink>
					))}

					<div
						className='profile-icon registration-nav-bar'
						onClick={() => {
							showData ? handleSearch() : handlePopup(popupName.loginRegister);
						}}
					>
						<span className={`${pathName === '/suche' ? 'active-page' : ''}`}>
							{showData ? 'Immobiliensuche' : 'Anmeldung'}
						</span>
					</div>
				</div>

				{showAuthPopup.showPopup && (
					<Modal closeModal={closeProfile} className={'user-register'}>
						{showAuthPopup.value === popupName.loginRegister && (
							<LoginRegister
								closePopup={closeProfile}
								handlePopup={(name) => handlePopup(name as string)}
								setUserEmail={setUserEmail}
								setForgotPasswordBack={setForgotPasswordBack}
							/>
						)}
						{showAuthPopup.value === popupName.register && (
							<UserRegister
								handlePopup={(name) => handlePopup(name as string)}
								closePopup={closeProfile}
								component={popupName.register}
								userEmail={userEmail}
							/>
						)}
						{showAuthPopup.value === popupName.login && (
							<Login
								closePopup={closeProfile}
								handlePopup={(name) => handlePopup(name as string)}
								userEmail={userEmail}
								component={component}
								setForgotPasswordBack={setForgotPasswordBack}
								showErrorPopup={showErrorPopup}
								setShowErrorPopup={setShowErrorPopup}
							/>
						)}
						{showAuthPopup.value === popupName.forgotpassword && (
							<ForgotPassword
								closePopup={closeProfile}
								handlePopup={(name) => handlePopup(name as string)}
								forgotPasswordBack={forgotPasswordBack}
							/>
						)}
						{showAuthPopup.value === popupName.forgotEmailPopup && (
							<ForgotPasswordEmailPopup
								closePopup={closeProfile}
								handlePopup={(name) => handlePopup(name as any)}
							/>
						)}
						{showAuthPopup.url && showAuthPopup.value === popupName.resetPassword && (
							<UserRegister
								handlePopup={(name) => handlePopup(name as string)}
								closePopup={closeProfile}
								component={popupName.resetPassword}
								userEmail={userEmail}
							/>
						)}
						{showAuthPopup.url && showAuthPopup.value === popupName.activation && (
							<ActivationUser
								closePopup={closeProfile}
								setUserEmail={setUserEmail}
								handlePopup={(name) => handlePopup(name as string)}
								setShowErrorPopup={setShowErrorPopup}
								setComponent={setComponent}
							/>
						)}
						{showAuthPopup.value === popupName.userVerification && (
							// <Verification closePopup={closeProfile} />
							<VerifyUser
								closePopup={closeProfile}
								userEmail={userEmail}
								component={popupName.userVerification}
							/>
						)}
						{showAuthPopup.value === popupName.passwordResetSuccessfully && (
							<PasswordChangeSuccessfully
								btnText={'Anmelden'}
								onSubmit={() => handlePopup(popupName.loginRegister)}
							/>
						)}
					</Modal>
				)}
				{showProfileDropDown && (
					<div className='profile-dropdown'>
						<li
							onClick={() => {
								navigate('/user-profile');
							}}
							style={{
								color: `${pathName === '/user-profile' ? '#FFB000' : ''}`
							}}
						>
							{/* <UserProfileIcon
								className='mr--15'
								color={
									pathName === '/user-profile' || showAuthPopup.value === 'MeinProfil'
										? '#FFB000'
										: '#2C344E'
								}
							/> */}
							Mein Profil
						</li>
						<li
							onClick={() => {
								navigate('/user-property');
							}}
							style={{
								color: `${pathName === '/user-property' ? '#FFB000' : ''}`
							}}
						>
							Meine Objekte
						</li>
						{/* <li
							onClick={() => handlePopup(popupName.menuList)}
							style={{
								color: `${showAuthPopup.value === popupName.menuList ? '#FFB000' : ''}`
							}}
						>
							<AddObjectIcon
								className='mr--15'
								color={showAuthPopup.value === popupName.menuList ? '#FFB000' : '#2C344E'}
							/>
							Meine Entwürfe
						</li> */}
						<li onClick={handleLogout}>
							{/* <LogoutIcon className='mr--15' /> */}
							Abmelden
						</li>
					</div>
				)}

				{showAuthPopup.value === popupName.menuList && showAuthPopup.showPopup && (
					<div className='popup-background'>
						<div className='user-register '>
							<CustomProperties closeAddProperty={closeProfile} />
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default HomeHeader;
