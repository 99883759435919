import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';

import german from 'languages/german';
import englishText from 'languages/english';

const resources = {
	en: {
		translations: {
			...englishText
		}
	},
	de: {
		translations: {
			...german
		}
	}
};

i18n.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources,
		debug: false,
		lng: 'de',
		fallbackLng: 'de',
		interpolation: {
			escapeValue: false // not needed for react as it escapes by default
		},
		// have a common namespace used around the full app
		ns: ['translations'],
		defaultNS: 'translations',
		react: {
			useSuspense: false
		}
	});

export default i18n;
