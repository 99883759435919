import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Notification: React.FC = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={4000}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
    />
  );
};
type NotificationType = "success" | "error";
export const notify = (
  message: string,
  type?: NotificationType,
  options: any = {}
) => {
  const msg = `${message[0].toUpperCase()}${message.substr(1)}`;
  options = {
    icon: true,
    ...options,
  };
  if (type === "success") {
    toast.success(msg, {
      ...options,
      position: toast.POSITION.TOP_RIGHT,
    });
  } else if (type === "error") {
    toast.error(msg, {
      ...options,
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast(message, options);
  }
};
export default Notification;
