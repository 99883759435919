import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosResponse, AxiosError } from 'axios';
import { createAction } from 'utils';
import { axiosInstance } from 'services';
import Notification from 'components/commonComponents/notification/notification';
import React from 'react';

import * as actionTypes from 'reducers/actionTypes';

let resInterceptor: number;

const ErrorHandler: FC = () => {
	const dispatch = useDispatch();

	const logout = useCallback(() => {
		dispatch(createAction(actionTypes.LOGOUT_SUCCESS, false));
	}, [dispatch]);

	useEffect(() => {
		resInterceptor = axiosInstance.interceptors.response.use(
			(res: AxiosResponse<any>) => {
				return res;
			},
			(error: AxiosError) => {
				let show = true;
				if (error && error.request && error.request.status === 404) {
					show = false;
				}
				if (error && error.request && error.request.status === 504) {
					throw new Error('504 Timeout');
				}
				// check if error is having data
				if (error.response && error.response.data && error.response.status && show) {
					const status = error.response.status;
					const responseData: any = error.response.data;
					// is http error code is 401, and message contains **SESSION_EXPIRED**, log out of the application
					if (status === 401) {
						logout();
					} else if (responseData.errorMessages && Object.keys(responseData.errorMessages).length) {
						// if error response contains any validation message, fetch it from response, and add error notification
						const validationError = responseData.errorMessages[Object.keys(responseData.errorMessages)[0]];
					} else if (error.response && responseData && responseData.message) {
						// if error data contains message field, add error notification
					}
					throw error;
				}
			}
		);
		return () => axiosInstance.interceptors.response.eject(resInterceptor);
	}, [logout]);
	return <Notification />;
};

export default ErrorHandler;
