import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import InputField from 'components/commonComponents/form/inputText';
import InputSelect from 'components/commonComponents/form/inputSelect';
import { countriesArray } from '../utils/constants';
import { IFormProps } from '../utils/form.interface';
type AddressInformationForm = {
	land: string;
	street: string;
	postalCode: number;
	city: string;
};

const AddressInformation = (props: IFormProps) => {
	const {
		control,
		formState: { errors }
	} = useFormContext<AddressInformationForm>();
	const { requiredFields, rules } = props;

	return (
		<>
			<Controller
				name='street'
				control={control}
				rules={rules.street}
				render={({ field: { ref, ...rest } }) => (
					<InputField
						label='Straße und Hausnummer'
						asterisk={requiredFields.includes('street')}
						error={errors.street}
						type={'text'}
						{...rest}
					/>
				)}
			/>
			<Controller
				name='postalCode'
				control={control}
				rules={rules.postalCode}
				render={({ field: { ref, ...rest } }) => (
					<InputField
						label='Postleitzahl/ZIP'
						asterisk={requiredFields.includes('postalCode')}
						error={errors.postalCode}
						type={'number'}
						{...rest}
					/>
				)}
			/>
			<Controller
				name='city'
				control={control}
				rules={rules.city}
				render={({ field: { ref, ...rest } }) => (
					<InputField
						label='Stadt/Ort'
						asterisk={requiredFields.includes('city')}
						error={errors.city}
						type={'text'}
						{...rest}
					/>
				)}
			/>
			<Controller
				name='land'
				control={control}
				rules={rules.land}
				render={({ field: { ref, ...rest } }) => (
					<InputSelect
						label='Land'
						placeholder='Bitte wählen'
						options={countriesArray}
						asterisk={requiredFields.includes('land')}
						error={errors.land}
						{...rest}
					/>
				)}
			/>
		</>
	);
};

export default AddressInformation;
