import React from 'react';
import { connect } from 'react-redux';
// components
import MapWithAMarker from 'components/objectDetails/components/mapForObject';
// functions
import * as mapPopupInterface from '../../interface/objectDetails';
import { clearReducer } from 'actions/clearAction';
import { CloseIcon } from 'components/commonComponents/icons';
import 'components/finder/search.scss';

const initialMapFilterState = {
	resultArray: {
		schools: [],
		supermarkets: [],
		transports: [],
		medicals: [],
		parks: []
	}
};

class MapPopup extends React.Component<mapPopupInterface.IMapPopupProps> {
	state: any = {
		disableClick: false,
		checkedType: [],
		removeMarker: null,
		iconFile: null,
		...initialMapFilterState
	};

	componentDidMount() {
		document.body.style.overflowY = 'hidden';
	}

	componentWillUnmount() {
		document.body.style.overflowY = '';
	}

	render() {
		const { resultArray, removeMarker, showType, iconFile } = this.state;

		const { propertyDetail, closeMap } = this.props;

		return (
			<div className={`image-gallery-popup relative`}>
				<div className='close-btn-div' onClick={closeMap} style={{ top: '8px', right: '8px' }}>
					<CloseIcon />
				</div>

				<MapWithAMarker
					loadingElement={<div style={{ height: `100%` }} />}
					containerElement={<div style={{ height: `96%`, padding: '2% 18px 0 18px' }} />}
					mapElement={
						<div
							style={{
								height: `100%`,
								width: ' 95%',
								margin: ' 0 auto'
							}}
						/>
					}
					lat={parseFloat(propertyDetail.location.lat)}
					lon={parseFloat(propertyDetail.location.lon)}
					title={propertyDetail.objectTitle}
					score={propertyDetail.score}
					fromPopup={true}
					resultArray={resultArray}
					removeMarker={removeMarker}
					showType={showType}
					iconFile={iconFile}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	addFavoReducer: state.favoReducer
});

const mapDispatchToProps = (dispatch: any) => ({
	clearReducer: (name: string) => {
		dispatch(clearReducer(name));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(MapPopup);
