import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import OfficeLocation from '../../home/components/officeLocation';
import { notify } from 'components/commonComponents/notification/notification';
import TitleImageSection from 'components/publicPage/components/titleImageSection';
import MetaDecorator from 'shared/meta/metaDecorator';
import { addressFieldRegex, emailFieldRegex, textFieldRegex } from 'components/newMyProperty/utils/constants';

import contactImage from '../../../assets/images/contact_teaser-image.png';

import { API_CONFIG, HttpService } from 'services';

import '../contact.scss';

interface IFormInput {
	name: '';
	email: '';
	regarding: '';
	message: '';
	example: string;
}
const Contact = () => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm<IFormInput>();
	const [isChecked, setIsChecked] = useState(true);

	const onSubmit = (data: IFormInput) => {
		setIsChecked(true);
		HttpService.post(API_CONFIG.path.request, data)
			.then((response) => {
				notify(`Nachricht gesendet`, 'success');
				setIsChecked(false);
			})
			.catch((err) => {
				notify(`${err.response.data.message}`, 'error');
				setIsChecked(false);
			});
		reset();
	};
	const formData = [
		{
			labalName: 'Dein Name',
			type: 'text',
			name: 'name',
			class: errors?.name?.type,
			pattern: textFieldRegex
		},
		{
			labalName: 'Deine E-Mail Adresse',
			type: 'email',
			name: 'email',
			class: errors?.email?.type,
			pattern: emailFieldRegex
		},
		{
			labalName: 'Betreff',
			type: 'text',
			name: 'regarding',
			class: errors?.regarding?.type,
			pattern: addressFieldRegex
		}
	];

	return (
		<div className='contact-container'>
			<MetaDecorator title='Kontakt - Immoweb | Immobilien, Wohnungen und Häuser bei immoweb' />
			<TitleImageSection backgroundImage={contactImage} titleText={'Wir sind \nfür Dich da'} />
			<section className='contact-form-container'>
				<div className='contact-form-wrapper'>
					<div className='contact-form-title'>
						<h4 className='text-center'>
							Du hast Fragen rund um Immobilien? Kein Problem, unser Team ist für Dich da und hilft Dir
							weiter.
						</h4>
					</div>
					<div className='contact-form'>
						<form onSubmit={handleSubmit(onSubmit)} className='form-group'>
							{formData.length > 0 &&
								formData.map((data, index) => {
									const errorclass = data.class;
									const id = data.name;
									const pattern = data.pattern;

									return (
										<div key={index}>
											<label>{data.labalName}</label>
											<input
												{...register(id as any, {
													required: true,
													pattern: pattern
												})}
												className={`form-tage ${errorclass ? 'error-border ' : ''}`}
											/>
										</div>
									);
								})}
							<label>Deine Nachricht</label>
							<textarea
								{...register('message', { required: true })}
								className={`form-tage massage-box ${errors?.message?.type ? 'error-border ' : ''}`}
							/>
							<div className='form-group form-check '>
								<label className='form-check-label' htmlFor='exampleCheck1'>
									<input
										type='checkbox'
										onChange={(e) => setIsChecked(!e.target.checked)}
										className='form-check-input'
										id='exampleCheck1'
									/>
									<span className='checkmark'></span>
									<div>
										Die <a href='/#'>Datenschutzhinweise </a> habe ich gelesen und verstanden.
									</div>
								</label>
							</div>
							<div className='text-center'>
								<button type='submit' className='common-button btn btn-primary' disabled={isChecked}>
									SENDEN
								</button>
							</div>
						</form>
					</div>
				</div>
			</section>
			<hr className='common-hr' />
			<OfficeLocation />
		</div>
	);
};
export default Contact;
