import { Action } from "utils";

export const INITIAL_STATE = {
  response: [],
};

export const suggestedLocationReducer = (
  state = INITIAL_STATE,
  action: Action
) => {
  switch (action.type) {
    case "AUTOSUGGESTION_LIST_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "AUTOSUGGESTION_LIST_PENDING":
      return {
        status: "pending",
      };

    case "CLEAR_AUTOSUGGESTION_LIST":
      return {
        response: [],
      };

    default:
      return {
        ...state,
      };
  }
};
