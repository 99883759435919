import React, { FC } from 'react';

import Modal from 'components/modal';
import CustomSpinner from 'components/commonComponents/customSpinner';

import Logo from '../../../assets/images/Logo.png';
interface IProps {
	titleTex?: string;
	subTitleTex?: string;
	onSubmit: () => void;
	cancel: () => void;
	isLoading: boolean;
}

const CommonWaringPopup: FC<IProps> = ({ titleTex, subTitleTex, onSubmit, cancel, isLoading = false }) => {
	return (
		<Modal className={'modal-position'} closeModal={cancel}>
			<div className='waring-popup-wrapper flex align__items--center flex--column'>
				<div className='immoweb-icon text--center'>
					<img src={Logo} />
				</div>
				<div className='waring-popup-text'>
					{titleTex && <h6 className='text--center mb--15'>{titleTex}</h6>}
					{subTitleTex && <p className='text--center'>{subTitleTex}</p>}
				</div>
				<div className='flex align__items--center flex--column'>
					<button className='common-button confirm-button' onClick={onSubmit} disabled={isLoading}>
						{isLoading && <CustomSpinner animation='border' className='spinner-border-sm' />}
						{!isLoading && 'JA'}
					</button>
					<button className='cancel-button cursor-pointer' onClick={cancel}>
						Abbrechen
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default CommonWaringPopup;
