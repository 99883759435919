import { getSinglefilterParameters } from 'utils/functions';
import { houseTypesArray, extraFeatures } from 'utils/constants';
import { recordPerPage } from 'actions/searchActions';

export const paginationInitalState = {
	currentPage: 1,
	totalRecords: 0,
	totalPages: 1,
	pageChanged: false
};

export const newSearchState = {
	showSmallFilter: false,
	isMoreFilterActive: false,
	emptyListError: '',
	radius: '',

	// min max values
	fromPrice: '0',
	toPrice: '0',
	fromAreaLiving: '0',
	toAreaLiving: '0',
	fromNumberOfRooms: '0',
	toNumberOfRooms: '0',

	// actual filters
	objectType: '',
	demandType: '',
	otherFeatures: [],
	price: '',
	houseTypesArray: JSON.parse(JSON.stringify(houseTypesArray)),
	extraFeatures: JSON.parse(JSON.stringify(extraFeatures))
};
// Get existing filters data and get search object
export const createSearchSummary = (data: any, getSearchResults: any) => {
	let isMoreFilterActive = false;
	let objectType = data.objectType || '';

	const rooms = data.toNumberOfRooms || '0';
	const space = data.toAreaLiving || '0';
	const price = data.toPrice || '0';
	const fromPrice = data.fromPrice || '0';
	const fromAreaLiving = data.fromAreaLiving || '0';
	const fromNumberOfRooms = data.fromNumberOfRooms || '0';
	const location = data.location || '';
	const houseTypesArrayState = JSON.parse(JSON.stringify(houseTypesArray));
	const extraFeaturesState = JSON.parse(JSON.stringify(extraFeatures));

	let obj = {
		currentPage: 1,
		recordPerPage: recordPerPage,
		fromAreaLiving,
		fromNumberOfRooms,
		fromPrice,
		toPrice: price,
		toAreaLiving: space,
		toNumberOfRooms: rooms,
		// objectType,
		location
	};

	getSearchResults(obj);

	houseTypesArrayState.forEach((element: any) => {
		if (objectType && objectType.includes(element.label)) {
			element.value = true;
			isMoreFilterActive = true;
		}
	});

	extraFeaturesState.forEach((element: any) => {
		if (data.otherFeatures && data.otherFeatures.includes(element.countName)) {
			element.value = true;
			isMoreFilterActive = true;
		}
	});

	const stateObject = {
		objectType,
		rooms: getValues(data.toNumberOfRooms, 'rooms'),
		space: getValues(data.toAreaLiving, 'space'),
		price: getValues(data.toPrice, 'price'),
		fromAreaLiving,
		fromNumberOfRooms,
		fromPrice,
		refreshLoad: true,
		houseTypesArray: houseTypesArrayState,
		extraFeatures: extraFeaturesState,
		isMoreFilterActive,
		pageChanged: true,

		location
	};
	return stateObject;
};

export const getValues = (value: string, name: string) => {
	const result = getSinglefilterParameters(name);
	if (!value) {
		return '0';
	}
	if (value === 'ALL') {
		if (name === 'rooms') {
			return result.maxValue + 0.1;
		} else {
			return result.maxValue + result.step;
		}
	}
	return value;
};

// search API call object
export const createApiObject = (state: any, isMoreFilterActive: boolean, sortObj: any) => {
	const {
		extraFeatures,
		houseTypesArray,
		space,
		price,
		rooms,
		latitude,
		longitude,
		locationId,
		fromPrice,
		fromAreaLiving,
		fromNumberOfRooms,
		radius,
		demandType,
		objectType,
		selectedSortType,
		selectedSortName,
		fromPlotApprox,
		toPlotApprox,
		fromYearOfContruction,
		toYearOfContruction,
		zipcode
	} = state;

	let extraFeaturesUpdated: any = [];
	let houseTypesUpdated: any = [];

	extraFeatures.forEach((element: any) => {
		if (element.value) {
			extraFeaturesUpdated.push(element.label);
			isMoreFilterActive = true;
		}
	});

	houseTypesArray.forEach((element: any) => {
		if (element.value) {
			houseTypesUpdated.push(element.label);
			isMoreFilterActive = true;
		}
	});

	if (
		(fromPlotApprox && parseFloat(fromPlotApprox) !== 0) ||
		(toPlotApprox && parseFloat(toPlotApprox) !== 2000) ||
		(fromYearOfContruction && parseFloat(fromYearOfContruction) !== 1950) ||
		(toYearOfContruction && parseFloat(toYearOfContruction) !== 2020)
	) {
		isMoreFilterActive = true;
	}

	const needChange = validateSingleFilters(state);
	let obj: any = {
		currentPage: 1,
		recordPerPage: recordPerPage,
		toAreaLiving: space ? space.toString() : '0',
		toNumberOfRooms: rooms ? rooms.toString() : '0',
		toPrice: price ? price.toString() : '0',
		fromAreaLiving: fromAreaLiving ? fromAreaLiving.toString() : '0',
		fromNumberOfRooms: fromNumberOfRooms ? fromNumberOfRooms.toString() : '0',
		fromPrice: fromPrice ? fromPrice.toString() : '0',
		latitude,
		longitude,
		locationId: locationId,
		zipcode: zipcode,
		radius: radius || Number,
		demandType: demandType || undefined,
		objectType: objectType || undefined,
		fromPlotApprox: fromPlotApprox ? fromPlotApprox.toString() : '0',
		toPlotApprox: toPlotApprox && toPlotApprox.toString(),
		fromYearOfContruction: fromYearOfContruction && fromYearOfContruction.toString(),
		toYearOfContruction: fromYearOfContruction && toYearOfContruction.toString()
	};
	if (extraFeaturesUpdated.length > 0) {
		obj.otherFeatures = extraFeaturesUpdated;
	}
	if (houseTypesUpdated.length > 0) {
		obj.objectSubType = houseTypesUpdated;
	}

	if (needChange.length > 0) {
		needChange.forEach((menuName: string) => {
			const name = getStateNameTo(menuName);
			//@ts-ignore
			obj[name] = 'ALL';
		});
	}

	const newObj = {
		...obj,
		sortType: sortObj ? sortObj.sortType : selectedSortType,
		sortBy: sortObj ? sortObj.sortBy : selectedSortName
	};

	obj = newObj;
	return { obj, isMoreFilterActive };
};

export const validateSingleFilters = (state: any) => {
	const changeNeeded: any = [];
	['rooms', 'space', 'price'].forEach((menuName) => {
		const result = getSinglefilterParameters(menuName);
		if (state[menuName] > result.maxValue) {
			changeNeeded.push(menuName);
		}
	});
	return changeNeeded;
};

// for setting active/inactive state of button
export const checkMoreFilterEnabled = (isMoreFilterActive: boolean, locationId: string, radius: number) => {
	const checkArray = [locationId];
	let isActive = checkArray.filter((i) => i && parseInt(i) !== 0);

	if (isActive && isActive.length > 0) {
		isMoreFilterActive = true;
	}
	return isMoreFilterActive;
};
// for small filter value handle
export const getStateNameTo = (menuName: string) => {
	let stateName = '';
	if (menuName === 'price') {
		stateName = 'toPrice';
	} else if (menuName === 'space') {
		stateName = 'toAreaLiving';
	} else if (menuName === 'rooms') {
		stateName = 'toNumberOfRooms';
	}
	return stateName;
};

//for small filter value handle
export const getStateName = (menuName: string) => {
	let stateName = '';
	if (menuName === 'price') {
		stateName = 'fromPrice';
	} else if (menuName === 'space') {
		stateName = 'fromAreaLiving';
	} else if (menuName === 'rooms') {
		stateName = 'fromNumberOfRooms';
	}
	return stateName;
};

export const resetStateObject = (fromMore: boolean, onboarding: boolean) => {
	let resetObj = {
		showSpinner: true,
		fromPlotApprox: '',
		toPlotApprox: '',
		fromYearOfContruction: '',
		toYearOfContruction: '',
		fromAgeOfadvert: '',
		toAgeOfadvert: '',
		objectType: '',
		otherFeatures: [],
		internet: '',
		isMoreFilterActive: false,
		houseTypesArray: JSON.parse(JSON.stringify(houseTypesArray)),
		extraFeatures: JSON.parse(JSON.stringify(extraFeatures)),
		location: '',
		typeAuctionForced: null,
		fromAreaLiving: '0',
		fromNumberOfRooms: '0',
		fromPrice: '0',
		toNumberOfRooms: '0',
		toAreaLiving: '0',
		toPrice: '0',
		demandType: '',
		locationId: '',
		zipcode: 0
	};

	if (!fromMore) {
		let updatedObject = {
			...resetObj,
			rooms: '0',
			price: '0',
			space: '0',
			fromPrice: '0',
			fromAreaLiving: '0',
			fromNumberOfRooms: '0',
			currentValueMax: 0,
			currentValueMin: 0,
			showSmallFilter: false,
			showSortMenu: false,
			showResultSpinner: true,
			optionChangedForMore: false,
			selectedSortType: 'DESC',
			selectedSortLabel: 'Match',
			selectedSortName: 'created_at',
			menuName: ''
		};

		if (onboarding) {
			const updated = {
				...updatedObject,
				topResult: true
			};
			updatedObject = updated;
		}
		return updatedObject;
	}

	return resetObj;
};

export const priceFormat = (price: string) => {
	if (price) {
		const lang = localStorage.getItem('language') || 'de-DE';
		const locale = lang && lang.includes('en') ? 'en-US' : 'de-DE';
		const a = Number(price).toLocaleString(locale);
		return a;
	}
	return price;
};

export const initialState = {
	resultArray: {
		schools: [],
		supermarkets: [],
		transports: [],
		medicals: [],
		parks: []
	},

	removeMarker: null,
	visibleIndex: 0,
	isOpen: false,
	isOpenMulti: false,
	lat: '',
	lon: '',
	map: null,
	mapMulti: null,
	highlightclass: '',
	checkedType: []
};
