import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './logoLoader.scss';
import 'react-loading-skeleton/dist/skeleton.css';

export const LogoLoader = () => {
	return (
		<div className='loader-wrapper flex justify__content--center align__items--center'>
			<div className='mask'>
				<Skeleton height='200px' width='200px' containerClassName='avatar-skeleton' />
			</div>
		</div>
	);
};
