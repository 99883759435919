import React from 'react';
import { AuthClassProps } from '../interface/auth';
import { ClosePopup } from 'components/commonComponents/icons';
import 'font-awesome/css/font-awesome.min.css';

const ForgotPasswordEmailPopup = (props: AuthClassProps) => {
	return (
		<div className='login-register-wrapper position-relative'>
			<div className='text--right position-absolute close-btn cursor-pointer' onClick={props.closePopup}>
				<ClosePopup />
			</div>

			<div className='login-div login-register-div forgot-password-email-div '>
				<div className='login-tittle-wrapper '>
					<h3 className='login-register-tittle'>E-Mail wurde versendet</h3>
				</div>
				<div className='forgot-password-tex-wrapper'>
					<p className='text--center'>
						Sollten Sie mit dieser E-Mail-Adresse / Benutzernamen bei uns registriert sein, erhalten Sie von
						uns eine E-Mail. Diese beinhaltet eine Anleitung zum Zurücksetzen Ihres Passworts.
					</p>
				</div>
				<button
					type='submit'
					value='register'
					className='login-button common-button'
					onClick={(e) => {
						window.location.href = 'https://mail.google.com/';
						e.preventDefault();
					}}
					formTarget='_blank'
				>
					Direkt zu mail
				</button>
				<div className='collapse-text-wrapper'>
					<input className='fa fa-angle-down ' aria-hidden='true' type='checkbox' id='faq-1' />
					<h2>
						<label htmlFor='faq-1'>Haben Sie keine E-Mail erhalten?:</label>
					</h2>
					<p>
						Bitte prüfen Sie ob die E-Mail Adresse richtig eingegeben wurde. Zusätzlich sollten Sie auch
						einen Blick in Ihren SPAM-Ordner werfen.
					</p>
				</div>
			</div>
		</div>
	);
};

export default ForgotPasswordEmailPopup;
