import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import InputTextarea from 'components/commonComponents/form/inputTextarea';
import { IFormProps } from '../utils/form.interface';
import InputField from 'components/commonComponents/form/inputText';
import CommonFormBtn from './commonFormBtn';
import Modal from 'components/modal';
import ImprintPopup from './imprintPopup';
type MoreInformationForm = {
	moreInformation: string;
	companyName: string;
	imprint: string;
	taxIdentifier: string;
	privacyPolicy: string;
};

const MoreInformation = (props: IFormProps & { isRealtor: boolean }) => {
	const {
		control,
		formState: { errors }
	} = useFormContext<MoreInformationForm>();
	const { requiredFields, rules, isRealtor } = props;
	const [isOpenImprint, setIsOpenImprint] = useState(false);

	return (
		<>
			{isRealtor && (
				<>
					<Controller
						name='moreInformation'
						control={control}
						rules={rules.lastName}
						render={({ field: { ref, ...rest } }) => (
							<InputField
								asterisk={requiredFields.includes('moreInformation')}
								label='Weitere Angaben '
								error={errors.moreInformation}
								type={'text'}
								{...rest}
							/>
						)}
					/>
					<Controller
						name='companyName'
						control={control}
						rules={rules.companyName}
						render={({ field: { ref, ...rest } }) => (
							<InputField
								asterisk={requiredFields.includes('companyName')}
								label='Firma / Unternehmensbezeichnung'
								error={errors.companyName}
								type={'text'}
								{...rest}
							/>
						)}
					/>
					{/* <CommonFormBtn
						setShowPopup={() => setIsOpenImprint(true)}
						labelText={'Impressum'}
						buttonText={'Hinzufügen / ändern'}
						asterisk={requiredFields.includes('imprint')}
						error={errors.imprint}
					/> */}
					<Controller
						name='imprint'
						control={control}
						rules={rules.imprint}
						render={({ field: { ref, ...rest } }) => (
							<InputField
								asterisk={requiredFields.includes('imprint')}
								label='Impressum'
								error={errors.imprint}
								type={'text'}
								{...rest}
							/>
							// <>
							// 	{isOpenImprint && (
							// 		<Modal closeModal={() => setIsOpenImprint(false)} className={'user-register'}>
							// 			<ImprintPopup
							// 				closePopup={() => setIsOpenImprint(false)}
							// 				asterisk={requiredFields.includes('imprint')}
							// 				error={errors.imprint}
							// 				{...rest}
							// 			/>
							// 		</Modal>
							// 	)}
							// </>
						)}
					/>
					<Controller
						name='privacyPolicy'
						control={control}
						rules={rules.privacyPolicy}
						render={({ field: { ref, ...rest } }) => (
							<InputField
								asterisk={requiredFields.includes('privacyPolicy')}
								label='Link zur Datenschutzerklärung'
								error={errors.privacyPolicy}
								type={'text'}
								{...rest}
							/>
						)}
					/>
					<Controller
						name='taxIdentifier'
						control={control}
						rules={rules.taxIdentifier}
						render={({ field: { ref, ...rest } }) => (
							<InputField
								asterisk={requiredFields.includes('taxIdentifier')}
								label='Umsatzsteuer-ID'
								error={errors.taxIdentifier}
								className={'user-id-input'}
								type={'text'}
								{...rest}
							/>
						)}
					/>
				</>
			)}
			{!isRealtor && (
				<Controller
					name='moreInformation'
					control={control}
					rules={rules.moreInformation}
					render={({ field: { ref, ...rest } }) => (
						<InputTextarea
							label='Weitere Angaben'
							rows={4}
							cols={50}
							asterisk={requiredFields.includes('moreInformation')}
							error={errors.moreInformation}
							{...rest}
						/>
					)}
				/>
			)}
		</>
	);
};

export default MoreInformation;
