import { LoginResponse } from "components/home/auth/interface/auth";
import CryptoJS from "crypto-js";
const KEY: string = "adsfghjkla2312safaaszAS";

/**
 * function to set user authentication data
 */
const setAuthData = (data: LoginResponse): void => {
  const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), KEY);
  localStorage.setItem("token", cipherText.toString());
};

/**
 * function to get user authentication data
 */

const getAuthData = () => {
  try {
    const data = localStorage.token;

    if (data) {
      const bytes = CryptoJS.AES.decrypt(data.toString(), KEY);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      return decryptedData;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

let refs: any = {};

/**
 * getRef - get html element reference
 */
const getRef = (key: string) => {
  if (refs[key]) {
    return refs[key].current;
  }
};

/**
 * setRef - set html element reference
 */
const setRef = (ref: any, key: string) => {
  refs = {
    ...refs,
    [key]: ref,
  };
};

export { getRef, setRef, setAuthData, getAuthData };
