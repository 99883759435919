import React from 'react';
import TitleImageSection from '../components/titleImageSection';
import energyCertificateImage from 'assets/images/energy-certificate-image.png';
import MetaDecorator from 'shared/meta/metaDecorator';
import { energyPageCards } from '../utils/constants';
import energyImage from 'assets/images/energy-image.png';
import energyImage2 from 'assets/images/energy-image-2.png';
import energyImage3 from 'assets/images/energy-image-3.png';

const EnergyCertificate = () => {
	return (
		<div className='energy-certificate-section public-page-font'>
			<MetaDecorator title='Energieausweise - Immoweb | Immobilien, Wohnungen und Häuser bei immoweb' />
			<TitleImageSection backgroundImage={energyCertificateImage} titleText={'Energieausweis'} />
			<section className='section-width information-section energy-bottom-padding-40 text--center'>
				<p>
					Willkommen auf unserer Informationsseite zu Energieausweisen. Hier erfahren Sie alles Wichtige rund
					um das Thema Energieausweise, warum sie notwendig sind und wie sie Ihnen bei der Suche nach einer
					energieeffizienten Immobilie in Deutschland helfen können.
				</p>
			</section>
			<section className='energy-certificate-div background-color-bunting'>
				<div className='section-width energy-bottom-padding-30'>
					<h1>Was ist ein Energieausweis?</h1>
					<p>
						Ein Energieausweis ist ein Dokument, das die Energieeffizienz eines Gebäudes bewertet. Er gibt
						Auskunft über den Energiebedarf oder -verbrauch und bietet damit eine transparente Grundlage, um
						die energetische Qualität einer Immobilie zu beurteilen.
					</p>
				</div>
			</section>
			<section className='text-center bg-gray pb-10'>
				<img className='energy-image' src={energyImage} alt='energyImage' />
			</section>
			<section className='section-width information-section'>
				<h1 className='text--center'>Warum ist ein Energieausweis wichtig?</h1>
			</section>
			<section className='section-width certificate-needed-section market-report-section'>
				<div className='flex justify__content--center certificate-cards'>
					{energyPageCards.map((item, i) => (
						<div className='certificate-needed-card height-unset' key={i}>
							<h1>{item.title}</h1>
							<p>{item.subTitle}</p>
						</div>
					))}
				</div>
			</section>
			<div className='bg-gray padding-top-bottom-40'>
				<section className='section-width information-section'>
					<h1 className='text--center'>Welche Arten von Energieausweisen gibt es?</h1>
				</section>
				<section className='section-width'>
					<div className='certificate-card-wrapper'>
						<div className='flex certificate-needed-card'>
							<div className='card-image'>
								<img src={energyImage2} alt='energyImage2' />
							</div>
							<div className='card-description'>
								<h1>Bedarfsausweis:</h1>
								<p>
									Dieser Ausweis basiert auf einer technischen Analyse des Gebäudes und gibt den
									berechneten Energiebedarf an. Er ist besonders aussagekräftig, da er objektive Daten
									über die energetischen Eigenschaften des Gebäudes nutzt.
								</p>
							</div>
						</div>
						<div className='flex certificate-needed-card m-t-25'>
							<div className='card-image'>
								<img src={energyImage3} alt='energyImage3' />
							</div>
							<div className='card-description'>
								<h1>Verbrauchsausweis</h1>
								<p>
									Dieser Ausweis beruht auf den tatsächlichen Energieverbrauchsdaten der letzten drei
									Jahre. Er gibt einen guten Überblick über die realen Energiekosten, ist jedoch
									stärker von den Gewohnheiten der bisherigen Bewohner abhängig.
								</p>
							</div>
						</div>
					</div>
				</section>
			</div>
			<section className='section-width information-section text--center'>
				<h1>Wie erhalten Sie einen Energieausweis?</h1>
				<p>
					Um einen Energieausweis zu erhalten, müssen Sie einen zertifizierten Energieberater beauftragen.
					Dieser wird entweder den Energiebedarf oder den Energieverbrauch Ihres Gebäudes analysieren und den
					entsprechenden Ausweis ausstellen.
				</p>
				<p className='m-t-25'>
					Ihren Persönlichen Energieausweis können Sie auch einfach und günstig{' '}
					<a href='http://www.deutsche-gutachten.de/energieausweis' target='_blank' rel='noreferrer'>
						hier
					</a>{' '}
					anfordern
				</p>
			</section>
		</div>
	);
};

export default EnergyCertificate;
