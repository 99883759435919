import dashboardGermanText from 'components/finder/language/de';
import mapGermanText from 'components/map/language/de';
import objectDetailGermanText from 'components/objectDetails/language/de';
import englishText from '../../src/languages/english';

const german = {
    ...dashboardGermanText,
    ...objectDetailGermanText,
    ...mapGermanText,

    [englishText.WELCOME_TO]: 'Willkommen bei Immozy',
    [englishText.LOGIN_SUBTITLE]: 'Willkommen zurück! Melde Dich hier mit deinen bestehenden Account-Daten bei IMMOZY an.',
    [englishText.LOGIN]: 'Einloggen',
    [englishText.LOGOUT]: 'Ausloggen',
    [englishText.PASSWORD]: 'Passwort',
    [englishText.NEW_HERE]: 'Neu bei IMMOZY?',
    [englishText.SIGN_UP]: 'Registrierung',
    [englishText.FORGOT_PWD]: 'Passwort vergessen?',
    [englishText.ONBAORDING_MENU]: 'Bedarf',
    [englishText.I_AGREE]: 'Ich stimme Immozy zu ',
    [englishText.TERMS_OF_USE]: 'Nutzungsbedingungen',
    [englishText.PRIVACY_POLICY]: 'Datenschutz',
    [englishText.WEBSITE]: 'Webseite',
    [englishText.FORGOT_PWD_SUBTITLE]: 'Gib deine E-Mail-Adresse ein, um das Passwort zurückzusetzen.',
    [englishText.SEND_EMAIL]: 'E-Mail senden',
    [englishText.BACK_TO_LOGIN]: 'Zurück zur Anmeldung',
    [englishText.UPDATE_PWD]: 'Kennwort aktualisieren',
    [englishText.RESET_YOUR_PWD]: 'Setze dein Passwort zurück',
    [englishText.TOO_WEAK]: 'zu schwach',
    [englishText.WEAK]: 'schwach',
    [englishText.AVERAGE]: 'OK',
    [englishText.GOOD]: 'gut',
    [englishText.STRONG]: 'sicher',
    [englishText.ACTIVATE_SUCCESS_MSG]: 'Dein Konto ist bestätigt - super!',
    [englishText.ACTIVATE_SUBTITLE]:
        'Damit du mit Immozy und unserem innovativen Matching Algorithmus die besten Immobilien findest, haben wir ein kurzes Onboarding für dich vorbereitet.',
    [englishText.FIND_PROPS_NOW]: 'Jetzt Immobilien finden!',
    [englishText.TERMS_ERROR]: 'Bitte akzeptiere die Allgemeinen Geschäftsbedingungen',
    [englishText.EXISTING_ACC_ERROR]: 'Diese E-Mail Adresse wird schon verwendet.',
    [englishText.EXISTING_USER_ERROR]: 'Der Nutzername existiert bereits',
    [englishText.EMAIL_SENT_SUCCESS]: 'E-Mail wurde an dich versandt.',
    [englishText.EMAIL_ERROR]: 'Bitte gebe deine E-Mail-Adresse ein',

    //dashboard
    [englishText.WISHLIST]: 'Wunschliste',
    [englishText.SEARCH]: 'Suche',
    [englishText.FIND]: 'Finden',
    [englishText.PREPARE_MENU]: 'Vorbereitung',
    [englishText.BUY]: 'KAUFEN',
    [englishText.LIVE]: 'LEBEN',
    [englishText.LIST]: 'Liste',
    [englishText.MAP]: 'Karte',
    [englishText.INSTRUCTIONS]: 'Anleitung',

    //search results
    [englishText.YOUR_FAVORITES]: 'Deine Favoriten',
    [englishText.RESET_FILTER]: 'Filter zurücksetzen',
    [englishText.PRICE]: 'Preis',
    [englishText.SPACE]: 'Wohnfläche',
    [englishText.SPACE_SMALL]: 'Fläche',
    [englishText.ROOMS]: 'Räume',
    [englishText.ROOMS_OP]: 'Zimmer',
    [englishText.ROOM]: 'Zimmer',
    [englishText.MORE_FILTERS]: 'Mehr Filter',
    [englishText.AREA]: 'Fläche',
    [englishText.YEAR_BUILT]: 'Baujahr',
    [englishText.LOCATION]: 'Ort',
    [englishText.LOCATION_1]: 'Ort / Bundesland',
    [englishText.FINANCIAL]: 'Finanzen',
    [englishText.PROPERTY]: 'Objekt',
    [englishText.RESULT_FOUND]: 'Ergebnis gefunden',
    [englishText.RESULTS_FOUND]: 'Ergebnisse gefunden',
    [englishText.WITH_TOTAL_RATING_OF]: 'mit einem Gesamtwertung von',
    [englishText.OR_MORE]: 'oder mehr',
    [englishText.PROPERTIES]: 'Objekte',
    [englishText.RESULT_FOUND_IN_HAMBURG]: 'Ergebnis in',
    [englishText.RESULTS_FOUND_IN_HAMBURG]: 'Ergebnisse in',
    [englishText.TOTAL_SCORE]: 'Gesamtwert',
    [englishText.LOCATION_SCORE]: 'Wertung Lage',
    [englishText.PROPERTY_SCORE]: 'Wertung Immobilie',
    [englishText.FINANCIAL_SCORE]: 'Wertung Finanzen',
    [englishText.DETACHED_HOUSE]: 'Freistehendes Haus',
    [englishText.SEMI_DETECHED_HOUSE]: 'Doppelhaushälfte',
    [englishText.BUNGLOW]: 'Bungalow',
    [englishText.MID_TERRACE_HOUSE]: 'Mittleres Reihenhaus',
    [englishText.VILLA]: 'Villa',
    [englishText.PENTHOUSE]: 'Penthouse',
    [englishText.RES_COMPLEX]: 'Wohnkomplex',
    [englishText.NEW_CONSTRUCTION]: 'Neu, geplant oder im Bau',
    [englishText.PARKING]: 'Parkplatz / Garage',
    [englishText.PARKING_SPACE]: 'Parkplatz',
    [englishText.GARDEN_BALCONY]: 'Garten / Balkon',
    [englishText.NO_COMMISSION]: 'Keine Maklercourtage',
    [englishText.STANDARD_SORTING]: 'Neu',
    [englishText.PRICE_PER_METER]: 'Preis pro m²',
    [englishText.YEAR_OF_CONSTRUCTION]: 'Baujahr',
    [englishText.INTERNET_SPEED]: 'Internetgeschwindigkeit',
    [englishText.NO_MATTER]: 'Egal',
    [englishText.INTERNET_ACCESS]: 'Internetanschluss',
    [englishText.MB_MIN]: 'Mindestens 50 MBit/s',
    [englishText.APPLY]: 'Anwenden',
    [englishText.YYYY]: 'JJJJ', //As in placaholder for year,
    [englishText.PLOT]: 'Grundstück',
    [englishText.ALL]: 'Alle',
    [englishText.OTHER_FEATURES]: 'Sonstige Merkmale',
    [englishText.SEARCH_BY_RADIUS]: 'Umkreissuche',
    [englishText.SEARCH_BY_ZIP]: 'Detaillierter Standort / PLZ',
    [englishText.SAERCH_LOCATION]: 'Standortsuche',
    [englishText.HIDE_FORECLOSURE]: 'Keine Zwangsversteigerungen',

    // MAP
    [englishText.MULTIPLE_PROPERTIES]: 'Mehrere Eigenschaften',
    [englishText.SCHOOLS]: 'Schulen',
    [englishText.TRANSPORTS]: 'Nahverkehr',
    [englishText.MEDICLES]: 'Ärzte',
    [englishText.PARKS]: 'Grünanlagen',
    [englishText.SUPERMARKETS]: 'Supermarkt',
    [englishText.NOT_AVAILABLE]: 'Nicht verfügbar auf der Karte',

    //On-boarding
    [englishText.HEY]: 'Hallo',
    [englishText.RELATED_ARTICLES]: 'Verwandte Themen',
    [englishText.SAVE_AND_PROCEED]: 'Speichern und fortfahren',
    [englishText.BACK]: 'Zurück',
    [englishText.THANKS_MSG]: 'Danke! Jetzt haben wir ein viel besseres Bild von dem, was du suchst.',
    [englishText.YOUR_SEARCH_PROFILE]: 'Dein Suchprofil',
    [englishText.GO_TO_SEARCH]: 'Zur Suche',
    [englishText.COME_ON]: 'LOS!',
    [englishText.TO_THE_TOP]: 'Zum Seitenanfang',
    [englishText.FILL_ANS_ERROR]: 'Bitte beantworte die Frage, um fortzufahren.',
    [englishText.EQUITY_LIMIT_ERROR]: 'Bitte gebe eine Zahl zwischen 0 und 1 Million ein',
    [englishText.EMI_LIMIT_ERROR]: 'Bitte gebe eine Zahl zwischen 0 und 10.000 ein',
    [englishText.OTHER_PLACAHOLDER]: 'Falls keine Antwort passt, teile uns hier bitte deine Gedanken mit.',

    //On-boarding Summary
    [englishText.PROPERTY_SPECIFIACTION]: 'Eigenheim Spezifikationen',
    [englishText.AREA_TYPE]: 'Wohnlage',
    [englishText.PROPERTY_TYPE]: 'Gebäude Typ',
    [englishText.LIVING_SPACE]: 'Wohnfläche',
    [englishText.REQUIREMENTS]: 'Anforderungen',
    [englishText.EQUITY]: 'Eigenkapital',
    [englishText.PRICE_BUDGET]: 'Finanzieller Rahmen (empfohlen)',
    [englishText.MONTHLY_COST]: 'Monatliche Kosten',
    [englishText.GOOD_INTERNET_CONNECTION]: 'Gute Internetverbindung',
    [englishText.GOOD_NEIGHBOURHOOD]: 'Nette Nachbarschaft',
    [englishText.TRAFFIC_CONNECTION]: 'Verkehrsanbindung',
    [englishText.SCHOOL_CHILDCARE]: 'Schulen und Kinderbetreuung',
    [englishText.MARKET_CULTUTE]: 'Supermärkte & kulturelle Angebote',
    [englishText.PARKS_GREEN_SPACES]: 'Parks und Grünflächen',
    [englishText.FAMILY_FRIENDLY]: 'Familienfreundlich',
    [englishText.FOR_INVESTMENT]: 'Als Geldanlage',
    [englishText.FREEDOM_LUXURY]: 'Freiheit und Luxus',
    [englishText.PENSION_SECURITY]: 'Altersvorsorge',
    [englishText.OTHER]: 'Sonstiges',

    //Profile
    [englishText.ENGLISH]: 'Englisch',
    [englishText.GERMAN]: 'Deutsch',
    [englishText.USER_NAME]: 'Nutzername',
    [englishText.EMAIL]: 'E-Mail-Adresse',
    [englishText.EMAIL_1]: 'E-Mail-Adresse*',
    [englishText.NAME]: 'Name',
    [englishText.CHANGE_PASSWORD]: 'Passwort ändern',
    [englishText.SAVE_PASSWORD]: 'PASSWORT SPEICHERN',
    [englishText.NEW_PASSWORD]: 'Neues Kennwort',
    [englishText.CONFIRM_PASSWORD]: 'Passwort wiederholen',
    [englishText.OLD_PASSWORD]: 'Altes Passwort',
    [englishText.SAVE]: 'Speichern',
    [englishText.EDIT_PROFILE]: 'Profil bearbeiten',
    [englishText.ABOUT]: 'Über',
    [englishText.ONBOARDING_SUMMARY]: 'Mein Suchprofil',
    [englishText.LANG_SETTINGS]: 'Spracheinstellungen',
    [englishText.PROFILE_SUCCESS_MSG]: 'Aktualisierung erfolgreich',
    [englishText.PWD_CHANGED_SUCCESS_ALERT]: 'Passwortänderung erfolgreich. Bitte melde Dich nun mit deinem neuen Passwort an.',
    [englishText.FIELD_FILL_ERROR]: 'Bitte alle Pflichtfelder ausfüllen',
    [englishText.FIRST_LAST_ERROR]: 'Bitte Vor- und Nachnamen angeben',
    [englishText.FIRST_ERROR]: 'Bitte trage dein Vorname ein',
    [englishText.LAST_ERROR]: 'Bitte trage deinen Nachnamen ein',
    [englishText.MAIL_ERROR]: 'Bitte trage deinen EMail ein',
    [englishText.IMAGE_SIZE_ERROR]: 'Bitte wählen Sie einen Anhang mit weniger als 512 KB',
    [englishText.IMAGE_TYPE_ERROR]: 'Nur die Formate JPG, JPEG und PNG sind erlaubt',
    [englishText.NOT_ADDED_YET]: 'Noch nicht hinzugefügt',
    [englishText.FIRST_NAME]: 'Vorname',
    [englishText.LAST_NAME]: 'Nachname',
    [englishText.BIRTH_DATE]: 'Geburtsdatum',
    [englishText.POSTAL_CODE]: 'PLZ',
    [englishText.EMPLOYMENT]: 'Arbeitsverhältnis',
    [englishText.SELECT_PHOTO]: 'Foto auswählen',
    [englishText.CHANGE_PHOTO]: 'Foto ändern',

    // Object Details
    [englishText.PROPERTY_DETAILS]: 'Objekt Details',
    [englishText.FINANCIAL_DETAILS]: 'Finanzielle Details',
    [englishText.TOTAL_MATCH_SCORE]: 'Gesamtwertung',
    [englishText.TOAL_SCORE_SUBTITLE]: 'Der Gesamtwert zeigt Dir wie gut diese Immobilie zu deinen persönlichen Anforderungen passt.',
    [englishText.TOTAL_PRICE]: 'Gesamtkaufpreis',
    [englishText.MONTHLY_COST_EST]: 'Monatliche Kosten (voraus.)',
    [englishText.PLOT_AREA]: 'Grundstücksfläche',
    [englishText.FLOORS]: 'Etagen',
    [englishText.CONDITION]: 'Gebäude-Zustand', //as in condition of house
    [englishText.PRICE_TREND]: 'Preistrend 10 Jahre',
    [englishText.VACANT_FROM]: 'Verfügbar ab',
    [englishText.ENERGY_EFF]: 'Energieeffizient',
    [englishText.LOCATION_SCORE_SUBTITLE]: 'Wohngebiet, Anbindung, Mikro- und Makrostandortanalyse',
    [englishText.PROPERTY_SCORE_SUBTITLE]: 'Wohnqualität, Platz, Zustand und Art. Basierend auf individuellen Anforderungen',
    [englishText.FINANCIAL_SCORE_SUBTITLE]: 'Finanzieller Rahmen, Preisbewertung und Entwicklung, Marktindikatoren',
    [englishText.LOCATION_INFO]: 'Wohnlage / Karte',
    [englishText.NOTICES]: 'Notizen',
    [englishText.SELLER_INFO]: 'Verkäufer Informationen',
    [englishText.SAVE_NOTICE]: 'Notiz speichern',
    [englishText.COURTAGE_FOR_BUYER]: 'Käuferprovision',
    [englishText.YES]: 'Ja',
    [englishText.NO]: 'Nein',
    [englishText.YES_1]: 'Ja',
    [englishText.NO_1]: 'Nein',
    [englishText.PLATFORM]: 'Plattform',
    [englishText.CALL_UP_AD]: 'Zum Angebot',
    [englishText.ONLINE_SINCE]: 'Online seit',
    [englishText.REMOVE_FAVO]: 'Aus der Wunschliste entfenern',
    [englishText.REMOVE_FAVO_CONFIRM]: 'Dieses Objekt wirklich aus der Wunhscliste entfernen?',
    [englishText.FAVORITES]: 'Favoriten',
    [englishText.OBJ_DESC]: 'Objektbeschreibung',
    [englishText.ENERGY_CERTIFICATE]: 'Energiezertifikat',
    [englishText.ENERGY_CLASS]: 'Energieeffizienzklasse',
    [englishText.UNDER_CONSTRUCTION]: 'Im Bau',
    [englishText.OPEN_LINK_IN_NEW]: 'Link im neuen Tab öffnen',
    [englishText.EMPTY_NOTICE_ERROR]: 'Bitte Text einfügen',
    [englishText.NOTICE_SUCCESS_MSG]: 'Notiz erfolgreich gespeichert',
    [englishText.FLOORPLAN]: 'Grundriss',

    //Misc
    [englishText.SWITCH_ENGLISH]: 'Zu Englisch wechseln',
    [englishText.SWITCH_GERMAN]: 'Zu Deutsch wechseln',
    [englishText.CALC_FINISH_MSG]: 'Bitte warte bis die Berechnung abgeschlossen ist',
    [englishText.CALCULATING]: 'berechne',
    [englishText.YOUR_MAIL_ADR]: 'Deine E-Mail Adresse',
    [englishText.MIN_VALUES]: 'Mindestwerte',
    [englishText.SET_LIMITS_TITLE]: 'Gebe deine Mindestwerte an um das Ergebnis zu verfeinern',
    [englishText.PROPERTY_MEET_CRITERIA]: 'Immobilien, die deinen Kriterien entsprechen',
    [englishText.SEND_NOTIF_FOR]: 'Schicke Benachrichten für',
    [englishText.NOTIF_NEW_TOP_MATCHES]: 'Neu hinzugefügte Top-Ergebnisse',
    [englishText.NOTIF_SUBTITLE__NEW_TOP_MATCHES]: 'Werde sofort benachrichtigt bei neuen Top-Ergebnissen',
    [englishText.NOTIF_DAILY_SUMMARY]: 'Tägliche Zusammenfassung',
    [englishText.NOTIF_SUBTITLE_DAILY_SUMMARY]: 'Neue Immobilien und Updates',
    [englishText.NOTIF_WEEKLY_REPORTS]: 'Wöchentliche Berichte',
    [englishText.NOTIF_SUBTITLE_WEEKLY_REPORTS]: 'Dein wöchentlicher und personalisierter Marktbericht',
    [englishText.MY_SEARCH_PROFILE]: 'Mein Suchprofil',
    [englishText.GOOD_CONNECTION_TO]: 'Gute Anbindung an',
    [englishText.AT_LEAST]: 'Ab',
    [englishText.MAX_PROPERTY_VALUE]: 'Maximaler Kaufpreis',
    [englishText.MONTHLY_PAYMENT]: 'Monatsbudget',
    [englishText.SAVING_EQUITY]: 'Rücklagen / Eigenkapital',
    [englishText.LOADING_MSG]: 'Suche nach Eigenheim läuft',
    [englishText.PWD_NOT_MATCH]: 'Die Passwörter stimmen nicht überein. Bitte überprüfe beide Passwörter.',
    [englishText.INCORRECT_OLD_PWD]: 'Du hast ein falsches altes Passwort hinzugefügt',
    [englishText.NOTIFICATION]: 'Benachrichtigung',
    [englishText.JUST_NOW]: 'Gerade jetzt',
    [englishText.SOMETHING_WENT_WRONG]: 'Etwas lief schief',
    [englishText.CANCEL]: 'Stornieren',
    [englishText.NO_SEARCH_RESULT_FOUND]: 'Für deine Kriterien wurden keine Ergebnisse gefunden. Gehe zur Suche oder passe dein Suchprofil an.',
    [englishText.NO_RESULT_FOUND_TRY_OTHER]: 'Keine Ergebnisse gefunden. Bitte passe die Filter an.',
    [englishText.NEXT]: 'Weiter',
    [englishText.PREV]: 'Zurück',
    [englishText.PREV_STEP]: 'Zurück',
    [englishText.SUB_SCORE]: 'Unterwerte',
    [englishText.MIN_SCORE]: 'Mindestwert',
    [englishText.NOTIF_COMING_SOON]: 'Benachrichtigung Kommen demnächst',
    [englishText.PROPERTIES_DISPLAYED]: 'Die angezeigten Immobilien und die Bewertungen',
    [englishText.IS_BASED_ON]: 'basieren auf dein',
    [englishText.PERSONAL_SEARCH_PROFILE]: 'persönliches Suchprofil.',
    [englishText.PROFILE]: 'Profil',
    [englishText.NO_FAVO_MSG]: 'Deine Wunschliste ist noch leer.',
    [englishText.ERROR_CONTACT_ADMIN]: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut oder kontaktiere den Administrator.',

    // house data
    [englishText.WELL_KEPT]: 'Gut erhalten',
    [englishText.MODERNIZED]: 'Modernisiert',
    [englishText.MINT]: 'Neuwertig',
    [englishText.FULLY_RENOVATED]: 'Komplett renoviert',
    [englishText.FIRST_TIME_USE]: 'Erstnutzung',
    [englishText.REFURBISHED]: 'Aufbereitet',
    [englishText.FIRST_AFTER_REFURB]: 'Erstnutzung nach Aufbereitung',
    [englishText.NAGOTIABLE]: 'Verhandelbar',
    [englishText.NO_INFO]: 'Ohne Angabe',
    [englishText.NEED_OF_RENOVATION]: 'Renovierungsbedürftig',
    [englishText.DEMOLITION]: 'Abbruchreif',
    [englishText.DOWNTOWN]: 'Stadtzentrum',
    [englishText.SUBURBAN]: 'Stadtrand',
    [englishText.DOWNTOWN_1]: 'Stadtzentrum',
    [englishText.SUBURBAN_1]: 'Stadtrand',
    [englishText.FLAT]: 'Wohnung',
    [englishText.ATTIC_FLOOR]: 'Dachgeschoss',
    [englishText.GROUND_FLOOR]: 'Erdgeschosswohnung',
    [englishText.MAISON]: 'Maisonette-Wohnung',
    [englishText.RAISED_FLOOR]: 'Hochparterre',
    [englishText.TERRACE_APT]: 'Wohnung mit Terasse',
    [englishText.BASEMENT]: 'Souterrain',
    [englishText.LOFT]: 'Loft',
    [englishText.OTHER_1]: 'Sonstiges',
    [englishText.SEMI_DETECTHED]: 'Doppelhaushälfte',
    [englishText.DETACHED_HOUSE_2]: 'Freistehendes Haus',
    [englishText.END_HOUSE]: 'End-Reihenhaus',
    [englishText.BUNGLOW_1]: 'Bungalow',
    [englishText.SPCL_PROPERTY]: 'Sondereigentum',
    [englishText.APT_BLOCK]: 'Mehrfamilienhaus',
    [englishText.MID_TERRACE_HOUSE_1]: 'Mittleres Reihenhaus',
    [englishText.FREE_HOLD_FLAT]: 'Eigentumswohnung',
    [englishText.RES_AND_OFFICE]: 'Wohn- und Bürogebäude',
    [englishText.HALL_STORAGE]: 'Hallenlager',
    [englishText.MISC]: 'Sonstiges',
    [englishText.COM_UNIT]: 'Gewerbliche Einheit',
    [englishText.RESIDEN_COMPLEX]: 'Wohnkomplex',
    [englishText.SHOPSALE]: 'Laden/Verkaufsbereich',
    [englishText.COM_PROPERTY]: 'Gewerbeimmobilie',
    [englishText.SPECIAL_PROPERTY]: 'Besondere Immobilie',
    [englishText.APART_BLOCK]: 'Mehrfamilienhaus',

    //new
    [englishText.LOGIN_AGAIN_ERROR]: 'Ihre Anmeldesitzung ist abgelaufen. Bitte melden Sie sich erneut an',
    [englishText.DOUBLE_TOWNHOUSE]: 'Doppel- oder Reihenhaus',
    [englishText.APAARTMENT]: 'Eigentumswohnung',
    [englishText.DETACHED_HOUSE_1]: 'Freistehendes Haus',
    [englishText.OBJECT_DISABLED_ERROR]: 'Leider ist diese Immobilie schon vergeben!',
    [englishText.MY_PROPERTIES]: 'My properties',
    [englishText.ADD_NEW_PROPERTY]: 'Add new property',

    // custom object
    [englishText.MY_PROPERTIES]: 'Meine Immobilien',
    [englishText.ADD_NEW_PROPERTY]: 'Immobilie hinzufügen',
    [englishText.NEW_PROP_HEADER]:
        'Du kannst weitere Immobilien anlegen um diese über Immozy zu überprüfen oder zu kaufen. Denn nicht jede Immobilie ist online. Diese Immobilien sind nur für Dich sichtbar.',
    [englishText.TITLE]: 'Name',
    [englishText.ACTION]: 'Aktion',
    [englishText.SEE_DETAILS]: 'Mehr Infos',
    [englishText.EDIT_PROPERTY]: 'Ändern',
    [englishText.DELETE_PROPERTY]: 'Löschen',
    [englishText.QUARTER]: 'Stadtgebiet',
    [englishText.CITY]: 'Stadt',
    [englishText.CITY_2]: 'Stadt',
    [englishText.EXPOSE]: 'Exposé',
    [englishText.PROP_SUB_TYPE]: 'Bestimmter Immobilientyp',
    [englishText.FLOOR_OF_BUILDING]: 'Etagen im Gebäude',
    [englishText.FLOOR_S]: 'Etagen',
    [englishText.FEATURES]: 'Austattung',
    [englishText.INT_SPEED]: 'Internetgeschwindigkeit (Mbps)',
    [englishText.HEATING]: 'Heizung',
    [englishText.PICTURES]: 'Bilder',
    [englishText.CLEAR]: 'Leeren',
    [englishText.REMOVE_ALL]: 'Alles entfernen',
    [englishText.IMG_ERROR_10]: 'Nur 10 Bilder erlaubt',
    [englishText.IMAGE_SIZE_ERR]: 'Bitte wählen Sie einen Anhang mit weniger als 512 KB',
    [englishText.AGENT_COM]: 'Maklercourtage',
    [englishText.ADD_FEES]: 'Kaufnebenkosten',
    [englishText.PURCHASE_STATUS]: 'Status Verhandlung',
    [englishText.GENERAL_INFO]: 'Allgemeine Informationen',
    [englishText.FINANCE]: 'Finanzen',
    [englishText.NAME_OF_OBJECT]: 'Name der Immobilie',
    [englishText.DESC_OF_PROPERTY]: 'Beschreibung',
    [englishText.IS_FOR_SALE]: 'Steht die Immobilie zum Verkauf?',
    [englishText.IS_RES]: 'Handelt es sich um eine Wohnimmobilie?',
    [englishText.COUNTRY]: 'Land',
    [englishText.ZIP_CODE]: 'Postleitzahl',
    [englishText.STREET]: 'Straße',
    [englishText.STREET_HOUSE]: 'Straße und Hausnummer',
    [englishText.STREET_NO]: 'Hausnr',
    [englishText.LIVING_AREA]: 'Wohnfläche (m2)',
    [englishText.PLOT_AREA_m2]: 'Gründstücksfläche (m2)',
    [englishText.AMOUNT_OF_ROOMS]: 'Anzahl Räume',
    [englishText.AMOUNT_OF_SLEEPROOMS]: 'Anzahl Schlafzimmer',
    [englishText.AMOUNT_OF_BATHROOMS]: 'Anzahl Badezimmer',
    [englishText.FURNISHED]: 'Möbliert',
    [englishText.NEW_WIN]: 'Neue Fenster',
    [englishText.AC]: 'Klimaanlage',
    [englishText.HEATING_SYSTEM]: 'Zentralheizung',
    [englishText.FIERPLACE]: 'Ofen',
    [englishText.GARAGE]: 'Garage',
    [englishText.DOUBLE_GARAGE]: 'Doppelgarage',
    [englishText.PARKING_LOT]: 'Parkplatz',
    [englishText.UND_GARAGE]: 'Tiefgarage',
    [englishText.GAS]: 'Gas',
    [englishText.GEO_THERMAL]: 'Fernwärme',
    [englishText.PHOTOVOLIC]: 'Photovoltaik',
    [englishText.NOT_STARTED]: 'Nicht gestartet',
    [englishText.ONGOING]: 'Offen',
    [englishText.COMPLETED]: 'Abgeschlossen',
    [englishText.ADDRESS]: 'Adresse',
    [englishText.PHONE]: 'Telefonnummer',
    [englishText.PHONE_2]: 'Telefon',
    [englishText.BALCONY]: 'Balkon / Terrasse',
    [englishText.GARDEN]: 'Garten',
    [englishText.GUEST_TOILET]: 'Gästetoilette',
    [englishText.SAVE_AND_CONT]: 'Speichern und weiter',
    [englishText.SAVE_ON_COM]: 'SPEICHERN',

    [englishText.ABORT]: 'Abbrechen',
    [englishText.CANCEL_CONFIRM]: 'Möchtest Du wirklich abbrechen?',
    [englishText.FILL_ERROR_OBJECT]: 'Bitte alle Felder ausfüllen um fortzufahren.',
    [englishText.OK]: 'OK',
    [englishText.PROPERTY_UPDATED]: 'Deine Immobilie wurde erfolgreich aktualisiert',
    [englishText.PROPERTY_SUCCESSS]: 'Erfolgreich. Deine Immobilie wurde hinzugefügt.',
    [englishText.HOUSE]: 'Haus',
    [englishText.ADD_TO_FAVO]: 'Zu den Favoriten hinzufügen',
    [englishText.APAART]: 'Wohnung',
    [englishText.IMG_INFO_UPLOAD]: 'Sie können bis zu 10 Dateien hochladen (jeweils 512 KB). Format: .png, .jpg, .jpeg',
    [englishText.SUBMIT_IMG]: 'Bilder einreichen',
    [englishText.IMG_SUCCESS]: 'Hochgeladen !',
    [englishText.IMG_SUBMIT_ERR]: 'Bitte klicken Sie auf Senden, um Ihre Bilder zu speichern.',
    [englishText.PDF_ERR]: 'Nur PDF-Dateien erlaubt',
    [englishText.PLEASE_SELECT]: 'Bitte auswählen...',
    [englishText.NO_INTERNET]: 'Kein Internet',
    [englishText.EMPTY_ERR]: 'Bitte füllen Sie dieses Feld aus',
    [englishText.NO_DATA]: 'Keine Ergebnisse gefunden',
    [englishText.SEARCH_LOCATION]: 'Suche Ort',
    [englishText.LOCATION_TOOLTIP]: 'Mit dieser Suche können Sie die ort automatisch füllen',
    [englishText.LOCATION_PLCAEHOLDER]: 'Suche nach Viertel, Postleitzahl oder Bezirk',
    [englishText.SEND_MAIL_CONFIRM]:
        'Um die Benachrichtigungen verwenden zu können, müssen Sie Ihre E-Mail-Adresse überprüfen. Bestätigungs-E-Mail jetzt senden?',
    [englishText.CANT_SEND]: 'Verifizierungsnachricht kann nicht gesendet werden',

    [englishText.CHAR_LIMIT]: 'Der Name muss kürzer oder gleich 24 Zeichen sein.',

    // new onboarding
    [englishText.WHY_LINK]: 'Warum stellt IMMOZY mir diese Frage?',
    [englishText.PROGRESS]: 'FORTSCHRITT',
    [englishText.GENERAL]: 'Allgemein',
    [englishText.RES_AREA]: 'Wohnlage',
    [englishText.PROP]: 'Immobilien',
    [englishText.FINAN]: 'Finanzen',
    [englishText.IMMOZY_EXP]: 'IMMOZY-Matches',
    [englishText.CONTINUE]: 'Weiter',
    [englishText.SKIP]: 'Fragen überspringen',
    [englishText.SKIP_1]: 'ÜBERSPRINGEN',

    [englishText.PARA_1]: 'damit deine wichtigste',
    [englishText.PARA_2]: 'Entscheidung auch die richtige ist!',
    [englishText.PARA_3]:
        'Die vollständige Beantwortung der Fragen bringt dir die Vorteile die du brauchst, um die Immobilie schnell und sicher zu finden, die am besten zu dir passt.',
    [englishText.PARA_4]: 'Ausserdem findest du jede Menge nützlicher Hinweise, die dir helfen werden, eine gute Entscheidung treffen zu können. ',
    [englishText.PARA_5]: 'Du kannst die Fragen aber auch jetzt überspringen und sie später beantworten.',

    //NEW SIGNUP
    [englishText.GREAT]: 'Klasse! Du hast es geschafft!',
    [englishText.ONE_MORE_STEP]: 'Nur noch ein Schritt bis zu deiner Traumimmobilie!',
    [englishText.YOUR_DATA]: 'DEINE KONTAKTDATEN',
    [englishText.HERE_WE_GO]: 'Los geht‘s!',
    [englishText.FIN]: 'FINANZEN',
    [englishText.SPEC]: 'SPEZIFIKATIONEN',
    [englishText.START_NOW]: 'Jetzt starten!',
    [englishText.BASED_ON_INFO]: 'Wir haben auf Basis Deiner Angaben',
    [englishText.WE_HAVE_PROPS]: 'passende Immobilien gefunden. Registriere Dich jetzt und siehe Dir Deine Top-Matches an.',
    [englishText.REQUEST_SHOW]: 'BESICHTIGUNG VEREINBAREN',
    [englishText.REQUEST_SHOW_MSG]:
        'Vielen Dank für dein Interesse! Wir werden uns zeitnah per E-Mail bei dir melden um einen Termin zu vereinbaren.',
    [englishText.REQUEST_DOCUMENTS]: 'UNTERLAGEN ANFORDERN',
    [englishText.ASK_QUESTION]: 'FRAGE STELLEN',
    [englishText.OFFER_PUBLISHED]: 'Angebot veröffentlicht auf:',
    [englishText.SIZE]: 'Fläche',
    [englishText.FIND_PROP]: 'Immobilie finden',
    [englishText.CHECK_PROP]: 'Immobilie prüfen',
    [englishText.BUY_PROP]: 'Immobilie kaufen',

    //TODAY
    [englishText.EXAMPLE_EMAIL]: 'beispiel@adresse.de',
    [englishText.SAVE_SEARCH]: 'Suche Speichern',
    [englishText.SAVED_SEARCH]: 'Suche Gespeichert',
    [englishText.EMAIL_NOTIF_FOROBJ]: 'Benachrichtigung per E-Mail falls neue, für die Suche passende Objekte auf den Markt kommen.',
    [englishText.MY_SEARCHLIST]: 'Gespeicherte Suchen',
    [englishText.VERIFY_YOUR_EMAIL]: 'Bestätigung deiner E-Mail-Adresse',
    [englishText.VERIFY_MAIL_MSG]:
        'Bitte überprüfe dein E-Mail Postfach. Du hast eine E-Mail von IMMOZY erhalten, mithilfe der du dein Profil bei uns bestätigen kannst. Sobald du dies getan hast, stehen dir alle Funktionen von IMMOZY frei zur Verfügung!',
    [englishText.RESEND_MAIL]: 'E-Mail erneut senden',
    [englishText.OP_1]: 'Suchen abspeichern',
    [englishText.OP_2]: 'Benachrichtigungen über interessante Immobilien abonnieren',
    [englishText.OP_3]: 'Direkter und kostenloser Kontakt zu deinem persönlichen IMMOZY Berater',
    [englishText.MATCH_FILTER]: 'Score',
    [englishText.PRICE_FILTER]: 'Kaufpreis',
    [englishText.RESET_FILTERS]: 'Alle Filter zurücksetzen',
    [englishText.RESET_FILTER_TEXT]: 'Möchtest du alle Filter Einstellungen zurücksetzen?',
    [englishText.RESET_ALL]: 'Zurücksetzen',
    [englishText.TOP_MATCHES]: 'Top Objekte',
    [englishText.MATCHING_SCORE]: 'Matching Score',
    [englishText.SCORE_MIN]: 'Min. Score',
    [englishText.SCORE_MAX]: 'Max. Score',
    [englishText.RESULTS]: 'Objekte',
    [englishText.SWITCH_OFF_FILTER]: 'Filter ausschalten',
    [englishText.ADVANCE_OPTION]: 'Nach Score filtern',
    [englishText.MATCH_FINDER]: 'Mein IMMOZY',
    [englishText.MIN_LEFT_5]: 'Noch 3-5 Minuten',
    [englishText.MIN_LEFT_3]: 'Noch 2-3 Minuten',
    [englishText.MIN_LEFT_2]: 'Noch 1-2 Minuten',
    [englishText.MIN_LEFT_1]: '< 1 Minute noch',

    [englishText.OUT_OF]: 'von',
    [englishText.PAGE_RESULTS]: 'Objekten',
    [englishText.ABOUT_US]: 'ÜBER UNS',
    [englishText.ABOUT_US_2]: 'Über Uns',
    [englishText.WHO]: 'Wer wir sind',
    [englishText.HOW]: 'Wie IMMOZY funktioniert',
    [englishText.FAQ]: 'FAQ',
    [englishText.CAREERS]: 'Karriere',
    [englishText.DESTINATIONS]: 'Orte',
    [englishText.LEGAL]: 'Rechtliches',
    [englishText.TERMS]: 'Nutzungsbedingungen',
    [englishText.IMPRINT]: 'Impressum',
    [englishText.NEW]: 'Neu! ',
    [englishText.SHOW_ALL]: 'Alle Anzeigen',
    [englishText.SHOW_ON_MAP]: 'Auf Karte anzeigen',
    [englishText.I_AM_INTERESTED]: 'Ich bin interessiert!',
    [englishText.SCORE_BASED]: 'Die Wertung basiert auf deinem persönlichen ',
    [englishText.FACTS]: 'Daten',
    [englishText.FINANCES]: 'Finanzen',
    [englishText.DETAILS]: 'Details',
    [englishText.FURNISHING]: 'Möbliert',
    [englishText.BEDROOMS]: 'Schlafzimmer',
    [englishText.BATHROOMS]: 'Badezimmer',
    [englishText.CONSTRUCTION_PHASE]: 'Konstruktionsphase',
    [englishText.CELLER]: 'Keller',
    [englishText.PURCHASE_PRICE]: 'Kaufpreis',
    [englishText.PURCHASE_PRICE_M]: 'Preis pro m²',
    [englishText.ADDITIONAL_COST]: 'Zzgl. Kaufkosten',
    [englishText.TOTAL_COSTS]: 'Gesamtkosten',
    [englishText.KITCHEN_FITTED]: 'Einbauküche',
    [englishText.LOCATION_INFORMATION]: 'Umgebung',
    [englishText.POPULATION]: 'Einwohner',
    [englishText.LOCAL_SUPPLY]: 'Versorgung',
    [englishText.SHOPPING]: 'Einkaufsmöglichkeit',
    [englishText.PRIMARY_SCHOOL]: 'Grundschule',
    [englishText.UNIVERSITY]: 'Universität',
    [englishText.PARKS]: 'Grünanlagen',
    [englishText.PUBLIC_TRANSPORT]: 'Öffentl. Nahverkehr',
    [englishText.HELP_TEXT_1]: 'Interessiert dich diese Immobilie? Wir würden dir gerne weiterhelfen und mit Rat und Tat bei Seite stehen.',
    [englishText.HELP_TEXT_2]: 'Lass uns jetzt kostenlos und ganz unverbindlich dich unterstützen!',
    [englishText.NO_EXTRA]: 'Nicht vorhanden',
    [englishText.WELCOME]: 'WILLKOMMEN!',
    [englishText.MY_PROFILE]: 'Mein Profil',
    [englishText.EMAIL_NOT_VERIFIED]: 'Du hast deine E-Mail-Adresse noch nicht bestätigt!',
    [englishText.RESEND_MAIL_MSG]: 'Klicke hier damit wir dir erneut eine Bestätigungsmail senden?',
    [englishText.VERIFY_MAIL_WARNING]:
        'Bitte überprüfe dein E-Mail Postfach und bestätige deine E-Mail Adresse damit wir wissen wie wir dich erreichen können!',
    [englishText.SAVE_PROFILE_ERROR]: 'Leider ist uns ein Fehler unterlaufen. Bitte versuche es später noch einmal. ',
    [englishText.SAVE_MAIL_ERROR]: 'Ein Fehler ist aufgetreten bei dem Versuch deine E-Mail zu ändern. Bitte versuche es später noch einmal.',
    [englishText.UNVERIFIED_USER]: 'Unverifizierter Benutzer',
    [englishText.EMAIL_VERIFICATION]: 'E-Mail Bestätigung',
    [englishText.REMOVE_IMG]: 'Bild entfernen',
    [englishText.LANGUAGE]: 'Sprache',
    [englishText.ADD_PROPERTY]: 'Immobilie Hinzufügen',
    [englishText.ADD_PROP_TITLE]: 'Du kannst eigene Immobilien bei IMMOZY hinzufügen und verwalten.',
    [englishText.ADD_PROP_SUBTITLE]: 'Diese Immobilien sind nur für dich sichtbar.',
    [englishText.SELECT_FILE]: 'Datei auswählen:',
    [englishText.MY_SEARCHES]: 'Meine Suchen',
    [englishText.ONBOARDING]: 'Fragebogen',
    [englishText.MY_PROP_LIST]: 'Meine Liste',
    [englishText.IN_EUROS]: 'In Euro',
    [englishText.IN_PRECENTAGE]: 'In Prozent',
    [englishText.REQUEST_AND_ACCESS]: 'DOKUMENTE ANFORDERN',
    [englishText.REQ_MSG_BODY]:
        'IMMOZY unterstützt dich mit Rat und Tat um dir eine möglichst reibungslose Erfahrung zu ermöglichen. Die Einsicht in die Immobilien-Dokumente ist ein wichtiger Schritt. Wir freuen und darauf dir dabei behilflich sein zu können und stellen sicher das die Dokumente korrekt und vollständig sind.',
    [englishText.REQ_MSG_BODY_2]: 'Sobald neue Dokumente vorhanden sind werden wir dich per E-Mail informieren.',
    [englishText.CAN_WE_HELP]: 'Können wir dir behilflich sein?',
    [englishText.CONTACT_US]: 'Sprich uns an',
    [englishText.TAKES_1_2_DAYS]: 'Meistens dauert es 1-2 Tage bis die Dokumente vorliegen, selten kann es jedoch auch länger dauern.',
    [englishText.REQUEST_DOCUMENTS_NOW]: 'DOKUMENTE ANFORDERN',
    [englishText.WAITING_FOR_DOC]: 'Auf Dokumente warten',
    [englishText.SELLER_NO_AN]: 'Der Verkäufer meldet sich leider nicht',
    [englishText.NO_AN_10_DAYS]:
        'Auch nach mehrfachen Kontaktversuchen ist es uns nicht gelungen eine Antwort vom Verkäufer zu erhalten. Wir melden uns bei dir sobald dies sich ändert.',
    [englishText.DOWNLOAD_AREA]: 'Herunterladen von Dateien',
    [englishText.DOC_AVAILABLE]: 'Anfrage vollständig! Unterlagen sind zum Download bereit.',
    [englishText.DOC_REQ_ERR]: 'Beim anfordern der Dokumente ist ein Fehler unterlaufen. Bitte versuches es später noch einmal.',
    [englishText.DOCUMENT_REQUESTED]: 'Dokumente angefordert.',
    [englishText.NO_SAVED_SEARCH]: 'Keine Gespeicherten Suchen.',
    [englishText.NO_SAVED_SEARCH_WARNING]: 'Du hast noch keine Suche gespeichert bisher.',
    [englishText.CHANGE_NAME]: 'Namen ändern',
    [englishText.MATCH_SCORE]: 'Punktzahl',
    [englishText.TYPE]: 'Typ',
    [englishText.SETTINGS]: 'Einstellungen',
    [englishText.SHOW_RESULTS]: 'Ergebnisse Anzeigen',
    [englishText.DELETE]: 'Entfernen',
    [englishText.DELETE_SEARCH_CONFIRM]: 'Möchtest du wirklich die folgende Suche entfernen',
    [englishText.DELETE_SEARCH]: 'SUCHE ENTFERNEN',
    [englishText.NOT_GIVEN]: 'Nicht angegeben',
    [englishText.EMAIL_NOTIF]: 'E-Mail Benachrichtigungen',
    [englishText.EMAIL_OFFERS_CHOOSE]: 'Wie oft möchtest du über neue Immobilien informiert werden?',
    [englishText.EVERY_HOUR]: 'Stündlich',
    [englishText.EVERY_3_HOURS]: 'Alle 3 Stunden',
    [englishText.ONE_TIME_DAY]: 'Täglich',
    [englishText.NEVER]: 'Niemals',
    [englishText.FULL_SCREEN]: 'Vollbild',
    [englishText.EXIT_FULL_SCREEN]: 'Beenden Sie den Vollbildmodus',
    [englishText.WELCOME_TO_LONG]: 'Jetzt kostenlos registrieren',
    [englishText.MY_FINDER_MSG]:
        'Hier findest du bald dein My-IMMOZY Center, wo du deine Wünsche und Anforderungen anpassen kannst um so von unserer brandneuen Technologie zu profitieren. Wir freuen uns darauf es dir bald präsentieren zu können!',
    [englishText.YOUR_OLD_MAIL]: 'Deine alte E-Mail-Adresse ',
    [englishText.WILL_BE_REMOVE]: ' wird entfernt, sobald du deine Neue bestätigt hast:',
    [englishText.SHOW_LESS]: 'Zeige weniger',
    [englishText.READ_MORE]: 'Weiterlesen...',
    [englishText.PRICE_1]: 'Preis',
    [englishText.TYPES_OF_BUILDING]: 'Gebäudetypen',
    [englishText.HOUSES]: 'Häuser',
    [englishText.ADVERT_AGE]: 'Inseratsalter in Tagen',
    [englishText.YEAR_MIN]: 'Jahr min.',
    [englishText.YEAR_MAX]: 'Jahr max.',
    [englishText.AGE_MIN]: 'Alter max.',
    [englishText.AGE_MAX]: 'Alter max.',
    [englishText.MY_SEARCH]: 'Meine Suche',
    [englishText.MUNICH]: 'München',
    [englishText.COLOGNE]: 'Köln',
    [englishText.COMING_SOON]: 'bald verfügbar',
    [englishText.CONTINUE_1]: 'fortsetzen',
    [englishText.WITH_COMMISSION]: 'Mit Provision',
    [englishText.FINISH_THE]: 'Fülle das ',
    [englishText.TO_SEE_PROPERTY]: ' aus, um festzustellen, wie gut diese Immobilie deinen Anforderungen entspricht.',
    [englishText.WHY_MSG]:
        'Immozy benutzt einen revolutionären Algorithmus der dir maßgeschneiderte Empfehlungen geben kann und somit deine Suche zu einem Finden macht! Hierfür müssen wir aber natürlich genau verstehen was du für Bedürfnisse und Anforderungen hast. Daher empfehlen wir dir unseren Fragebogen zu beantworten um die volle Leistung unserer Technologie zu gewährleisten.',
    [englishText.WHY_MSG_1]:
        'Selbstverständlich behandeln wir alle deine Daten streng vertraulich und wir teilen sie nicht mit Drittanbietern o.ä. Alle Daten die wir erheben dienen alleine dem Zweck dir passende Angebote zu unterbreiten, dass garantieren wir dir!',
    [englishText.SHOW_ERROR]: 'Ihre Anfrage wird bereits bearbeitet.',
    [englishText.REQUEST_COMPLETED]: 'Ihre Anfrage wurde abgeschlossen.',
    [englishText.BUILDING_TYPE]: 'Gebäudetyp',
    [englishText.DEACTIVATED]: 'Schon vergeben',
    [englishText.FARMHOUSE]: 'Bauernhaus',
    [englishText.REGISTRATION]: 'Anmeldung',
    [englishText.LOG_IN]: 'Einloggen',
    [englishText.PRICE_SMALL]: 'Preis',
    [englishText.FIRST_SELECT_CITY]: 'Wählen Sie zuerst die Stadt aus',
    [englishText.LOADING_DREAM_HOMES]: 'LADE TRAUMIMMOBILIEN AUS ',
    [englishText.LOAD_MSG_1]: 'Suche nach schönen Häusern',
    [englishText.LOAD_MSG_2]: 'Suche nach Villen',
    [englishText.LOAD_MSG_3]: 'Wohnungen laden',
    [englishText.LOAD_MSG_4]: 'Karten aufklappen',
    [englishText.LOAD_MSG_5]: 'Pins sortieren',
    [englishText.LOAD_MSG_6]: 'Preise prüfen',
    [englishText.MEETING_YOUR]: 'treffen',

    // calculator
    [englishText.FINANCING]: 'Finanzierung',
    [englishText.MORTGAGE_CALCULATOR]: 'Finanzierungsrechner',
    [englishText.GET_FREE_RECOM]: 'Erhalte deine kostenlose, persönliche Baufinanzierungsempfehlung',
    [englishText.GET_STARTED]: 'Jetzt starten',
    [englishText.GET_FRWW_FINANCING_OFFER]: 'Jetzt kostenloses Finanzierungsangebot',
    [englishText.CALCULATE_NOW]: 'Jetzt berechnen',
    [englishText.ESTATE_AGENT_FEE]: 'Maklerprovision',
    [englishText.PURCHASE_TAX]: 'Grunderwerbsteuer',
    [englishText.LAND_RESGISTER_ENTRY]: 'Grundbucheintrag',
    [englishText.NOTARY_FEES]: 'Notarkosten',
    [englishText.PURCHASE_COST_COMPOSITION]: 'Wie setzen sich die Kaufnebenkosten zusammen?',
    [englishText.FIXATION_PERIOD]: 'Zinsbindung',
    [englishText.YEARS]: 'Jahre',
    [englishText.YEARS_2]: 'Jahren',
    [englishText.MONTHS]: 'Monaten',
    [englishText.REPAY_MORGAGE]: 'Wann werde ich meine Baufinanzierung abbezahlt haben?',
    [englishText.MONTHLY_RATE]: 'Monatliche Rate',
    [englishText.INT_RATE]: 'Zinsrate',
    [englishText.REMAINING_DEBT]: 'Restschuld',
    [englishText.FIXED_UNTIL]: 'Fest bis',
    [englishText.LOAN_AMOUNT]: 'Kreditbetrag',
    [englishText.PROPERTY_PRICE]: 'Immobilienpreis',
    [englishText.REAL_ESTATE_COMM]: 'Maklerprovision',
    [englishText.ANNUAL_REPAY_RATE]: 'Jährliche Rückzahlrate (Tilgung)',
    [englishText.SAVINGS]: 'Ersparnisse',
    [englishText.TOTAL_PURCHASE_COSTS]: 'Kosten gesamt',
    [englishText.GET_STARTED_SUCESS]:
        'Deine Anfrage wurde erfolgreich übermittelt. Unsere Berater melden sich bei dir und erklären dir, welche Finanzierungsoption am besten zu dir passt.',
    [englishText.GET_STARTED_WARNING]:
        'Wir helfen Ihnen, Ihre Finanzierungsoptionen und grundlegende Alternativen für die Verwendung Ihrer Ersparnisse zu verstehen. Bitte loggen Sie sich zuerst in Ihr Immozy- Konto ein, um fortzufahren.',
    [englishText.FREE_FINANCE_OFFER]: 'Kostenloses Finanzierungsangebot',
    [englishText.GET_FREE_REC]: 'Erhalte deine kostenlose, persönliche Baufinanzierungsempfehlung',
    [englishText.COMPOSITION_OF_COST]: 'Zusammensetzung der Kaufnebenkosten',
    [englishText.VIEW_HISTROY]: 'Tilgungsverlauf anzeigen',
    [englishText.AMORTISATION_HISTORY]: 'Rückzahlungshistorie anzeigen',
    [englishText.SHOW_REPAYMENT_PROGRESSION]: 'Tilgungsverlauf',
    [englishText.SELECT]: 'Auswählen',
    [englishText.GET_FREE_RECOMMENDATIONS]: 'Erhalte deine kostenlose, persönliche Baufinanzierungsempfehlung',
    [englishText.COMPARE_THE_TERMS]: 'Vergleiche hier die Laufzeiten und wähle eine andere Bindung:',

    //signup popup
    [englishText.EMAIL_ADDRESS_1]: 'E-Mail-Adresse',
    [englishText.ALREADY_MEMBER]: 'Bist du schon bei IMMOZY?',
    [englishText.ALREADY_HAVE_AN_ACCOUNT]: 'Du hast bereits ein Konto?',
    [englishText.LOGIN_HERE]: 'Hier einloggen',
    [englishText.COMP_MARKET]: 'UMFASSENDES MARKTANGEBOT.',
    [englishText.ALL_OFFER_GERMANY]: 'Alle Angebote der wichtigsten Portale',
    [englishText.IN_GERMANY]: 'Deutschlands.',
    [englishText.FIND_1]: 'Finden',
    [englishText.REAL_ESTATE]: 'IMMOBILIEN',
    [englishText.SIMPLE_FAST]: 'Einfach. Schnell. Für dich personalisiert.',
    [englishText.IMMOZY_MATCH]: 'Dank des IMMOZY Matching-Algorithmus.',
    [englishText.INSIDER_TIPS]: 'Insider-Tipps. Informative Artikel. ',
    [englishText.COMP_MATERIAL]: 'Umfassendes Hilfsmaterial.',
    [englishText.OVER]: 'Über',
    [englishText.OFFER_PER_YEAR]: 'Angebote im Jahr inkl. erweiterter Datensets.',

    //login popup
    [englishText.REMEMBER_ME]: 'Meine Login-Daten merken',
    [englishText.DONT_HAVE_AN_ACCOUNT]: 'Du hast noch kein Konto?',
    [englishText.WELCOME_BACK]: 'Willkommen zurück!',
    [englishText.REG_WITH_EXISTING_ACC]: 'Melde Dich hier mit deinen bestehenden Account-Daten bei IMMOZY an.',

    //Welcome popup
    [englishText.WELCOME_DETAIL_1]:
        'Es wird Zeit zu Finden, statt zu Suchen. Dank unseres Maklernetzwerks in Verbindung mit den wichtigsten Immobilienportalen Deutschlands, deckt IMMOZY über 96% des gesamten Marktes ab*.',
    [englishText.WELCOME_DETAIL_2]: `Unser innovatives Matching System hilft dir schnell und einfach die passenden Immobilien zu finden. Beantworte ein paar Fragen damit unser System deine individuellen Wünsche versteht und du auf einen Blick erkennst wie gut eine Immobilie wirklich zu dir passt.`,
    [englishText.WELCOME_DETAIL_3]:
        'Mithilfe eines Accounts schaltest du das Matching System frei und kannst deine Suche personalisieren. Deine Daten sind bei uns selbstverständlich sicher!',
    [englishText.ACCOUNT_CREATE]: 'ACCOUNT ANLEGEN',
    [englishText.LATER]: 'Später',
    [englishText.LETS_GO]: 'Los geht’s',
    [englishText.QUESTION_TOOLTIP_1]: 'Beantworte ein paar Fragen um das ',
    [englishText.QUESTION_TOOLTIP_2]: 'Matching System ',
    [englishText.QUESTION_TOOLTIP_3]: 'für dich zu aktivieren. Klicke hier um den Fragebogen jederzeit zu starten!',
    [englishText.PREPARE_TOOLTIP]: 'Bereite dich besser vor und erhöhe deine Chancen auf den Kauf einer Immobilie. Klicke hier, um loszulegen! ',
    [englishText.REGISTER_NOW]: 'Jetzt Registrieren!',

    // User contact feature screens
    [englishText.CONTACT_SELLER]: 'Verkäufer kontaktieren',
    [englishText.IMMOZY_HELP]: 'IMMOZY Hilfe',
    [englishText.FINANCING_DESC]: 'Eine geprüfte Finanzierung erhöht deine Chancen auf den Erwerb dieser Immobilie um ein Vielfaches!',
    [englishText.FINANCE_CHECK]: 'Finanzierungscheck',
    [englishText.CONTACT_NOW]: 'Jetzt kontaktieren',
    [englishText.HELP_TO_REAL]: 'HILFE ZUR IMMOBILIE',
    [englishText.BROKER_MANDATE]: 'IMMOZY hat kein Maklermandat, sondern unterstützt den selbstständigen Kauf mit Service und Know How.',
    [englishText.DO_YOU_HAVE_QUESTION]: 'Hast du Fragen?',
    [englishText.HAPPY_TO_HELP]: 'Wir helfen Dir gern weiter - kompetent,',
    [englishText.FREE_OF_CHARGE]: 'kostenlos und unverbindlich.',
    [englishText.SELLER_DESC]: 'Hier kannst du den Verkäufer dieser immobilie kontaktieren.',
    [englishText.HELP_DESC]: 'Spreche direkt mit einem IMMOZY Mitarbeiter um Hilfestellung rund um diese Immobilie zu bekommen.',
    [englishText.BEST_WAY_TO_REACH]: 'Wie können wir dich am besten erreichen?',
    [englishText.GENDER]: 'Anrede*',
    [englishText.GENDER_1]: 'Anrede',
    [englishText.FNAME_AND_LNAME]: 'Vorname / Nachname*',
    [englishText.ENTER_YOUR_PHONE]: 'Bitte gebe deine Telefonnummer ein',
    [englishText.REACHABILTY]: 'Erreichbarkeit',
    [englishText.REQUIRED]: '*Pflichtfelder',
    [englishText.PLEASE_FILL_REQUIRED]: 'Bitte fülle die mit * gekennzeichnete Felder diese aus.',
    [englishText.SEND]: 'Senden',
    [englishText.BACK_SMALL]: 'zurück',
    [englishText.SECURELY_ENCRYPTED]: 'Sicher verschlüsselt über SSL',
    [englishText.SECURELY_ENCRYPTED_2]: 'Kostenlos. Sicher. Ohne Schufa.',
    [englishText.ABOUT_TO_LEAVE]: 'DU BIST DABEI, UNS ZU VERLASSEN!',
    [englishText.ABOUT_LEAVE_WARNING_MSG]: 'Du bist dabei, IMMOZY zu verlassen. Bist Du sicher, dass wir dir nicht helfen sollen?',
    [englishText.DONT_WANT_TO_LEAVE]: 'Ich will nicht gehen',
    [englishText.THANK_YOU]: 'VIELEN DANK!',
    [englishText.REQUEST_RCVD_INFORM]: 'Wir haben deine Anfrage erhalten und werden uns zeitnah bei dir melden.',
    [englishText.MEETING_ALREADY_SET]:
        'Sie haben bereits einen Termin für dieses Objekt mit dem Immozy-Team vereinbart. Sie können Ihre E-Mail für Besprechungsdetails abrufen.',
    [englishText.BOOK_DIRECT_APOINTMENT]: 'Wenn du nicht warten willst, kannst du hier direkt einen Termin buchen.',
    [englishText.BOOK]: 'Buchen',
    [englishText.FIND_SELLER_INFO_BELOW]: 'Nachfolgend finden Sie den Kontakt Ihres Verkäufers.',
    [englishText.BEAWARE_FIRST_CONTACT_SELLER]:
        'Sei dir bewusst, das bereits beim ersten Kontakt mit der Verkäuferseite einige Sachen zu beachten sind. ',
    [englishText.SELLER_CONTACT_DETAILS]: 'KONTAKTDATEN DES VERKÄUFERS',
    [englishText.SELLER_DETAILS_DISABLED]: 'Der Verkäufer hat seine Kontaktinformationen nicht veröffentlicht.',
    [englishText.CONTACT_ASAP]: 'Wir werden uns umgehend bei dir mit den angefragten Informationen melden',
    [englishText.SELLER]: 'VERKÄUFER',
    [englishText.NO_DETAILS_AVAILABLE]: 'Keine Kontakt Information verfügbar.',
    [englishText.OFFER_FOUND_AT]: 'Angebot gefunden bei:',
    [englishText.BOOK_APPOINTMENT_WITH_US]: 'Vereinbare vorher einen Termin mit deinem IMMOZY Berater um dich zu unterstützen. ',
    [englishText.CALENDLY_TITLE]: 'Vereinbaren Sie Ihren Termin mit dem Immozy-Team',
    [englishText.MR]: 'Herr',
    [englishText.MS]: 'Frau',
    [englishText.MISCELLANEOUS]: 'Divers',
    [englishText.EARLY_MORNING]: '8 - 10 Uhr',
    [englishText.MORNING]: '10 - 13 Uhr',
    [englishText.AFTERNOON]: '13 - 16 Uhr',
    [englishText.EVENING]: '16 - 19 Uhr',
    [englishText.LOGING_IN_ADMIN]: 'Sie werden angemeldet. Bitte warten....!',
    [englishText.ENTER_PRICE]: 'Bitte geben Sie einen Preis ein',
    [englishText.CONDITION_BROKER]:
        'Immozy hat kein Maklermandat in Bezug auf die hier verlinkten Angebote und steht in keinerlei Verbindung zu den Inserenten oder den externen Plattformen bzw. deren Betreibern.',
    [englishText.ONE_MORE_CLICK]: 'Nur noch ein Klick zur Immobilie',
    [englishText.WE_FOUND_THIS_ON]: 'Wir haben diese Immobilie für dich auf ',
    [englishText.FOLLOW_THE_LINK_BELOW]:
        'gefunden*. Um dir die Immobilie genauer anzusehen und den Verkäufer zu kontaktieren, folge dem unten stehenden Link',
    [englishText.ANY_QUESTIONS_CONTACT_US]: 'Du hast noch Fragen oder wünschst Unterstützung? Dann kontaktiere uns gerne',
    [englishText.WE_WILL_GET_BACK]:
        'Wir haben deine Anfrage erhalten und du erhältst zeitnah eine Rückmeldung, damit du bei deinem Anliegen bestmöglich unterstützt wirst.',
    [englishText.BASED_ON_INFORMATION]: 'Auf Basis Deiner Angaben haben wir',
    [englishText.SUITABLE_PROPS]: 'passende Immobilien gefunden',
    [englishText.ANSWER_MORE_QUESTION]: 'Beantworte weitere Fragen, um Deine individuellen Matching-Ergebnisse zu verfeinern.',
    [englishText.WE_CALCULATE]: 'Wir berechnen auf Basis Deiner Angaben Deine Top-Matches.',
    [englishText.PROPERTY_DISABLED]: 'Die Immobilie ist nicht mehr verfügbar',

    // new signup popup
    [englishText.ONE_ACCOUNT_MANY_ADV]: 'Ein Konto, viele Vorteile',
    [englishText.REG_NOW_FOR_FREE]: 'Registriere Dich für den vollen Service.',
    [englishText.IMMOZY]: ' Unverbindlich, sicher und ohne Kosten.',
    [englishText.FULL]: 'UMFASSEND',
    [englishText.DIVERSE_RANGE]: 'Vielfältiges Angebot an Inseraten von regionalen Spezialisten ',
    [englishText.REGIONAL_SPECIALIST]: 'und den wichtigsten Immobilien-Plattformen.',
    [englishText.PERSONALLY]: 'PERSÖNLICH',
    [englishText.MATCHING_ALGO_FINDS]: 'Unser Matching-Algorithmus findet genau die Immobilien, die ',
    [englishText.SUIT_NEEDS]: 'am besten zu deinen individuellen Vorstellungen passen.',
    [englishText.PROFESSIONAL]: 'PROFESSIONELL',
    [englishText.IF_YOU_HAVE_QUEST]: 'Solltest du doch mal eine Frage haben, helfen dir unsere',
    [englishText.IMMOZY_EXPRTS_ADVICE]: ' erfahrenen Immobilien-Experten schnell und fundiert.',
    [englishText.WANT_PRECIE_RESULT]: 'Passgenaue Ergebnisse gewünscht?',
    [englishText.TO_THE_ONB]: 'Zum Onboarding',
    [englishText.OPEN_ONBOARDING]: 'Zum Onboarding',
    [englishText.OR]: 'oder',
    [englishText.FILL_ONBORADING]:
        'Beantworte zunächst einige Fragen im Onboarding, damit wir dir auf Basis deiner Antworten deine Top-Objekte empfehlen können.',
    [englishText.EMAIL_STAR]: 'E-Mail-Adresse*',
    [englishText.CONTACT_TITLE]: 'Deine Kontaktanfrage an:',
    [englishText.OPT_IN]: 'Ich möchte einen kostenlosen Experten-Check durch Immozy, um meine Chancen beim Immobilien-Kauf zu erhöhen (opt.)',
    [englishText.FOR_FREE]: 'KOSTENLOS',
    [englishText.FREE_REUQEST_1]: 'Deine Anfrage ist kostenlos, es ',
    [englishText.NO_FEES_CHARGED]: 'werden keinerlei Gebühren erhoben.',
    [englishText.SECURE]: 'SICHER',
    [englishText.DATA_SAFE]: 'Deine Daten sind sicher und ',
    [englishText.PROCESSED_CONF]: 'werden verschlüsselt verarbeitet',
    [englishText.AFTER_YOUR_CONTACT]: 'Nach deiner Kontaktanfrage melden wir uns',
    [englishText.HELP_YOU]: 'persönlich bei dir und helfen dir umgehend weiter.',
    [englishText.LONG_TEXT]:
        'Mit Absenden der Anfrage nimmst du den Immozy Service in Anspruch. Du erhältst anhand der von dir eingegebenen Daten, genutzten Services und auf Grundlage unseres Geschäftszwecks auf  dein Anliegen ausgerichtete Informationen. Diesem Service kannst  du jederzeit unter datenschutz@immozy.de widersprechen. Weitere Informationen findest du in der Datenschutzerklärung.',
    [englishText.YOUR_CONTACT_REQUEST]: 'Deine Kontaktanfrage',
    [englishText.ALL_OFFERS]: 'Alle Angebote',
    [englishText.ACTIVE_OFFERS]: 'Aktive Angebote',
    [englishText.ALL_OFFER_TITLE]: 'Alle Angebote, einschließlich abgelaufener und deaktivierter',
    [englishText.ACTIVE_OFFER_TITLE]: 'Alle Angebote, die aktiv sind',
    [englishText.NO_OFFER_AVAILABLE]: 'Für diesen Makler sind keine aktiven Angebote verfügbar',
    [englishText.UNSUBSCRIBE_MESSAGE]: 'Sie haben sich erfolgreich von unserer Newsletter-E-Mail-Liste abgemeldet.',
    [englishText.SMART_FINDER]: 'Smart Immobilien finden',
    [englishText.PERSONAL_AND_PROFESSIONAL]: 'Persönlich & Professionell',
    [englishText.COMPREHENSIVE_OFFER]: 'Umfassendes Angebot',
    [englishText.FIRST_STEP]: 'Dein Schritt ins Eigenheim',

    // Immozy Home

    [englishText.VERY_IMPORTANT]: 'Sehr wichtig',
    [englishText.IMPORTANT]: 'Wichtig',
    [englishText.LESS_IMPORTANT]: 'Weniger wichtig',
    [englishText.WHY_QUESTION_HEADING]: 'Warum ist dies relevant?',
    [englishText.UPLOAD_DOCUMENT_HEADING]: 'Upload your ID card for verification',
    [englishText.UPLOAD_FILE_LABEL]: 'Wähle eine Datei aus oder ziehe sie hierher',
    [englishText.UPLOAD_FILE_DESCRIPTION]: 'JPG, PNG oder PDF, Dateigröße nicht mehr als 10 MB',

    // Immozy Home - new contact pop-up

    [englishText.PROFILE_COMPLETION_POPUP_HEADING]: 'Bist du bestens vorbereitet?',
    [englishText.PROFILE_COMPLETION_POPUP_SUBTEXT]:
        'Ein Verkäufer einer Immobilie bekommt häufig sehr viele Kaufanfragen, insbesondere bei einer guten Immobilie in beliebter Lage. Um Deine Chance auf die Immobilie zu erhöhen, wollen wir bei Immozy Dir helfen, Dich optimal vorzubereiten, um den besten Aufschlag beim Verkäufer zu machen.',
    [englishText.PROFILE_COMPLETION_POPUP_SUBTEXT_2]: 'Wir raten Dir, die folgenden Themen abzuklären:',
    [englishText.STEPS_COMPLETED]: 'Punkte erledigt',
    [englishText.PROVIDE_PERSONAL_INFORMATION]: 'Persönliche Daten (Selbstauskunft) angeben',
    [englishText.CLARIFY_PERSONAL_NEEDS]: 'Dein Suchprofil vervollständigen',
    [englishText.CHECK_AND_SECURE_YOUR_FINANCING]: 'Finanzierungscheck abschließen',
    [englishText.PREPARE_PROPERTY_INSPECTION]: 'Immobilien-Besichtigung vorbereiten',
    // [englishText.CONTACT_SELLER_INSTRUCTION_LABEL]:
    //   "Du bist bestens vorbereitet! Kontaktiere jetzt den Anbieter.",
    [englishText.DONT_WANT_TO_SEE_THIS_CHECK_AGAIN]: 'Diese Seite nicht mehr anzeigen.',
    [englishText.FILL_ALL]: 'Bitte füllen Sie alle Felder aus',
    [englishText.SECURE_FINANCING_LABEL]: 'Beste Finanzierung sichern',
    [englishText.REQUEST_FREE_ADVICE]: 'Kostenlose Beratung anfragen',
    [englishText.FINANCE_ADVICE_FOOTER_TEXT]:
        'Mit Absenden der Anfrage nimmst du den Immozy-Service in Anspruch. Diesem Service kannst du jederzeit unter datenschutz@immozy.de widersprechen.',
    [englishText.FINANCIAL_ADVICE_DESCRIPTION]: 'Wir finden kostenlos deine optimale Finanzierung beim besten Finanzierungspartner.',

    [englishText.CONTACT_SLIDER_TITLE_1]: 'Umfassender Angebotsvergleich',
    [englishText.CONTACT_SLIDER_SUBTITLE_1]: 'Vergleiche mit uns mehr als 700 Anbieter für Immobilienfinanzierungen.',
    [englishText.CONTACT_SLIDER_SUBTITLE_2]: 'Profitiere von den besten Zinsen und kompetenter Beratung',
    [englishText.CONTACT_SLIDER_TITLE_2]: 'Persönlich und schnell',
    [englishText.CONTACT_SLIDER_SUBTITLE_2_1]: 'Wir beraten und betreuen dich mit erfahrenen Experten im persönlichen',
    [englishText.CONTACT_SLIDER_SUBTITLE_2_2]: 'Gespräch - innerhalb von 24 Stunden hast du dein Beratungsgespräch.',
    [englishText.CONTACT_SLIDER_TITLE_3]: 'Kostenlos und seriös',
    [englishText.CONTACT_SLIDER_SUBTITLE_3_1]: 'Deine Anfrage und die folgende Beratung ist für dich kostenlos.',
    [englishText.CONTACT_SLIDER_SUBTITLE_3_2]:
        'Wir arbeiten mit einer sorgfältigen Auswahl der renommiertesten deutschen Vermittler und Finanzinstitute zusammen.',
    [englishText.CONTACT_SLIDER_TITLE_4]: "Geht nicht? Gibt's nicht!",
    [englishText.CONTACT_SLIDER_SUBTITLE_4_1]: 'Wir haben ausgesuchte und geprüfte Partner für Selbständige,',
    [englishText.CONTACT_SLIDER_SUBTITLE_4_2]:
        'Personen ohne deutsche Staatsbürgerschaft und Kreditnachfrager mit Schufa-Eintrag in unserem Netzwerk. Nicht möglich, gibt es bei uns nicht.',
    [englishText.WELCOME_TITLE]: 'Moin! Ich bin Ralf Baumann, Gründer von Immozy.',
    [englishText.WELCOME_LINE_1]: 'Für deinen idealen Einstieg haben wir ein kurzes Onboarding für dich vorbereitet.',
    [englishText.WELCOME_LINE_2]: 'Wofür ein Onboarding?',
    [englishText.WELCOME_LINE_3]:
        'Um Dir die optimal zu Deinen Wünschen passenden Immobilien präsentieren zu können, braucht der neue und einmalige Immozy Matching Algorithmus einige wenige, aber sehr wichtige Infos von Dir.',
    [englishText.WELCOME_LINE_4]: 'Vertraulich, sicher und immer DSGVO-konform.',

    //-----------------realtor info pop up------------------

    [englishText.REALTOR_INFO_OPTION_1]: 'Ich möchte die Immobilie besichtigen.',
    [englishText.REALTOR_INFO_OPTION_1_TEXT]: ' Gerne möchte ich die Immobilie besichtigen und bitte um einen Terminvorschlag Ihrerseits.',
    [englishText.REALTOR_INFO_OPTION_2]: 'Ich möchte Unterlagen zur Immobilie erhalten.',
    [englishText.REALTOR_INFO_OPTION_2_TEXT]: ' Ich würde mich über die Zusendung relevanter Unterlagen zur Immobilie sehr freuen.',
    [englishText.REALTOR_INFO_OPTION_3]: 'Ich möchte, dass Immozy mir weiterhilft.',
    [englishText.I_AM_INT_TEXT]: 'Sehr geehrte Damen und Herren, ich interessiere mich für Ihr Objekt.',

    // cities name
    [englishText.HAM]: 'Hamburg',
    [englishText.BER]: 'Berlin',
    [englishText.BAV]: 'Bayern',
    [englishText.BA_WU]: 'Baden-Württemberg',
    [englishText.SAXONY]: 'Sachsen',
    [englishText.BRE]: 'Bremen',
    [englishText.NIED]: 'Niedersachsen',
    [englishText.RH_PF]: 'Rheinland-Pfalz',
    [englishText.SA_AN]: 'Sachsen-Anhalt',
    [englishText.HES]: 'Hessen',
    [englishText.ME_VO]: 'Mecklenburg-Vorpommern',
    [englishText.BRND]: 'Brandenburg',
    [englishText.N_RH_WE]: 'Nordrhein-Westfalen',
    [englishText.SL]: 'Saarland',
    [englishText.SC_HO]: 'Schleswig-Holstein',
    [englishText.THUR]: 'Thüringen',

    [englishText.THANK_YOU_FOR_REG]: 'Vielen Dank für deine Registrierung!',
    [englishText.MAIL_SENT_MSG]:
        'Wir haben dir soeben eine E-Mail mit einem Bestätigungslink zugeschickt, mit welchem du deine E-Mail-Adresse und dein Konto bestätigen kannst. Bitte prüfe dein E-Mail-Postfach (inkl.Spam-Ordner), um deine Registrierung abzuschließen.',
    [englishText.DIDNT_GET_MAIL]: 'Mail mit Bestätigungslink nicht erhalten?',
    [englishText.SEND_AGAIN]: 'Erneut zusenden',
    [englishText.YOUR_CONTACT_REQUEST_TO]: 'Deine Kontaktanfrage an:',
    [englishText.IMMOZY_CHECK]: 'Immozy-Check',
    [englishText.COMPLETE_PROFILE]: 'Profil vervollständigen',
    [englishText.INCREASE_CHANCES]: 'Chancen erhöhen',
    [englishText.SELECT_PLACEHOLDER]: 'Option auswählen',
    [englishText.UHR]: 'Uhr',
    [englishText.UHR_AM]: 'Uhr',
    [englishText.UHR_PM]: 'Uhr',
    [englishText.REACHABILTY_TEXT]: 'Wann bist du am besten erreichbar?',

    [englishText.PROFILE_SECTION_LABEL]: 'Selbstauskunft',
    [englishText.ONBOARDING_SECTION_LABEL]: 'Onboarding abschließen',
    [englishText.FINANCING_SECTION_LABEL]: 'Finanzierung',
    [englishText.FIN_START]: 'Finanzierungscheck starten',

    [englishText.EMPLOYED]: 'Angestellt',
    [englishText.SELF_EMPLOYED]: 'Selbstständig',
    [englishText.PUBLIC_SERVICE]: 'Öffentlicher Dienst',
    [englishText.FREELANCE]: 'Freiberuflich',
    [englishText.Student]: 'Student',
    [englishText.PRIVATE_INDIVIDUAL]: 'Privatier',
    [englishText.PENSIONER]: 'Rentner',

    [englishText.WE_WILL_HELP_YOU]: 'Noch Fragen? Wir helfen dir.',

    [englishText.SCHUFA_CREDIT_CHECK_LABEL]: 'Schufa-Bonitätscheck hochladen',
    [englishText.SCHUFA_FIN_CHECK_LABEL]: 'Finanzierungsbestätigung hochladen',
    [englishText.ALREADY_HAVE_SCHUFA_CREDIT_CHECK_LABEL]: 'Hast du bereits einen Schufa-Bonitätsnachweis?',
    [englishText.ALREADY_HAVE_FINANCING_CONFIRMATION_LABEL]: 'Hast du bereits ein Finanzierungszertifikat?',

    [englishText.JA]: 'Ja',
    [englishText.NEIN]: 'Nein',
    [englishText.DONE]: 'Erledigt',
    [englishText.THANK_YOU_HEADING]: 'Vielen Dank für Deine Anfrage!',
    [englishText.THANK_YOU_TEXT]:
        'Wir haben deine Anfrage erhalten und du erhältst zeitnah eine Rückmeldung, damit dir bei deinem Anliegen schnellstmöglich weitergeholfen wird.',
    [englishText.SECURE_FREE_CONSULT]: 'Kostenloses Beratungsgespräch sichern',
    [englishText.HI]: 'Hallo',
    [englishText.PROFILE_TOOLTIP]: 'Damit der Anbieter dich bestmöglich kontaktieren kann, benötigt dieser einige Angaben von dir.',
    [englishText.ONB_TOOLTIP]:
        'Damit unser Matching-Algorithmus für dich maßgeschneiderte Angebote identifizieren kann, benötigen wir einige Angaben zu deinen Anforderungen.',
    [englishText.FINANCE_TOOLTIP]:
        'Beantworte ein paar Fragen zum Thema Finanzen, damit wir die bestmöglichen Angebote und Partner für dich finden können.',

    [englishText.LOCATION_30]: 'Die Wohngegend entspricht nicht sehr deinen Wünschen.',
    [englishText.LOCATION_60]: 'Die Wohngegend entspricht teilweise deinen Wünschen',
    [englishText.LOCATION_100]: 'Die Wohngegend entspricht sehr gut deinen Wünschen.',
    [englishText.FINANCE_30]: 'Die Kaufkosten könnten deutlich zu hoch für dein Budget sein',
    [englishText.FINANCE_60]: 'Die Kaufkosten könnten etwas zu hoch für dein Budget sein.',
    [englishText.FINANCE_100]: 'Die Kaufkosten passen gut zu deinem Budget.',
    [englishText.REQUIRE_30]: 'Die Ausstattung passt eher weniger zu deinen Anforderungen.',
    [englishText.REQUIRE_60]: 'Die Ausstattung passt teilweise zu deinen Anforderungen.',
    [englishText.REQUIRE_100]: 'Die Ausstattung passt sehr gut zu deinen Anforderungen.',

    [englishText.HI]: 'Vorbereitung',
    [englishText.PROFILE_TRUE]: 'Du hast alle hilfreichen Daten vorbereitet.',
    [englishText.PROFILE_FALSE]: 'Es fehlen noch persönliche Daten von dir.',
    [englishText.ONB_TRUE]: 'Wir haben die Passung der Immobilie geprüft.',
    [englishText.ONB_FALSE]: "Schließe das <a href='/onboarding' target='_blank'>Onboarding</a> ab, um die Passung der Immobilie zu prüfen.",
    [englishText.FIN_ONB_TRUE]: 'Du hast deine Finanzierung geprüft.',
    [englishText.FIN_ONB_FALSE]: 'Schließe den Finanzierungscheck ab, um alle Finanzierungsoptionen prüfen zu lassen.',
    [englishText.FIN_UPLOAD_TRUE]: 'Du kannst ein Finanzierungszertifikat vorlegen.',
    [englishText.FIN_UPLOAD_FALSE]: 'Dir fehlt ggfls. noch ein Finanzierungszertifikat.',
    [englishText.CONTACT_PROVIDER]: 'Anbieter kontaktieren',
    [englishText.USE_LINK_BELOW]:
        'Wir haben diese Immobilie für dich auf einer externen Plattform gefunden. Um dir die Immobilie genauer anzusehen und den Verkäufer der Immobilie zu kontaktieren, verwende einen untenstehenden Link:',
    [englishText.UPLOAD_SCHUFA]: 'Lade jetzt deine Schufa-BonitätAuskunft hoch.',
    [englishText.UPLOAD_FINANCE]: 'Lade jetzt deine Finanzierungsbestätigung hoch.',
    [englishText.PREPARE]: 'Vorbereitung',
    [englishText.WE_HELP_PREPARE]: 'Wir helfen dir dabei, dich bestmöglich auf den Erstkontakt mit Verkäufern vorzubereiten.',
    [englishText.WE_HELP_PREPARE_1]: 'Schließe die einzelnen Schritte ab, um deinen Vorbereitungsstatus zu erhöhen.',
    [englishText.OKAY]: 'Alles klar',
    [englishText.START_ONBOARDING]: 'Onboarding starten',
    [englishText.INVALID_INPUT_FIELDS]: 'Bitte geben Sie gültige Details ein.',
    [englishText.DATE_ERROR]: 'Du musst mindestens 18 Jahre alt sein, um Immozy zu nutzen',
    [englishText.CITY_ERROR]: 'Bitte gib an, in welcher Stadt du wohnst.',
    [englishText.STREET_ERROR]: 'Bitte gib den Straßennamen und die Hausnummer an, in der du wohnst.',
    [englishText.POSTAL_CODE_ERROR]: 'Bitte gib eine gültige Postleitzahl ein.',
    [englishText.REQUEST_FREE_CONSULATION]: 'Kostenloses Beratungsgespräch anfragen',
    [englishText.BETTER_PREPARED]: 'Besser vorbereitet mit Immozy',
    [englishText.HEADER_30]: 'Bereite dich besser vor',
    [englishText.HEDAER_60]: 'Bereite dich besser vor',
    [englishText.HEADER_80]: 'Gut vorbereitet',
    [englishText.SUB_HEADER_30]: 'Deine Chancen auf den Kauf dieser Immobilie können mit ein paar weiteren Angaben nochmals deutlich erhöht werden.',
    [englishText.SUB_HEADER_60]: 'Deine Chancen auf den Kauf dieser Immobilie können mit ein paar weiteren Angaben nochmals deutlich erhöht werden.',
    [englishText.SUB_HEADER_80]: 'Durch deine Vorbereitung hast du deine Chancen auf den Kauf der Immobilie deutlich erhöht - super!',
    [englishText.COMPLETE]: 'Abschließen',
    [englishText.ENCRYPTED_SSL]: 'Sicher verschlüsselt über SSL',
    [englishText.PERSONAL_DATA]: 'Persönliche Daten',
    [englishText.CONTACT_DATA]: 'Kontaktdaten',
    [englishText.DATA_SAVED]: 'Daten wurden gespeichert.',
    [englishText.FIN_CHECK_COMPLETED]: 'Finanzierungscheck abgeschlossen - super!',
    [englishText.WE_WILL_HELP]: 'Falls Du weitere Unterstützung benötigst, kontaktiere uns jederzeit gerne und wir helfen dir umgehend weiter',
    [englishText.GO_BACK_TO_OBJ]: 'Zur letzten aufgerufenen Immobilie zurückkehren',
    [englishText.YOUR_PWD_MUST_HAVE]: 'Dein Passwort sollte Folgendes enthalten:',
    [englishText.NOT_ALLOWED]: 'nicht erlaubt',
    [englishText.TO_THE_OBJECT]: 'Zum Objekt',
    [englishText.LAST_VIEWED_PROPERTY]: 'Deine zuletzt angesehene Immobilie',
    [englishText.YOUR_PREPARATION_STATUS]: 'Dein Vorbereitungsstatus',

    //------------- Password Assistant ---------------------------------------
    [englishText.LOWER_CASE_CONTRAINT]: 'Kleinbuchstaben',
    [englishText.UPPER_CASE_CONTRAINT]: 'Großbuchstaben',
    [englishText.DIGIT_CONTRAINT]: 'Mindestens eine Zahl',
    [englishText.SPECIAL_CHARACTER_CONTRAINT]: 'Mindestens ein Sonderzeichen',
    [englishText.EIGHT_OR_MORE_CHARACTER_CONTRAINT]: 'Mindestens 8 Zeichen',

    //----------------------------------------------------

    [englishText.AND]: 'und',
    [englishText.CALENDER_PLACEHOLDER]: 'TT/MM/JJJJ',
    [englishText.SOCIAL_LOGIN_ERROR]: 'Sie können sich über diese Plattform nicht anmelden. Bitte versuchen Sie es mit einer anderen Methode.',
    [englishText.SOCIAL_LOGIN_ERROR_EMAIL]:
        'Die E-Mail-Adresse ist für die Anmeldung bei sozialen Konten obligatorisch. Bitte versuchen Sie es mit einer anderen Methode.',
    [englishText.ENTER_NEW_PWD]: 'Neues Passwort eingeben',
    [englishText.NO_ENTRY_AVAIALBLE]: 'Keine Einträge vorhanden',
    [englishText.PERSONAL_DATA_TOOLTIP]: 'Damit Verkäufer wissen, wer du bist, benötigen diese einige Angaben zu dir.',
    [englishText.CONTACT_TOOLTIP]: 'Damit Verkäufer wissen, wann und wie sie dich kontaktieren können, benötigen sie deine Kontaktdaten.',
    [englishText.CONTINUE_WITH]: 'oder weiter mit',
    [englishText.REGISTER_NOW_LINK]: 'Jetzt anmelden',

    [englishText.SEND_REQUEST]: 'Anfrage senden',
    [englishText.CHARACTERS]: 'Zeichen',
    [englishText.WRITE_PERSONAL_MESSAGE]: 'Persönliche Nachricht schreiben *',
    [englishText.REALTOR_HANDSHAKE_TERMS_TEXT]: `<p>Mit Absenden der Anfrage nimmst du den Immozy-Service in Anspruch. Du erhältst anhand der von dir eingegebenen Daten, genutzten Services und auf Grundlage unseres Geschäftszwecks auf dein Anliegen ausgerichtete Informationen. Diesem Service kannst du jederzeit unter <a href="mailto:datenschutz@immozy.de">datenschutz@immozy.de</a> widersprechen. Weitere Informationen findest du in der Datenschutzerklärung.</p>`,
    [englishText.SAVE_DATA]: 'Daten speichern',
    [englishText.BACK_TO_REQUEST]: 'Zurück zur Anfrage',
    [englishText.NEED_INFO]: 'Für deine Anfrage benötigen wir noch einige notwendige Angaben.',
    [englishText.COMPLETE_MISSING_INFO]: 'Fehlende Angaben ergänzen',
    [englishText.CHECK_DATA_AGAIN]: 'Prüfe deine zuvor eingegebenen Daten nochmals vor dem Absenden.',
    [englishText.CHECK_DETAILS]: 'Angaben prüfen',

    //----------------------- Realtor Handshake PDF -----------------------------------------

    [englishText.HANDSHAKE_PDF_HEADING]: 'INTERESSENTEN-AUSKUNFT',

    [englishText.SEARCH_PREFERENCES]: 'Suchpräferenzen',
    [englishText.FINANCIAL_CONDITIONS]: 'Finanzielle Rahmenbedingungen',

    [englishText.REASON_FOR_BUYING_PROPERTY]: 'Grund für den Kauf einer Immobilie',
    [englishText.DESIRED_TYPE_OF_PROPERTY]: 'Gewünschter Immobilientyp',
    [englishText.DESIRED_RESIDENTAL_LOCATION]: 'Gewünschte Wohnlage',
    [englishText.PARTICULARLY_IMPORTANT_FOR_THE_RESIDENTIAL_LOCATION]: 'Besonders wichtig für die Wohnlage',
    [englishText.DESIRED_LIVING_SPACE]: 'Gewünschte Wohnfläche',
    [englishText.DESIRED_NUMBER_OF_ROOMS]: 'Gewünschte Zimmeranzahl',

    [englishText.AVAILABLE_EQUITY]: 'Verfügbares Eigenkapital',
    [englishText.OWNER_OF_PROPERTY]: 'Eigentümer/in einer Immobilie',
    [englishText.NOT_CLEAR_YET]: 'Noch nicht klar?',
    [englishText.MAXIMUM_PURCHASE_PRICE]: 'Maximaler Kaufpreis',
    [englishText.GERMAN_CITIZENSHIP]: 'Deutsche Staastbürgerschaft',
    [englishText.PURCHASE_WITH_PARTNER]: 'Kauf mit Partner/in',
    [englishText.MONTHLY_NET_INCOME]: 'Monatliches Nettoeinkommen',

    [englishText.FINANCING_CERTIFICATE_AVAILABLE]: 'Finanzierungszertifikat vorhanden?',

    [englishText.BEST_ACHIEVABLE]: 'Am besten erreichbar',
    [englishText.IN_BTWN]: 'Zwischen ',
    [englishText.OCLOCK_BY_PHONE]: 'Uhr per Telefon',
    [englishText.CONTACT]: 'Kontakt',
    [englishText.BORN]: 'Geb',
    [englishText.COMPLETE_QUALIFY_WARNING]: 'Bitte füllen Sie Immozy Qualify aus, um das PDF anzuzeigen oder herunterzuladen.',
    [englishText.DOWNLOAD_PDF]: 'Selbstauskunft heunterladen',
    [englishText.INCREASE_CHANCES_VISIT]: 'Erhöhe deine Chancen auf eine Besichtigung!',
    [englishText.SHARE_AS_PDF]: 'Teile deine Selbstauskunft mit Verkäufern per Link oder als PDF:',
    [englishText.COPY]: 'Kopieren',
    [englishText.PREVIEW_LINK]: 'Vorschaulink in neuem Tab',
    [englishText.URL_COPIED]: 'URL kopiert',
    [englishText.PREMIUM_PROPERTY]: 'Premium Objekt',
    [englishText.FORCLOSURE_SALE]: 'Zwangsversteigerung',
    [englishText.DIRECT_INVENTORY_TOOLTIP]:
        'Hier ist die vertrauensvolle und rasche Bearbeitung Ihrer Anfrage garantiert: Das Objekt wird von einem Immozy geprüften Partner angeboten.',
    [englishText.FORCLOSURE_TOOLTIP]:
        'Bei dieser Immobilie handelt es sich um eine Zwangsversteigerung. Der Kaufpreis der Immobilie ist wahrscheinlich ein Startpreis der Auktion und steigt noch an, wodurch der Matchingscore verfälscht sein könnte.',
    [englishText.QUALIFY_PDF_WARNING]: 'Um deine Selbstauskunft herunterladen zu können, benötigst du einen Vorbereitungsstatus von 100 %.',
    [englishText.GO_TO_QUALIFY]: '» Jetzt Vorbereitungsstatus erhöhen',
};
export default german;
