import React from 'react';
import MetaDecorator from 'shared/meta/metaDecorator';
import TitleImageSection from '../components/titleImageSection';
import aboutUsImage from '../../../assets/images/about-us-image.png';
import { aboutUsCards } from '../utils/constants';

const Aboutus = () => {
	return (
		<div className='about-us-wrapper public-page-font'>
			<MetaDecorator title='Über Uns - Immoweb | Immobilien, Wohnungen und Häuser bei immoweb' />
			<TitleImageSection backgroundImage={aboutUsImage} titleText={'Über Uns'} />
			<div className='about-us-text'>
				<div className='text-wrapper'>
					{/* <h3>Willkommen bei der Immoweb AG!</h3> */}
					<p>
						Herzlich willkommen bei immoweb, Ihrem zuverlässigen Partner für alle Belange rund um Immobilien
						in Deutschland. Egal ob Sie eine Wohnung, ein Haus oder eine Gewerbeimmobilie suchen oder
						anbieten möchten – bei uns sind Sie genau richtig.
					</p>
					<h3>Unsere Mission</h3>
					<p>
						Unsere Mission bei immoweb ist es, Ihnen eine benutzerfreundliche, umfassende und transparente
						Plattform für die Suche und das Anbieten von Immobilien zu bieten. Wir möchten den Prozess der
						Immobiliensuche und -vermittlung so einfach und effizient wie möglich gestalten, damit Sie
						schnell und unkompliziert Ihr neues Zuhause oder den passenden Mieter bzw. Käufer finden.
					</p>
					<h3>Was uns auszeichnet</h3>
				</div>

				<section className='section-width certificate-needed-section market-report-section'>
					<div className='flex justify__content--center certificate-cards'>
						{aboutUsCards.map((item, i) => (
							<div className='certificate-needed-card' key={i}>
								<h1>{item.title}</h1>
								<p>{item.subTitle}</p>
							</div>
						))}
					</div>
				</section>
				<div className='text-wrapper'>
					<h3>Unsere Vision</h3>
					<p>
						Bei immoweb glauben wir an die Bedeutung von Transparenz, Effizienz und Kundenzufriedenheit. Wir
						arbeiten kontinuierlich daran, unser Angebot zu erweitern und unsere Plattform zu verbessern, um
						Ihnen den bestmöglichen Service zu bieten. Unsere Vision ist es, die erste Anlaufstelle für
						Immobilien in Deutschland zu werden und Ihnen zu helfen, Ihre Immobilienziele schnell und
						unkompliziert zu erreichen.
					</p>
					<p>
						Vielen Dank, dass Sie immoweb gewählt haben. Wir freuen uns darauf, Ihnen bei der Suche nach
						Ihrer Traumimmobilie oder dem passenden Käufer bzw. Mieter behilflich zu sein.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Aboutus;
