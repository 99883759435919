import React, { useState, useEffect } from 'react';
import getLocalizeText from 'utils/getLocalizeText';
import PropTypes from 'prop-types';
// componets
import { Spinner } from 'react-bootstrap';
import { ClosePopup } from 'components/commonComponents/icons';
import { notify } from 'components/commonComponents/notification/notification';
import { PropertyDetail } from 'components/newMyProperty/utils/myProperty.interface';
import MyProperty from 'components/newMyProperty/container/myProperty';
// constants and functions
import englishText from 'languages/english';
import HttpService from 'services/http.service';
import { API_CONFIG } from 'services';
// assets
import customObjectImage from 'assets/images/customObject_image.png';
import '../myprop.scss';
import ModalComponent from 'components/commonComponents/modalComponent';

interface ICustomProps {
	closeAddProperty: () => void;
	getFavoCount?: () => void;
}

let Id = '';

const CustomProperties = (props: ICustomProps) => {
	const [show, setShow] = useState(false);
	const [list, setList] = useState([]);
	const [showSpinner, setSpinner] = useState(true);
	const [objectData, setData] = useState<PropertyDetail>();
	const [opened, setOpened] = useState(false);

	useEffect(() => {
		if (list.length === 0) {
			fetchList();
		}
		document.body.style.overflowY = 'hidden';
		return () => {
			document.body.style.overflowY = '';
		};
	}, [list.length]);

	// Fetch list of custom properties
	const fetchList = async () => {
		setSpinner(true);
		HttpService.get(API_CONFIG.path.draftListNewUserProperty)
			.then((response) => {
				setList(response.data);
				setSpinner(false);
			})
			.catch(() => {
				setSpinner(false);
			});
	};

	// Open any property to edit
	const openEdit = (index: number) => {
		setData(list[index]);
		setShow(true);
	};

	// Delete custom property from list
	const deleteObject = async () => {
		setSpinner(true);
		HttpService.deleteRequest(`${API_CONFIG.path.deleteNewUserProperty}/${Id}`)
			.then((response) => {
				notify(`${response.message}`, 'success');
				fetchList();
				setOpened(false);
			})
			.catch((err) => {
				console.log(err);
				setOpened(false);
				notify(`${err.response.data.message}`, 'error');
			});
	};

	// Close add property popup
	const closeModal = (isRefreshRequired: boolean) => {
		// setData();
		setShow(false);
		if (isRefreshRequired) {
			fetchList();
			setSpinner(true);
		}
	};
	const showPopup = (id: any) => {
		Id = id;
		setOpened(true);
	};

	const ClosedeleteModel = () => {
		setOpened(false);
		// document.body.style.overflowY = "hidden";
	};
	return (
		<>
			{!show && (
				<div className='custom-properties-div'>
					<div className='flex align__items--center justify__content--end '>
						<div className='text--right popup-close' onClick={() => props.closeAddProperty()}>
							<ClosePopup />
						</div>
					</div>

					<div className='custom-properties-wrapper'>
						<div className='section-title p-0 d-flex align-items-center justify-content-center w-100'>
							<h6 className='mb-2 ml-3 custom-properties-tittle'>
								MEINE IMMOBILIEN
								{showSpinner && (
									<div className='spinner-wrapper'>
										<Spinner animation='border' variant='info' />
									</div>
								)}
							</h6>
						</div>
						<div className={`properties-table mt-4 ${list.length > 5 && 'overflow-table'}`}>
							{list.length > 0 &&
								list.map((item: any, index: number) => {
									return (
										<div
											className='prop-list '
											key={index}
											style={{ animationDelay: `${index * 0.2}s` }}
										>
											<div className='custom-prop-item d-flex justify-content-between align__items--center '>
												<div className='custom-thumbnail'>
													<img
														src={
															item.adPicturesUrls && item.adPicturesUrls.length > 0
																? item.adPicturesUrls[0]
																: customObjectImage
														}
														alt={'img'}
													/>
												</div>
												{item.objectTypeSub && (
													<p className='custom-prop-tittle'>{item.objectTypeSub}</p>
												)}
												<div className='custom-prop-icons'>
													{/* Show object details button */}
													{item.status === 'Active' && (
														<a
															href={`/objekt/${item.objectId}`}
															target='_blank'
															rel='noopener noreferrer'
														>
															<i
																className='fa fa-eye'
																aria-hidden='true'
																title={getLocalizeText(englishText.SEE_DETAILS)}
															></i>
														</a>
													)}
													{/* Edit property button */}
													<i
														className='fa fa-pencil'
														aria-hidden='true'
														onClick={() => openEdit(index)}
														title={getLocalizeText(englishText.EDIT_PROPERTY)}
													></i>
													{/* Delete property button */}
													<i
														className='fa fa-trash'
														aria-hidden='true'
														// onClick={() => deleteObject(item.id)}
														onClick={() => showPopup(item.objectId)}
														title={getLocalizeText(englishText.DELETE_PROPERTY)}
													></i>
												</div>
											</div>
										</div>
									);
								})}
							{/* No custom property found */}
							{list.length === 0 && !showSpinner && (
								<p className='text-center w-100'>{getLocalizeText(englishText.NO_DATA)}</p>
							)}
						</div>

						{/* Add new property button */}
						<div className='w-100 text-center'>
							<button
								className='mt-4 add-prop-btn'
								onClick={() => {
									setShow(true);
									setData({} as PropertyDetail);
								}}
								title={getLocalizeText(englishText.ADD_PROPERTY)}
							>
								INSERAT ANLEGEN
							</button>
						</div>
					</div>
				</div>
			)}
			{opened && (
				<ModalComponent
					title={'Möchten Sie die Eigenschaft wirklich löschen?'}
					btnTextCancel={'Schließen'}
					closeModal={ClosedeleteModel}
					btnTextConform={'löschen'}
					handleYes={deleteObject}
					className={'delete-info-popup'}
				/>
			)}
			{show && <MyProperty closeModal={() => closeModal(true)} objectDetail={objectData} />}
		</>
	);
};

CustomProperties.propTypes = {
	closeAddProperty: PropTypes.func.isRequired,
	getFavoCount: PropTypes.func
};

export default CustomProperties;
