import React, { CSSProperties, FC } from "react";
import { Spinner } from "react-bootstrap";

type AnimationType = "grow" | "border";

interface ICustomSpinnerProps {
  animation: AnimationType;
  variant?: string;
  className?: string;
  style?: CSSProperties;
}

const CustomSpinner: FC<ICustomSpinnerProps> = (props) => {
  const { animation, variant, className, style } = props;
  return (
    <Spinner
      animation={animation}
      variant={variant || ""}
      className={className || ""}
      style={style || {}}
    />
  );
};

export default CustomSpinner;
