import React, { FC } from 'react';

interface IProps {
	backgroundImage: string;
	titleText: string;
}
const TitleImageSection: FC<IProps> = ({ backgroundImage, titleText }) => {
	return (
		<>
			<hr className='common-hr' />
			<div className='title-image-wrapper position-relative'>
				<div
					className='tittle-img flex justify__content--center'
					style={{ backgroundImage: `url(${backgroundImage})` }}
				>
					<div className='common-page-tittle-div position-absolute flex align__items--center justify__content--center'>
						<h1 className='text--center'>{titleText}</h1>
					</div>
				</div>
			</div>
			<section className='flex align__items--center justify__content--center '>
				<div className='common-title-background'></div>
			</section>
		</>
	);
};

export default TitleImageSection;
