import { CSSProperties } from "react";

export const formSelectStyles = {
  option: (base: CSSProperties, state: any) => ({
    ...base,
    color: state.isSelected ? "#FFB000" : "#2C344E",
    backgroundColor: state.isSelected
      ? "rgba(217, 217, 217, 0.37)"
      : !state.isSelected && state.isFocused
      ? "transparent"
      : "transparent",
    fontWeight: state.isSelected ? "700" : "400",
    cursor: "pointer",
    fontSize: "14px",
    lineHeight: 1,
    // width: "fit-content",
    borderRadius: "4px",
    padding: "9px 20px",
    margin: "5px 0px 5px 0px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    // margin: "0",
    ":active": {
      backgroundColor: "rgba(217, 217, 217, 0.37)",
      fontWeight: state.isSelected ? "700" : "400",
    },
    ":hover": {
      backgroundColor: !state.isSelected
        ? "rgba(217, 217, 217, 0.37)"
        : "rgba(217, 217, 217, 0.37)",
      opacity: 0.8,
      borderRadius: "4px",
      transition: "all 0.2s linear",
    },
    ":focus": {
      backgroundColor: "rgba(217, 217, 217, 0.37)",
      outline: 0,
      borderRadius: "4px",
      fontWeight: 700,
    },
  }),

  menu: (base: CSSProperties) => ({
    ...base,
    zIndex: 3,
    marginTop: "-1px",
    backgroundColor: "#FFF",
    border: "0.5px solid #AEAEAE",
    borderRadius: "8px",
    width: "100%",
    boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
    padding: "0 5px ",
    ".react-select__menu-list": {
      maxHeight: "157px",
    },
  }),

  valueContainer: (base: CSSProperties) => ({
    ...base,
    padding: "6px 10px",
  }),

  menuList: (base: CSSProperties) => ({
    ...base,
    padding: 0,
    backgroundColor: "#FFF",
  }),
  clearIndicator: (base: CSSProperties) => ({
    ...base,
    cursor: "pointer",
  }),
  dropdownIndicator: (base: CSSProperties, state: any) => ({
    ...base,
    cursor: "pointer",
    transition: "all 0.2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "none",
    color: state.selectProps.menuIsOpen && "#2C344E",
    padding: "0 16px ",

    ":hover": {
      color: "#2C344E",
    },
  }),
  indicatorSeparator: () => ({
    width: "0",
  }),
  control: (state: any) => ({
    // none of react-selects styles are passed to <View />
    display: "flex",
    border: "1px solid #AEAEAE",
    borderRadius: "6px",
    backgroundColor: "#FFF",
    width: "100%",
    height: "100%",
    minHeight: "38px",
    cursor: "pointer",
    fontSize: "14px",
  }),
  input: (base: CSSProperties, state: any) => ({
    ...base,
    color: "#2C344E",
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "23px",
  }),
  placeholder: (base: CSSProperties, state: any) => ({
    ...base,
    color: "#2C344E",
    fontSize: "15px",
    lineHeight: "23px",
    fontWeight: "400",
  }),
  singleValue: (base: CSSProperties, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      ...base,
      opacity: opacity,
      transition: transition,
      color: "#2C344E",
      fontSize: "15px",
      fontWeight: "400",
      lineHeight: "23px",
    };
  },
};

export const errorFormSelectStyles = {
  ...formSelectStyles,

  control: (state: any) => ({
    display: "flex",
    borderRadius: "6px",
    backgroundColor: "#FFF",
    width: "100%",
    height: "100%",
    minHeight: "38px",
    cursor: "pointer",
    fontSize: "14px",
    border: "1px solid red",
  }),
};
