import React, { FC, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputSelect from 'components/commonComponents/form/inputSelect';
import {
	generallyTabCheckBox,
	grundstückAccommodationOption,
	hausAccommodationOption,
	myPropertyRules,
	propertyOption,
	propertyTab,
	requiredFields,
	wohnungAccommodationOption
} from '../utils/constants';
import PropertyButtonDiv from './propertyButtonDiv';
import { CustomCheckBox } from 'components/commonComponents/form/checkBox/checkbox';
import { PropertyTabProps } from '../utils/myProperty.interface';
interface GenerallyTabForm {
	objectTypeSub: string;
	typePropertyUse: any;
}

const GenerallyTab: FC<PropertyTabProps> = ({ gotoBack, selectedValue, isLoading }) => {
	const {
		control,
		setValue,
		clearErrors,
		formState: { errors }
	} = useFormContext<GenerallyTabForm>();

	const [isCheckedBoxShow, setIsCheckedBoxShow] = useState(true);
	const checkBoxHideOption = ['Forst', 'Landwirtschaft', 'Grünland'];
	const getOptions = useCallback(() => {
		if (selectedValue === propertyOption.apartment) {
			return {
				option: wohnungAccommodationOption,
				label: 'Welche Art von Wohnung bieten Sie an?'
			};
		} else if (selectedValue === propertyOption.house) {
			return {
				option: hausAccommodationOption,
				label: 'Welche Art von Haus bieten Sie an?'
			};
		}
		return {
			option: grundstückAccommodationOption,
			label: 'Welche Art von Grundstück bieten Sie an?'
		};
	}, [selectedValue]);
	return (
		<div>
			<Controller
				name='objectTypeSub'
				control={control}
				rules={myPropertyRules.objectTypeSub}
				render={({ field: { ref, onChange, ...rest } }) => (
					<InputSelect
						label={getOptions().label}
						placeholder='Bitte wählen'
						options={getOptions().option}
						asterisk={requiredFields.includes('objectTypeSub')}
						onChange={(e: any) => {
							onChange(e);
							if (checkBoxHideOption.includes(e.value)) {
								setIsCheckedBoxShow(false);
							} else {
								setIsCheckedBoxShow(true);
							}
						}}
						error={errors.objectTypeSub}
						{...rest}
					/>
				)}
			/>
			{!isCheckedBoxShow && <div className='empty-div'></div>}
			{isCheckedBoxShow && (
				<div>
					<div className='flex align__items--center'>
						<label className='form-label'>Welche Art der Nutzung ist möglich?</label>
						<span className='form-asterisk'>&nbsp;*</span>
					</div>
					<div>
						<div className={'flex justify__content--between generally-tab-check-box'}>
							<Controller
								name='typePropertyUse'
								control={control}
								rules={myPropertyRules.typePropertyUse}
								render={({ field: { ref, ...rest } }) => {
									let fieldValue = rest.value || [];
									return (
										<>
											{generallyTabCheckBox.map((item, i) => (
												<CustomCheckBox
													{...rest}
													key={i}
													checked={fieldValue.includes(item.label)}
													label={item.label}
													customOnChange={(isChecked) => {
														if (fieldValue.includes(item.label) && !isChecked) {
															fieldValue = fieldValue.filter(
																(i: string) => i !== item.label
															);
														} else {
															fieldValue.push(item.label);
														}
														setValue(`typePropertyUse`, fieldValue);
														clearErrors('typePropertyUse');
													}}
												/>
											))}
										</>
									);
								}}
							/>
						</div>
						{errors && <span className='error-message'>{(errors as any).typePropertyUse?.message}</span>}
					</div>
				</div>
			)}
			<PropertyButtonDiv
				backButton={false}
				showSpinner={isLoading}
				goBack={() => gotoBack(propertyTab.addPropertyTab)}
			/>
		</div>
	);
};
export default GenerallyTab;
